import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "../../components/commons";
import { JournalEntryTable } from "../../components";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";

import "./style.scss";
import { JournalEntry } from "../../types/JournalEntryV2";
import {
  JournalEntryController,
  JournalEntryReducerAction,
} from "../../components/JournalEntryTable/JournalEntryFunctions";

const CustomJournalEntryModal = ({
  onHide,
  onAdd,
  loading,
  show,
  journalEntry,
  journalEntryDispatch,
  journalEntryController,
}: {
  onHide: () => void;
  onAdd: (account_entries: any) => void;
  loading: boolean;
  show: boolean;
  journalEntry: JournalEntry;
  journalEntryDispatch: React.Dispatch<JournalEntryReducerAction>;
  journalEntryController: JournalEntryController;
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (!journalEntry.lines?.every((line) => line.debit_fc || line.credit_fc))
      return;

    const account_entries = journalEntry.lines.slice(1).map((line) => ({
      line_id: line.line_id,
      account_code: line.account_code,
      amount: (line.debit_fc || 0) - (line.credit_fc || 0),
      currency: currencyCode,
      tax_code_id: line.tax_code_id,
      tax_base_amount: line.tax_base_amount_fc,
      tax_amount: line.tax_amount_fc,
      tax_line: line.tax_line,
      tax_for_line: line.tax_for_line,
      description: line.description,
      dimensions: [...(line.dimensions || [])].filter(Boolean),
    }));
    onAdd(account_entries);
  };

  const currencyCode = journalEntry?.lines?.[0]?.currency_code;

  const tableFooter = (
    <div className="function-buttons">
      <Button
        variant={BOOTSTRAP_VARIANTS.PRIMARY}
        text={t("reconcile.add")}
        onClick={handleClick}
      />
    </div>
  );

  return (
    <Modal
      className="custom-journal-entry-modal"
      show={show}
      loading={loading}
      size="xl"
      onHide={onHide}
      title={t("reconcile.custom_journal_entry")}
      draggable
    >
      <div className="content">
        <JournalEntryTable
          footer={tableFooter}
          journalEntry={journalEntry || {}}
          journalEntryDispatch={journalEntryDispatch}
          journalEntryController={journalEntryController}
        />
      </div>
    </Modal>
  );
};

export default CustomJournalEntryModal;
