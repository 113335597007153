import { useQuery, useMutation, useInfiniteQuery } from "react-query";

import { DEFAULT_PAGE_SIZE } from "../constants/common";

import {
  authenticatedApiClient,
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "./apiClients";

function handlePagination(
  data,
  isUsePaging = true,
  defaultPageSize = DEFAULT_PAGE_SIZE
) {
  if (!isUsePaging || !data) return data;

  const paginationObj = { ...data };

  const per_page = data.pageSize || defaultPageSize;
  const page = +(data.page || 1);

  delete paginationObj.pageSize;
  delete paginationObj.page;

  return {
    ...paginationObj,
    page,
    per_page,
  };
}

export function useGetPendingDocuments(
  params,
  expanded = 1,
  noLoading = false
) {
  // const method = this.Methods.GET;
  // const endpoint = `/posts/pending/?expanded=${expanded}`;

  const client_account_ids = params.client_account_ids;

  if (params.client_account_ids) {
    params.client_account_ids = params.client_account_ids.join(",");
  }

  return useQuery(
    ["getPendingDocuments", params, expanded],
    async () => {
      const res = await authenticatedApiClient.get(
        `/posts/pending/?expanded=${expanded}`,
        { params }
      );
      throwErrorByStatus(res);
      return res.data.res;
    },
    {
      enabled: client_account_ids && client_account_ids.length > 0,
      meta: { ignoreLoadingIndicator: noLoading },
    }
  );
}

export function useGetGlobalSearch(params, isUsePaging) {
  // const method = this.Methods.GET;
  // const endpoint = "/search/?expanded=1";

  return useQuery(["getGlobalSearch", params, isUsePaging], async () => {
    if (isUsePaging) {
      params = handlePagination(params);
    }
    const res = await authenticatedApiClient.get(`/search/?expanded=1`, {
      params,
    });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useSplitPdf() {
  // const method = this.Methods.GET;
  // const endpoint = `/files/split/${id}?list_of_pages=${pages_range}&expanded=1`

  return useMutation(async ({ id, splitPageNumber }) => {
    const res = await authenticatedApiClient.get(
      `/files/split/${id}?list_of_pages=${splitPageNumber}&expanded=1`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function getJournalEntriesReport(params) {
  return authenticatedApiClient.get(
    `/journal-entries/base-report/?expanded=1`,
    { params }
  );
}

export function useGetJournalEntriesReport(params, isUsePaging) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entries/base-report/?expanded=1";

  return useQuery(
    ["getJournalEntriesReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params, true, 100);
      }
      const res = await getJournalEntriesReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getGeneralLedgerReport(params) {
  return authenticatedApiClient.get(
    `/journal-entries/general-ledger-report/?expanded=1`,
    { params }
  );
}

export function useGetGeneralLedgerReport(params, isUsePaging) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entries/general-ledger-report/?expanded=1";

  return useQuery(
    ["getGeneralLedgerReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params, true, 100);
      }
      const res = await getGeneralLedgerReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getVatReport(params) {
  return authenticatedApiClient.get(`/journal-entries/vat-report/?expanded=1`, {
    params,
  });
}

export function useGetVatReport(params) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entries/vat-report/?expanded=1";

  return useQuery(
    ["getVatReport", params],
    async () => {
      const res = await getVatReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getAccountReceivableReport(params) {
  return authenticatedApiClient.get(
    `/journal-entries/accounts-receivable-report/?expanded=1`,
    { params }
  );
}

export function useGetAccountReceivableReport(params, isUsePaging) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entries/accounts-receivable-report/?expanded=1";

  return useQuery(
    ["getAccountReceivableReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params, true, 100);
      }
      const res = await getAccountReceivableReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getAccountPayableReport(params) {
  return authenticatedApiClient.get(
    `/journal-entries/accounts-payable-report/?expanded=1`,
    { params }
  );
}

export function useGetAccountPayableReport(params, isUsePaging) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entries/accounts-payable-report/?expanded=1";

  return useQuery(
    ["getAccountPayableReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params, true, 100);
      }
      const res = await getAccountPayableReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getBalanceSheetReport(params) {
  return authenticatedApiV2Client.get(`/reports/balance-sheet`, { params });
}

export function useGetBalanceSheetReport(params, isUsePaging) {
  return useQuery(
    ["getBalanceSheetReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params);
      }
      const res = await getBalanceSheetReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getTrialBalanceReport(params) {
  return authenticatedApiV2Client.get(`/reports/trial-balance`, { params });
}

export function useGetTrialBalanceReport(params, isUsePaging) {
  return useQuery(
    ["getTrialBalanceReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params);
      }
      const res = await getTrialBalanceReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function getIncomeStatementReport(params) {
  return authenticatedApiV2Client.get(`/reports/income-statement`, { params });
}

export function useGetIncomeStatementReport(params, isUsePaging) {
  return useQuery(
    ["getIncomeStatementReport", params, isUsePaging],
    async () => {
      if (isUsePaging) {
        params = handlePagination(params);
      }
      const res = await getIncomeStatementReport(params);
      throwErrorByStatus(res);
      return res.data;
    },
    {
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useGetBankTransactionsV1(
  clientId,
  bankAccountId,
  includeReconciled = false
) {
  return useQuery(
    ["getBankTransactions", clientId, bankAccountId],
    async () => {
      const res = await authenticatedApiClient.get(`/bank-transactions/`, {
        params: {
          client_account_id: clientId,
          bank_account_id: bankAccountId,
        },
      });
      throwErrorByStatus(res);
      return res.data?.bank_transactions;
    },
    { enabled: !!clientId && !!bankAccountId }
  );
}

export function useGetBankTransactions(params) {
  return useQuery(
    ["getBankTransactions", params],
    async () => {
      const res = await authenticatedApiV2Client.get(`/bank-transactions/`, {
        params,
      });
      throwErrorByStatus(res);
      return res.data?.data;
    },
    { enabled: !!params }
  );
}

export function useInfiniteGetBankTransactions(params) {
  return useInfiniteQuery(
    ["getBankTransactions", params],
    async ({ pageParam = 1 }) => {
      const res = await authenticatedApiV2Client.get(`/bank-transactions/`, {
        params: {
          ...params,
          page: pageParam,
        },
      });
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.meta?.page < lastPage?.meta?.pages
          ? lastPage?.meta?.page + 1
          : undefined;
      },
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useCreateBankTransaction() {
  // const method = this.Methods.POST;
  // const endpoint = "/payments/";

  return useMutation(async (data) => {
    const res = await authenticatedApiV2Client.post(`/bank-transactions`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetDocumentSuggestions(
  client_account_id,
  bankTransactionId,
  docType,
  currency,
  exchange_rate,
  amount
) {
  // const method = this.Methods.GET;
  // const endpoint = "/document-suggestions/";
  const params = {
    bank_transaction_id: bankTransactionId,
    document_type: docType,
    currency,
    exchange_rate,
    amount,
    client_account_id,
  };

  return useQuery(
    ["getDocumentSuggestions", params],
    async () => {
      const res = await authenticatedApiClient.get(`/document-suggestions/`, {
        params,
      });
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled:
        !!client_account_id &&
        !!bankTransactionId &&
        !!currency &&
        !!amount &&
        !!exchange_rate,
    }
  );
}

export function useGetAccountBalances(
  bankAccountId,
  fromDate = null,
  toDate = null
) {
  // const method = this.Methods.GET;
  // const endpoint = `/account-balances/${bankAccountId}`;

  const params = {
    date: fromDate || `2000-01-01`,
    to_date: toDate || new Date().toISOString().substring(0, 10),
  };

  return useQuery(
    ["getAccountBalances", bankAccountId, params],
    async () => {
      const res = await authenticatedApiClient.get(
        `/account-balances/${bankAccountId}`,
        { params }
      );
      throwErrorByStatus(res);
      const data = {};
      res.data.account_balances.forEach((r) => {
        data[r.date] = {
          currency: res.data.currency,
          bank_account_id: res.data.bank_account_id,
          ...r,
        };
      });
      return data;
    },
    { enabled: !!bankAccountId }
  );
}

export function useCreatePayment() {
  // const method = this.Methods.POST;
  // const endpoint = "/payments/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/payments/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreatePaymentRequest() {
  // const method = this.Methods.POST;
  // const endpoint = "/payment-requests/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/payment-requests/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateExternalReconciliation() {
  // const method = this.Methods.POST;
  // const endpoint = "/payments/";

  return useMutation(async (data) => {
    const res = await authenticatedApiV2Client.post(
      `/external-reconciliations/`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useSearchDocuments(bankTransactionId, partnerName) {
  // const method = this.Methods.GET;

  const query = [];

  if (bankTransactionId) {
    query.push(`bank_transaction_id=${bankTransactionId}`);
  }

  if (partnerName) {
    query.push(`name=${partnerName}`);
  }

  // const endpoint = `/documents-bank-suggestions/?${query.join("&")}`;

  return useQuery(
    ["searchDocuments", bankTransactionId, partnerName],
    async () => {
      const res = await authenticatedApiClient.get(
        `/documents-bank-suggestions/?${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    }
  );
}

export function useGetJournalEntries(params) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entries/";

  return useQuery(
    ["getJournalEntries", params],
    async () => {
      const res = await authenticatedApiClient.get(`/journal-entries/`, {
        params,
      });
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!params }
  );
}

export function useCreateJournalEntry() {
  // const method = this.Methods.POST;
  // const endpoint = "/journal-entries/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/journal-entries/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetJournalEntryLinesV1(params) {
  // const method = this.Methods.GET;
  // const endpoint = "/journal-entry-lines/";

  return useQuery(
    ["getJournalEntryLinesV1", params],
    async () => {
      const res = await authenticatedApiClient.get(`/journal-entry-lines/`, {
        params,
      });
      throwErrorByStatus(res);
      return res.data.journal_entry_lines;
    },
    {
      enabled: !!params,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useGetJournalEntryLines(params) {
  return useQuery(
    ["getJournalEntryLines", params],
    async () => {
      const res = await authenticatedApiV2Client.get(`/journal-entry-lines/`, {
        params,
      });
      throwErrorByStatus(res);
      return res.data?.data;
    },
    { enabled: !!params }
  );
}

export function useInfiniteGetJournalEntryLines(params) {
  return useInfiniteQuery(
    ["getJournalEntryLines", params],
    async ({ pageParam = 1 }) => {
      const res = await authenticatedApiV2Client.get(`/journal-entry-lines/`, {
        params: {
          ...params,
          page: pageParam,
        },
      });
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!params,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.meta?.page < lastPage?.meta?.pages
          ? lastPage?.meta?.page + 1
          : undefined;
      },
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useGetReconciliations(params) {
  // const method = this.Methods.GET;
  // const endpoint = "/reconciliations/";

  return useQuery(["getReconciliations", params], async () => {
    const res = await authenticatedApiClient.get(`/reconciliations/`, {
      params,
    });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateReconciliation() {
  // const method = this.Methods.POST;
  // const endpoint = "/reconciliations/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/reconciliations/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetDimensionValues(clientAccount) {
  return useQuery(
    ["dimension-values", { client_account_id: clientAccount?.id }],
    async () => {
      const res = await authenticatedApiClient.get("/dimensions/-/values", {
        params: { client_account_id: clientAccount.id },
      });
      return res.data;
    },
    {
      enabled: !!clientAccount,
      staleTime: 60000,
    }
  );
}
