import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, GridView, Modal, Text } from "..";
import {
  useGetCurrentUser,
  useGetDimensionValues,
  useGetUser,
} from "../../../api";
import {
  authenticatedApiClient,
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "../../../api/apiClients";
import { BOOTSTRAP_VARIANTS } from "../../../constants";
import { DimensionValue } from "../../../types/JournalEntry";
import { JournalEntry, JournalEntryLine } from "../../../types/JournalEntryV2";
import { Pages } from "../../../types/PagesV2";
import { currencyFormat } from "../../../utils/common";
import { ClientAccountContext } from "../../../views/App";

import "./style.scss";
import { useReverseJournalEntry } from "../../../api/accountingApi";
import { IconLabel, IconVioletLabel } from "../../../assests/icons";
import getLabelAbbreviation from "../../../constants/labelShortcuts";

const JournalEntryDetailsPopup = (props: {
  journalEntryId?: number;
  show: boolean;
  onHide: (changes: boolean) => void;
}) => {
  const { journalEntryId, show, onHide } = props;

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const { t } = useTranslation();

  // State
  const [mutationsHappened, setMutationsHappened] = useState(false);

  // Queries
  const queryClient = useQueryClient();
  const journalEntryQuery = useQuery(
    [
      "journal-entries",
      {
        client_account_id: clientAccount?.id,
        journal_entry_id: journalEntryId,
      },
    ],
    async () => {
      if (!journalEntryId) return;
      const res = await authenticatedApiV2Client.get<JournalEntry>(
        `/journal-entries/${journalEntryId}`,
        {
          params: {
            with: [
              "created_by",
              "updated_by",
              "lines.dimensions",
              "lines.account.mandatory_dimensions",
            ].join(","),
          },
        }
      );
      if (!res.data) onHide(true);
      return res.data;
    },
    {
      meta: { ignoreLoadingIndicator: true },
    }
  );

  const dimensionValuesQuery = useGetDimensionValues(clientAccount);

  const { data: cancelledByUser } = useGetUser(
    journalEntryQuery.data?.cancelled_by_id
  );

  const journalEntryReverseMutation = useReverseJournalEntry();

  const reverseJournalEntry = (je: JournalEntry) => {
    if (!window.confirm(t("Do you want to reverse this posting?"))) return;

    journalEntryReverseMutation.mutate(je.id!, {
      onSuccess: () => {
        setMutationsHappened(true);
        queryClient.invalidateQueries(["journal-entries"]);

        // Get any referenced external reconciliations
        authenticatedApiV2Client
          .get(`/external-reconciliations`, {
            params: {
              client_account_id: clientAccount.id,
              relation_type: "JE",
              relation_id: je.id,
            },
          })
          .then((response) => {
            if (response?.data?.data) {
              // Delete referenced external reconciliations
              const externalReconciliationId = response?.data?.data[0].id;
              authenticatedApiV2Client.delete(
                `/external-reconciliations/${externalReconciliationId}`
              );
            }
          });
      },
    });
  };

  const postingDateCell = (jel: JournalEntryLine) => (
    <Text as="span">{jel.posting_date || ""}</Text>
  );

  const descriptionCell = (jel: JournalEntryLine) => {
    const lineMandatoryDimensions =
      jel.dimensions
        ?.filter(
          (d) =>
            d.dimension_id &&
            (
              jel.account?.mandatory_dimensions.map((md) => md.id) || []
            ).includes(d.dimension_id)
        )
        .map((d) => `${d.relation_type}:${d.relation_id}`) || [];
    const lineMandatoryDimensionNames =
      dimensionValuesQuery.data?.dimension_values
        ?.filter((dv: DimensionValue) =>
          lineMandatoryDimensions.includes(
            `${dv.relation_type}:${dv.relation_id}`
          )
        )
        .map((d: DimensionValue) => d.relation_name) || [];

    return (
      <>
        {[jel.account?.description, ...lineMandatoryDimensionNames].join(" - ")}
        {jel.description ? (
          <>
            <br />
            <small style={{ opacity: "50%" }}>{jel.description}</small>
          </>
        ) : null}
      </>
    );
  };

  const lineAmountCell = (jel: JournalEntryLine) => (
    <Text as="span">
      {debitAmountCell(
        (jel.debit || 0) - (jel.credit || 0),
        (jel.debit_fc || 0) - (jel.credit_fc || 0),
        jel.currency_code
      )}
    </Text>
  );

  const taxAmountCell = (jel: JournalEntryLine) => (
    <Text as="span">
      {debitAmountCell(
        jel.tax_amount || 0,
        jel.tax_amount_fc || 0,
        jel.currency_code
      )}
    </Text>
  );

  const debitAmountCell = (
    amount_lc: number,
    amount_fc: number,
    currency_code: string
  ) => {
    return amount_lc ? (
      <>
        {currencyFormat(amount_lc, clientAccount.accounting_currency)}
        {amount_fc ? (
          <>
            <br />
            <small style={{ opacity: "50%" }}>
              {currencyFormat(amount_fc, currency_code)}
            </small>
          </>
        ) : null}
      </>
    ) : (
      ""
    );
  };

  const getDimensionValue = (relation_type: string, relation_id: number) => {
    return dimensionValuesQuery.data?.dimension_values?.find(
      (l: any) =>
        l?.relation_type === relation_type && l?.relation_id === relation_id
    );
  };

  const dimensionsPopoverText = (jel: JournalEntryLine) => {
    let text = "";
    if (!jel?.dimensions?.length) return text;

    jel.dimensions.forEach(({ relation_type, relation_id }) => {
      if (relation_type) {
        const dim = getDimensionValue(relation_type, relation_id);
        text = text.concat(
          `<div>${getLabelAbbreviation(relation_type) || relation_type} - ${
            dim?.relation_name
          }</div>`
        );
      }
    });
    return text;
  };

  const dimensionsCell = (jel: JournalEntryLine) => (
    <div className="label-wrap" role="button">
      {jel.dimensions?.length ? (
        <Text
          as="span"
          placement="top"
          popover
          popoverText={dimensionsPopoverText(jel)}
          whitePopoverBackground
        >
          {jel.dimensions.length} <IconVioletLabel />
        </Text>
      ) : (
        <span>
          0 <IconLabel />
        </span>
      )}
    </div>
  );

  const journalEntry = journalEntryQuery.data;

  return (
    <Modal
      className="journal-entry-details-modal"
      show={show}
      size="lg"
      onHide={() => onHide(mutationsHappened)}
      title={`${t("Journal Entry")} #${journalEntry?.sequence_number} ${
        journalEntry?.is_draft ? t("(Draft)") : ""
      }`}
    >
      <div className="content">
        {journalEntry && (
          <Container key={journalEntry.id} fluid>
            <Row>
              <Col>{journalEntry.description}</Col>
              <Col md="3" style={{ textAlign: "end" }}>
                <Button
                  className="float-right"
                  text={t("Reverse")}
                  variant={BOOTSTRAP_VARIANTS.OUTLINE_DANGER}
                  size="sm"
                  onClick={() => reverseJournalEntry(journalEntry)}
                  disabled={["Y", "C"].includes(journalEntry.cancelled || "")}
                />
              </Col>
            </Row>
            <GridView
              columns={[
                {
                  Header: t("#"),
                  accessor: "line_id",
                  align: "left",
                  maxWidth: 50,
                },
                {
                  Header: t("Date"),
                  accessor: "posting_date",
                  align: "left",
                  maxWidth: 110,
                  Cell: (cellProps) => postingDateCell(cellProps.row.original),
                },
                {
                  Header: t("G/L Account"),
                  accessor: "account_code",
                  align: "left",
                  maxWidth: 80,
                },
                {
                  Header: t("Description"),
                  accessor: "description",
                  align: "left",
                  maxWidth: 300,
                  Cell: (cellProps) => descriptionCell(cellProps.row.original),
                },
                {
                  Header: t("Tax Code"),
                  accessor: "tax_code",
                  align: "left",
                  maxWidth: 40,
                },
                {
                  Header: t("Tax Am"),
                  accessor: "tax_amount",
                  align: "right",
                  maxWidth: 150,
                  Cell: (cellProps) => taxAmountCell(cellProps.row.original),
                },
                {
                  Header: t("Amount"),
                  accessor: "debit_amount_fc",
                  align: "right",
                  maxWidth: 150,
                  Cell: (cellProps) => lineAmountCell(cellProps.row.original),
                },
                {
                  accessor: "dimensions",
                  align: "right",
                  maxWidth: 60,
                  Cell: (cellProps) => dimensionsCell(cellProps.row.original),
                },
              ]}
              data={journalEntry?.lines || []}
            />
            <Text>
              {t("Created at {{date}} by {{user}}", {
                date: moment(journalEntry?.created_at).format("YYYY-MM-DD"),
                user: !!journalEntry.created_by
                  ? `${journalEntry.created_by.first_name} ${journalEntry.created_by.last_name}`
                  : journalEntry?.created_by_id,
              })}
              <br />
              {journalEntry.cancelled === "Y" &&
                t("Cancelled at {{date}} by {{user}}", {
                  date: moment(
                    journalEntry?.updated_at || journalEntry?.created_at
                  ).format("YYYY-MM-DD"),
                  user: !!cancelledByUser
                    ? `${cancelledByUser.first_name} ${cancelledByUser.last_name}`
                    : journalEntry?.cancelled_by_id,
                })}
              {journalEntry.cancelled === "C" &&
                t("J/E is a cancellation entry", {
                  date: moment(
                    journalEntry?.updated_at || journalEntry?.created_at
                  ).format("YYYY-MM-DD"),
                  user: !!cancelledByUser
                    ? `${cancelledByUser.first_name} ${cancelledByUser.last_name}`
                    : journalEntry?.cancelled_by_id,
                })}
            </Text>
          </Container>
        )}
      </div>
    </Modal>
  );
};

export default JournalEntryDetailsPopup;
