import React, { memo, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { I18nextProvider } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ForgotPassword,
  Login,
  NotFound,
  ResetPassword,
} from ".";
import { ProtectedRoute } from "../components/commons";

import { pathKeys } from "../constants";
import i18next from "../translations/i18next";
import { ClientAccount } from "../types/ClientAccount";
import PreviewView from "./Preview";
import ClientContainerWithPreview from "./ClientContainerWithPreview";
import Pusher from "pusher-js";
import Signup from "./Signup";
import VerifyEmail from "./VerifyEmail";
import { useUserAccounts } from "../dataHooks/user";
import { FullScreenLoader } from "../components/FullScreenLoader/FullScreenLoader";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "-", {
  cluster: "eu",
});

const RedirectToClientAccounts = () => {
  const navigate = useNavigate();
  const clients = useUserAccounts();

  useEffect(() => {
    if (clients.length) {
      navigate(`${pathKeys.CLIENT_ACCOUNTS}/${clients[0].id}/overview`);
    }
  }, [clients]);


  return <FullScreenLoader />;
}


const App = () => {
  const renderScrollBarsTrackHorizontal = (props: any) => (
    <div {...props} className="track-horizontal" style={{ display: "none" }} />
  );
  const renderScrollBarsThumbHorizontal = (props: any) => (
    <div {...props} className="thumb-horizontal" style={{ display: "none" }} />
  );

  return (
    <I18nextProvider i18n={i18next}>
      <PusherContext.Provider value={pusher}>
        <div className="main">
          <div className="content-wrapper">
            <Scrollbars
              renderTrackHorizontal={renderScrollBarsTrackHorizontal}
              renderThumbHorizontal={renderScrollBarsThumbHorizontal}
              autoHide
            >
              <SentryRoutes>
              <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <RedirectToClientAccounts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/clients"
                  element={
                    <ProtectedRoute>
                      <RedirectToClientAccounts />
                    </ProtectedRoute>
                  }
                />
                <Route path={pathKeys.LOGIN} element={<Login />} />
                <Route path={pathKeys.SIGNUP} element={<Signup />} />
                <Route
                  path={pathKeys.VERIFY_IDENTITY}
                  element={<VerifyEmail />}
                />
                <Route
                  path={pathKeys.FORGOT_PASSWORD}
                  element={<ForgotPassword />}
                />
                <Route
                  path={pathKeys.RESET_PASSWORD}
                  element={<ResetPassword />}
                />
                <Route
                  path={`/preview`}
                  element={
                    <ProtectedRoute>
                      <PreviewView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${pathKeys.CLIENT_ACCOUNTS}/:clientId/*`}
                  element={
                    <ProtectedRoute>
                      <ClientContainerWithPreview />
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                  path={`${pathKeys.CLIENT_ACCOUNTS}/*`}
                  element={
                    <ProtectedRoute>
                      <AccountantContainer />
                    </ProtectedRoute>
                  }
                /> */}
                <Route path="*" element={<NotFound />} />
              </SentryRoutes>
            </Scrollbars>
          </div>
        </div>
        <ToastContainer />
      </PusherContext.Provider>
    </I18nextProvider>
  );
};

export const PusherContext = React.createContext<Pusher>(pusher);

export const ClientAccountContext = React.createContext<ClientAccount>(
  {} as ClientAccount
);

export default memo(Sentry.withProfiler(App));
