import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'

import { CheckBox, Select, DefaultLogo } from '../index'

import './style.scss'

// FIXME improve code (nested loops)
const CustomSelect = ({
  recipients,
  preSelectedRecipients,
  loading,
  withCompanies,
  className,
  onSelect,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const [companies, setCompanies] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])

  useEffect(() => {
    setSelectedRecipients(preSelectedRecipients)
  }, [preSelectedRecipients])
  
  useEffect(() => {
    if (withCompanies) {
      let companiesList = []
      recipients.forEach(({ companies }) => companiesList.push(...companies))
      // remove company duplicates, add companyUsers array and isCompany flag
      companiesList = companiesList
        .filter((company, index, self) => self.findIndex(el => (el.id === company.id)) === index)
        .map(el => {
          const companyUsers = recipients.reduce((res, user) => {
            if (user.companies.some(({ id }) => id === el.id)) {
              res.push(user)
            }
            return res
          }, [])
          return { ...el, companyUsers, isCompany: true }
        })
      setCompanies(companiesList)
    }
  }, [recipients])

  useEffect(() => {
    onSelect(selectedRecipients.filter(({ isCompany }) => !isCompany))
  }, [selectedRecipients])

  const handleOnChange = (options, e) => {
    if (!options || e.action === 'clear') {
      setSelectedRecipients([])
      return
    }
    if (!withCompanies) {
      setSelectedRecipients(options)
      return
    }
    if (e.action === 'select-option') {
      const companyUsersArr = options.reduce((res, { isCompany, companyUsers }) => {
        if (isCompany) {
          res.push(...companyUsers)
        }
        return res
      }, [])

      // auto select company if all companyUsers are selected (without duplicates)
      const autoFillCompanies = companies.reduce((res, company) => {
        if (company.companyUsers.every(el => options.includes(el)) && !options.includes(company)) {
          res.push(company)
        }
        return res
      }, [])

      let newSelectedRecipients = [...companyUsersArr, ...autoFillCompanies, ...options]
      // remove recipients duplicates
      newSelectedRecipients = newSelectedRecipients.filter((option, index, self) =>
        option.isCompany || self.findIndex(el => !el.isCompany && (el.id === option.id)) === index)

      setSelectedRecipients(newSelectedRecipients)
    } else {
      // auto remove company if not all companyUsers are selected and auto remove users if company is deselected
      const newSelectedRecipients = options.filter(el => el.isCompany
        ? el.companyUsers.every(user => options.includes(user))
        : el.companies.some(({ id }) => options.filter(({ isCompany }) => isCompany).map(({ id }) => id).includes(id)))

      setSelectedRecipients(newSelectedRecipients)
    }
  }

  const isOptionSelected = item => selectedRecipients?.includes(item)

  const Option = props => (
    <components.Option
      {...props}
      className={cn('custom-option', { selected: isOptionSelected(props.data), 'is-company': props.data.isCompany })}
    >
      <CheckBox checked={isOptionSelected(props.data)} onClick={() => props.selectOption(props.data)} />
      {props.data.isCompany ? (
        <>
          <span className="name">{props.data.name}</span>
          <span className="light-text">{`(${t('messages.anyone')})`}</span>
        </>
      ) : (
        <>
          <DefaultLogo size="sm" title={props.data.first_name} />
          <span className="name">{`${props.data.first_name} ${props.data.last_name}`}</span>
          {withCompanies && (
            <span className="light-text">{`(${props.data?.companies.map(el => el.name).join(', ')})`}</span>
          )}
        </>
      )}
    </components.Option>
  )

  const MultiValue = props => (
      <components.MultiValue
        {...props}
        className={cn('selected-multi-value', { hide: selectedRecipients.some(({ companyUsers }) =>
            companyUsers && companyUsers.includes(props.data)) })}
      >
      {props.data.isCompany ? (
        <>
          <span>{props.data.name}</span>
          <span className="light-text">{`(${t('messages.anyone')})`}</span>
        </>
      ) : (
        <>
          <DefaultLogo size="sm" title={props.data.first_name} />
          <span>{`${props.data.first_name} ${props.data.last_name}`}</span>
        </>
      )}
    </components.MultiValue>
  )

  return (
    <div className={cn('recipients-select', className || '')}>
      <Select
        loading={loading}
        placeholder={t('common_components.input.placeholder_search_or_select')}
        components={{ Option, MultiValue }}
        options={[...companies, ...recipients]}
        value={selectedRecipients}
        onChange={handleOnChange}
        getOptionValue={({ id, name, first_name, last_name }) => `${id} ${name} ${first_name} ${last_name}`}
        isOptionSelected={isOptionSelected}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isSearchable
        isClearable
        isMulti
        {...otherProps}
      />
    </div>
  )
}

CustomSelect.propTypes = {
  recipients: PropTypes.array,
  preSelectedRecipients: PropTypes.array,
  loading: PropTypes.bool,
  withCompanies: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
}

CustomSelect.defaultProps = {
  recipients: [],
  preSelectedRecipients: [],
  loading: false,
  withCompanies: false,
  className: '',
  onSelect: () => {},
}

export default memo(CustomSelect)
