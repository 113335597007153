import React, { useEffect, useState, memo } from "react";
import moment from "moment";

import { CurrencyInput, Input } from "../index";
import { currencyFormat } from "../../../utils/common";
import { useGetExchangeRates } from "../../../api";

import "./style.scss";

const CurrencyExchange = ({
  baseAmount,
  baseCurrencyCode,
  exchangeRate,
  rateDate = moment().format("YYYY-MM-DD"),
  counterAmount,
  counterCurrencyCode,
  accountingCurrencyCode,
  onChange,
}) => {
  const [currentAmount, setCurrentAmount] = useState(
    baseAmount ? Math.sign(counterAmount) * Math.abs(baseAmount) : counterAmount
  );
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(
    baseCurrencyCode || accountingCurrencyCode
  );
  const [currentExchangeRate, setCurrentExchangeRate] = useState(
    exchangeRate || 1
  );

  const { data } = useGetExchangeRates({
    base_currency: currentCurrencyCode,
    counter_currency: counterCurrencyCode,
    date: rateDate || moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    changeCurrentAmount(baseAmount || counterAmount);
    setCurrentCurrencyCode(baseCurrencyCode || accountingCurrencyCode);
    if (!baseAmount) changeCurrentExchangeRate(exchangeRate || 1);
  }, [baseAmount, baseCurrencyCode, exchangeRate]);

  const changeCurrentAmount = (amount) => {
    const signedAmount = Math.sign(counterAmount) * Math.abs(amount);
    setCurrentAmount(signedAmount);
    setCurrentExchangeRate(counterAmount / signedAmount);
  };

  const changeCurrentExchangeRate = (rate) => {
    setCurrentAmount(counterAmount / rate);
  };

  useEffect(() => {
    onChange({
      baseAmount: currentAmount,
      baseCurrencyCode: currentCurrencyCode,
      exchangeRate: currentExchangeRate,
      counterAmount: counterAmount,
      counterCurrencyCode: counterCurrencyCode,
    });
  }, [currentAmount, currentCurrencyCode, currentExchangeRate]);

  return (
    <div className="currency-exchange">
      <CurrencyInput
        size="md"
        currencyCode={currentCurrencyCode}
        onCurrencyCodeChange={setCurrentCurrencyCode}
        value={currentAmount}
        onChange={changeCurrentAmount}
      />
      <div>X</div>
      <Input
        size="md"
        value={currentExchangeRate}
        onChange={changeCurrentExchangeRate}
      />
      <div>=</div>
      <div className="result-value">
        {currencyFormat(counterAmount, counterCurrencyCode)}
      </div>
    </div>
  );
};

export default memo(CurrencyExchange);
