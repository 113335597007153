import moment from "moment";
import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { authenticatedApiClient } from "../../../api/apiClients";
import { CurrencyInput } from "../../../components/commons";
import { currencyFormat } from "../../../utils/common";

import "./style.scss";

const CurrencyField = (props) => {
  const {
    currencyCode,
    onCurrencyCodeChange,
    value,
    onChange,
    label,
    disabled,
    accountingCurrencyCode,
    rateDate,
    tabIndex,
  } = props;

  const { t } = useTranslation();
  const [amount, setAmount] = useState(value);

  useEffect(() => {
    setAmount(value);
  }, [value]);

  // Queries
  const exchangeRateQuery = useQuery(
    [
      "exchange-rates",
      {
        base_currency: currencyCode,
        counter_currency: accountingCurrencyCode,
        date: rateDate,
      },
    ],
    async () => {
      if (currencyCode === accountingCurrencyCode) return 1;

      const res = await authenticatedApiClient.get("/exchange-rates/", {
        params: {
          base_currency: currencyCode,
          counter_currency: accountingCurrencyCode,
          date: moment(rateDate).format("YYYY-MM-DD"),
        },
      });
      return res.data.exchange_rate;
    },
    { enabled: !!currencyCode && !!accountingCurrencyCode && !!rateDate }
  );

  const localOnChange = (a) => {
    onChange && onChange(a);
  };

  return (
    <div className="input-control">
      <CurrencyInput
        size="md"
        label={label}
        currencyCode={currencyCode}
        onCurrencyCodeChange={onCurrencyCodeChange}
        value={value}
        onChange={localOnChange}
        isCheckValid
        disabled={disabled}
        tabIndex={tabIndex}
      />
      {!exchangeRateQuery.isLoading &&
        exchangeRateQuery.data?.rate &&
        accountingCurrencyCode &&
        currencyCode &&
        accountingCurrencyCode !== currencyCode && (
          <div className="currency-converter">
            {`${amount} ${currencyCode} X ${
              exchangeRateQuery.data?.rate
            } = ${currencyFormat(
              amount * exchangeRateQuery.data?.rate,
              accountingCurrencyCode
            )}`}
          </div>
        )}
    </div>
  );
};

export default memo(CurrencyField);
