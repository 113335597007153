import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconGreenQuestions } from "../../assests/icons";
import { Expandable } from "../../components/commons";
import "./style.scss";

const AccountingQuestionsField = ({ accountingQuestions, isLoading }) => {
  const { t } = useTranslation();

  const getIsQuestionAnswered = (accountingQuestion) => {
    if (accountingQuestion.answer_options) {
      const answeredOption = (accountingQuestion.answer_options || []).find(
        (i) => i.selected_answer
      );
      if (answeredOption) {
        return true;
      }
    }
    return false;
  };

  const getAccountingQuestionAnswer = (accountingQuestion) => {
    if (accountingQuestion.answer_options) {
      const answeredOption = (accountingQuestion.answer_options || []).find(
        (i) => i.selected_answer
      );
      if (accountingQuestion.answer_action === "STATEMENT") {
        if (answeredOption)
          return <span>{t("post_detail.statement_provided")}</span>;
        else return <span>{t("post_detail.statement_waiting")}</span>;
      } else if (answeredOption) {
        return <span>{answeredOption.option}</span>;
      }
    }
    return " -";
  };

  return (
    <div className="questions-to-client">
      <Expandable
        contentClassName="padding accounting-question-items"
        title={
          isLoading
            ? t("app.loadingTitle")
            : `${t("post_detail.questions_to_client")} (${
                accountingQuestions?.length
              })`
        }
        headerIcon={<IconGreenQuestions />}
        defaultActiveKey="1"
      >
        {!isLoading &&
          accountingQuestions?.map((accountingQuestion) => (
            <div
              key={accountingQuestion.id}
              className="accounting-question-item"
            >
              <div className="title">{accountingQuestion.question}</div>
              <div className="answer">
                {`${t("post_detail.answer_status")}: `}
                {getAccountingQuestionAnswer(accountingQuestion)}
              </div>
              <div
                className={cn("answer-status", {
                  "is-open": !getIsQuestionAnswered(accountingQuestion),
                })}
              >
                {!getIsQuestionAnswered(accountingQuestion)
                  ? t("post_detail.unanswered_status")
                  : t("post_detail.answered_status")}
              </div>
            </div>
          ))}
      </Expandable>
    </div>
  );
};

export default AccountingQuestionsField;
