import moment from "moment";
import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Button, GridView, Modal, Text } from "../";
import { authenticatedApiV2Client } from "../../../api/apiClients";
import { BOOTSTRAP_VARIANTS, pathKeys } from "../../../constants";
import {
  InternalReconciliation,
  InternalReconciliationEntry,
} from "../../../types/InternalReconciliation";
import { currencyFormat } from "../../../utils/common";
import { ClientAccountContext } from "../../../views/App";
import "./style.scss";

const ReconciliationDetailsPopup = (props: {
  journalEntryLineId?: number;
  show: boolean;
  onHide: (changes: boolean) => void;
}) => {
  const { journalEntryLineId, show, onHide } = props;

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const { clientId } = useParams();

  const { t } = useTranslation();

  // State
  const [mutationsHappened, setMutationsHappened] = useState(false);

  // Queries
  const queryClient = useQueryClient();
  const reconciliationsQuery = useQuery(
    [
      "reconciliations",
      {
        client_account_id: clientAccount?.id,
        journal_entry_line_id: journalEntryLineId,
      },
    ],
    async () => {
      if (!journalEntryLineId) return;
      const res = await authenticatedApiV2Client.get("/reconciliations", {
        params: {
          client_account_id: clientAccount?.id,
          journal_entry_line_id: journalEntryLineId,
          with: "entries.journal_entry_line, entries.journal_entry_line.journal_entry",
        },
      });
      if (!res.data?.data?.length) onHide(true);
      return res.data;
    }
  );

  const reconciliationsDeleteMutation = useMutation(
    (re: InternalReconciliation) => {
      return authenticatedApiV2Client.delete(`/reconciliations/${re.id}`);
    },
    {
      onSuccess: () => {
        setMutationsHappened(true);
        queryClient.invalidateQueries(["reconciliations"]);
      },
    }
  );

  const openVoucherInNewWindow = (voucher_id: number) => {
    if (voucher_id) {
      const win = window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}/${voucher_id}`,
        "_blank"
      );
    }
  };

  const deleteReconciliation = (re: InternalReconciliation) => {
    if (!window.confirm("Vil du virkelig slette avstemmingen?")) return;

    reconciliationsDeleteMutation.mutate(re);
  };

  const postingDateCell = (re: InternalReconciliationEntry) => (
    <Text
      as="span"
      className={
        re.journal_entry_line_id === journalEntryLineId ? "current-line" : ""
      }
    >
      {re.journal_entry_line?.posting_date || ""}
    </Text>
  );

  const sequenceNumberCell = (re: InternalReconciliationEntry) => (
    <Text
      as="a"
      className={
        re.journal_entry_line_id === journalEntryLineId ? "current-line" : ""
      }
      onClick={() =>
        openVoucherInNewWindow(
          (re.journal_entry_line.journal_entry as any).voucher_id
        )
      }
    >
      {re.journal_entry_line?.journal_entry?.sequence_number || ""}
    </Text>
  );

  const lineIdCell = (re: InternalReconciliationEntry) => (
    <Text
      as="span"
      className={
        re.journal_entry_line_id === journalEntryLineId ? "current-line" : ""
      }
    >
      {re.journal_entry_line?.line_id || ""}
    </Text>
  );

  const descriptionCell = (re: InternalReconciliationEntry) => (
    <Text
      as="span"
      className={
        re.journal_entry_line_id === journalEntryLineId ? "current-line" : ""
      }
    >
      {(re.journal_entry_line?.journal_entry as any)?.description ||
        re.journal_entry_line?.description ||
        ""}
    </Text>
  );

  const originalAmountCell = (re: InternalReconciliationEntry) => (
    <Text
      as="span"
      className={
        re.journal_entry_line_id === journalEntryLineId ? "current-line" : ""
      }
    >
      {debitAmountCell(
        (re.journal_entry_line?.debit || 0) -
          (re.journal_entry_line?.credit || 0),
        (re.journal_entry_line?.debit_fc || 0) -
          (re.journal_entry_line?.credit_fc || 0),
        re.journal_entry_line?.currency_code
      )}
    </Text>
  );

  const reconciledAmountCell = (re: InternalReconciliationEntry) => (
    <Text
      as="span"
      className={
        re.journal_entry_line_id === journalEntryLineId ? "current-line" : ""
      }
    >
      {debitAmountCell(
        (re.debit || 0) - (re.credit || 0),
        (re.debit_fc || 0) - (re.credit_fc || 0),
        re.journal_entry_line?.currency_code
      )}
    </Text>
  );

  const debitAmountCell = (
    amount_lc: number,
    amount_fc: number,
    currency_code: string
  ) => {
    return amount_lc ? (
      <>
        {currencyFormat(amount_lc, clientAccount.accounting_currency)}
        {amount_fc ? (
          <>
            <br />
            <small style={{ opacity: "50%" }}>
              {currencyFormat(amount_fc, currency_code)}
            </small>
          </>
        ) : null}
      </>
    ) : (
      ""
    );
  };

  return (
    <Modal
      className="reconciliation-details-modal"
      show={show}
      size="lg"
      onHide={() => onHide(mutationsHappened)}
      title={t("Reconciliation Entries")}
    >
      <div className="content">
        {reconciliationsQuery.data?.data?.map((re: InternalReconciliation) => (
          <Container key={re.id} fluid>
            <Row>
              <Col>
                {t("Reconciliation")} #{re.sequence_number} at{" "}
                {moment(re.date).format("YYYY-MM-DD")}
              </Col>
              <Col>
                <Button
                  className="float-right"
                  text={t("Delete")}
                  variant={BOOTSTRAP_VARIANTS.OUTLINE_DANGER}
                  size="sm"
                  onClick={() => deleteReconciliation(re)}
                />
              </Col>
            </Row>
            <GridView
              columns={[
                {
                  Header: t("Date"),
                  accessor: "posting_date",
                  align: "left",
                  maxWidth: 110,
                  Cell: (cellProps) => postingDateCell(cellProps.row.original),
                },
                {
                  Header: t("J/E"),
                  accessor: "sequence_number",
                  align: "left",
                  maxWidth: 80,
                  Cell: (cellProps) =>
                    sequenceNumberCell(cellProps.row.original),
                },
                {
                  Header: t("Line"),
                  accessor: "line_id",
                  align: "left",
                  maxWidth: 80,
                  Cell: (cellProps) => lineIdCell(cellProps.row.original),
                },
                {
                  Header: t("Description"),
                  accessor: "description",
                  align: "left",
                  Cell: (cellProps) => descriptionCell(cellProps.row.original),
                },
                {
                  Header: t("Orig. Am"),
                  accessor: "debit_amount",
                  align: "right",
                  maxWidth: 150,
                  Cell: (cellProps) =>
                    originalAmountCell(cellProps.row.original),
                },
                {
                  Header: t("Rec. Am"),
                  accessor: "debit_amount_fc",
                  align: "right",
                  maxWidth: 150,
                  Cell: (cellProps) =>
                    reconciledAmountCell(cellProps.row.original),
                },
              ]}
              data={re.entries}
            />
          </Container>
        ))}
      </div>
    </Modal>
  );
};

export default ReconciliationDetailsPopup;
