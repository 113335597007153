import React, {
  useRef,
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
  memo,
} from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

// Core viewer
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { selectionModePlugin } from "@react-pdf-viewer/selection-mode";
import { toolbarPlugin, MoreActionsPopover } from "@react-pdf-viewer/toolbar";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/selection-mode/lib/styles/index.css";

import "./style.scss";

const renderToolbar = (Toolbar) => (
  <Toolbar>
    {(toolbarSlot) => {
      const {
        CurrentPageInput,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Zoom,
        ZoomIn,
        ZoomOut,
        Download,
        Print,
      } = toolbarSlot;

      return (
        <div
          className="rpv-toolbar"
          role="toolbar"
          aria-orientation="horizontal"
        >
          <div className="rpv-toolbar__left">
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <GoToPreviousPage />
              </div>
            </div>
            <div className="rpv-toolbar__item">
              <CurrentPageInput />{" "}
              <span className="rpv-toolbar__label">
                / <NumberOfPages />
              </span>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <GoToNextPage />
              </div>
            </div>
          </div>
          <div className="rpv-toolbar__center">
            <div className="rpv-toolbar__item">
              <ZoomOut />
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} />
              </div>
            </div>
            <div className="rpv-toolbar__item">
              <ZoomIn />
            </div>
          </div>
          <div className="rpv-toolbar__right">
            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
              <div className="rpv-toolbar__item">
                <EnterFullScreen />
              </div>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
              <div className="rpv-toolbar__item">
                <Print />
              </div>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
              <div className="rpv-toolbar__item">
                <Download />
              </div>
            </div>
            <div className="rpv-toolbar__item">
              <MoreActionsPopover toolbarSlot={toolbarSlot} />
            </div>
          </div>
        </div>
      );
    }}
  </Toolbar>
);

let pdfjs, pdfjsWorker;

const PDFViewer = forwardRef((props, ref) => {
  const { url, onLoadSuccess, style, thumbnailsActive } = props;

  const [isPDFLoaded, setIsPDFLoaded] = useState(false);
  const [isLoadSuccessCalled, setIsLoadSuccessCalled] = useState(false);

  const targetRef = useRef();

  // Create new plugin instance
  const selectionModePluginInstance = selectionModePlugin();
  const toolbarPluginInstance = toolbarPlugin();

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    renderToolbar,
  });

  useEffect(() => {
    async function fetchData() {
      if (!pdfjs) {
        pdfjs = await import("pdfjs-dist/build/pdf");
      }

      if (!pdfjsWorker) {
        pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
      }

      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      setIsPDFLoaded(true);
    }
    fetchData();
  }, []);

  useImperativeHandle(ref, () => ({}));

  if (!isPDFLoaded) {
    return <>Loading....</>;
  }

  const loadOk = () => {
    if (!isLoadSuccessCalled) {
      setIsLoadSuccessCalled(true);
      onLoadSuccess && onLoadSuccess();
    }
  };

  const handleDocumentLoad = (e) => {
    const { activateTab } = defaultLayoutPluginInstance;
    thumbnailsActive && activateTab(0);

    setTimeout(() => loadOk(), 2000);
  };

  return (
    <div
      className="pdf-viewer"
      ref={targetRef}
      style={{ height: "575px", ...style }}
    >
      {!isLoadSuccessCalled && (
        <div className="spinner-container">
          <Spinner className="spinner" animation="border" variant="secondary" />
        </div>
      )}
      <Viewer
        fileUrl={url}
        plugins={[
          // Register plugins
          defaultLayoutPluginInstance,
          selectionModePluginInstance,
          toolbarPluginInstance,
        ]}
        defaultScale={SpecialZoomLevel.PageWidth}
        onZoom={(e) => {
          if (e.scale != null) {
            localStorage.setItem("zoom", e.scale);
          }
        }}
        onDocumentLoad={handleDocumentLoad}
        renderError={(error) => {
          console.error(error);
        }}
        renderLoader={(percent) => {
          if (percent >= 100) {
            loadOk();
          }
        }}
      />
    </div>
  );
});

PDFViewer.propTypes = {
  url: PropTypes.string,
  showPages: PropTypes.bool,
  showAllPages: PropTypes.bool,
  onLoadSuccess: PropTypes.func,
  onPageChange: PropTypes.func,
};

export default memo(PDFViewer);
