import React, { useState, memo, useEffect, useRef } from "react";
import Select from "react-select";
import cn from "classnames";
import { create, all } from "mathjs";

import { currencyValues } from "../../../constants";
import {
  formatNumberToLocaleValue,
  formatLocaleValueToNumber,
} from "../../../utils/common";
import { getSelectStyles } from "./utils";
import "./style.scss";

interface InputProps {
  currencyCode: string;
  onCurrencyCodeChange?: (c: string) => void;
  label?: string;
  onChange?: (v: any) => void;
  value: any;
  className?: string;
  noPadding?: boolean;
  isCheckValid?: boolean;
  placeholder?: string;
  size?: string | number;
  required?: boolean;
  focus?: any;
  disabled?: boolean;
  fixedCurrency?: boolean;
  tabIndex?: number;
  [x: string]: any;
}

const Input = (props: InputProps) => {
  const {
    currencyCode,
    onCurrencyCodeChange,
    label,
    onChange,
    value,
    className,
    noPadding,
    isCheckValid,
    placeholder,
    size,
    required,
    focus,
    disabled,
    fixedCurrency = false,
    tabIndex,
    ...otherProps
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const selectStyles = getSelectStyles(disabled, size);
  const currencyOptions = currencyValues;
  const math = create(all, {});

  const [checkValid, changeCheckValid] = useState(false);
  const [isCleanNum, setIsCleanNum] = useState(true);
  const [localValue, setLocalValue] = useState("");

  const currencyOption = currencyOptions.find((o) => o.value === currencyCode);

  useEffect(() => {
    setLocalValue(formatNumberToLocaleValue(value));
  }, [value]);

  useEffect(() => {
    if (focus) {
      setTimeout(() => inputRef.current?.focus());
    }
  }, [focus]);

  useEffect(() => {
    if (isCheckValid) {
      changeCheckValid(true);
    }
  }, [isCheckValid]);

  const getFormattedNumber = (num: any) => {
    if (!num) return "";
    const currencyCodesRegex = new RegExp(
      `${currencyOptions
        .flatMap((opt) => [
          opt.label.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
          opt.value.toUpperCase(),
          opt.value.toLowerCase(),
        ])
        .join("|")} `,
      "ig"
    );
    let cleanNum = num
      .replaceAll(currencyCodesRegex, "")
      .replaceAll(",", ".")
      .replaceAll("−", "-")
      .replaceAll(/\s/gi, "");
    cleanNum = math.evaluate(cleanNum);
    if (isNaN(cleanNum)) {
      setIsCleanNum(false);
      return num;
    } else {
      setIsCleanNum(true);
    }
    const currencyOption = currencyOptions.find(
      (opt) =>
        num.includes(opt.label) ||
        num.includes(opt.value.toUpperCase()) ||
        num.includes(opt.value.toLowerCase())
    );
    if (currencyOption)
      onCurrencyCodeChange && onCurrencyCodeChange(currencyOption.value);

    return cleanNum;
  };

  const handleOnBlur = () => {
    const updated = getFormattedNumber(localValue.toString());
    if (!isNaN(updated)) {
      setLocalValue(formatNumberToLocaleValue(updated));
      onChange && onChange(+(+updated).toFixed(2));
    }
  };

  let isInvalid = false;
  if (required && checkValid) {
    isInvalid = value == null || value === "";
  }

  return (
    <div
      className={cn("currency-input-control", {
        [`input-${size}`]: size,
        [className || ""]: className,
        invalid: isInvalid || !isCleanNum,
        "no-padding": noPadding,
      })}
    >
      {label && (
        <div className={cn("label", required && "required")}>{label}</div>
      )}
      <div className="currency-dropdown-input-wrap">
        <Select
          pageSize={Number(size)}
          placeholder=""
          value={currencyOption}
          onChange={(o: any) =>
            onCurrencyCodeChange && onCurrencyCodeChange(o.value)
          }
          options={currencyOptions}
          isSearchable={false}
          isDisabled={fixedCurrency}
          styles={selectStyles as any}
        />
        <input
          placeholder={placeholder}
          ref={inputRef || null}
          type="text"
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={handleOnBlur}
          disabled={disabled}
          tabIndex={tabIndex}
          {...otherProps}
        />
      </div>
    </div>
  );
};

export default memo(Input);
