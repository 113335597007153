import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import {
  authenticatedApiClient,
  throwErrorByStatus,
} from "../../api/apiClients";
import { ClientAccount } from "../../types/ClientAccount";
import { ClientAccountContext } from "../../views/App";
import { Button } from "../commons";

import { cloneDeep } from "lodash";
import { IconAdd } from "../../assests/icons";
import { BusinessPartner } from "../../types/BusinessPartner";
import { PaymentDraft } from "../../types/Payments";
import LabelsSelectPopup from "../commons/LabelsSelectPopup";
import "./style.scss";
import CreateBankAccountModal from "../../views/PostDetail/CommercialDocumentForm/CreateBankAccountModal";
import { BankAccount } from "../../types/BankAccount";
import { BOOTSTRAP_VARIANTS } from "../../constants";
import { PaymentDraftReducerAction } from "./PaymentDraftReducer";
import { PaymentDraftEditorRow } from "./PaymentDraftEditorRow";

interface PaymentDraftEditorProps {
  paymentDraft?: PaymentDraft;
  paymentDraftDispatcher: React.Dispatch<PaymentDraftReducerAction>;
  onSave?: (pd: PaymentDraft) => void;
  disabled?: boolean;
  tabIndex?: number;
}

const PaymentDraftEditor = (props: PaymentDraftEditorProps) => {
  const { paymentDraft, paymentDraftDispatcher, onSave, disabled, tabIndex } =
    props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  // Data state
  const [transferBusinessPartners, setTransferBusinessPartners] =
    React.useState<{ [key: number]: BusinessPartner }>({});

  // Helper state
  const [bankAccountPopupIndex, setBankAccountPopupIndex] = React.useState<
    number | null
  >(null);
  const [dimensionPopupIndex, setDimensionPopupIndex] = React.useState<
    number | null
  >(null);

  // Mutations
  const bankAccountMutation = useMutation(
    async (ba: BankAccount) => {
      const res = await authenticatedApiClient.post("bank-accounts", ba);
      throwErrorByStatus(res);
      return res.data as BankAccount;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bank-accounts");
      },
    }
  );

  const onBankAccountSave = async (bankAccount: BankAccount) => {
    if (!bankAccount.client_account_id)
      bankAccount.client_account_id = clientAccount.id;
    if (!bankAccount.business_partner_id)
      bankAccount.business_partner_id =
        transferBusinessPartners[bankAccountPopupIndex!].id;

    const res = await bankAccountMutation.mutateAsync(bankAccount);
    paymentDraftDispatcher({
      type: "setTransferCreditorAccount",
      transferIndex: bankAccountPopupIndex!,
      value: res,
    });

    setBankAccountPopupIndex(null);
  };

  const onSaveClicked = () => {
    // Correct any missing data
    const draft = cloneDeep(paymentDraft);
    draft?.transfers.forEach((transfer) => {
      transfer.account_entries = transfer.account_entries?.filter(
        (ae) => ae.account_code
      );
    });

    draft && onSave && onSave(draft);
  };

  return (
    <>
      <CreateBankAccountModal
        show={bankAccountPopupIndex !== null}
        onHide={() => setBankAccountPopupIndex(null)}
        onSave={onBankAccountSave}
      />
      <LabelsSelectPopup
        show={dimensionPopupIndex !== null}
        onHide={() => setDimensionPopupIndex(null)}
        className="labels-select"
        positionTop={150 * (dimensionPopupIndex || 0) + 1}
        dimensions={
          dimensionPopupIndex
            ? paymentDraft?.transfers[dimensionPopupIndex]?.account_entries?.[0]
                ?.dimensions || []
            : []
        }
        onDimensionsChange={(d) =>
          dimensionPopupIndex &&
          paymentDraftDispatcher({
            type: "setTransferDimensions",
            transferIndex: dimensionPopupIndex,
            value: d,
          })
        }
      />
      <div className={"payment-draft-transfer-list"}>
        <div className="payment-draft-transfer-list-header">
          <div>{t("Date")}</div>
          <div>{t("From bank account #")}</div>
          <div>{t("To businesspartner")}</div>
          <div>
            {t("To bank account #")}
            <br />
            {t("G/L account")}
          </div>
          <div>
            {t("Transfer amount")}
            <br />
            {t("Tax code")}
          </div>
          <div>
            {t("Payment reference")}
            <br />
            {t("Memo")}
          </div>
          <div></div>
        </div>

        {paymentDraft?.transfers.map((transfer, index) => (
          <PaymentDraftEditorRow
            key={index}
            paymentDraft={paymentDraft}
            paymentDraftDispatcher={paymentDraftDispatcher}
            transfer={transfer}
            onEditDimensionsClick={() => setDimensionPopupIndex(index)}
            onAddBankAccountClick={() => setBankAccountPopupIndex(index)}
            disabled={disabled}
            tabIndex={(tabIndex || 30) + index * 9}
          />
        ))}
        <div className="payment-draft-transfer-list-footer">
          <span
            className="add-icon"
            role="button"
            onClick={() =>
              paymentDraftDispatcher({
                type: "addTransferByIndex",
                value: { currency_code: clientAccount.accounting_currency },
              })
            }
          >
            <IconAdd />
          </span>

          {!paymentDraft?.id && (
            <Button
              className="book-button"
              variant={BOOTSTRAP_VARIANTS.PRIMARY}
              text={t("post_detail.book_button")}
              onClick={() => onSaveClicked()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentDraftEditor;
