import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching, useIsMutating, useQueryClient } from "react-query";
import {
  IconClear,
  IconEdit,
  IconHeadQuarters,
  IconMoneybag,
  IconPerson,
} from "../../assests/icons";
import { Button, GridView, Loading } from "../../components/commons";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import EditBusinessPartners from "./EditBusinessPartners";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  useCreateBusinessPartners,
  useEditBusinessPartners,
  useGetBusinessPartners,
  useUpdateBusinessPartner,
} from "../../api";
import { currencyFormat } from "../../utils/common";
import { useGetClientAccount } from "../../api/clientAccountApi";

const BusinessPartners = (props) => {
  const { keyWord, clientAccountId } = props;
  const [selectedBusinessPartner, setBusinessPartner] = useState(null);
  const [query, setQuery] = useState({ page: 1, pageSize: 8 });
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const { data: clientAccount } = useGetClientAccount(clientAccountId);
  const { data, refetch: refetchBusinessPartners } = useGetBusinessPartners(
    clientAccountId,
    keyWord,
    query.pageSize,
    query.page,
    true
  );
  const businessPartners = data?.business_partners;
  const businessPartnerPages = data?.pages;

  const { mutateAsync: updateBusinessPartner } = useUpdateBusinessPartner();
  const { mutateAsync: editBusinessPartners } = useEditBusinessPartners();
  const { mutateAsync: createBusinessPartners } = useCreateBusinessPartners();

  const handleQueryChange = (data) => {
    const q = {
      ...(query || {}),
      ...data,
    };
    setQuery(q);
  };

  const handleDeactivateClick = (data) => {
    if (!confirm(t("Do you want to deactivate this business partner?"))) return;
    editBusinessPartners({ id: data.id, is_active: false }).then(() => {
      refetchBusinessPartners();
      queryClient.invalidateQueries("dimension-values");
    });
  };

  const handleCreateSupplier = (supplierData) => {
    const data = { ...supplierData };
    data.client_account_id = clientAccountId;
    if (data.id) {
      updateBusinessPartner(data).then(() => {
        setBusinessPartner(null);
        refetchBusinessPartners();
        queryClient.invalidateQueries("dimension-values");
      });
    } else {
      createBusinessPartners(data).then(() => {
        setBusinessPartner(null);
        refetchBusinessPartners();
        queryClient.invalidateQueries("dimension-values");
      });
    }
  };

  return (
    <div className="detail-page">
      <Loading childLoading loading={isFetching > 0 || isMutating > 0} />
      {selectedBusinessPartner === null ? (
        <div className="business-partner-list">
          <div className="business-partner-gridview">
            <GridView
              columns={[
                {
                  Header: t("master_data.gridview.id"),
                  accessor: "sequence_number",
                  align: "left",
                  maxWidth: "8%",
                },
                {
                  Header: t("master_data.gridview.name"),
                  accessor: "name",
                  align: "left",
                },
                {
                  accessor: "balance",
                  align: "left",
                  maxWidth: "50px",
                  Cell: (cellProps) => {
                    const balance = cellProps.row.original.statistics?.balances
                      ?.map((b) => b.balance)
                      .reduce((a, b) => a + b, 0);
                    if (balance) {
                      return (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip>
                              {cellProps.row.original.statistics?.balances?.map(
                                (b, idx) => (
                                  <div key={idx} style={{ textAlign: "left" }}>
                                    {b.account_code}
                                    {": "}
                                    {currencyFormat(
                                      b.balance,
                                      clientAccount.accounting_currency
                                    )}
                                  </div>
                                )
                              )}
                            </Tooltip>
                          }
                        >
                          <IconMoneybag />
                        </OverlayTrigger>
                      );
                    }
                    return <></>;
                  },
                },
                {
                  accessor: "type",
                  align: "left",
                  maxWidth: "50px",
                  Cell: (cellProps) => {
                    if (cellProps.row.original.is_organization) {
                      return (
                        <div
                          style={
                            cellProps.row.original.organization
                              ? { fontWeight: "bold" }
                              : { opacity: "0.25" }
                          }
                        >
                          <IconHeadQuarters />
                        </div>
                      );
                    }
                    return <IconPerson />;
                  },
                },
                {
                  Header: t("master_data.gridview.organization_number"),
                  accessor: "organization_number",
                  align: "left",
                  maxWidth: "12%",
                  Cell: (cellProps) => {
                    if (
                      cellProps.row.original.is_organization &&
                      cellProps.row.original.country === "NO"
                    ) {
                      if (cellProps.value)
                        return (
                          <a
                            href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${cellProps.value}`}
                            target="_blank"
                          >
                            {cellProps.value}
                          </a>
                        );
                      else
                        return (
                          <a
                            href={`https://w2.brreg.no/enhet/sok/treffliste.jsp?navn=${cellProps.row.original.name}&orgform=0&fylke=0&kommune=0`}
                            target="_blank"
                          >
                            {t("Search")}
                          </a>
                        );
                    } else {
                      return <>{cellProps.value}</>;
                    }
                  },
                },
                {
                  Header: t("master_data.gridview.address"),
                  accessor: "address",
                  align: "left",
                  maxWidth: "24%",
                  Cell: (cellProps) =>
                    [
                      cellProps.row.original.address,
                      cellProps.row.original.zip,
                      cellProps.row.original.city,
                      cellProps.row.original.state,
                    ]
                      .filter((v) => !!v)
                      .join(", "),
                },
                {
                  accessor: "country",
                  align: "left",
                  maxWidth: "60px",
                  Cell: (cellProps) =>
                    cellProps.value ? (
                      <img
                        src={`https://flagcdn.com/h20/${cellProps.value.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/h40/${cellProps.value.toLowerCase()}.png 2x,
                        https://flagcdn.com/h60/${cellProps.value.toLowerCase()}.png 3x`}
                        height="20"
                        alt={t(`app.country_list.${cellProps.value}`)}
                        title={t(`app.country_list.${cellProps.value}`)}
                      />
                    ) : (
                      <></>
                    ),
                },
                {
                  Header: "",
                  accessor: "delete",
                  align: "left",
                  maxWidth: "40px",
                  className: "delete-wrap",
                  Cell: (cellProps) => (
                    <span
                      className="delete-icon"
                      role="button"
                      onClick={() => setBusinessPartner(cellProps.row.original)}
                    >
                      <IconEdit />
                    </span>
                  ),
                },
                {
                  Header: "",
                  accessor: "deactivate",
                  align: "left",
                  maxWidth: "40px",
                  className: "delete-wrap",
                  Cell: (cellProps) => (
                    <span
                      className="delete-icon"
                      role="button"
                      onClick={() =>
                        handleDeactivateClick(cellProps.row.original)
                      }
                    >
                      <IconClear />
                    </span>
                  ),
                },
              ]}
              data={businessPartners || []}
              pages={businessPartnerPages || null}
              relativeRowHeight={30}
              onStateChange={(query) => handleQueryChange(query)}
              emptyStateTitle="No Records"
            />
          </div>
          <div className="text-right">
            <Button
              size="md40"
              className="add-button"
              variant={BOOTSTRAP_VARIANTS.SECONDARY}
              text={t("master_data.add_new_businesspartner")}
              onClick={() => setBusinessPartner(undefined)}
            />
          </div>
        </div>
      ) : (
        <EditBusinessPartners
          businessPartner={selectedBusinessPartner}
          onBack={() => setBusinessPartner(null)}
          onSave={handleCreateSupplier}
        />
      )}
    </div>
  );
};

export default memo(BusinessPartners);
