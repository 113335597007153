import cn from "classnames";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";

import { IconBlueQuestions } from "../../assests/icons";
import { CheckBox, DateIntervalPicker, Select } from "../../components/commons";

import {
  useGetBankAccounts,
  useGetAccountBalances,
  useInfiniteGetJournalEntryLines,
  useInfiniteGetBankTransactions,
} from "../../api";

import { ClientAccountContext } from "../App";

import "./style.scss";
import TransactionDayList from "./TransactionDayList";
import MultiPaymentCreationContainer from "./MultiPaymentCreationContainer";
import SinglePaymentCreationContainer from "./SinglePaymentCreationContainer";
import ExternalReconciliationCreationContainer from "./ExternalReconciliationCreationContainer";
import SinglePaymentDetails from "./SinglePaymentDetails";

const BankReconciliation = () => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state - filtering
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [queryStartDate, setQueryStartDate] = useState();
  const [queryEndDate, setQueryEndDate] = useState();
  const [isShowAllTransactions, setIsShowAllTransactions] = useState(false);

  // Data state
  const [groupedTransactions, setGroupedTransactions] = useState({});
  const [selectedBankTransactions, setSelectedBankTransactions] = useState([]);
  const [selectedJournalEntryLines, setSelectedJournalEntryLines] = useState(
    []
  );

  // Queries
  const { data: bankAccounts, isLoading: bankAccountsFetching } =
    useGetBankAccounts(clientAccount?.id);

  const {
    data: bankTransactions,
    isLoading: bankTransactionsFetching,
    fetchNextPage: fetchNextBankTransactionsPage,
    refetch: refetchBankTransactions,
  } = useInfiniteGetBankTransactions(
    selectedAccount
      ? {
          client_account_id: clientAccount?.id,
          bank_account_id: selectedAccount?.id,
          from_date: queryStartDate
            ? moment(queryStartDate).format("YYYY-MM-DD")
            : null,
          to_date: queryEndDate
            ? moment(queryEndDate).format("YYYY-MM-DD")
            : null,
          include_externally_reconciled: isShowAllTransactions,
          with: [
            "details",
            "receivable_payments",
            "questions.answer_options",
            "statements.business_partner",
          ].join(","),
          per_page: 50,
        }
      : null
  );

  const {
    data: journalEntryLines,
    isLoading: journalEntryLinesFetching,
    fetchNextPage: fetchNextJournalEntryLinesPage,
    refetch: refetchJournalEntryLines,
  } = useInfiniteGetJournalEntryLines(
    selectedAccount
      ? {
          client_account_id: clientAccount?.id,
          from_date: queryStartDate
            ? moment(queryStartDate).format("YYYY-MM-DD")
            : null,
          to_date: queryEndDate
            ? moment(queryEndDate).format("YYYY-MM-DD")
            : null,
          account_code: "1920",
          relation_type: "bankacc",
          relation_id: selectedAccount.id,
          include_externally_reconciled: isShowAllTransactions,
          include_drafts: true,
          per_page: 50,
        }
      : null
  );

  const {
    data: accountBalances,
    isLoading: accountBalancesFetching,
    refetch: refetchAccountBalances,
  } = useGetAccountBalances(selectedAccount?.id);

  const handleQueryChange = () => {
    setSelectedBankTransactions([]);
    setSelectedJournalEntryLines([]);
    setGroupedTransactions({});
  };

  useEffect(() => {
    if (bankTransactions && journalEntryLines && accountBalances) {
      const dates = new Set([
        ...bankTransactions.pages
          .flatMap((p) => p.data)
          .map((t) => t.booking_date),
        ...journalEntryLines.pages
          .flatMap((p) => p.data)
          .map((jel) => jel.posting_date),
      ]);
      let groups = {};
      [...dates].sort().forEach(
        (date) =>
          (groups[date] = {
            bankTransactions: bankTransactions.pages
              .flatMap((p) => p.data)
              .filter((t) => t.booking_date === date),
            journalEntryLines: journalEntryLines.pages
              .flatMap((p) => p.data)
              .filter((jel) => jel.posting_date === date),
            balances: accountBalances[date],
          })
      );
      setGroupedTransactions(groups);
    }
  }, [bankTransactions, journalEntryLines, accountBalances]);

  // Event handlers
  const changeBankTransactionForPayment = (bankTransaction) => {
    setSelectedBankTransactions([bankTransaction]);
    setSelectedJournalEntryLines([]);
  };

  const toggleBankTransaction = (t) => {
    // Either one or array of transactions
    const transactions = [...(t.constructor === Array ? t : [t])];

    let temp = [...selectedBankTransactions];
    transactions.forEach((tt) => {
      if (temp.find((ttt) => ttt.id === tt.id))
        temp = [...temp.filter((ttt) => ttt.id !== tt.id)];
      else temp = [...temp, tt];
    });

    setSelectedBankTransactions(temp);
  };

  const toggleJournalEntryLine = (l) => {
    // Either one or array of J/E lines
    const lines = [...(l.constructor === Array ? l : [l])];

    let temp = [...selectedJournalEntryLines];
    lines.forEach((ll) => {
      if (temp.find((lll) => lll.id === ll.id))
        temp = [...temp.filter((lll) => lll.id !== ll.id)];
      else temp = [...temp, ll];
    });

    setSelectedJournalEntryLines(temp);
  };

  const handleClearSelection = () => {
    setSelectedBankTransactions([]);
    setSelectedJournalEntryLines([]);
  };

  const handleExternalReconciliationCreated = (e) => {
    handleClearSelection();

    refetchBankTransactions();
    refetchJournalEntryLines();
    refetchAccountBalances();
  };

  const handlePaymentsCreated = (p) => {
    handleClearSelection();

    refetchBankTransactions();
    refetchJournalEntryLines();
    refetchAccountBalances();
  };

  const handleQuestionAsked = (q) => {
    if (q.constructor === Array) handleClearSelection();

    refetchBankTransactions();
  };

  const isFetchingTransactions =
    bankTransactionsFetching ||
    journalEntryLinesFetching ||
    accountBalancesFetching;

  return (
    <>
      <div className={cn("reconcile-page")}>
        <div className="left-side">
          <Scrollbars autoHide>
            <div className="title">{t("reconcile.bank_reconciliation")}</div>
            <div className="account-detail">
              <div>
                <Select
                  className="accounts-select"
                  size="md"
                  noPadding
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  value={selectedAccount}
                  onChange={(v) => {
                    setSelectedAccount(v);
                    handleQueryChange();
                  }}
                  options={bankAccounts?.bank_accounts}
                  loading={bankAccountsFetching}
                  isClearable={false}
                />
              </div>
              <div>
                <DateIntervalPicker
                  dateFormat="dd.MM.yyyy"
                  startDate={queryStartDate}
                  endDate={queryEndDate}
                  onChangeStartDate={(v) => {
                    setQueryStartDate(v);
                    handleQueryChange();
                  }}
                  onChangeEndDate={(v) => {
                    setQueryEndDate(v);
                    handleQueryChange();
                  }}
                />
              </div>
              <div>
                <CheckBox
                  checked={isShowAllTransactions}
                  onClick={() => {
                    setIsShowAllTransactions(!isShowAllTransactions);
                    handleQueryChange();
                  }}
                >
                  Show reconciled
                </CheckBox>
              </div>
            </div>
            <TransactionDayList
              transactions={groupedTransactions}
              isLoading={isFetchingTransactions}
              selectedBankTransactions={selectedBankTransactions}
              selectedJournalEntryLines={selectedJournalEntryLines}
              onBankTransactionToggle={(t, multi) =>
                multi
                  ? toggleBankTransaction(t)
                  : changeBankTransactionForPayment(t)
              }
              onJournalEntryLineToggle={(jel) => toggleJournalEntryLine(jel)}
              onJournalEntryLineChange={() => {
                refetchJournalEntryLines();
                refetchBankTransactions();
              }}
              onViewingLastBankTransaction={() =>
                fetchNextBankTransactionsPage()
              }
              onViewingLastJournalEntryLine={() =>
                fetchNextJournalEntryLinesPage()
              }
            />
          </Scrollbars>
        </div>
        <div className="right-side">
          {!selectedBankTransactions?.length &&
            !selectedJournalEntryLines?.length && (
              <div className="no-data">
                <IconBlueQuestions />
                <span>{t("reconcile.choose_bank_transaction")}</span>
              </div>
            )}
          {(selectedBankTransactions || []).length === 1 &&
            selectedBankTransactions[0].external_reconciled_amount !== 0 &&
            (selectedJournalEntryLines || []).length === 0 && (
              <SinglePaymentDetails
                bankAccount={selectedAccount}
                bankTransaction={selectedBankTransactions[0]}
                questions={selectedBankTransactions[0].questions}
                onPaymentCreated={handlePaymentsCreated}
                onQuestionAsked={handleQuestionAsked}
              />
            )}
          {(selectedBankTransactions || []).length === 1 &&
            selectedBankTransactions[0].external_reconciled_amount === 0 &&
            (selectedJournalEntryLines || []).length === 0 && (
              <SinglePaymentCreationContainer
                bankAccount={selectedAccount}
                bankTransaction={selectedBankTransactions[0]}
                questions={selectedBankTransactions[0].questions}
                onPaymentCreated={handlePaymentsCreated}
                onQuestionAsked={handleQuestionAsked}
              />
            )}
          {(selectedBankTransactions || []).length > 1 &&
            (selectedJournalEntryLines || []).length === 0 && (
              <MultiPaymentCreationContainer
                bankAccount={selectedAccount}
                bankTransactions={selectedBankTransactions}
                onPaymentsCreated={handlePaymentsCreated}
                onQuestionsAsked={handleQuestionAsked}
                onCancel={handleClearSelection}
              />
            )}
          {(((selectedBankTransactions || []).length >= 1 &&
            (selectedJournalEntryLines || []).length >= 1) ||
            (selectedJournalEntryLines || []).length >= 1) && (
            <ExternalReconciliationCreationContainer
              bankAccount={selectedAccount}
              bankTransactions={selectedBankTransactions}
              journalEntryLines={selectedJournalEntryLines}
              onExternalReconciliationCreated={
                handleExternalReconciliationCreated
              }
              onBankTransactionCreated={() => refetchBankTransactions()}
              onCancel={handleClearSelection}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(BankReconciliation);
