import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "../../components/commons";
import { JournalEntryTable } from "../../components";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";

import "./style.scss";
import { JournalEntry } from "../../types/JournalEntryV2";
import {
  JournalEntryController,
  JournalEntryReducerAction,
} from "../../components/JournalEntryTable/JournalEntryFunctions";

const JournalEntryEditorModal = ({
  onHide,
  onAdd,
  loading,
  show,
  journalEntry,
  journalEntryDispatch,
  journalEntryController,
}: {
  onHide: () => void;
  onAdd: (je: JournalEntry) => void;
  loading: boolean;
  show: boolean;
  journalEntry: JournalEntry;
  journalEntryDispatch: React.Dispatch<JournalEntryReducerAction>;
  journalEntryController: JournalEntryController;
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (!journalEntry.lines?.every((line) => line.debit_fc || line.credit_fc))
      return;

    onAdd(journalEntry);
  };

  const tableFooter = (
    <div className="function-buttons">
      <Button
        variant={BOOTSTRAP_VARIANTS.PRIMARY}
        text={t("reconcile.add")}
        onClick={handleClick}
      />
    </div>
  );

  return (
    <Modal
      className="journal-entry-editor-modal"
      show={show}
      loading={loading}
      size="xl"
      onHide={onHide}
      title={t("reconcile.custom_journal_entry")}
      draggable
    >
      <div className="content">
        <JournalEntryTable
          footer={tableFooter}
          journalEntry={journalEntry || {}}
          journalEntryDispatch={journalEntryDispatch}
          journalEntryController={journalEntryController}
        />
      </div>
    </Modal>
  );
};

export default JournalEntryEditorModal;
