import { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useEditClientCompany, useGetOverview } from "../../../../api";
import { IconGreenQuestions } from "../../../../assests/icons";
import { useCurrentClientAccountFromContext } from "../../../../dataHooks/user";
import { BOOTSTRAP_VARIANTS } from "../../../../constants";
import { Form } from "react-bootstrap";
import { Button } from "../..";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const AccountingNotesOverlay = () => {
  const [editField, setEditField] = useState("");
  const { mutateAsync: editClientCompany, isLoading, isError, isSuccess } = useEditClientCompany();
  const currentClientAccount = useCurrentClientAccountFromContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("header.menu.accountant_notes.success_message"));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Something went wrong");
    }
  }, [isError]);

  const handleSaveAccountingNotes = () => {
    editClientCompany({
      id: currentClientAccount?.id,
      accountant_notes: editField,
    });
  };

  const { data: overview } = useGetOverview(currentClientAccount?.id);

  useEffect(() => {
    if (overview) {
      setEditField(overview.accountant_notes);
    }
  }, [overview]);

  const popover = (
    <Popover
      id="popover-basic"
      className="shadow-sm p-3 bg-white d-flex flex-column align-items-end"
      style={{ width: 400 }}
    >
      <Form.Label htmlFor="notes" as="h6" className="w-100">
        {t("header.menu.accountant_notes.title")}
      </Form.Label>
      <textarea
        className="form-control "
        id="exampleFormControlTextarea1"
        rows={4}
        value={editField}
        onChange={(e) => {
          setEditField(e.target.value);
        }}
      />
      <Button
        text={t("header.menu.accountant_notes.save_btn")}
        size="sm"
        className="col-3 mt-2 float-right"
        variant={BOOTSTRAP_VARIANTS.PRIMARY}
        onClick={handleSaveAccountingNotes}
        loading={isLoading}
        disabled={isLoading}
      />
    </Popover>
  );
  return (
    <div className="difference-wrap">
      <OverlayTrigger
        rootClose={true}
        trigger="click"
        placement="bottom"
        overlay={popover}
      >
        <IconGreenQuestions style={{ cursor: "pointer" }} />
      </OverlayTrigger>
    </div>
  );
};
