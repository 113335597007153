import cn from "classnames";
import React, { useContext, useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import {
  authenticatedApiClient,
  authenticatedApiV2Client,
} from "../../api/apiClients";
import { Button, GridView, PostStatus, Radio } from "../../components/commons";
import { DOCUMENT_STATUSES } from "../../constants";
import {
  BOOTSTRAP_VARIANTS,
  FILE_UPLOAD_ACCEPTED,
} from "../../constants/common";
import { showErrorNotification } from "../../utils/toastr";

import { currencyFormat, localeFormatDate } from "../../utils/common";

import { useUploadDocuments } from "../../api";

import { ClientAccountContext, PusherContext } from "../App";
import "./style.scss";
import { last } from "lodash";
import { useStateRef } from "../../utils/useStateRef";
import { memoizeDebounce } from "../../utils/memoizeDebounce";
import PostFileUpload from "../../components/commons/PostFileUpload/PostFileUpload";
import { useCurrentClientAccount } from "../../dataHooks/user";
import { Spinner } from "react-bootstrap";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";

const ThumbnailImage = memo((props) => {
  return (
    <img
      alt=""
      style={{
        height: "100px",
        width: "150px",
        objectFit: "cover",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
      }}
      src={props.src}
    />
  );
});

const Post = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();

  // Context state
  const clientAccount = useContext(ClientAccountContext);
  const pusher = useContext(PusherContext);

  const navigate = useNavigate();
  const [query, setQuery] = useState(null);
  const queryRef = useStateRef(query);
  const [withDnD, setWithDnD] = useState(false);
  const [selector, setSelector] = useState(DOCUMENT_STATUSES.PENDING);
  const { mutateAsync: uploadDocuments } = useUploadDocuments();

  const [showNewPostModal, setShowNewPostModal] = useState(false);

  const currentClientAccount = useCurrentClientAccount();
  const isCustomer = currentClientAccount?.amICustomer;

  const selectPendingDocuments = () => {
    setSelector(DOCUMENT_STATUSES.PENDING);
  };

  const selectBookedDocuments = () => {
    setSelector(DOCUMENT_STATUSES.BOOKED);
  };

  const selectArchivedDocuments = () => {
    setSelector(DOCUMENT_STATUSES.ARCHIVED);
  };

  const vouchersQuery = useQuery(
    ["vouchers", query],
    async () => {
      const res = await authenticatedApiClient.get(`/vouchers`, {
        params: {
          ...query,
          posting_status:
            query?.status === DOCUMENT_STATUSES.PENDING
              ? "PENDING,PENDING_ANSWER,DRAFT"
              : query?.status,
        },
      });
      return res.data;
    },
    {
      enabled: !!clientAccount && !!query && !!selector,
      // meta: { ignoreLoadingIndicator: true },
      staleTime: 60000,
    }
  );
  const isLoadingVouchers = vouchersQuery.isFetching;

  const handleQueryChange = (data) => {
    if (query?.status && data?.status && query.status !== data.status) {
      // reset pagination state on changing type of requested documents
      query.page = 1;
    }

    const q = {
      ...(query || {}),
      ...data,
    };
    setQuery(q);
  };

  const handleVoucherUpdated = memoizeDebounce((id) => {
    authenticatedApiClient.get(`/vouchers/${id}`).then((res) => {
      queryClient.setQueryData(["vouchers", queryRef.current], (oldData) => {
        // Find the index of the voucher that was updated
        const index = oldData?.vouchers.findIndex(
          (voucher) => voucher.voucher_id === id
        );
        if (!oldData || index < 0) return oldData;
        // Return the new data with the updated voucher
        const newData = {
          ...oldData,
          vouchers: [
            ...oldData.vouchers.slice(0, index),
            res.data,
            ...oldData.vouchers.slice(index + 1),
          ],
        };
        return newData;
      });
    });
  }, 2000);

  const handleContributorTaskUpdated = memoizeDebounce((id) => {
    authenticatedApiV2Client.get(`/contributor/tasks/${id}`).then((res) => {
      if (res.data?.relation_type === "VOUCHER") {
        handleVoucherUpdated(res.data.relation_id);
      }
    });
  }, 2000);

  useEffect(() => {
    if (clientAccount) {
      handleQueryChange({
        client_account_id: clientAccount.id,
        status: selector,
        per_page: selector === DOCUMENT_STATUSES.PENDING ? 500 : 25,
        order_by:
          selector === DOCUMENT_STATUSES.PENDING
            ? "upload_date desc"
            : "document_date desc",
      });
    }
  }, [selector]);

  useEffect(() => {
    if (clientAccount) {
      handleQueryChange({
        client_account_id: clientAccount.id,
        status: selector,
        per_page: selector === DOCUMENT_STATUSES.PENDING ? 500 : 25,
        order_by:
          selector === DOCUMENT_STATUSES.PENDING
            ? "upload_date desc"
            : "document_date desc",
      });
    }
  }, [clientAccount]);

  useEffect(() => {
    // Setup server messages listener
    if (pusher && clientAccount) {
      const clientChannel = pusher.subscribe(`data-events;${clientAccount.id}`);
      clientChannel.bind("create_file", () => {
        vouchersQuery.refetch();
      });
      clientChannel.bind("update_voucher", (msg) => {
        handleVoucherUpdated(msg.id);
      });
      clientChannel.bind("update_task", (msg) => {
        handleContributorTaskUpdated(msg.id);
      });

      return () => {
        pusher.unsubscribe(`data-events;${clientAccount.id}`);
      };
    }
  }, []);

  const handleRowClick = (index, data) => {
    navigate(`${location.pathname}/${data.voucher_id}`);
  };

  const deleteDocument = (voucherId) => {
    queryClient.setQueryData(["vouchers", query], (oldData) => {
      return {
        ...oldData,
        vouchers: oldData?.vouchers.filter(
          (voucher) => voucher.voucher_id != voucherId
        ),
      };
    });
  };

  const handleFilesUpload = async (files) => {
    await uploadDocuments({ accountId: clientAccount.id, files });
    handleQueryChange();
    vouchersQuery.refetch();
  };

  const handleFilesRejected = () => {
    showErrorNotification(
      `${t("client_container.upload_error")} ${FILE_UPLOAD_ACCEPTED.join(", ")}`
    );
  };

  const onDragEnter = () => setWithDnD(true);

  const onDrop = (e) => {
    setWithDnD(false);
    e.preventDefault();
  };

  const handleOnShow = () => {
    setShowNewPostModal(true);
  };

  return (
    <div className={cn("posts-page")} onDragEnter={onDragEnter} onDrop={onDrop}>
      <PostFileUpload
        showNewPostModal={showNewPostModal}
        setShowNewPostModal={setShowNewPostModal}
      />
      <div className="title-wrap">
        <div className="title">
          {t(isCustomer ? "documents.title" : "Vouchers")}
          <span style={{ marginLeft: "10px" }}>
            {isLoadingVouchers && (
              <Spinner
                animation="border"
                variant={BOOTSTRAP_VARIANTS.OUTLINE_LIGHT_BLUE}
                size="sm"
              />
            )}
          </span>
        </div>
        <div className="radio">
          <Radio
            checked={selector === DOCUMENT_STATUSES.PENDING}
            label="Pending"
            onClick={() => selectPendingDocuments()}
          />
          <Radio
            checked={selector === DOCUMENT_STATUSES.BOOKED}
            label="Booked"
            onClick={() => selectBookedDocuments()}
          />
          <Radio
            checked={selector === DOCUMENT_STATUSES.ARCHIVED}
            label="Archived"
            onClick={() => selectArchivedDocuments()}
          />
        </div>
        <div>
          <Button
            size="md"
            text={t("posts.new")}
            onClick={handleOnShow}
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
          />
        </div>
      </div>
      <div className="document-list">
        <GridView
          columns={[
            {
              Header: t("posts.gridview.name"),
              accessor: "file.thumbnail_url",
              align: "left",
              Cell: (cellProps) => {
                return (
                  <div
                    style={
                      cellProps.row.original?.documents?.length > 0
                        ? { borderLeft: "2px solid green" }
                        : {}
                    }
                  >
                    <ThumbnailImage src={cellProps.value} />
                  </div>
                );
              },
            },
            ...(selector === DOCUMENT_STATUSES.ARCHIVED
              ? [
                  {
                    Header: t("posts.gridview.document_date"),
                    accessor: "archived_document.date",
                    align: "left",
                    maxWidth: "15%",
                    Cell: (cellProps) => localeFormatDate(cellProps.value),
                  },
                  {
                    Header: t("posts.gridview.category"),
                    accessor: "archived_document.category",
                    align: "left",
                  },
                  {
                    Header: t("posts.gridview.period"),
                    accessor: "archived_document.period",
                    align: "left",
                  },
                  {
                    Header: t("posts.gridview.description"),
                    accessor: "archived_document.description",
                    align: "left",
                  },
                ]
              : [
                  {
                    Header: t("posts.gridview.document_type"),
                    accessor: "document_type",
                    align: "left",
                    maxWidth: "15%",
                    Cell: (cp) => {
                      const docType =
                        cp.row.original?.document?.document_type ||
                        last(cp.row.original?.documents)?.document_type ||
                        cp.row.original?.data_extraction_results
                          ?.internal_document_type ||
                        cp.row.original?.journal_entry?.document_relation_type;
                      if (!docType) {
                        return "";
                      }
                      return t(`object_types.${docType}`);
                    },
                  },
                  {
                    Header: t("posts.gridview.supplier"),
                    accessor: "supplier",
                    align: "left",
                    maxWidth: "15%",
                    Cell: (cp) =>
                      cp.row.original?.document?.business_partner?.name ||
                      last(cp.row.original?.documents)?.business_partner
                        ?.name ||
                      cp.row.original?.data_extraction_results?.merchant_name ||
                      "",
                  },
                  {
                    Header: t("posts.gridview.document_date"),
                    accessor: "document_date",
                    align: "left",
                    maxWidth: "15%",
                    Cell: (cp) => {
                      const dt =
                        cp.row.original?.document?.document_date ||
                        last(cp.row.original?.documents)?.document_date ||
                        cp.row.original?.journal_entry?.journal_entry_lines?.[0]
                          ?.posting_date ||
                        cp.row.original?.archived_document?.archived_date ||
                        cp.row.original.data_extraction_results?.date ||
                        cp.row.original.file?.upload_date;
                      return localeFormatDate(dt);
                    },
                  },
                  {
                    Header: t("posts.gridview.gross_total"),
                    accessor: "gross_total",
                    align: "left",
                    maxWidth: "15%",
                    Cell: (cellProps) =>
                      cellProps.row.original?.document?.gross_total ||
                      last(cellProps.row.original?.documents)?.gross_total ||
                      cellProps.row.original?.data_extraction_results?.amount
                        ? currencyFormat(
                            cellProps.row.original?.document?.gross_total ||
                              cellProps.row.original?.data_extraction_results
                                ?.amount,
                            cellProps.row.original?.document?.currency_code ||
                              cellProps.row.original?.data_extraction_results
                                ?.currency
                          )
                        : "?",
                  },
                  {
                    Header: t("posts.gridview.status"),
                    accessor: "posting_status",
                    align: "left",
                    maxWidth: "20%",
                    Cell: (cellProps) => (
                      <PostStatus status={cellProps.value} greyIfPending />
                    ),
                  },
                  {
                    Header: t("posts.gridview.questions"),
                    accessor: "questions",
                    align: "left",
                    maxWidth: "7%",
                    Cell: (cellProps) =>
                      cellProps.row.original.questions_count > 0
                        ? `${cellProps.row.original.answers_count}/${cellProps.row.original.questions_count}`
                        : "-",
                  },
                ]),
            {
              Header: "",
              accessor: "delete",
              align: "right",
              maxWidth: "3%",
              className: "delete-wrap",
              Cell: (cellProps) =>
                cellProps.row.original.posting_status === "PENDING" ||
                cellProps.row.original.posting_status === "PENDING_ANSWER" ||
                cellProps.row.original.posting_status === "DRAFT" ? (
                  <>
                    <div className="text-right float-right">
                      <DeleteConfirmationPopup
                        voucherId={cellProps.row.original.voucher_id}
                        onSubmit={() => deleteDocument(cellProps.row.original.voucher_id)}
                      />
                    </div>
                  </>
                ) : (
                  ""
                ),
            },
          ]}
          data={vouchersQuery.data?.vouchers || []}
          pages={
            selector === DOCUMENT_STATUSES.PENDING
              ? null
              : vouchersQuery.data?.pages
          }
          loading={vouchersQuery.isLoading}
          onRowClick={handleRowClick}
          relativeRowHeight={30}
          onStateChange={(query) => handleQueryChange(query)}
          emptyStateTitle=""
          withDnD={withDnD}
          handleFilesUpload={handleFilesUpload}
          handleFilesRejected={handleFilesRejected}
          hidePaging={selector === DOCUMENT_STATUSES.PENDING}
        />
      </div>
      <small className="text-muted">
        Upload documents by drag and drop directly into the list, or send
        documents by email to {clientAccount?.unique_name}@in.snapbooks.app
      </small>
    </div>
  );
};

export default memo(Post);
