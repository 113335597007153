import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { useQuery } from "react-query";

import { BOOTSTRAP_VARIANTS, ObjectTypes } from "../../constants";
import { AsyncSelect, Button } from "../commons";
import noImgImage from "../../assests/images/no-image.png";
import { authenticatedApiClient } from "../../api/apiClients";
import { Organization } from "../../types/Organization";
import { BusinessPartner } from "../../types/BusinessPartner";
import { Pages } from "../../types/Pages";
import { ClientAccountContext } from "../../views/App";

import "./style.scss";

const BusinessPartnerSelect = ({
  label,
  onChange,
  onOrganizationSelected,
  onAddButtonClicked,
  isCheckValid,
  required,
  onlyPeople,
  disabled,
  value,
  tabIndex,
  documentType,
  isPayor,
}: {
  label?: string;
  onChange: (bp: BusinessPartner) => void;
  onOrganizationSelected?: (org: Organization) => void;
  onAddButtonClicked?: () => void;
  isCheckValid?: boolean;
  required?: boolean;
  onlyPeople?: boolean;
  disabled?: boolean;
  value: any;
  tabIndex?: number;
  documentType?: string;
  isPayor?: boolean;
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [businessPartners, setBusinessPartners] = useState<BusinessPartner[]>(
    []
  );

  const clientAccount = useContext(ClientAccountContext);

  const businessPartnersQuery = useQuery(
    [
      "businesspartners",
      {
        description: searchQuery,
        is_organization: onlyPeople == undefined ? undefined : !onlyPeople,
      },
    ],
    () => {
      return authenticatedApiClient
        .get("/business-partners", {
          params: {
            client_account_id: clientAccount?.id,
            description: searchQuery,
            is_organization: onlyPeople == undefined ? undefined : !onlyPeople,
            per_page: 10,
            with: "statistics",
          },
        })
        .then(
          (res) =>
            res.data as { business_partners: BusinessPartner[]; pages: Pages }
        );
    },
    { meta: { ignoreLoadingIndicator: true }, enabled: !!clientAccount }
  );

  useEffect(() => {
    if (businessPartnersQuery?.data) {
      let business_partners =
        businessPartnersQuery.data?.business_partners || [];

      if (documentType) {
        const is_anything = (p: any) => {
          return (
            !p.is_customer && !p.is_supplier && !p.is_employee && !p.is_owner
          );
        };

        business_partners = business_partners.filter((p) => {
          if (!p.statistics) {
            return p;
          }

          const statistic = p.statistics as any;

          if (documentType === ObjectTypes.AP_RECEIPT) {
            if (isPayor) {
              return (
                statistic.is_employee ||
                statistic.is_owner ||
                (is_anything(statistic) && !p.is_organization)
              );
            }
            return statistic.is_supplier || is_anything(statistic);
          }

          if (documentType === ObjectTypes.AR_INVOICE) {
            return statistic.is_customer || is_anything(statistic);
          }

          if (documentType === ObjectTypes.AP_INVOICE) {
            return statistic.is_supplier || is_anything(statistic);
          }

          if (documentType === ObjectTypes.AR_CREDIT_NOTE) {
            return statistic.is_customer || is_anything(statistic);
          }

          if (documentType === ObjectTypes.AP_CREDIT_NOTE) {
            return statistic.is_supplier || is_anything(statistic);
          }

          return p;
        });
      }

      setBusinessPartners(business_partners);
    }
  }, [documentType, businessPartnersQuery?.data]);

  const organizationsQuery = useQuery(
    ["organizations", searchQuery],
    () => {
      return authenticatedApiClient
        .get("/organizations", {
          params: { description: searchQuery, per_page: 100 },
        })
        .then(
          (res) => res.data as { organizations: Organization[]; pages: Pages }
        );
    },
    { meta: { ignoreLoadingIndicator: true }, enabled: !onlyPeople }
  );

  const SuppliersMenu = (props: any) => (
    <components.Menu {...props}>
      {props.children}
      <div className="add-new-supplier">
        <Button
          size="sm"
          variant={BOOTSTRAP_VARIANTS.SECONDARY}
          text={t("Add business partner")}
          onClick={() => onAddButtonClicked && onAddButtonClicked()}
        />
      </div>
    </components.Menu>
  );

  const SupplierOption = (props: any) => (
    <components.Option {...props}>
      <div>
        <img src={props.data.logo ? props.data.logo : noImgImage} alt="" />
      </div>
      <div>
        <div>{props.data.name}</div>
        <div>{`${props.data.address}, ${props.data.city}`}</div>
      </div>
    </components.Option>
  );

  const groupedBusinessPartners = [
    {
      label: t("Used"),
      options: [],
    },
    {
      label: t("Businesspartners"),
      options: (businessPartners || []).map((bp) => ({
        ...bp,
        __type: "bp",
      })),
    },
    ...(!onlyPeople && !!onOrganizationSelected
      ? [
          {
            label: t("Search"),
            options:
              (organizationsQuery.data?.organizations || [])
                .filter(
                  (org) =>
                    !(businessPartners || []).find(
                      (bp) => bp.organization_number === org.organization_number
                    )
                )
                .map((org) => ({ ...org, __type: "org" })) || [],
            from_organization_search: true,
          },
        ]
      : []),
  ];

  const onChangeInternal = (item: any) => {
    if (item?.__type === "org") {
      const bp = businessPartnersQuery.data?.business_partners?.find(
        (i) =>
          i.organization_number === item.organization_number ||
          i.organization_id === item.id
      );
      if (bp) onChange(bp);
      else
        onOrganizationSelected && onOrganizationSelected(item as Organization);
    } else onChange(item as BusinessPartner);
  };

  const formatGroupLabel = (data: any) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span className="group-name">{data.label}</span>
      <span
        style={{
          backgroundColor: "#EBECF0",
          borderRadius: "2em",
          color: "#172B4D",
          display: "inline-block",
          fontSize: 12,
          fontWeight: "normal",
          lineHeight: "1",
          minWidth: 1,
          padding: "0.16666666666667em 0.5em",
          textAlign: "center",
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  return (
    <AsyncSelect
      size="md"
      label={label}
      options={groupedBusinessPartners}
      filterOption={() => true}
      loading={businessPartnersQuery.isLoading || organizationsQuery.isLoading}
      formatGroupLabel={formatGroupLabel}
      components={{
        Option: SupplierOption,
        ...(onAddButtonClicked && { Menu: SuppliersMenu }),
      }}
      className="supplier select"
      onChange={onChangeInternal}
      onInputChange={(v: string) => setSearchQuery(v)}
      onClose={() => setSearchQuery(undefined)}
      value={value}
      getOptionLabel={(opt: any) => opt.name}
      getOptionValue={(opt: any) => opt.id}
      isCheckValid={isCheckValid}
      required={required}
      disabled={!!disabled}
      tabIndex={tabIndex}
      placeholder={undefined}
      noPadding={undefined}
    />
  );
};

export default BusinessPartnerSelect;
