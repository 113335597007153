import React, { useEffect, useContext, memo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { LinkButton, DefaultLogo } from "../../components/commons";
import { OverviewChart } from "../../components";
import {
  IconArrowRight,
  IconGreenArrow,
  IconRedArrow,
  IconRightVioletArrow,
} from "../../assests/icons";
import { currencyFormat, formatDate } from "../../utils/common";

import { useGetOverview } from "../../api";
import { ClientAccountContext, PusherContext } from "../App";

import SummaryLoader from "./summaryLoader";
import RPBLoader from "./RPBLoader";

import "./style.scss";

const Overview = () => {
  const { t } = useTranslation();
  const pusher = useContext(PusherContext);
  const clientAccount = useContext(ClientAccountContext);

  const {
    data: overview,
    isLoading: overviewFetching,
    refetch: refetchOverview,
  } = useGetOverview(clientAccount?.id);

  let totalReceivables = 0;
  let totalPayables = 0;
  if (overview) {
    totalReceivables = overview.receivables.reduce(
      (totalReceivables: any, item: any) => totalReceivables + item.gross_total,
      0
    );
    totalPayables = overview.payables.reduce(
      (totalPayables: any, item: any) => totalPayables + item.gross_total,
      0
    );
  }

  const loadSummary = () => {
    if (!overview || overviewFetching) {
      return <SummaryLoader />;
    }

    return (
      <>
        <div>
          <div>{t("overview.sales")}</div>
          <div>
            {currencyFormat(overview.sales, overview.client_currency, true)}{" "}
            <IconGreenArrow />
          </div>
        </div>
        <div>
          <div>{t("overview.expenses")}</div>
          <div>
            {currencyFormat(overview.expenses, overview.client_currency, true)}{" "}
            <IconRedArrow />
          </div>
        </div>
        <div>
          <div>
            {overview.results >= 0 ? t("overview.profit") : t("overview.loss")}
          </div>
          <div>
            {currencyFormat(overview.results, overview.client_currency, true)}
          </div>
        </div>
      </>
    );
  };

  const isPayable = (item: any) => {
    // To-Do: implement proper statuses handling
    if (item.payment_status === null) {
      return true;
    }
    if (item.payment_status_error_message !== null) {
      return true;
    }
    return false;
  };

  const paymentStatus = (item: any) => {
    const zdata_statuses: any = {
      0: "requested",
      1: "to be approved",
      2: "rejected",
      3: "ready for transfer",
      4: "pending",
      5: "rejected",
      6: "accepted",
      7: "completed",
      8: "dismissed",
      9: "reconciled",
    };
    const sbanken_statuses: any = {
      0: "requested",
      1: "to be approved",
      2: "ready for due date",
      3: "processing",
      4: "stopped",
      5: "validated",
      6: "incomplete",
      7: "lack of funds",
      8: "to be verified",
      9: "rejected",
      10: "cancelled",
      11: "completed",
    };

    if (item.payment_service === null) {
      return "";
    } else if (item.payment_status_error_message !== null) {
      return `${item.payment_status_error_message}`;
    } else if (item.payment_service === "Z") {
      return `ZD. ${zdata_statuses[item.payment_status]}`;
    } else if (item.payment_service === "S") {
      return `SB. ${sbanken_statuses[item.payment_status]}`;
    }
  };

  const loadRPB = () => {
    if (!overview || overviewFetching) {
      return (
        <>
          <RPBLoader />
          <RPBLoader />
        </>
      );
    }

    const totalBankAccounts = overview.bank_accounts.reduce(
      (totalBankAccounts: any, item: any) => totalBankAccounts + item.balance,
      0
    );

    return (
      <>
        <div className="receivables-group">{t("overview.receivables")}</div>
        {overview.receivables.map((item: any, index: number) => (
          <div key={index} className="receivables-item">
            <div>
              {item.logo ? (
                <img
                  src={item.logo}
                  alt=""
                  className="default-logo default-logo--md"
                />
              ) : (
                <DefaultLogo title={item.business_partner_name} />
              )}
            </div>
            <div>{item.business_partner_name}</div>
            <div>{formatDate(item.due_date)}</div>
            <div className="amount">
              {currencyFormat(
                item.gross_total || 0,
                item.currency_symbol,
                true
              )}
            </div>
          </div>
        ))}
        <div className="receivables-item">
          {overview.receivables.length ? (
            <>
              <div />
              <div />
              <div />
              <div className="total">
                <LinkButton
                  text={currencyFormat(
                    totalReceivables,
                    overview.client_currency,
                    true
                  )}
                />
              </div>
            </>
          ) : (
            <div className="no-data-item">
              {t("overview.no_open_documents")}
            </div>
          )}
        </div>

        <div className="receivables-group">{t("overview.payables")}</div>
        {overview.payables.map((item: any, index: number) => (
          <div key={index} className="payables-item">
            <div>
              {item.logo ? (
                <img
                  src={item.logo}
                  alt=""
                  className="default-logo default-logo--md"
                />
              ) : (
                <DefaultLogo title={item.business_partner_name} />
              )}
            </div>
            <div>{item.business_partner_name}</div>
            <div>{formatDate(item.due_date)}</div>
            <div className="amount">
              {currencyFormat(
                item.gross_total -
                  (item.already_paid_amount_fc ?? item.already_paid_amount) ||
                  0,
                item.currency_symbol,
                true
              )}
            </div>
            {isPayable(item) ? (
              <div className="payment" role="button">
                <IconRightVioletArrow />
              </div>
            ) : (
              <div className="payment-disabled" role="button">
                <IconArrowRight />
              </div>
            )}
            <div>{paymentStatus(item)}</div>
          </div>
        ))}
        <div className="receivables-item">
          {overview.payables.length ? (
            <>
              <div />
              <div />
              <div />
              <div className="total">
                <LinkButton
                  text={currencyFormat(
                    totalPayables,
                    overview.client_currency,
                    true
                  )}
                />
              </div>
            </>
          ) : (
            <div className="no-data-item">
              {t("overview.no_open_documents")}
            </div>
          )}
        </div>

        <div className="bank-accounts-group">{t("overview.bank_accounts")}</div>
        {overview.bank_accounts.map((item: any) => (
          <div key={item.id} className="bank-accounts-item">
            <div>
              <LinkButton text={item.name as string} />
            </div>
            <div>{formatDate(item.date)}</div>
            <div className="amount">
              {currencyFormat(item.balance, item.currency, true)}
            </div>
          </div>
        ))}
        <div className="bank-accounts-item">
          {overview.bank_accounts.length ? (
            <>
              <div />
              <div />
              <div className="total">
                <LinkButton
                  text={currencyFormat(
                    totalBankAccounts,
                    overview.client_currency,
                    true
                  )}
                />
              </div>
            </>
          ) : (
            <div className="no-data-item">
              {t("overview.no_bank_account_info")}
            </div>
          )}
        </div>
      </>
    );
  };

  const getTitle = () => {
    if (!clientAccount) {
      return t("app.loadingTitle");
    }

    return clientAccount.display_name;
  };

  return (
    <div className={cn("overview-page")}>
      <div className="title">{getTitle()}</div>
      <div className={cn("summary", { loading: overviewFetching })}>
        {loadSummary()}
      </div>
      <div className="pt-3">
        <OverviewChart
          loading={overviewFetching}
          chartData={overview?.charts_new}
          currency={overview?.client_currency}
        />
      </div>
      {loadRPB()}
    </div>
  );
};

export default memo(Overview);
