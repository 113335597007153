import cn from "classnames";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";

import { IconEye, IconEyeHidden } from "../../../assests/icons";
import { validateEmail } from "../../../utils/common";

import { debounce } from "lodash";
import "./style.scss";

interface InputProps {
  label?: string;
  onChange?: (v: any) => void;
  value: any;
  type?: any;
  onPasswordIconClick?: any;
  className?: string;
  autocompleteItems?: any;
  isPassword?: boolean;
  placeholder?: string;
  size?: string | number;
  required?: boolean;
  focus?: any;
  onEnter?: any;
  noPadding?: boolean;
  isCheckValid?: boolean;
  shouldCheckEmail?: boolean;
  setEmailValid?: any;
  debounceTime?: number;
  [x: string]: any;
  ref?: any
}

const Input = (props: InputProps) => {
  const {
    label,
    onChange,
    value,
    type,
    onPasswordIconClick,
    className,
    autocompleteItems,
    isPassword,
    placeholder,
    size,
    required,
    focus,
    onEnter,
    noPadding,
    isCheckValid,
    shouldCheckEmail,
    setEmailValid,
    debounceTime,
    loading,
    ref,
    ...otherProps
  } = props;
  const { t } = useTranslation();

  const [checkValid, changeCheckValid] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [focusing, setFocusing] = useState(false);

  const [internalValue, setInternalValue] = useState(value);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (focus) {
      setTimeout(() => inputRef.current?.focus());
    }
  }, [focus]);

  useEffect(() => {
    if (autocompleteItems && autocompleteItems.length > 0) {
      if (value) {
        setFilteredData(
          autocompleteItems.filter(
            (i: any) => i.search(new RegExp(value, "i")) !== -1
          )
        );
      } else {
        setFilteredData([]);
      }
    } else {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isCheckValid) changeCheckValid(true);
  }, [isCheckValid]);

  const handleOnBlur = () => {
    changeCheckValid(true);
    setTimeout(() => setFocusing(false), 300);
  };

  const handleOnFocus = () => {
    setFocusing(true);
  };

  const internalChangeHandler = (value: any) => {
    onChange && onChange(value);
    shouldCheckEmail && setEmailValid(validateEmail(value));
  };

  const debouncedOnChangeHandler = useMemo(
    () => debounce(internalChangeHandler, debounceTime),
    []
  );

  const handleOnChange = (value: any) => {
    setInternalValue(value);

    if (debounceTime) debouncedOnChangeHandler(value);
    else internalChangeHandler(value);
  };

  let isInvalid = false;

  if (required && checkValid) {
    isInvalid = value == null || value === "";
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };

  return (
    <div
      className={cn("input-control", {
        [`input-${size}`]: size,
        [className || ""]: className,
        invalid: isInvalid,
        "no-padding": noPadding,
      })}
    >
      {label && (
        <div className={cn("label", required && "required")}>{label}</div>
      )}
      <input
        placeholder={placeholder || t("common_components.input.placeholder")}
        ref={(el: HTMLInputElement) => {
          inputRef.current = el;
          ref && ref(el)
        }}
        type={type || "text"}
        value={internalValue}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyDown={handleKeyDown}
        {...otherProps}
      />
      {loading == true && <Spinner className="spinner" animation="border" size="sm" />}
      {isPassword && (
        <span
          role="button"
          onClick={onPasswordIconClick}
          className="password-icon"
        >
          {type === "password" ? <IconEyeHidden /> : <IconEye />}
        </span>
      )}
      {focusing && filteredData && filteredData.length > 0 && (
        <div className="autocomplete-items">
          {filteredData.map((item, index) => (
            <div
              key={item + index}
              role="button"
              onClick={() => onChange && onChange(item)}
              className="autocomplete-item"
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(Input);
