import { useQuery, useMutation } from "react-query";

import {
  authenticatedApiClient,
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "./apiClients";
import { Contract } from "../types/Contract";

export function useGetClientAccounts(params: any) {
  // const method = this.Methods.GET
  // const endpoint = '/client-accounts/'

  return useQuery(
    ["getClientAccounts", params],
    async () => {
      const res = await authenticatedApiClient.get(
        `/client-accounts/${params.id || ""}`,
        { params }
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!params }
  );
}

export const useGetClientAccountsV2 = () => {
  return useQuery(
    "getClientAccountsV2",
    async () => {
      const res = await authenticatedApiV2Client.get("/client-accounts/?with=client_contracts,users,provider_contracts&per_page=500");
      throwErrorByStatus(res);
      return res.data;
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
}

export function useGetVATLiabilityOfAllContracts(clientAccounts: number[] = []) {
  return useQuery(
    ["getVATLiabilityOfAllContracts"],
    async () => {
      const result = [];

      for (let index = 0; index < clientAccounts.length; index++) {
        const clientAccountId = clientAccounts[index];
        const res = await authenticatedApiClient.get(
          `/client-accounts/${clientAccountId}`,
          { expanded: 1 } as any
        );
        throwErrorByStatus(res);
        result.push(res.data);
      }

      return result;
    },
    { enabled: clientAccounts && clientAccounts.length > 0 }
  );
}

export function useGetPendingVouchersOfAllContracts(clientAccounts: number[] = []) {
  return useQuery(
    ["getPendingVouchersOfAllContracts"],
    async () => {
      const result = [];

      for (let index = 0; index < clientAccounts.length; index++) {
        const clientAccountId = clientAccounts[index];
        const res = await authenticatedApiClient.get(`/vouchers`, {
          params: {
            client_account_id: clientAccountId,
            per_page: 1,
            posting_status: "PENDING,PENDING_ANSWER,DRAFT",
            status: "Pending",
          },
        });
        throwErrorByStatus(res);
        result.push({
          id: clientAccountId,
          ...res.data,
        });
      }

      return result;
    },
    { enabled: clientAccounts && clientAccounts.length > 0 }
  );
}

export function useGetPendingQuestionsOfAllContracts(clientAccounts: number[] = []) {
  return useQuery(
    ["getPendingQuestionsOfAllContracts"],
    async () => {
      const result = [];

      for (let index = 0; index < clientAccounts.length; index++) {
        const clientAccountId = clientAccounts[index];
        const res = await authenticatedApiClient.get(`/accounting-questions`, {
          params: {
            client_account_id: clientAccountId,
            per_page: 1,
          },
        });
        throwErrorByStatus(res);
        result.push({
          id: clientAccountId,
          ...res.data,
        });
      }

      return result;
    },
    { enabled: clientAccounts && clientAccounts.length > 0 }
  );
}

export function useGetPendingMessagesOfAllContracts(clientAccounts: number[] = []) {
  return useQuery(
    ["getPendingMessagesOfAllContracts"],
    async () => {
      const res = await authenticatedApiClient.get(`/chat/channels`, {
        params: {
          client_account_ids: clientAccounts.join(","),
          expanded: 1,
        },
      });
      throwErrorByStatus(res);

      return res.data;
    },
    { enabled: clientAccounts && clientAccounts.length > 0 }
  );
}

export function useGetUsers(params: any) {
  // const method = this.Methods.GET
  // const endpoint = '/users/'

  return useQuery(["getUsers", params], async () => {
    const res = await authenticatedApiClient.get(`/users/`, { params });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetUsersStatistic(id: string, expanded = 1) {
  // const method = this.Methods.GET
  // const endpoint = `/users/statistic/${id}`

  return useQuery(["getUsersStatistic", id, expanded], async () => {
    const res = await authenticatedApiClient.get(`/users/statistic/${id}`, {
      params: { expanded },
    });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetContracts(params: any) {
  return useQuery<{ data: Contract[] }, Error>(
    ["getContracts", params],
    async () => {
      const res = await authenticatedApiV2Client.get("/contracts", { params });
      throwErrorByStatus(res);
      return res.data
    }
  );
}

export function useCreateUser() {
  // const method = this.Methods.POST
  // const endpoint = '/users/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/users/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useEditUser() {
  // const method = this.Methods.PUT
  // const endpoint = `/users/${id}`

  return useMutation(async ({ id, ...data }: any) => {
    const res = await authenticatedApiClient.put(`/users/${id}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateContract() {
  // const method = this.Methods.POST
  // const endpoint = '/contracts/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/contracts/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useEditContract() {
  // const method = this.Methods.PUT
  // const endpoint = `/contracts/${id}`

  return useMutation(async ({ id, ...data }: any) => {
    const res = await authenticatedApiClient.put(`/contracts/${id}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateClientCompany() {
  // const method = this.Methods.POST
  // const endpoint = '/client-accounts/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/client-accounts/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useEditClientCompany() {
  // const method = this.Methods.PUT
  // const endpoint = `/client-accounts/${id}`

  return useMutation(async ({ id, ...data }: any) => {
    const res = await authenticatedApiClient.put(
      `/client-accounts/${id}`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useDeleteClientCompany() {
  // const method = this.Methods.DELETE
  // const endpoint = `/client-accounts/${id}`

  return useMutation(async (id) => {
    const res = await authenticatedApiClient.delete(`/client-accounts/${id}`);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useDeleteUser() {
  // const method = this.Methods.DELETE
  // const endpoint = `/users/${id}`

  return useMutation(async (id) => {
    const res = await authenticatedApiClient.delete(`/users/${id}`);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useDeleteContract() {
  // const method = this.Methods.DELETE
  // const endpoint = `/contracts/${id}`

  return useMutation(async (id) => {
    const res = await authenticatedApiClient.delete(`/contracts/${id}`);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateClientUserRelationship() {
  // const method = this.Methods.POST
  // const endpoint = '/client-accounts-users/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(
      "/client-accounts-users/",
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useEditClientUserRelationship() {
  // const method = this.Methods.PUT
  // const endpoint = `/client-accounts-users/${id}`

  return useMutation(async ({ id, ...data }: any) => {
    const res = await authenticatedApiClient.put(
      `/client-accounts-users/${id}`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useDeleteClientUserRelationship() {
  // const method = this.Methods.DELETE
  // const endpoint = `/client-accounts-users/${id}`

  return useMutation(async (id) => {
    const res = await authenticatedApiClient.post(
      `/client-accounts-users/${id}`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}
