import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { ExportToExcelButton } from '../commons'

import './style.scss'

const AdminTableWrap = ({ title, className, filterItems, data, exportData, handleGetAllData, exportButton=true }) =>  (
  <div className={cn('report-page-wrap', className)}>
    <div className="report-header">
      <div className="title">{title}</div>
        {exportButton && <ExportToExcelButton filename={title} data={exportData} handleGetAllData={handleGetAllData} />}
    </div>
    <div className="filter-header">
      {filterItems}
    </div>
    <div className="report-list">
      {data}
    </div>
  </div>
)

AdminTableWrap.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  filterItems: PropTypes.node,
  data: PropTypes.node,
  exportData: PropTypes.array,
}

AdminTableWrap.defaultProps = {
  title: '',
  className: '',
  filterItems: null,
  data: null,
  exportData: [],
}

export default AdminTableWrap
