import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { CurrencyInput, Input } from "../index";
import { currencyFormat } from "../../../utils/common";
import { currencyValues } from "../../../constants";
import { useGetExchangeRates } from "../../../api";

import "./style.scss";

const formatNum = (num) => +num.toFixed(2).replace(/\.0+$/, "");
const formatRate = (num) => +num.toFixed(6).replace(/\.0+$/, "");

const CurrencyExchangeOld = ({
  resultValue,
  resultCurrency,
  accountingCurrency,
  getData,
}) => {
  const [selectedCurrency, changeSelectedCurrency] = useState(
    currencyValues.find((el) => el.value === resultCurrency)
  );
  const [exchangeValue, setExchangeValue] = useState(resultValue);
  const [rate, setRate] = useState(1);
  const [q, setQ] = useState(null);

  const { data } = useGetExchangeRates(q);

  useEffect(() => {
    const newSelectedCurrency = currencyValues.find(
      (el) => el.value === resultCurrency
    );
    changeSelectedCurrency(newSelectedCurrency);
    const newExchangeValue = formatNum(resultValue / rate);
    setExchangeValue(newExchangeValue);
  }, [resultValue, resultCurrency]);

  useEffect(() => {
    const todayDate = moment().format("YYYY-MM-DD");
    if (selectedCurrency.value === accountingCurrency) {
      const newRate = formatRate(1);
      setRate(newRate);
      const newExchangeValue = formatNum(resultValue / newRate);
      setExchangeValue(newExchangeValue);
      getData({
        selectedCurrency,
        rate: newRate,
        exchangeValue: newExchangeValue,
      });
    } else {
      const query = {
        base_currency: selectedCurrency.value,
        counter_currency: resultCurrency,
        date: todayDate,
        expanded: 1,
      };

      setQ(query);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (data?.rate) {
      const newRate = formatRate(data.rate);
      setRate(newRate);
      const newExchangeValue = formatNum(resultValue / newRate);
      setExchangeValue(newExchangeValue);
      getData({
        selectedCurrency,
        rate: newRate,
        exchangeValue: newExchangeValue,
      });
    }
  }, [data?.rate]);

  const handleChangeExchangeValue = (value) => {
    let newExchangeValue = value;
    let newRate = formatRate(resultValue / value);
    if (newRate < 0) {
      newExchangeValue = -newExchangeValue;
      newRate = -newRate;
    }
    setExchangeValue(newExchangeValue);
    setRate(newRate);
    getData({
      selectedCurrency,
      rate: newRate,
      exchangeValue: newExchangeValue,
    });
  };

  const handleChangeRate = (value) => {
    let newRate = value;
    let newExchangeValue = formatNum(resultValue / value);
    if (newRate < 0) {
      newRate = -newRate;
      newExchangeValue = -newExchangeValue;
    }
    setRate(newRate);
    setExchangeValue(newExchangeValue);
    getData({
      selectedCurrency,
      rate: newRate,
      exchangeValue: newExchangeValue,
    });
  };

  return (
    <div className="currency-exchange">
      <CurrencyInput
        size="md"
        currencyCode={selectedCurrency.value}
        onCurrencyCodeChange={(code) =>
          changeSelectedCurrency(currencyValues.find((cv) => cv.value === code))
        }
        value={exchangeValue}
        onChange={handleChangeExchangeValue}
      />
      <div>X</div>
      <Input size="md" value={rate} onChange={handleChangeRate} />
      <div>=</div>
      <div className="result-value">
        {currencyFormat(resultValue, resultCurrency)}
      </div>
    </div>
  );
};

CurrencyExchangeOld.propTypes = {
  resultCurrency: PropTypes.oneOf(["NOK", "USD", "EUR"]),
  resultValue: PropTypes.number,
  getData: PropTypes.func,
};

CurrencyExchangeOld.defaultProps = {
  resultCurrency: "NOK",
  resultValue: 0,
  getData: () => {},
};

export default memo(CurrencyExchangeOld);
