import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Legend, Title, Tooltip, CategoryScale } from 'chart.js'

import 'chartjs-plugin-annotation'
import { randomRGBA } from '../../../utils/common'

ChartJS.register(CategoryScale, LineElement, PointElement, LinearScale, Legend, Title, Tooltip)

import './style.scss'

const LineChart = props => {
  const { options, height, colors, labels, datasets, onLegendColorsUpdated } = props
  const chartRef = useRef(null)

  const chartData = {
    labels,
    datasets
  }

  chartData.datasets = chartData.datasets.map((item, index) => {
    let borderColor

    if (colors && colors.length > index) {
      borderColor = colors[index]
    } else {
      borderColor = randomRGBA(0.8)
    }

    return {
      ...item,
      fill: true,
      lineTension: 0,
      borderColor,
      pointRadius: 4,
      pointHitRadius: 4,
      pointBackgroundColor: 'white',
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointHoverBackgroundColor: borderColor.replace(/[^,]+(?=\))/, '0.5'),
      borderWidth: 2,
      backgroundColor: borderColor.replace(/[^,]+(?=\))/, '0.05')
    }
  })

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart) {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      const text = []

      text.push(`<ul class="line-chart-legend ${chart.id}-legend">`)
      for (let i = 0; i < items.length; i++) {
        text.push(`<li><span id="legend-${i + 1}-item" class="legend-item">
          <span style="background-color:${items[i].strokeStyle}"></span>`)
        if (items[i].text) {
          text.push(items[i].text)
        }
        text.push('</span></li>')
      }
      text.push('</ul>')

      document.getElementById('line-chart-legend').innerHTML = text.join('')
    }
  }

  useEffect(() => {
    if (options && options.legendCallback) {
      console.log(chartRef.current)
      // const legend = chartRef.current.chartInstance.generateLegend()
      // setLegend(legend)
    }

    if (onLegendColorsUpdated) {
      onLegendColorsUpdated(chartData.datasets.map(i => i.borderColor))
    }
  }, [options, datasets, labels, onLegendColorsUpdated])

  return (
    <div className="line-chart">
      <div id="line-chart-legend" className="chart-legend" />
      <Line redraw data={chartData} ref={chartRef} height={height} options={options} plugins={[htmlLegendPlugin]} />
    </div>
  )
}

LineChart.propTypes = {
  datasets: PropTypes.array,
  height: PropTypes.number,
  labels: PropTypes.array,
  colors: PropTypes.array,
  options: PropTypes.object,
  onLegendColorsUpdated: PropTypes.func
}

LineChart.defaultProps = {
  datasets: [],
  labels: [],
  height: 120,
  colors: [],
  options: undefined
}

export default LineChart
