import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconClose } from "../../../assests/icons";
import { useCurrentClientAccount } from "../../../dataHooks/user";
import { FILE_UPLOAD_ACCEPTED } from "../../../constants/common";
import { BOOTSTRAP_VARIANTS, pathKeys } from "../../../constants";
import { Button, UploadFile } from "..";
import { useUploadDocuments } from "../../../api";
import { ClientAccountContext } from "../../../views/App";
import { showErrorNotification } from "../../../utils/toastr";
import "./style.scss";

interface PostFileUploadProps {
  showNewPostModal: boolean;
  setShowNewPostModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PostFileUpload: React.FC<PostFileUploadProps> = ({
  showNewPostModal,
  setShowNewPostModal,
}) => {
  const { mutateAsync: uploadDocuments } = useUploadDocuments();
  const { t } = useTranslation();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [documentsUploading, setDocumentsUploading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const currentClientAccount = useCurrentClientAccount();
  const isCustomer = currentClientAccount?.amICustomer;
  const clientAccount = useContext(ClientAccountContext);

  const handleOnHide = () => {
    setShowNewPostModal(false);
  };

  const handleFilesUploaded = async (files: File[]) => {
    if (files.length) {
      setFileToUpload(files[0]);
    }
  };
  const handleFilesRejected = () => {
    showErrorNotification(
      `${t("client_container.upload_error")} ${FILE_UPLOAD_ACCEPTED.join(", ")}`
    );
  };

  const handleOnSave = async () => {
    if (!fileToUpload) {
      return;
    }
    setDocumentsUploading(true);

    try {
      const data = await uploadDocuments({
        accountId: clientAccount.id,
        files: [fileToUpload],
      });
      const newPostId = data.files[0]?.id;
      if (newPostId) {
        navigate(
          `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${
            isCustomer ? pathKeys.DOCUMENTS : pathKeys.VOUCHERS
          }/${newPostId}`
        );
      }
    } catch (error) {
      console.error("Error uploading documents", error);
    } finally {
      setDocumentsUploading(false);
    }
  };

  return (
    <Modal
      className="new-post-modal"
      show={showNewPostModal}
      size="lg"
      backdrop="static"
      onHide={handleOnHide}
    >
      <Modal.Title className="modal-title-container">
        <div className="upload-title">
          {t(
            isCustomer
              ? "posts.upload_new_document"
              : "posts.upload_new_voucher"
          )}
        </div>
        <span role="button" onClick={handleOnHide} className="close-icon">
          <IconClose />
        </span>
      </Modal.Title>
      <div className="content position-relative">
        <div>
          <UploadFile
            accept={FILE_UPLOAD_ACCEPTED}
            onDropAccepted={handleFilesUploaded}
            onDropRejected={handleFilesRejected}
            multiple={false}
            withIcon
          />
        </div>
        <div className="mx-3 mt-2 ">
          {fileToUpload && (
            <div className="rounded row p-3 px-4 text-secondary bg-light d-flex justify-content-between">
              {fileToUpload.name}
              <CloseButton onClick={() => setFileToUpload(null)} />
            </div>
          )}
        </div>
        <div className="button-list">
          <Button
            size="sm"
            className="ms-1"
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("posts.continue")}
            onClick={handleOnSave}
            disabled={!fileToUpload}
            loading={documentsUploading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PostFileUpload;
