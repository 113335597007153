import { useQueryClient } from "react-query";
import { ClientAccountWithRole, User } from "../types/User";
import { useParams } from "react-router-dom";
import { ClientAccount } from "../types/ClientAccount";
import { useContext, useEffect, useState } from "react";
import { ClientAccountContext } from "../views/App";

export const getIsAccountant = (selectedAccount: ClientAccountWithRole) => {
  return selectedAccount?.role?.name === "AA";
};

export const getIsAgency = (selectedAccount: ClientAccountWithRole) => {
  return selectedAccount?.role?.name === "SA";
};

export const convertToClientAccount = (clientAccountWithRole: ClientAccountWithRole): ClientAccount => {
  return {
    ...clientAccountWithRole.account,
    role: clientAccountWithRole.role.name,
  };
}

export const useUserData = () => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<User>(["User"]);

  return userData;
}

export const useUserAccounts = () => {
  const queryClient = useQueryClient();
  const clientAccounts = queryClient.getQueryData<ClientAccount[]>(["Clients"]);

  return clientAccounts ?? [];
}

export const useCurrentClientAccount = () => {
  const queryClient = useQueryClient();
  const clientAccounts = queryClient.getQueryData<ClientAccount[]>(["Clients"]);
  const { clientId } = useParams<{ clientId: string }>();

  const [userClientAccount, setUserClientAccount] = useState<ClientAccount | undefined>(undefined);

  useEffect(() => {
    if (clientId && clientAccounts && Number(clientId) !== userClientAccount?.id) {
      const clientAccount = clientAccounts.find((account) => {
        return account.id === Number(clientId);
      });

      setUserClientAccount(clientAccount)
    }
  }, [clientId]);

  return userClientAccount;
}

export const useCurrentClientAccountFromContext = () => {
  return useContext(ClientAccountContext);
}
