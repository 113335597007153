import React from 'react'
import ContentLoader from 'react-content-loader'
import './style.scss'

const AccountLoader = () => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
    height="250px"
  >
    {/* <rect x="50%" y="10" rx="5" ry="5" width="50%" height="20" /> */}
    <rect x="0" y="10" rx="5" ry="5" width="100%" height="20" />
    <rect x="40" y="40" rx="5" ry="5" width="calc(50% - 75px)" height="20" />
    <rect x="calc(50% + 40px)" y="40" rx="5" ry="5" width="calc(50% - 75px)" height="20" />

    <rect x="40" y="70" rx="5" ry="5" width="calc(50% - 75px)" height="50" />
    <rect x="calc(50% + 40px)" y="70" rx="5" ry="5" width="calc(50% - 75px)" height="50" />

    <rect x="40" y="130" rx="5" ry="5" width="calc(50% - 75px)" height="50" />
    <rect x="calc(50% + 40px)" y="130" rx="5" ry="5" width="calc(50% - 75px)" height="50" />

    <rect x="40" y="190" rx="5" ry="5" width="calc(50% - 75px)" height="50" />
    <rect x="calc(50% + 40px)" y="190" rx="5" ry="5" width="calc(50% - 75px)" height="50" />
  </ContentLoader>
)

export default AccountLoader
