import React, { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateAccountingQuestions, useCreatePayment } from "../../api";
import { AskQuestionModal } from "../../components";
import { Button, Text } from "../../components/commons";
import GeneralLedgerAccountSelect from "../../components/JournalEntryTable/GeneralLedgerAccountSelect";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { BankAccount } from "../../types/BankAccount";
import { BankTransaction } from "../../types/BankTransactionV2";
import {
  JournalEntry,
  AccountDimensionCombination,
} from "../../types/JournalEntry";
import { Question } from "../../types/Question";
import { ClientAccountContext } from "../App";
import { getBankTransactionDescription } from "./bankTransactionFunctions";

const MultiPaymentCreationContainer = ({
  bankAccount,
  bankTransactions,
  onQuestionsAsked,
  onPaymentsCreated,
  onCancel,
}: {
  bankAccount: BankAccount;
  bankTransactions: BankTransaction[];
  onQuestionsAsked: (j: Question[]) => void;
  onPaymentsCreated: (j: JournalEntry[]) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [
    multiSelectionGeneralLedgerAccount,
    setMultiSelectionGeneralLedgerAccount,
  ] = useState<AccountDimensionCombination>();

  // Helper state
  const [showAskQuestionModal, setShowAskQuestionModal] = useState(false);
  const [mutationInProgress, setMutationInProgress] = useState(false);

  // Mutations
  const { mutateAsync: createAccountingQuestions } =
    useCreateAccountingQuestions();
  const { mutateAsync: createPayment } = useCreatePayment();

  // Event handlers
  const handleAskQuestions = (data: Question) => {
    setMutationInProgress(true);
    Promise.allSettled(
      bankTransactions.map((bankTransaction) => {
        return createAccountingQuestions({
          ...data,
          bank_transaction_id: bankTransaction.id,
        } as any);
      })
    ).then((d) => {
      setMutationInProgress(false);
      setShowAskQuestionModal(false);
      onQuestionsAsked && onQuestionsAsked(d as any);
    });
  };

  const handleCreatePayments = async () => {
    try {
      setMutationInProgress(true);

      const payments = await Promise.all(
        bankTransactions.map((bankTransaction) => {
          const data = {
            client_account_id: clientAccount.id,
            bank_account_id: bankAccount.id,
            bank_transaction_id: bankTransaction.id,
            currency: bankTransaction.currency_code,
            exchange_rate: 1,
            payment_date: bankTransaction.booking_date,
            amount: bankTransaction.amount,
            memo: getBankTransactionDescription(bankTransaction),
            reconciliation_entries: { journal_entries: [] },
            account_entries: [
              {
                account_code:
                  multiSelectionGeneralLedgerAccount?.account.account_code,
                amount: -bankTransaction.amount,
                currency: bankTransaction.currency_code,
                dimensions: multiSelectionGeneralLedgerAccount?.dimension
                  ? [multiSelectionGeneralLedgerAccount.dimension]
                  : [],
              },
            ],
          };

          return createPayment(data as any);
        })
      );

      onPaymentsCreated && onPaymentsCreated(payments);
    } finally {
      setMutationInProgress(false);
    }
  };

  return (
    <>
      <AskQuestionModal
        show={showAskQuestionModal}
        loading={mutationInProgress}
        onHide={() => setShowAskQuestionModal(false)}
        onSave={handleAskQuestions}
        answerTypes={["ATTACH_FILE", "TYPE_ANSWER"]}
      />
      <div>
        <Text>{t("Custom entry")}</Text>
        <div>
          <GeneralLedgerAccountSelect
            account={multiSelectionGeneralLedgerAccount}
            onAccountChange={setMultiSelectionGeneralLedgerAccount}
          />
        </div>
      </div>
      <div className="buttons">
        <div className="buttons-inline">
          <Button
            variant={BOOTSTRAP_VARIANTS.DARK}
            text={t("reconcile.ask_customer")}
            onClick={() => setShowAskQuestionModal(true)}
          />
          <Button
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("reconcile.create_payment")}
            onClick={handleCreatePayments}
            disabled={mutationInProgress}
            loading={mutationInProgress}
          />
        </div>
      </div>
    </>
  );
};

export default memo(MultiPaymentCreationContainer);
