import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useQueryClient, useIsFetching, useIsMutating } from "react-query";

import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { Button, GridView, Loading } from "../../components/commons";
import { showErrorNotification } from "../../utils/toastr";

import { IconEdit, IconGreenQuestions, IconClear } from "../../assests/icons";

import {
  useGetBankAccounts,
  useCreateBankAccounts,
  useEditBankAccounts,
} from "../../api";

import EditBankAccount from "./EditBankAccount";
import "./style.scss";

const BankAccounts = (props) => {
  const { keyWord, clientAccountId } = props;
  const { t } = useTranslation();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const queryClient = useQueryClient();
  const [selectedBankAccount, setBankAccount] = useState(null);
  const [query, setQuery] = useState({ page: 1, pageSize: 7 });

  const { data: bankAccounts, refetch: refetchBankAccounts } =
    useGetBankAccounts(
      clientAccountId,
      null,
      query.page,
      query.pageSize,
      keyWord
    );
  const { mutateAsync: createBankAccounts } = useCreateBankAccounts();
  const { mutateAsync: editBankAccounts } = useEditBankAccounts();

  const handleCreateBankAccount = (bankAccountData) => {
    const data = { ...bankAccountData };
    data.client_account_id = clientAccountId;

    createBankAccounts(data).then(() => {
      setBankAccount(null);
      refetchBankAccounts();
      queryClient.invalidateQueries("dimension-values");
    });
  };

  const handleQueryChange = (data) => {
    const q = {
      ...(query || {}),
      ...data,
    };
    setQuery(q);
  };

  const handleDeactivateClick = (data) => {
    editBankAccounts({ id: data.id, is_active: false }).then(() => {
      refetchBankAccounts();
      queryClient.invalidateQueries("dimension-values");
    });
  };

  const renderTooltip = (props, bank_name) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <div>{bank_name}</div>
      </Tooltip>
    );
  };
  return (
    <div className="detail-page">
      <Loading childLoading loading={isFetching > 0 || isMutating > 0} />
      {selectedBankAccount === null ? (
        <div className="business-partner-list">
          <div className="business-partner-gridview">
            <GridView
              columns={[
                {
                  Header: t("master_data.gridview.id"),
                  accessor: "sequence_number",
                  align: "left",
                  maxWidth: "9%",
                },
                {
                  Header: t("master_data.gridview.name"),
                  accessor: "name",
                  align: "left",
                  maxWidth: "25%",
                },
                {
                  Header: t("Type"),
                  accessor: "type",
                  align: "left",
                  maxWidth: "7%",
                },
                {
                  Header: t("Bank"),
                  accessor: "bank_code",
                  align: "left",
                  maxWidth: "6%",
                  Cell: (cellProps) =>
                    cellProps.value ? (
                      <div className="text-right float-right">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 150, hide: 300 }}
                          overlay={(props) =>
                            renderTooltip(
                              props,
                              cellProps.row.original.bank_name
                            )
                          }
                        >
                          <IconGreenQuestions />
                        </OverlayTrigger>
                      </div>
                    ) : (
                      ""
                    ),
                },
                {
                  Header: t("master_data.gridview.account_number"),
                  accessor: "bban",
                  align: "left",
                  maxWidth: "15%",
                },
                {
                  Header: t("master_data.gridview.swift_btc"),
                  accessor: "bic",
                  align: "left",
                  maxWidth: "12%",
                },
                {
                  Header: t("master_data.gridview.iban"),
                  accessor: "iban",
                  align: "left",
                  maxWidth: "18%",
                },
                {
                  Header: "",
                  accessor: "delete",
                  align: "left",
                  maxWidth: "6%",
                  className: "delete-wrap",
                  Cell: (cellProps) => (
                    <span
                      className="delete-icon"
                      role="button"
                      onClick={() => setBankAccount(cellProps.row.original)}
                    >
                      <IconEdit />
                    </span>
                  ),
                },
                {
                  Header: "",
                  accessor: "deactivate",
                  align: "left",
                  maxWidth: "6%",
                  className: "delete-wrap",
                  Cell: (cellProps) => (
                    <span
                      className="delete-icon"
                      role="button"
                      onClick={() =>
                        handleDeactivateClick(cellProps.row.original)
                      }
                    >
                      <IconClear />
                    </span>
                  ),
                },
              ]}
              data={(bankAccounts || {}).bank_accounts}
              pages={(bankAccounts || {}).pages}
              relativeRowHeight={30}
              onStateChange={(q) => handleQueryChange(q)}
              emptyStateTitle=""
            />
          </div>
          <div className="text-right">
            <Button
              size="md40"
              className="add-button"
              variant={BOOTSTRAP_VARIANTS.SECONDARY}
              text={t("master_data.add_new_bank_account")}
              onClick={() => setBankAccount(undefined)}
            />
          </div>
        </div>
      ) : (
        <EditBankAccount
          onBack={() => setBankAccount(null)}
          onSave={handleCreateBankAccount}
          showErrorNotification={showErrorNotification}
          bankAccount={selectedBankAccount}
        />
      )}
    </div>
  );
};

export default memo(BankAccounts);
