import React, { useEffect, useState, useContext, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  GridView,
  DateIntervalPicker,
  LabelsFilter,
} from "../../../components/commons";
import { ReportWrap } from "../../../components";
import {
  decimalFormat,
  getReportDateRanges,
  setReportDateRanges,
} from "../../../utils/common";

import { useGetIncomeStatementReport } from "../../../api";

import { ClientAccountContext } from "../../App";

import "./style.scss";
import { UseQueryResult } from "react-query";
import _, { sum } from "lodash";
import { authenticatedApiV2Client } from "../../../api/apiClients";

interface IncomeStatementRecord {
  grouping: {
    level_1_code: string;
    level_1_name: string;
    level_2_code: string;
    level_2_name: string;
    level_3_code: string;
    level_3_name: string;
  };
  account_code: string;
  account_name: string;
  period_debit_movements: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    6: number;
    7: number;
    8: number;
    9: number;
    10: number;
    11: number;
    12: number;
  };
}

const IncomeStatementReport = () => {
  const { t } = useTranslation();
  const clientAccount = useContext(ClientAccountContext);

  const reportDateRanges = getReportDateRanges();

  const [startDate, changeStartDate] = useState(reportDateRanges[0]);
  const [endDate, changeEndDate] = useState(reportDateRanges[1]);

  const [parsedReport, changeParsedReport] = useState<any[]>([]);
  const [activeLabels, setActiveLabels] = useState<any[]>([]);

  const reportParams = {
    client_account_id: clientAccount?.id,
    date_from: moment(startDate).format("YYYY-MM-DD"),
    date_to: moment(endDate).format("YYYY-MM-DD"),
    dimensions: activeLabels
      .map((d) => `${d.relation_type}:${d.relation_id}`)
      .join(","),
  };
  const {
    data: incomeStatementReport,
    isLoading: incomeStatementReportFetching,
  } = useGetIncomeStatementReport(reportParams) as UseQueryResult<
    IncomeStatementRecord[],
    boolean
  >;

  useEffect(() => {
    setReportDateRanges(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (incomeStatementReport) {
      const res = parseRawData();
      if (res !== parsedReport) {
        changeParsedReport(res || []);
      }
    }
  }, [incomeStatementReport]);

  const monthsInInterval = _.range(
    startDate.getMonth() + 1,
    endDate.getMonth() + 2
  );

  const parseRawData = () => {
    if (incomeStatementReport && clientAccount?.accounting_currency) {
      const res: any = [];

      const addRecord = (
        level: number,
        description: string,
        amounts: any,
        _class: string
      ) => {
        res.push({
          level,
          description,
          month1Amount: (amounts?.[1] ? decimalFormat(amounts[1], 0) : 0) || "",
          month2Amount: (amounts?.[2] ? decimalFormat(amounts[2], 0) : 0) || "",
          month3Amount: (amounts?.[3] ? decimalFormat(amounts[3], 0) : 0) || "",
          month4Amount: (amounts?.[4] ? decimalFormat(amounts[4], 0) : 0) || "",
          month5Amount: (amounts?.[5] ? decimalFormat(amounts[5], 0) : 0) || "",
          month6Amount: (amounts?.[6] ? decimalFormat(amounts[6], 0) : 0) || "",
          month7Amount: (amounts?.[7] ? decimalFormat(amounts[7], 0) : 0) || "",
          month8Amount: (amounts?.[8] ? decimalFormat(amounts[8], 0) : 0) || "",
          month9Amount: (amounts?.[9] ? decimalFormat(amounts[9], 0) : 0) || "",
          month10Amount:
            (amounts?.[10] ? decimalFormat(amounts[10], 0) : 0) || "",
          month11Amount:
            (amounts?.[11] ? decimalFormat(amounts[11], 0) : 0) || "",
          month12Amount:
            (amounts?.[12] ? decimalFormat(amounts[12], 0) : 0) || "",
          sum: amounts
            ? decimalFormat(sum(Object.values(amounts)), 0)
            : 0 || "",
          class: _class,
        });
      };

      const emptyMovements = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      };

      let current_category_0 = {
        period_debit_movements: { ...emptyMovements },
      } as {
        code?: string;
        name?: string;
        period_debit_movements?: any;
      };
      let current_category_1 = {
        period_debit_movements: { ...emptyMovements },
      } as {
        code?: string;
        name?: string;
        period_debit_movements?: any;
      };
      let current_category_2 = {
        period_debit_movements: { ...emptyMovements },
      } as {
        code?: string;
        name?: string;
        period_debit_movements?: any;
      };

      incomeStatementReport.forEach((record) => {
        if (record.grouping.level_2_code !== current_category_2.code) {
          // Add previous group footer
          if (current_category_2.code)
            addRecord(
              2,
              current_category_2.name || "",
              current_category_2.period_debit_movements,
              "is-indent"
            );

          // Add new group level 1 header and footer
          if (record.grouping.level_1_code !== current_category_1.code) {
            // Add previous group footer
            if (current_category_1.code)
              addRecord(
                1,
                current_category_1.name || "",
                current_category_1.period_debit_movements,
                "is-indent"
              );

            // Add group header
            addRecord(1, record.grouping.level_1_name, null, "category");

            current_category_1.period_debit_movements = { ...emptyMovements };
          }

          // Add new group level 2 header
          addRecord(2, record.grouping.level_2_name, null, "is-indent");

          current_category_2.period_debit_movements = { ...emptyMovements };
        }

        addRecord(
          3,
          `${record.account_code} - ${record.account_name}`,
          record.period_debit_movements,
          "is-indent-2"
        );

        current_category_0 = {
          period_debit_movements: Object.entries(
            record.period_debit_movements
          ).reduce(
            (acc, [k, v]) => ({ ...acc, [k]: (acc[k] || 0) + v }),
            current_category_0.period_debit_movements
          ),
        };
        current_category_1 = {
          code: record.grouping.level_1_code,
          name: record.grouping.level_1_name,
          period_debit_movements: Object.entries(
            record.period_debit_movements
          ).reduce(
            (acc, [k, v]) => ({ ...acc, [k]: (acc[k] || 0) + v }),
            current_category_1.period_debit_movements
          ),
        };

        current_category_2 = {
          code: record.grouping.level_2_code,
          name: record.grouping.level_2_name,
          period_debit_movements: Object.entries(
            record.period_debit_movements
          ).reduce(
            (acc, [k, v]) => ({ ...acc, [k]: (acc[k] || 0) + v }),
            current_category_2.period_debit_movements
          ),
        };
      });

      if (res.length > 0) {
        addRecord(
          2,
          current_category_2.name || "",
          current_category_2.period_debit_movements,
          "is-indent"
        );
        addRecord(
          1,
          current_category_1.name || "",
          current_category_1.period_debit_movements,
          "is-indent"
        );
        addRecord(
          0,
          `Profit & Loss`,
          current_category_0.period_debit_movements,
          "result, is-underline"
        );
      }
      return res;
    }
  };

  const exportOptions: any = {
    EXCEL: {
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      filename: "Income statement.xlsx",
    },
    PDF: { mimeType: "application/pdf", filename: "Income statement.pdf" },
  };

  const onExportClicked = async (exportOption: string) => {
    const response = await authenticatedApiV2Client.get(
      "/reports/income-statement",
      {
        responseType: "blob",
        headers: {
          Accept: exportOptions[exportOption].mimeType,
        },
        params: reportParams,
      }
    );

    const exportURL = window.URL.createObjectURL(response.data);
    const tempLink = document.createElement("a");
    tempLink.href = exportURL;
    tempLink.setAttribute("download", exportOptions[exportOption].filename);
    tempLink.click();
  };

  return (
    <ReportWrap
      className="income-statement-report"
      title={t("income_statement.title")}
      onExportClicked={onExportClicked}
      exportOptions={Object.keys(exportOptions)}
      filterItems={
        <>
          <div className="filter-row">
            <DateIntervalPicker
              dateFormat="dd/MM/yyyy"
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={changeStartDate}
              onChangeEndDate={changeEndDate}
            />
            <LabelsFilter
              activeLabels={activeLabels}
              setActiveLabels={setActiveLabels}
              noMargin
            />
          </div>
        </>
      }
      data={
        <GridView
          columns={[
            {
              Header: t("income_statement.gridview.description"),
              accessor: "description",
              align: "left",
              classCustom: "is-underline",
              Cell: (cellProps) => (
                <div
                  style={{
                    marginLeft: `${
                      Math.max(0, cellProps.row.original.level - 1) * 30
                    }px`,
                    fontWeight: `${
                      Math.abs(3 - cellProps.row.original.level) * 300
                    }`,
                  }}
                >
                  {cellProps.value}
                </div>
              ),
            },
            ...(monthsInInterval.map((m) => ({
              Header: t(`month_names_abreviated.month${m}`),
              accessor: `month${m}Amount`,
              align: "right",
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: `${
                      Math.abs(3 - cellProps.row.original.level) * 300
                    }`,
                  }}
                >
                  {cellProps.value}
                </span>
              ),
              maxWidth: 800 / monthsInInterval.length + 1,
            })) || []),
            {
              Header: t("Sum {{currency}}", {
                currency: clientAccount?.accounting_currency,
              }),
              accessor: "sum",
              align: "right",
              maxWidth: 800 / monthsInInterval.length + 1,
              classCustom: "is-underline",
              Cell: (cellProps) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {cellProps.value}
                </span>
              ),
            },
          ]}
          data={parsedReport}
          loading={incomeStatementReportFetching}
          relativeRowHeight={30}
          emptyStateTitle=""
          stickyHeader
        />
      }
    />
  );
};

export default IncomeStatementReport;
