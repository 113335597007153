const MONTH1 = "January"
const MONTH2 = "February"
const MONTH3 = "March"
const MONTH4 = "April"
const MONTH5 = "May"
const MONTH6 = "June"
const MONTH7 = "July"
const MONTH8 = "August"
const MONTH9 = "September"
const MONTH10 = "October"
const MONTH11 = "November"
const MONTH12 = "December"
const TERM1 = "Term 1"
const TERM2 = "Term 2"
const TERM3 = "Term 3"
const TERM4 = "Term 4"
const TERM5 = "Term 5"
const TERM6 = "Term 6"
const Q1 = "First quarter"
const Q2 = "Second quarter"
const Q3 = "Third quarter"
const Q4 = "Fourth quarter"
const H1 = "First half"
const H2 = "Second half"

const ARRAY_ARCHIVED_DOCUMENT_PERIODS = [
  { label: 'archived_document_periods.month1', value: 'January' },
  { label: 'archived_document_periods.month2', value: 'February' },
  { label: 'archived_document_periods.month3', value: 'March' },
  { label: 'archived_document_periods.month4', value: 'April' },
  { label: 'archived_document_periods.month5', value: 'May' },
  { label: 'archived_document_periods.month6', value: 'June' },
  { label: 'archived_document_periods.month7', value: 'July' },
  { label: 'archived_document_periods.month8', value: 'August' },
  { label: 'archived_document_periods.month9', value: 'September' },
  { label: 'archived_document_periods.month10', value: 'October' },
  { label: 'archived_document_periods.month11', value: 'November' },
  { label: 'archived_document_periods.month12', value: 'December' },
  { label: 'archived_document_periods.term1', value: 'Term 1' },
  { label: 'archived_document_periods.term2', value: 'Term 2' },
  { label: 'archived_document_periods.term3', value: 'Term 3' },
  { label: 'archived_document_periods.term4', value: 'Term 4' },
  { label: 'archived_document_periods.term5', value: 'Term 5' },
  { label: 'archived_document_periods.term6', value: 'Term 6' },
  { label: 'archived_document_periods.q1', value: 'First quarter' },
  { label: 'archived_document_periods.q2', value: 'Second quarter' },
  { label: 'archived_document_periods.q3', value: 'Third quarter' },
  { label: 'archived_document_periods.q4', value: 'Fourth quarter' },
  { label: 'archived_document_periods.h1', value: 'First half' },
  { label: 'archived_document_periods.h2', value: 'Second half' }
]

export default {
  MONTH1,
  MONTH2,
  MONTH3,
  MONTH4,
  MONTH5,
  MONTH6,
  MONTH7,
  MONTH8,
  MONTH9,
  MONTH10,
  MONTH11,
  MONTH12,
  TERM1,
  TERM2,
  TERM3,
  TERM4,
  TERM5,
  TERM6,
  Q1,
  Q2,
  Q3,
  Q4,
  H1,
  H2,
  toArray: () => ARRAY_ARCHIVED_DOCUMENT_PERIODS
}
