import { useContext } from "react";
import { useGetBusinessPartner } from "../../api/businessPartnerApi";
import { IconClear, IconLabel, IconVioletLabel } from "../../assests/icons";
import { ClientAccount } from "../../types/ClientAccount";
import {
  PaymentAccountEntry,
  PaymentDraft,
  PaymentTransfer,
} from "../../types/Payments";
import { ClientAccountContext } from "../../views/App";
import BankAccountSelect from "../BankAccountSelect/BankAccountSelect";
import BusinessPartnerSelect from "../BusinessPartnerSelect/BusinessPartnerSelect";
import GeneralLedgerAccountSelect from "../JournalEntryTable/GeneralLedgerAccountSelect";
import TaxCodeSelect from "../JournalEntryTable/TaxCodeSelect";
import { CurrencyInput, DatePicker, Input, Text } from "../commons";
import { PaymentDraftReducerAction } from "./PaymentDraftReducer";
import { useGetDimensionValues, useGetTaxCodes } from "../../api";
import { useTranslation } from "react-i18next";
import { useGetGeneralLedgerAccounts } from "../../api/accountingApi";
import { TaxCode } from "../../types/TaxCode";
import { GeneralLedgerAccount as GeneralLedgerAccountV2 } from "../../types/GeneralLedgerAccountV2";
import getLabelAbbreviation from "../../constants/labelShortcuts";
import { AccountDimensionCombination } from "../../types/JournalEntryV2";

export const PaymentDraftEditorRow = ({
  paymentDraft,
  paymentDraftDispatcher,
  onEditDimensionsClick,
  onAddBankAccountClick,
  transfer,
  disabled,
  tabIndex,
}: {
  paymentDraft: PaymentDraft;
  paymentDraftDispatcher: React.Dispatch<PaymentDraftReducerAction>;
  onEditDimensionsClick: () => void;
  onAddBankAccountClick: () => void;
  transfer: PaymentTransfer;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  // Queries
  const taxCodesQuery = useGetTaxCodes(clientAccount?.id);
  const dimensionValuesQuery = useGetDimensionValues(clientAccount);
  const generalLedgerAccountsQuery = useGetGeneralLedgerAccounts(
    clientAccount?.id
  );

  const index = paymentDraft.transfers.indexOf(transfer);
  const businessPartnerQuery = useGetBusinessPartner(
    transfer.creditor?.bank_account?.business_partner_id
  );

  const getDimensionValue = (relation_type: string, relation_id: number) => {
    return dimensionValuesQuery.data?.dimension_values?.find(
      (l: any) =>
        l?.relation_type === relation_type && l?.relation_id === relation_id
    );
  };

  const getTaxCode = (id?: number) => {
    return id
      ? taxCodesQuery.data?.tax_codes.find((tc: TaxCode) => tc.id === id)
      : null;
  };

  const getAccountDimensionCombination = (pae: PaymentAccountEntry) => {
    if (!pae) return;

    const account = generalLedgerAccountsQuery.data?.accounting_accounts.find(
      (a) => a.account_code === pae.account_code
    ) as unknown as GeneralLedgerAccountV2;

    if (!account) return;

    const dimension = pae.dimensions?.find(
      (dim) =>
        !!dim &&
        dim.relation_type === account?.mandatory_dimensions?.[0]?.relation_type
    );

    return { account, dimension } as AccountDimensionCombination;
  };

  const getDimensionsPopoverText = (index: number) => {
    let text = "";
    const transfer = paymentDraft?.transfers[index];
    if (!transfer?.account_entries?.[0].dimensions?.length) return text;

    transfer?.account_entries?.[0].dimensions?.forEach(
      ({ relation_type, relation_id }) => {
        if (relation_type) {
          const dim = getDimensionValue(relation_type, relation_id);
          text = text.concat(
            `<div>${getLabelAbbreviation(relation_type) || relation_type} - ${
              dim?.relation_name
            }</div>`
          );
        }
      }
    );
    return text;
  };

  return (
    <>
      <div className="payment-draft-transfer-list-item transfer">
        <div>
          <DatePicker
            selectedDate={
              transfer.requested_transfer_date
                ? new Date(transfer.requested_transfer_date)
                : undefined
            }
            onChange={(dt) =>
              paymentDraftDispatcher({
                type: "setTransferRequestedTransferDate",
                transferIndex: index,
                value: dt,
              })
            }
            required
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 1}
          />
        </div>
        <div>
          <BankAccountSelect
            value={transfer.debtor?.bank_account}
            onChange={(ba) =>
              paymentDraftDispatcher({
                type: "setTransferDebtorAccount",
                transferIndex: index,
                value: ba,
              })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 2}
          />
        </div>
        <div>
          <BusinessPartnerSelect
            value={businessPartnerQuery.data}
            onChange={(bp) =>
              paymentDraftDispatcher({
                type: "setTransferCreditorBusinessPartner",
                transferIndex: index,
                value: bp,
              })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 3}
          />
        </div>
        <div>
          <BankAccountSelect
            value={transfer.creditor?.bank_account}
            businessPartnerId={businessPartnerQuery.data?.id}
            onChange={(ba) =>
              paymentDraftDispatcher({
                type: "setTransferCreditorAccount",
                transferIndex: index,
                value: ba,
              })
            }
            onAddButtonClicked={() =>
              onAddBankAccountClick && onAddBankAccountClick()
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 4}
          />
        </div>
        <div>
          <CurrencyInput
            value={transfer.amount}
            onChange={(amount) =>
              paymentDraftDispatcher({
                type: "setTransferAmount",
                transferIndex: index,
                value: amount,
              })
            }
            currencyCode={
              transfer.currency_code || clientAccount.accounting_currency
            }
            onCurrencyCodeChange={(currencyCode) =>
              paymentDraftDispatcher({
                type: "setTransferCurrencyCode",
                transferIndex: index,
                value: currencyCode,
              })
            }
            size="md"
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 5}
          />
        </div>
        <div>
          <Input
            size="md"
            value={transfer.creditor_reference || ""}
            onChange={(reference: string) =>
              paymentDraftDispatcher({
                type: "setTransferCreditorReference",
                transferIndex: index,
                value: reference,
              })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 6}
          />
        </div>
        <div>
          <span
            className="clear-icon"
            role="button"
            onClick={() =>
              !disabled &&
              paymentDraftDispatcher({
                type: "removeTransferByIndex",
                transferIndex: index,
              })
            }
          >
            <IconClear />
          </span>
        </div>
      </div>
      <div className="payment-draft-transfer-list-item account-entry">
        <div>{t("Will be posted to:")}</div>
        <div>
          <GeneralLedgerAccountSelect
            account={getAccountDimensionCombination(
              transfer.account_entries?.[0] || ({} as PaymentAccountEntry)
            )}
            onAccountChange={(account) =>
              paymentDraftDispatcher({
                type: "setTransferAccount",
                transferIndex: index,
                value: account,
              })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 7}
          />
        </div>
        <div>
          <TaxCodeSelect
            taxCode={getTaxCode(
              transfer.account_entries?.[0]?.tax_code_id || undefined
            )}
            onTaxCodeChange={(taxCode) =>
              paymentDraftDispatcher({
                type: "setTransferTaxCode",
                transferIndex: index,
                value: taxCode,
              })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 8}
          />
        </div>
        <div>
          <Input
            size="md"
            value={transfer.account_entries?.[0]?.memo || ""}
            onChange={(memo: string) =>
              paymentDraftDispatcher({
                type: "setTransferMemo",
                transferIndex: index,
                value: memo,
              })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 9}
          />
        </div>
        <div>
          <div
            className="label-wrap"
            role="button"
            onClick={() =>
              !disabled && onEditDimensionsClick && onEditDimensionsClick()
            }
          >
            {transfer.account_entries?.[0]?.dimensions?.length ? (
              <Text
                as="span"
                placement="top"
                popover
                popoverText={getDimensionsPopoverText(index)}
                whitePopoverBackground
              >
                {transfer.account_entries[0].dimensions.length}
                <IconVioletLabel />
              </Text>
            ) : (
              <span>
                0 <IconLabel />
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
