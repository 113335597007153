export const getSelectStyles = (disabled, size) => ({
  control: () => ({
    display: "flex",
    flexWrap: "nowrap",
    background: disabled ? "#f5f6f7" : "#fff",
    borderRight: "1px solid #D5DADF",
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
  }),
  valueContainer: () => ({
    maxWidth: 44,
    height: size === "lg" ? 38 : 33,
    paddingLeft: 4,
    paddingRight: 4,
    color: disabled ? "#ACB5C0" : "rgba(65, 72, 90, 0.6)",
    textAlign: "center",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
  singleValue: () => ({
    height: size === "lg" ? 38 : 33,
    lineHeight: size === "lg" ? "38px" : "33px",
  }),
});
