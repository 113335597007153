import { useMutation } from "react-query";
import { authenticatedApiClient, throwErrorByStatus } from "../../api/apiClients";

interface SignUpData {
  organization_id: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
}

export function useSignUp() {
  return useMutation(async (data: SignUpData) => {
    const res = await authenticatedApiClient.post("/auth/signup", data);
    throwErrorByStatus(res);
    return res.data;
  });
}
