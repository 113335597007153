import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import {
  authenticatedApiClient,
  throwErrorByStatus,
} from "../../../api/apiClients";
import { Input } from "../../../components/commons";
import { pathKeys } from "../../../constants";
import { ClientAccountContext } from "../../App";
import { useDebounce } from "../../../utils";

import "./style.scss";

const PaymentReferenceField = (props) => {
  const {
    value,
    onChange,
    disabled,
    documentType,
    voucherId,
    businessPartnerId,
    tabIndex,
  } = props;

  const { t } = useTranslation();
  const { clientId } = useParams();
  const debouncedvalue = useDebounce(value);

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Helper state
  const [showDuplicateWarning, setShowDuplicateWarning] = useState(false);
  const [duplicateVoucherId, setDuplicateVoucherId] = useState();

  const checkExistingDocuments = (result) => {
    const duplicate = result?.documents?.find(
      (el) =>
        el.business_partner_id === businessPartnerId &&
        el.document_type === documentType &&
        el.payment_ref === value &&
        !el.files?.find((f) => f.type === "VOUCHER" && f.is_active === false) &&
        (!voucherId || el.file_id !== voucherId)
    );

    setShowDuplicateWarning(!!duplicate);
    setDuplicateVoucherId(duplicate?.file_id);
  };

  // Queries
  const { isLoading } = useQuery(
    [
      "documents",
      {
        client_account_id: clientAccount?.id,
        business_partner_id: businessPartnerId,
        document_type: documentType,
        payment_ref: debouncedvalue,
      },
    ],
    async () => {
      const res = await authenticatedApiClient.get("/documents", {
        params: {
          client_account_id: clientAccount?.id,
          business_partner_id: businessPartnerId,
          document_type: documentType,
          payment_ref: debouncedvalue,
        },
      });
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: debouncedvalue?.length > 2,
      meta: { ignoreLoadingIndicator: true },
      onSuccess: checkExistingDocuments,
    }
  );

  const onDuplicateLabelClicked = () => {
    if (duplicateVoucherId) {
      const win = window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}/${duplicateVoucherId}`,
        "_blank"
      );
      win.focus();
    }
  };

  const label = (
    <>
      {t("post_detail.payment_reference")}
      {showDuplicateWarning && (
        <a
          style={{
            position: "absolute",
            right: "0px",
            fontSize: "0.8em",
            fontWeight: "bold",
            color: "red",
            lineHeight: "1.8em",
          }}
          role="link"
          onClick={() => onDuplicateLabelClicked()}
        >
          {t("Duplicate?")}
        </a>
      )}
    </>
  );

  return (
    <>
      <Input
        size="md"
        label={label}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
        tabIndex={tabIndex}
        loading={isLoading}
      />
    </>
  );
};

export default PaymentReferenceField;
