import React, { useState, memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { InputGroup } from 'react-bootstrap'

import { IconEye, IconEyeHidden } from '../../../assests/icons'

import './style.scss'

const Inputgroup = ({
  prepend, append,  label, onChange, value, type, onPassordIconClick, className, autocompleteItems,
  isPassword, placeholder, size, required, focus, onEnter, noPadding, isCheckValid, ...otherProps
}) => {
  const { t } = useTranslation()

  const [checkValid, changeCheckValid] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [focusing, setFocusing] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (focus) {
      setTimeout(() => inputRef.current.focus())
    }
  }, [focus])

  useEffect(() => {
    if (autocompleteItems && autocompleteItems.length > 0) {
      if (value) {
        setFilteredData(autocompleteItems.filter(i => i.search(new RegExp(value, 'i')) !== -1))
      } else {
        setFilteredData([])
      }
    }
  }, [value])

  useEffect(() => {
    if (isCheckValid) changeCheckValid(true)
  }, [isCheckValid])

  const handleOnBlur = () => {
    changeCheckValid(true)
    setTimeout(() => setFocusing(false), 300)
  }

  const handleOnFocus = () => {
    setFocusing(true)
  }

  let isInvalid = false

  if (required && checkValid) {
    isInvalid = (value == null || value === '')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnter && onEnter()
    }
  }

  return (
    <div className={cn('input-group-control',
      {
        [`input-${size}`]: size != null,
        [className]: className != null,
        invalid: isInvalid,
        'no-padding': noPadding
      })}>
      {label && <div className={cn('label', required && 'required')}>{label}</div>}
      <InputGroup>
        {prepend &&
          <InputGroup.Prepend>
            {prepend}
          </InputGroup.Prepend>
        }
        <input placeholder={placeholder || t('common_components.input.placeholder')}
               ref={inputRef}
               type={type || 'text'} value={value}
               onChange={e => onChange(e.target.value)}
               onBlur={handleOnBlur}
               onFocus={handleOnFocus}
               onKeyDown={handleKeyDown}
               {...otherProps}
        />
        {isPassword && <span role="button" onClick={onPassordIconClick} className="password-icon">
        {type === 'password' ? <IconEyeHidden /> : <IconEye />}</span>}
        {focusing && filteredData && filteredData.length > 0 && <div className="autocomplete-items">
          {filteredData.map((item, index) => {
            return <div key={item + index} role="button" onClick={() => onChange(item)}
                        className="autocomplete-item">{item}</div>
          })}
        </div>}


        {append &&
        <InputGroup.Append>
          {append}
        </InputGroup.Append>
        }
      </InputGroup>
    </div>
  )
}

InputGroup.propTypes = {
  prepend: PropTypes.string,
  append: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onPassordIconClick: PropTypes.func,
  isPassword: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  required: PropTypes.bool,
  focus: PropTypes.bool,
  onEnter: PropTypes.func,
  noPadding: PropTypes.bool,
  isCheckValid: PropTypes.bool
}

export default memo(Inputgroup)
