import React from 'react'
import 'currency-flags/dist/currency-flags.min.css'

export default [
  {
    label: <div className="currency-label-with-flag"><div>USD</div><div className="currency-flag currency-flag-usd" /></div>,
    value: 'USD'
  },
  {
    label: <div className="currency-label-with-flag"><div>EUR</div><div className="currency-flag currency-flag-eur" /></div>,
    value: 'EUR'
  },
  {
    label: <div className="currency-label-with-flag"><div>NOK</div><div className="currency-flag currency-flag-nok" /></div>,
    value: 'NOK'
  }
]
