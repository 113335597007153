import ReactHtmlParser from "html-react-parser";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReportWrap } from "../../../components";
import {
  AsyncSelect,
  DateIntervalPicker,
  GridView,
  Input,
} from "../../../components/commons";
import {
  colorizeKeyword,
  detectLanguage,
  formatNumberToLocaleValue,
  getReportDateRanges,
  setReportDateRanges,
} from "../../../utils/common";

import { getVatReport, useGetCurrency, useGetVatReport } from "../../../api";
import { ClientAccountContext } from "../../App";

import { useGetGeneralLedgerAccounts } from "../../../api/accountingApi";
import "./style.scss";

const VatReport = () => {
  const { t } = useTranslation();
  const clientAccount = useContext(ClientAccountContext);

  const [selectedAccount, setSelectedAccount] = useState(null);

  const reportDateRanges = getReportDateRanges();

  const [startDate, changeStartDate] = useState(reportDateRanges[0]);
  const [endDate, changeEndDate] = useState(reportDateRanges[1]);

  const [keyWord, changeKeyWord] = useState("");
  const [parsedReport, changeParsedReport] = useState([]);
  const [lines, setLines] = useState({ 0: {} });
  const [vatReportParams, setVatReportParams] = useState(null);

  const { data: currency } = useGetCurrency(clientAccount?.id, false);
  const {
    data: accountingAccounts,
    isLoading: accountingAccountsFetching,
    refetch: refetchAccountingAccounts,
  } = useGetGeneralLedgerAccounts(clientAccount?.id);

  const { data: vatReport, isLoading: vatReportFetching } =
    useGetVatReport(vatReportParams);

  const handleQueryChange = (data) => {
    const q = {
      client_account_id: clientAccount.id,
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
      pageSize: 10,
      ...(data || {}),
    };
    setVatReportParams(q);
  };

  useEffect(() => {
    setReportDateRanges(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (clientAccount) {
      handleQueryChange();
    }
  }, [clientAccount, startDate, endDate, keyWord, selectedAccount]);

  useEffect(() => {
    if (vatReport) {
      const res = parseRawData(vatReport);
      if (res !== parsedReport) {
        changeParsedReport(parseRawData(vatReport));
      }
    }
  }, [vatReport, currency]);

  const parseRawData = (report) => {
    if (report) {
      const formattedReport = [];
      report.journal_entries.forEach((el) => {
        const newEl = {
          ...el,
          vat_amount: formatNumberToLocaleValue(el.vat_amount),
          vat_base_amount: formatNumberToLocaleValue(el.vat_base_amount),
        };
        formattedReport.push(newEl);
      });
      return formattedReport;
    } else {
      return [];
    }
  };

  const handleSetLines = (value, key, index, field) => {
    const newLines = { ...lines };
    newLines[key].line_id = index + 1;
    newLines[key][field] = value;
    setLines(newLines);
    setSelectedAccount((value || {}).account_code);
  };

  const exportData = (report) =>
    report?.map((el) => ({
      [t("vat.gridview.item_number")]: el.item_number,
      [t("vat.gridview.amount")]: el.vat_amount,
      [t("vat.gridview.base_amount")]: el.vat_base_amount,
      [t("vat.gridview.description")]: el.description,
    }));

  const handleGetAllData = async () => {
    const lang = detectLanguage();

    const q = {
      client_account_id: clientAccount.id,
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
      // description: keyWord,
      // account_code: selectedAccount,
      pageSize: Infinity,
    };

    const { data: report } = await getVatReport({ ...q, lang });

    const res = exportData(parseRawData(report));
    return { data: res, labels: [] };
  };

  return (
    <ReportWrap
      className="general-leader-report"
      title={t("vat.title")}
      handleGetAllData={handleGetAllData}
      filterItems={
        <>
          <div className="filter-row">
            <AsyncSelect
              size="md"
              className="account-code"
              options={accountingAccounts}
              loading={accountingAccountsFetching}
              onChange={(item) => handleSetLines(item, 0, 0, "account_code")}
              onInputChange={() => refetchAccountingAccounts()}
              value={(lines[0] || {}).account_code}
              defaultOptions={(lines[0] || {}).accounting_account}
              getOptionLabel={({ account_code, description_key }) =>
                `${account_code} ${description_key}`
              }
              getOptionValue={({ id }) => id}
              disabled
            />
            <DateIntervalPicker
              dateFormat="dd/MM/yyyy"
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={changeStartDate}
              onChangeEndDate={changeEndDate}
            />
            <Input
              size="md"
              placeholder={t("common_components.input.placeholder_search")}
              value={keyWord}
              onChange={changeKeyWord}
              disabled
            />
          </div>
        </>
      }
      data={
        <GridView
          columns={[
            {
              Header: t("vat.gridview.item_number"),
              accessor: "item_number",
              align: "left",
              maxWidth: 60,
            },
            {
              Header: t("vat.gridview.description"),
              accessor: "description",
              align: "left",
              Cell: (cellProps) => {
                if (
                  !keyWord ||
                  cellProps.value === t("general_ledger.ingoing_balance") ||
                  cellProps.value === t("general_ledger.outgoing_balance")
                ) {
                  return cellProps.value;
                }
                const newSentence = colorizeKeyword(cellProps.value, keyWord);
                return ReactHtmlParser(newSentence);
              },
              // maxWidth: 150
            },
            {
              Header: t("vat.gridview.amount"),
              accessor: "vat_amount",
              align: "left",
              maxWidth: 150,
            },
            {
              Header: t("vat.gridview.base_amount"),
              accessor: "vat_base_amount",
              align: "left",
              maxWidth: 150,
            },
          ]}
          data={parsedReport}
          //        pages={(vatReport || {}).pages}
          loading={vatReportFetching}
          relativeRowHeight={30}
          onStateChange={(query) => handleQueryChange(query)}
          emptyStateTitle=""
        />
      }
    />
  );
};

export default memo(VatReport);
