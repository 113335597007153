import React, { useEffect, useState, useContext, memo } from "react";
import cn from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import { ChannelList, MessagesList } from "../../components/Chat";
import NewThreadModal from "./NewThreadModal";
import ManageRecipientsSelect from "./ManageRecipientsSelect";

import {
  useGetChatChannels,
  useGetChatMessages,
  useCreateChatMessage,
  useGetChatUsers,
  useCreateChatChannel,
  useUpdateChatUser,
  useReadChatMessage,
} from "../../api";
import { ClientAccountContext } from "../App";

import "./style.scss";

const Messages = () => {
  const location = useLocation();
  const clientAccount = useContext(ClientAccountContext);
  const queryClient = useQueryClient();
  const { clientId } = useParams();

  const user = queryClient.getQueryData(["User"]);

  const clientAccountId = clientAccount?.id;
  const currentAccountId = clientAccount?.account?.id;
  const [activeChannel, setActiveChannel] = useState(null);
  const [creatingNewThread, setCreatingNewThread] = useState(false);
  const [showNewThreadModal, setShowNewThreadModal] = useState(
    !!location.state?.showNewThreadModal
  );
  const [showManageRecipientsSelect, setShowManageRecipientsSelect] =
    useState(false);

  const {
    data: chatChannels,
    isLoading: chatChannelsFetching,
    refetch: refetchChatChannels,
  } = useGetChatChannels(
    {
      client_account_ids: clientId, //user?.contracts.map(el => el.client_account_id).join(','),
      current_account_id: currentAccountId,
      expanded: 1,
    },
    true
  );

  const { data: chatUsers } = useGetChatUsers(clientAccountId);
  const {
    data: chatMessages,
    isLoading: chatMessagesFetching,
    refetch: refetchChatMessages,
  } = useGetChatMessages(activeChannel?.channel_id);
  const { mutateAsync: createChatMessage, isLoading: creatingChatMessage } =
    useCreateChatMessage();
  const { mutateAsync: readChatMessage } = useReadChatMessage();
  const { mutateAsync: updateChatUser } = useUpdateChatUser();
  const { mutateAsync: createChatChannel } = useCreateChatChannel();

  useEffect(() => {
    if (chatChannels && chatChannels.length) {
      if (!activeChannel && location.state?.channel) {
        // set channel if it comes from GL
        handleChannelSelect(location.state?.channel);
      } else if (!activeChannel) {
        // set first channel as default
        handleChannelSelect(chatChannels[0]);
      } else {
        // update current activeChannel
        const newActiveChannel = chatChannels.find(
          ({ channel_id }) => channel_id === activeChannel?.channel_id
        );
        setActiveChannel(newActiveChannel);
      }
    } else {
      setActiveChannel(null);
      // clearChatMessages()
      if (activeChannel) {
        queryClient.removeQueries([
          "getChatMessages",
          activeChannel.channel_id,
        ]);
      }
    }
  }, [chatChannels]);

  useEffect(() => {
    if (
      chatMessages &&
      chatMessages.messages &&
      chatMessages.messages.length &&
      activeChannel
    ) {
      // read messages when they are opened for seeing
      const newMessageIds = chatMessages.messages.reduce((res, message) => {
        if (!message.read_flags?.read_flag) {
          res.push(message.id);
        }
        return res;
      }, []);
      if (newMessageIds.length) {
        Promise.all(
          newMessageIds.map(async (message_id) => {
            await readChatMessage({
              channel_id: activeChannel.channel_id,
              message_id,
            });
          })
        ).then(() => {
          refetchChatChannels();
          refetchChatMessages();
        });
      }
    }
  }, [chatMessages]);

  const handleChannelSelect = (channel) => {
    setActiveChannel(channel);
  };

  const handleMessageSend = (message) => {
    const data = { message, channel: activeChannel.channel_id };
    createChatMessage(data).then(() => {
      refetchChatMessages();
    });
  };

  const handleThreadStart = (d) => {
    setCreatingNewThread(true);
    const data = {
      client_account_id: clientAccountId,
      channel_name: d.subject,
      user_id: [...d.recipients.map((el) => el.id), user.id],
    };
    createChatChannel(data).then(({ channels }) => {
      const data = { message: d.text, channel: channels.channel_id };
      createChatMessage(data).then(() => {
        refetchChatChannels();
        handleChannelSelect(channels);
        setCreatingNewThread(false);
        setShowNewThreadModal(false);
      });
    });
  };

  const handleAddChatUser = (id) => {
    updateChatUser({ user_id: id, channelId: activeChannel?.channel_id }).then(
      () => {
        refetchChatChannels();
      }
    );
  };

  return (
    <div className={cn("messages-screen")}>
      <NewThreadModal
        loading={creatingNewThread}
        show={showNewThreadModal}
        onHide={() => setShowNewThreadModal(false)}
        users={chatUsers?.users}
        onAdd={handleThreadStart}
        isAccountant={true}
      />
      <ManageRecipientsSelect
        show={showManageRecipientsSelect}
        onHide={() => setShowManageRecipientsSelect(false)}
        onAdd={handleAddChatUser}
        recipients={chatUsers?.users}
        channelUsers={activeChannel?.users}
      />
      <ChannelList
        loading={chatChannelsFetching}
        channels={chatChannels}
        activeChannel={activeChannel}
        handleChannelSelect={handleChannelSelect}
        addNew={() => setShowNewThreadModal(true)}
      />
      <MessagesList
        loading={chatMessagesFetching || creatingChatMessage}
        channel={activeChannel}
        messages={chatMessages?.messages}
        onMessageSend={handleMessageSend}
        onAddRecipient={() => setShowManageRecipientsSelect(true)}
      />
    </div>
  );
};

export default memo(Messages);
