import { useMutation } from "react-query";
import { authenticatedApiClient, throwErrorByStatus } from "./apiClients";

interface NewPasswordBody {
  token: string;
  password: string;
}

export function useSubmitNewPassword() {
  // const method = this.Methods.POST;
  // const endpoint = "/new-password";

  return useMutation(async (data: NewPasswordBody) => {
    const res = await authenticatedApiClient.post(`/new-password`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}
