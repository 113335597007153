import cn from "classnames";
import React, { useEffect, useState, useContext, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _, { every } from "lodash";
import { useParams } from "react-router-dom";

import {
  AsyncSelect,
  Button,
  CheckBox,
  ConfirmModal,
  CurrencyInput,
  Select,
  Text,
} from "../../components/commons";
import { pathKeys } from "../../constants";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";

import {
  currencyFormat,
  localeFormatDate,
  truncateString,
} from "../../utils/common";

import {
  useGetBusinessPartners,
  useGetJournalEntryLinesV1,
  useCreateJournalEntry,
  useCreateReconciliation,
} from "../../api";

import { ClientAccountContext } from "../App";

import Loader from "./loader";
import "./style.scss";
import GeneralLedgerAccountSelect from "../../components/JournalEntryTable/GeneralLedgerAccountSelect";
import {
  IconCircleC,
  IconCircleR,
  IconReceipt,
  IconReconciled,
} from "../../assests/icons";
import ReconciliationDetailsPopup from "../../components/commons/ReconciliationDetailsPopup";
import { PreviewTypes, preview } from "../Preview";
import JournalEntryDetailsPopup from "../../components/commons/JournalEntryDetailsPopup";
import {
  useJournalEntryReducer,
  useSaveJournalEntry,
} from "../../api/accountingApi";
import JournalEntryEditorModal from "../../components/JournalEntryEditorModal";
import { authenticatedApiClient } from "../../api/apiClients";

const InternalReconciliation = () => {
  const { t } = useTranslation();
  const clientAccount = useContext(ClientAccountContext);

  const [accountCode, setAccountCode] = useState({});
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState({});
  const [selectedJournalEntryLines, setSelectedJournalEntryLines] = useState(
    []
  );
  const [lastToggledJournalEntryLine, setLastToggledJournalEntryLine] =
    useState();
  const [reconciliationDetailsPopupId, setReconciliationDetailsPopupId] =
    useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [businessPartnersDesc, setBusinessPartnersDesc] = useState(null);
  const [journalEntryLinesQ, setJournalEntryLinesQ] = useState(null);
  const [businessPartners, setBusinessPartners] = useState([]);
  const [journalEntryDetailsPopupId, setJournalEntryDetailsPopupId] =
    useState();

  // Custom Journal Entry Modal
  const [showCustomJournalEntryModal, setShowCustomJournalEntryModal] =
    useState(false);
  const [
    customJournalEntry,
    customJournalEntryDispatch,
    journalEntryController,
  ] = useJournalEntryReducer(clientAccount?.id);

  const { data, isLoading: businessPartnersFetching } = useGetBusinessPartners(
    clientAccount?.id,
    businessPartnersDesc,
    500,
    null,
    true,
    1,
    false
  );

  const {
    data: jelData,
    isLoading: journalEntryLinesFetching,
    refetch: refetchJournalEntryLines,
  } = useGetJournalEntryLinesV1(journalEntryLinesQ);
  const journalEntryLines = jelData;

  const { mutateAsync: createJournalEntry } = useCreateJournalEntry();
  const { mutateAsync: saveJournalEntry } = useSaveJournalEntry();
  const { mutateAsync: createReconciliation } = useCreateReconciliation();

  useEffect(() => {
    if (clientAccount) {
      setAccountCode(accountCodeOptions[0]);
    }
  }, [clientAccount]);

  useEffect(() => {
    if (accountCode?.value) {
      queryJournalEntryLines();
      setSelectedJournalEntryLines([]);
    }
  }, [accountCode, selectedBusinessPartner]);

  useEffect(() => {
    if (data && accountCode) {
      let business_partners = data.business_partners || [];
      const is_anything = (p) => {
        return (
          !p.is_customer && !p.is_supplier && !p.is_employee && !p.is_owner
        );
      };

      business_partners = business_partners.filter((p) => {
        if (!p.statistics) {
          return p;
        }

        const statistic = p.statistics;

        if (accountCode.value == "1500") {
          return statistic.is_customer || is_anything(statistic);
        }

        if (accountCode.value == "2400") {
          return statistic.is_supplier || is_anything(statistic);
        }

        return p;
      });

      setBusinessPartners(business_partners);
    }
  }, [accountCode, data]);

  const queryJournalEntryLines = () => {
    setJournalEntryLinesQ({
      client_account_id: clientAccount.id,
      account_code: accountCode.value,
      relation_type: "businesspartner",
      relation_id: selectedBusinessPartner?.id,
      reconciliation_status: "NONE, PART",
      ignore_draft: true,
      expanded: 1,
    });
  };

  const accountCodeOptions = [
    { value: "1500", label: "Accounts Receivable" },
    { value: "2400", label: "Accounts Payable" },
  ];

  const toggleJournalEntryLine = (val, multiToggle) => {
    if (multiToggle) {
      const lastToggledIndex = journalEntryLines.findIndex(
        (jel) => jel.id === lastToggledJournalEntryLine.id
      );
      const valIndex = journalEntryLines.findIndex((jel) => jel.id === val.id);

      const start = Math.min(lastToggledIndex, valIndex);
      const end = Math.max(lastToggledIndex, valIndex);

      const jels = [...selectedJournalEntryLines];
      for (let i = start; i <= end; i++) {
        const jel = journalEntryLines[i];
        if (
          i === lastToggledIndex ||
          getJournalEntryLineBusinessPartnerId(jel) !==
            getJournalEntryLineBusinessPartnerId(val)
        )
          continue;
        if (jels.findIndex((l) => l.id === jel.id) === -1) {
          jels.push({
            ...jel,
            openAmount: getOpenAmount(jel),
            appliedAmount: getOpenAmount(jel),
          });
        } else {
          jels.splice(
            jels.findIndex((l) => l.id === jel.id),
            1
          );
        }
      }

      setSelectedJournalEntryLines([...jels]);
    } else {
      if (selectedJournalEntryLines.findIndex((l) => l.id === val.id) > -1) {
        setSelectedJournalEntryLines(
          selectedJournalEntryLines.filter((l) => l.id !== val.id)
        );
      } else {
        setSelectedJournalEntryLines([
          ...selectedJournalEntryLines,
          {
            ...val,
            openAmount: getOpenAmount(val),
            appliedAmount: getOpenAmount(val),
          },
        ]);
      }
    }

    setLastToggledJournalEntryLine(val);
  };

  const onAppliedAmountChange = (jel, val) => {
    const lines = [...selectedJournalEntryLines];
    const index = lines.findIndex((l) => l.id === jel.id);

    const line = { ...lines[index] };
    line.appliedAmount = val;
    lines[index] = line;

    setSelectedJournalEntryLines(lines);
  };

  const getAmountLC = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getAmountLC(cur), 0);
    else return jel.debit - jel.credit;
  };

  const getAmountFC = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getAmountFC(cur), 0);
    else return jel.debit_fc - jel.credit_fc;
  };

  const getAmount = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getAmount(cur), 0);
    else
      return jel.currency_code === clientAccount.accounting_currency
        ? getAmountLC(jel)
        : getAmountFC(jel);
  };

  const getOpenAmountLC = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getOpenAmountLC(cur), 0);
    else
      return (
        jel.debit -
        jel.credit -
        jel.reconciled_amount * Math.sign(jel.debit - jel.credit)
      );
  };

  const getOpenAmountFC = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getOpenAmountFC(cur), 0);
    else
      return (
        jel.debit_fc -
        jel.credit_fc -
        jel.reconciled_amount_fc * Math.sign(jel.debit_fc - jel.credit_fc)
      );
  };

  const getOpenAmount = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getOpenAmount(cur), 0);
    else
      return jel.currency_code === clientAccount.accounting_currency
        ? getOpenAmountLC(jel)
        : getOpenAmountFC(jel);
  };

  const getAppliedAmount = (jel) => {
    if (Array.isArray(jel))
      return jel.reduce((prev, cur) => prev + getAppliedAmount(cur), 0);
    else
      return (
        selectedJournalEntryLines?.find((l) => l.id === jel.id)
          ?.appliedAmount || 0
      );
  };

  const getPostingDate = () => {
    return selectedJournalEntryLines.reduce(
      (prev, cur) => (cur.posting_date > prev ? cur.posting_date : prev),
      selectedJournalEntryLines[0].posting_date
    );
  };

  const selectedPostingCurrencyCode = selectedJournalEntryLines
    .map((jel) => jel.currency_code)
    .find(() => true);

  const selectedJournalEntryLinesRelationId = selectedJournalEntryLines
    ?.map(
      (jel) =>
        jel?.dimensions?.find((dim) => dim.relation_type === "businesspartner")
          ?.relation_id
    )
    ?.find(() => true);

  const isOpenAppliedAmount =
    currencyFormat(
      Math.abs(getAppliedAmount(selectedJournalEntryLines)),
      selectedPostingCurrencyCode
    ) !== currencyFormat(0, selectedPostingCurrencyCode);

  const getJournalEntryLineBusinessPartnerId = (jel) => {
    return jel?.dimensions?.find(
      (dim) => dim.relation_type === "businesspartner"
    )?.relation_id;
  };

  const getJournalEntryLinesGrouped = () => {
    const groups = _.uniq(
      journalEntryLines.map((jel) => getJournalEntryLineBusinessPartnerId(jel))
    ).sort((a, b) =>
      (businessPartners?.find((bp) => bp.id === a)?.name || a) >
      (businessPartners?.find((bp) => bp.id === b)?.name || b)
        ? 1
        : -1
    );

    return groups.map((g) => ({
      businessPartnerId: g,
      lines: journalEntryLines.filter((jel) =>
        jel.dimensions.find(
          (d) => d.relation_type === "businesspartner" && d.relation_id === g
        )
      ),
    }));
  };

  const onReconciliationDetailsPopupHide = (changes) => {
    setReconciliationDetailsPopupId(null);
    if (changes) refetchJournalEntryLines();
  };

  const onJournalEntryDetailsPopupHide = (changes) => {
    setJournalEntryDetailsPopupId(null);
    if (changes) refetchJournalEntryLines();
  };

  const onPostDifferenceButtonClick = () => {
    const appliedAmount = getAppliedAmount(selectedJournalEntryLines);
    const postingDate = getPostingDate();

    if (Math.abs(appliedAmount) > 0) {
      const journal_entry = {
        client_account_id: clientAccount.id,
        posting_date: moment(postingDate).format("YYYY-MM-DD"),
        memo: "Reconciliation difference posting",
        journal_entry_lines: [
          {
            line_id: 1,
            accounting_account_code: accountCode.value,
            dimensions: [
              {
                relation_type: "businesspartner",
                relation_id: selectedJournalEntryLinesRelationId,
              },
            ],
            debit_fc: -appliedAmount > 0 ? -appliedAmount : 0,
            credit_fc: -appliedAmount < 0 ? appliedAmount : 0,
            currency_code: selectedPostingCurrencyCode,
          },
          {
            line_id: 2,
            accounting_account_code: selectedAccount.account.account_code,
            debit_fc: appliedAmount > 0 ? appliedAmount : 0,
            credit_fc: appliedAmount < 0 ? -appliedAmount : 0,
            currency_code: selectedPostingCurrencyCode,
          },
        ],
      };

      createJournalEntry(journal_entry).then((je) => {
        refetchJournalEntryLines().then(() => {
          toggleJournalEntryLine(
            je.journal_entry_lines.find(
              (l) => l.accounting_account_code === accountCode.value
            )
          );
        });
      });
    }
  };

  const onReconcileButtonClick = () => {
    const reconciliationDate = selectedJournalEntryLines.reduce(
      (prev, cur) => (prev > cur.posting_date ? prev : cur.posting_date),
      selectedJournalEntryLines[0].posting_date
    );

    const reconciliation = {
      date: moment(reconciliationDate).format("YYYY-MM-DD"),
      client_account_id: clientAccount.id,
      entry_lines: selectedJournalEntryLines.map((l) => {
        const isForeignCurrency =
          l.currency_code !== clientAccount.accounting_currency;
        const exchangeRate = isForeignCurrency ? l.exchange_rate : 1;
        const appliedDebit = l.appliedAmount > 0 ? l.appliedAmount : 0;
        const appliedCredit = l.appliedAmount < 0 ? -l.appliedAmount : 0;

        return {
          journal_entry_id: l.journal_entry_id,
          journal_entry_line_id: l.id,
          debit: appliedDebit * exchangeRate,
          debit_fc: isForeignCurrency ? appliedDebit : null,
          credit: appliedCredit * exchangeRate,
          credit_fc: isForeignCurrency ? appliedCredit : null,
          currency_code: l.currency_code,
          exchange_rate: exchangeRate,
        };
      }),
    };

    createReconciliation(reconciliation).then((data) => {
      if (data && data.id) {
        refetchJournalEntryLines();
        setSelectedJournalEntryLines([]);
      }
    });
  };

  const onBalanceClicked = async (group) => {
    const openAmountLC = getOpenAmountLC(group.lines);
    const openAmountFC = getOpenAmountFC(group.lines);
    const businessPartner = (
      await authenticatedApiClient.get(
        "/business-partners/" + group.businessPartnerId
      )
    ).data;

    customJournalEntryDispatch({
      type: "set",
      payload: {
        client_account_id: clientAccount.id,
        description: t(`Move balance of ${businessPartner.name} to `),
        lines: [
          {
            line_id: 1,
            posting_date: moment().format("YYYY-MM-DD"),
            account_code: accountCode.value,
            debit_fc: openAmountFC < 0 ? -openAmountFC : 0,
            credit_fc: openAmountFC > 0 ? openAmountFC : 0,
            currency_code: group.lines[0].currency_code,
            dimensions: [
              {
                relation_type: "businesspartner",
                relation_id: group.businessPartnerId,
              },
            ],
          },
          {
            line_id: 2,
            posting_date: moment().format("YYYY-MM-DD"),
            debit_fc: openAmountFC > 0 ? openAmountFC : 0,
            credit_fc: openAmountFC < 0 ? -openAmountFC : 0,
            currency_code: group.lines[0].currency_code,
          },
        ],
      },
    });

    setShowCustomJournalEntryModal(true);
  };

  const handleAddCustomJournalEntry = async (journal_entry) => {
    saveJournalEntry(journal_entry).then((je) => {
      refetchJournalEntryLines();
      setShowCustomJournalEntryModal(false);
    });
  };

  const handleSearchBusinessPartner = (e) => {
    setBusinessPartnersDesc(e);
  };

  const handleCleanSearch = () => {
    setBusinessPartnersDesc(null);
  };

  const handleOpenVoucher = (
    voucherId,
    journalEntry = null,
    fullScreen = false
  ) => {
    if (fullScreen) {
      window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.POSTS}/${voucherId}`,
        "_blank"
      );
    } else {
      preview(PreviewTypes.Voucher, voucherId);
    }
  };

  const debitAmountCell = (amount_lc, amount_fc, currency_code) => {
    return (
      <Text>
        {clientAccount.accounting_currency === currency_code
          ? currencyFormat(amount_lc, currency_code)
          : currencyFormat(amount_fc, currency_code)}
        {clientAccount.accounting_currency !== currency_code ? (
          <>
            <br />
            <small style={{ opacity: "50%" }}>
              {currencyFormat(amount_lc, clientAccount.accounting_currency)}
            </small>
          </>
        ) : null}
      </Text>
    );
  };

  return (
    <div className={cn("internal-reconciliation-page")}>
      <ConfirmModal
        title={t("posts.do_you_want_to_delete_item")}
        show={false}
        onHide={() => false}
      />
      <JournalEntryEditorModal
        journalEntry={customJournalEntry}
        journalEntryDispatch={customJournalEntryDispatch}
        journalEntryController={journalEntryController}
        show={showCustomJournalEntryModal}
        onHide={() => setShowCustomJournalEntryModal(false)}
        onAdd={handleAddCustomJournalEntry}
      />
      <JournalEntryDetailsPopup
        journalEntryId={journalEntryDetailsPopupId}
        show={!!journalEntryDetailsPopupId}
        onHide={onJournalEntryDetailsPopupHide}
      />
      <ReconciliationDetailsPopup
        journalEntryLineId={reconciliationDetailsPopupId}
        show={!!reconciliationDetailsPopupId}
        onHide={onReconciliationDetailsPopupHide}
      />
      <div className="title-wrap">
        <div className="title">{t("internal_reconciliation.title")}</div>
      </div>
      <div className="filter-header">
        <div className="filter-row">
          <div>
            <Select
              size="md"
              className="tax-codes"
              options={accountCodeOptions}
              value={accountCode}
              onChange={setAccountCode}
            />
          </div>
          <div>
            <AsyncSelect
              size="md"
              options={businessPartners}
              filterOption={() => true}
              loading={businessPartnersFetching}
              className="tax-codes"
              onChange={setSelectedBusinessPartner}
              onInputChange={handleSearchBusinessPartner}
              value={selectedBusinessPartner}
              getOptionLabel={(bp) => bp.name}
              getOptionValue={(bp) => bp}
              required
              onClose={handleCleanSearch}
              tabIndex={2}
            />
          </div>
          <div />
        </div>
      </div>
      {journalEntryLinesFetching ? (
        <Loader />
      ) : (
        <div className="postings-list-container">
          {journalEntryLines &&
            getJournalEntryLinesGrouped().map((group, index, arr) => (
              <div
                className="postings-list"
                key={group.businessPartnerId || index}
                style={{ zIndex: arr.length - index, userSelect: "none" }}
              >
                <div
                  className="postings-list-header"
                  style={{ gridTemplateColumns: "30px auto" }}
                >
                  <div />
                  <div>
                    {
                      businessPartners?.find(
                        (bp) => bp.id === group.businessPartnerId
                      )?.name
                    }
                  </div>
                </div>
                <div className="postings-list-header">
                  <div />
                  <div>{t("internal_reconciliation.posting_date")}</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div>{t("internal_reconciliation.journal_entry")}</div>
                  <div>&nbsp;</div>
                  <div>{t("internal_reconciliation.memo")}</div>
                  <div>{t("internal_reconciliation.amount")}</div>
                  <div>{t("internal_reconciliation.open_amount")}</div>
                  <div>{t("internal_reconciliation.applied_amount")}</div>
                </div>

                {group.lines &&
                  group.lines
                    .filter((jel) => !!jel)
                    .map((jel) => (
                      <div key={jel.id} className="postings-list-item">
                        <div>
                          <CheckBox
                            checked={
                              selectedJournalEntryLines.findIndex(
                                (l) => l.id === jel.id
                              ) !== -1
                            }
                            onClick={(e) =>
                              toggleJournalEntryLine(jel, e.shiftKey)
                            }
                            disabled={
                              selectedJournalEntryLines?.length > 0 &&
                              (jel.currency_code !==
                                selectedPostingCurrencyCode ||
                                group.businessPartnerId !==
                                  selectedJournalEntryLinesRelationId)
                            }
                          />
                        </div>
                        <div>
                          <Text>{localeFormatDate(jel.posting_date)}</Text>
                        </div>
                        <div>
                          {!!jel.reconciled_amount &&
                            Math.abs(jel.reconciled_amount) > 0 && (
                              <Text
                                as="a"
                                onClick={() =>
                                  setReconciliationDetailsPopupId(jel.id)
                                }
                              >
                                <IconReconciled
                                  width={16}
                                  height={16}
                                  opacity={
                                    Math.abs(jel.debit_amount) -
                                      jel.reconciled_amount >
                                    0
                                      ? "20%"
                                      : "100%"
                                  }
                                />
                              </Text>
                            )}
                        </div>
                        <div>
                          {jel.journal_entry?.cancelled === "Y" ? (
                            <IconCircleR width={16} height={16} />
                          ) : jel.journal_entry?.cancelled === "C" ? (
                            <IconCircleC width={16} height={16} />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <Text
                            as="a"
                            onClick={() =>
                              setJournalEntryDetailsPopupId(
                                jel.journal_entry?.id
                              )
                            }
                          >
                            {jel.journal_entry?.sequence_number}
                          </Text>
                        </div>
                        <div>
                          {jel.journal_entry.document_id && (
                            <Text
                              as="a"
                              onClick={(e) =>
                                handleOpenVoucher(
                                  jel.journal_entry.document_id,
                                  null,
                                  e.ctrlKey
                                )
                              }
                            >
                              <IconReceipt width={16} height={16} />
                            </Text>
                          )}
                        </div>
                        <div>
                          <Text>
                            {jel.description || jel.journal_entry.memo}
                          </Text>
                        </div>
                        <div>
                          {debitAmountCell(
                            getAmountLC(jel),
                            getAmountFC(jel),
                            jel.currency_code
                          )}
                        </div>
                        <div>
                          {debitAmountCell(
                            getOpenAmountLC(jel),
                            getOpenAmountFC(jel),
                            jel.currency_code
                          )}
                        </div>
                        <div>
                          {selectedJournalEntryLines.findIndex(
                            (l) => l.id === jel.id
                          ) !== -1 && (
                            <CurrencyInput
                              value={getAppliedAmount(jel)}
                              currencyCode={jel.currency_code}
                              onChange={(v) => onAppliedAmountChange(jel, v)}
                              size="sm"
                              fixedCurrency
                            />
                          )}
                        </div>
                      </div>
                    ))}

                <div
                  className="postings-list-footer"
                  style={
                    selectedJournalEntryLines?.length &&
                    group.businessPartnerId ===
                      selectedJournalEntryLinesRelationId
                      ? { position: "sticky", bottom: "5px" }
                      : {}
                  }
                >
                  <div className="function-buttons">
                    {selectedJournalEntryLines?.length >= 1 &&
                      group.businessPartnerId ===
                        selectedJournalEntryLinesRelationId && (
                        <>
                          {!isOpenAppliedAmount && (
                            <Button
                              size="md"
                              variant={BOOTSTRAP_VARIANTS.SECONDARY}
                              text={t(
                                "internal_reconciliation.reconcile_postings",
                                {
                                  num_postings:
                                    selectedJournalEntryLines.length,
                                }
                              )}
                              onClick={onReconcileButtonClick}
                            />
                          )}
                          {isOpenAppliedAmount && (
                            <>
                              <Button
                                size="md"
                                variant={BOOTSTRAP_VARIANTS.SECONDARY}
                                text={t(
                                  "internal_reconciliation.post_difference",
                                  {
                                    num_postings:
                                      selectedJournalEntryLines.length,
                                  }
                                )}
                                onClick={onPostDifferenceButtonClick}
                                disabled={!selectedAccount}
                              />
                              <GeneralLedgerAccountSelect
                                account={selectedAccount}
                                onAccountChange={setSelectedAccount}
                                selectDimensions={false}
                              />
                            </>
                          )}
                          <Text>at {localeFormatDate(getPostingDate())}</Text>
                        </>
                      )}
                  </div>
                  <div></div>
                  <div>
                    {selectedJournalEntryLines?.length > 0 &&
                      group.businessPartnerId ===
                        selectedJournalEntryLinesRelationId &&
                      debitAmountCell(
                        getOpenAmountLC(selectedJournalEntryLines),
                        getOpenAmountFC(selectedJournalEntryLines),
                        selectedPostingCurrencyCode
                      )}
                    {!selectedJournalEntryLines?.length &&
                      new Set(group.lines?.map((l) => l.currency_code) || [])
                        .size === 1 && (
                        <Text as="a" onClick={() => onBalanceClicked(group)}>
                          {currencyFormat(
                            getOpenAmount(group.lines),
                            group.lines[0].currency_code
                          )}
                        </Text>
                      )}
                  </div>
                  <div>
                    {selectedJournalEntryLines?.length > 0 &&
                      group.businessPartnerId ===
                        selectedJournalEntryLinesRelationId && (
                        <Text>
                          {currencyFormat(
                            getAppliedAmount(selectedJournalEntryLines),
                            selectedPostingCurrencyCode
                          )}
                        </Text>
                      )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default memo(InternalReconciliation);
