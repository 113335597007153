import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { loginBgImg, loginImg, SnapBooks } from "../../assests/images";
import SignupForm from "../../components/SignupForm";
import "./style.scss";

const Signup = () => {
  const { t } = useTranslation();

  return (
    <div
      className="signup-page"
      style={{ backgroundImage: `url(${loginBgImg})` }}
    >
      <div className="logo-container">
        <div className="logo">
          <img src={SnapBooks} alt="" />
        </div>
        <div className="desc">{t("signup_page.slogan")}</div>
      </div>

      <SignupForm />
    </div>
  );
};

export default memo(Signup);
