import { useQuery } from "react-query";
import { authenticatedApiClient, throwErrorByStatus } from "./apiClients";
import { BusinessPartner } from "../types/BusinessPartner";
import { Pages } from "../types/Pages";

export function useGetBusinessPartner(id?: number, _with: string[] = []) {
  return useQuery(
    ["business-partners", id],
    async () => {
      const res = await authenticatedApiClient.get(`/business-partners/${id}`, {
        params: { with: _with.join(",") },
      });
      throwErrorByStatus(res);
      return res.data as BusinessPartner;
    },
    { enabled: !!id }
  );
}

export function useGetBusinessPartners(
  clientAccountId: number,
  _with: string[] = []
) {
  const params = { client_account_id: clientAccountId, with: _with.join(",") };
  return useQuery(
    ["business-partners", params],
    async () => {
      const res = await authenticatedApiClient.get(`/business-partners`, {
        params: params,
      });
      throwErrorByStatus(res);
      return res.data as { business_partners: BusinessPartner[]; pages: Pages };
    },
    { enabled: true }
  );
}
