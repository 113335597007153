import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Select } from "../../../components/commons";

const DebtCollectionSeverityField = ({
  value,
  onChange,
  disabled,
  tabIndex,
}: {
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();

  const severities = [
    {
      value: "REMINDER",
      label: t(`debt_collection_severity.REMINDER`),
    },
    {
      value: "COLLECTION_NOTICE",
      label: t(`debt_collection_severity.COLLECTION_NOTICE`),
    },
    {
      value: "COLLECTION",
      label: t(`debt_collection_severity.COLLECTION`),
    },
  ];

  const severity = severities.find((p) => p.value === value);

  return (
    <Select
      size="md"
      label={t("post_detail.debt_collection_severity")}
      options={severities}
      value={severity}
      onChange={(o: any) => onChange(o?.value)}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

export default memo(DebtCollectionSeverityField);
