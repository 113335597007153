import React from 'react'

import ContentLoader from 'react-content-loader'

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height="120"
    backgroundColor="#f5f6f7"
    foregroundColor="#adacac"
  >
    <rect x="30" y="15" rx="5" ry="5" width="70" height="15" />

    <rect x="30" y="50" rx="5" ry="5" width="20" height="15" />
    <rect x="70" y="50" rx="5" ry="5" width="80" height="15" />
    <rect x="65%" y="50" rx="5" ry="5" width="80" height="15" />
    <rect x="80%" y="50" rx="5" ry="5" width="80" height="15" />

    <rect x="30" y="85" rx="5" ry="5" width="20" height="15" />
    <rect x="70" y="85" rx="5" ry="5" width="80" height="15" />
    <rect x="65%" y="85" rx="5" ry="5" width="80" height="15" />
    <rect x="80%" y="85" rx="5" ry="5" width="80" height="15" />

    <rect x="80%" y="120" rx="5" ry="5" width="80" height="15" />

  </ContentLoader>
)

export default Loader
