import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, CheckBox, Input, Select } from "../../components/commons";
import { IconArrowRight, IconGreenQuestions } from "../../assests/icons";
import { currencyValues } from "../../constants";

const EditBankAccount = (props) => {
  const { bankAccount, onBack, onSave, showErrorNotification } = props;
  const { t } = useTranslation();
  const initialData = {
    name: "",
    bank_country: "NO",
    bank_code: "",
    currency: "NOK",
    bban: "",
    bic: "",
    iban: "",
    routing_number: "",
  };

  const BankAccountTypes = [
    { value: "CURRENT", label: t("Current") },
    { value: "TAX", label: t("Tax") },
    { value: "INVESTMENT", label: t("Investment") },
    { value: "SAVINGS", label: t("Savings") },
    { value: "LOAN", label: t("Loan") },
  ];

  const [bankAccountData, setBankAccountData] = useState(initialData);
  const [selectedCountry, changeSelectedCountry] = useState(null);
  const [selectedCurrency, changeSelectedCurrency] = useState(
    currencyValues[0]
  );
  const [countries, setCountries] = useState([]);
  const [isBankAccountFormSubmitted, setIsBankAccountFormSubmitted] =
    useState(false);
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    const c = t("app.country_list");

    const countries = Object.keys(c).map((i) => {
      return {
        value: i,
        label: c[i],
      };
    });
    setCountries(countries);
    if (
      bankAccount?.is_invoice_account === "D" &&
      bankAccount?.is_payment_account === "D"
    ) {
      setIsDefault(true);
    }
    if (bankAccount) {
      setBankAccountData(bankAccount);
    }
    const country = countries.find(
      (el) => el.value === bankAccountData.bank_country
    );
    changeSelectedCountry(country);

    const currency = currencyValues.find(
      (el) => el.value === bankAccountData.currency
    );
    changeSelectedCurrency(currency);
  }, [bankAccount]);

  const handleChangeBankAccountData = (filed, value) =>
    setBankAccountData({ ...bankAccountData, [filed]: value });

  const handleCreateBankAccount = () => {
    setIsBankAccountFormSubmitted(true);

    if (!bankAccountData.name) {
      return;
    }

    if (!bankAccountData.bban && !bankAccountData.iban) {
      showErrorNotification(
        t("create_bank_account_modal.iban_or_bban_not_filled")
      );
      return;
    }

    const body = {
      ...bankAccountData,
      currency: selectedCurrency.value,
      bank_country: selectedCountry.value,
      is_default: isDefault,
    };

    onSave(body);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>{bankAccountData.bank_name}</div>
    </Tooltip>
  );

  return (
    <div className="edit-page">
      <div className="header">
        <span role="button" className="back-button" onClick={() => onBack()}>
          <IconArrowRight />
        </span>
        {bankAccount ? (
          <span className="title">Edit {bankAccount.name}</span>
        ) : (
          <span className="title">Add New Bank Account</span>
        )}
      </div>
      <div className="detail-form">
        <Input
          className="master-data-modal__input"
          size="lg"
          required
          isCheckValid={isBankAccountFormSubmitted}
          label={t("create_bank_account_modal.account_name")}
          value={bankAccountData.name ?? ""}
          onChange={(e) => handleChangeBankAccountData("name", e)}
        />
        <div className="one-line">
          <Select
            label={t("Account type")}
            className="account-type"
            size="lg"
            options={BankAccountTypes}
            value={BankAccountTypes.find(
              (t) => t.value === bankAccountData.type
            )}
            onChange={(t) => handleChangeBankAccountData("type", t.value)}
          />
          <div className="check-box">
            <div className="check-box-2">
              <CheckBox
                checked={isDefault}
                onClick={() => setIsDefault(!isDefault)}
              >
                {t("Default")}
              </CheckBox>
            </div>
          </div>
        </div>
        <div className="one-line">
          <Input
            className="master-data-modal__input"
            label={t("create_bank_account_modal.bban")}
            size="lg"
            isCheckValid={isBankAccountFormSubmitted}
            value={bankAccountData.bban ?? ""}
            onChange={(e) => handleChangeBankAccountData("bban", e)}
          />
          <Input
            className="master-data-modal__input"
            label={t("create_bank_account_modal.iban")}
            size="lg"
            value={bankAccountData.iban ?? ""}
            onChange={(e) => handleChangeBankAccountData("iban", e)}
          />
        </div>

        <div className="one-line">
          <Input
            className="master-data-modal__input"
            label={t("create_bank_account_modal.swift_bic")}
            size="lg"
            value={bankAccountData.bic ?? ""}
            onChange={(e) => handleChangeBankAccountData("bic", e)}
          />
          <Select
            className="currency"
            size="lg"
            options={currencyValues}
            value={selectedCurrency}
            onChange={changeSelectedCurrency}
          />
        </div>
        <div className="one-line">
          <Select
            className="master-data-modal__input"
            label={t("create_bank_account_modal.bank_country")}
            size="lg"
            required
            isCheckValid
            options={countries}
            value={selectedCountry}
            onChange={changeSelectedCountry}
          />
        </div>
        <div className="one-line">
          <Input
            className="master-data-modal__input"
            label={t("create_bank_account_modal.bank_code")}
            size="lg"
            isCheckValid={isBankAccountFormSubmitted}
            value={bankAccountData.bank_code ?? ""}
            onChange={(e) => handleChangeBankAccountData("bank_code", e)}
          />
          <div className="bank-name">{bankAccountData.bank_name}</div>
        </div>
      </div>
      <div>
        <Button
          className="save-button"
          size="md40"
          variant="success"
          onClick={handleCreateBankAccount}
          text={t("master_data.save_button")}
        />
      </div>
    </div>
  );
};
export default EditBankAccount;
