export default [
  {
    label: '$',
    value: 'USD'
  },
  {
    label: '€',
    value: 'EUR'
  },
  {
    label: 'NOK',
    value: 'NOK'
  },
  {
    label: 'SEK',
    value: 'SEK'
  },
  {
    label: 'DKK',
    value: 'DKK'
  },
  {
    label: '£',
    value: 'GBP'
  },  
]
