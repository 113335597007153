import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  GridView,
  LabelsFilter,
  DatePicker,
} from "../../../components/commons";
import { ReportWrap } from "../../../components";
import { decimalFormat } from "../../../utils/common";

import { ClientAccountContext } from "../../App";

import "./style.scss";
import { UseQueryResult } from "react-query";
import { authenticatedApiV2Client } from "../../../api/apiClients";
import { useGetBalanceSheetReport } from "../../../api";
import { ClientAccount } from "../../../types/ClientAccount";

interface BalanceSheetRecord {
  grouping: {
    level_1_code: string;
    level_1_name: string;
    level_2_code: string;
    level_2_name: string;
    level_3_code: string;
    level_3_name: string;
  };
  account_code: string;
  account_name: string;
  balance: number;
}

const BalanceSheetReport = () => {
  const { t } = useTranslation();
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  const [reportDate, changeReportDate] = useState(
    moment().endOf("year").toDate()
  );
  const [parsedReport, changeParsedReport] = useState<any[]>([]);
  const [activeLabels, setActiveLabels] = useState<any[]>([]);

  const reportParams = {
    client_account_id: clientAccount?.id,
    report_date: moment(reportDate).format("YYYY-MM-DD"),
    dimensions: activeLabels
      .map((d) => `${d.relation_type}:${d.relation_id}`)
      .join(","),
  };
  const { data: balanceSheetReport, isLoading: balanceSheetReportFetching } =
    useGetBalanceSheetReport(reportParams) as UseQueryResult<
      BalanceSheetRecord[],
      boolean
    >;

  useEffect(() => {
    if (balanceSheetReport) {
      const res = parseRawData();
      if (res !== parsedReport) {
        changeParsedReport(res || []);
      }
    }
  }, [balanceSheetReport]);

  const parseRawData = () => {
    if (balanceSheetReport && clientAccount?.accounting_currency) {
      const res: any = [];

      const addRecord = (
        level: number,
        description: string,
        balance?: number,
        _class?: string
      ) => {
        res.push({
          level,
          description,
          balance: balance ? decimalFormat(balance, 0) : 0 || "",
          class: _class,
        });
      };

      let current_category_0 = {
        balance: 0,
      } as {
        code?: string;
        name?: string;
        balance?: number;
      };
      let current_category_1 = {
        balance: 0,
      } as {
        code?: string;
        name?: string;
        balance?: number;
      };
      let current_category_2 = {
        balance: 0,
      } as {
        code?: string;
        name?: string;
        balance?: number;
      };

      balanceSheetReport.forEach((record) => {
        if (record.grouping.level_2_code !== current_category_2.code) {
          // Add previous group footer
          if (current_category_2.code)
            addRecord(
              2,
              current_category_2.name || "",
              current_category_2.balance,
              "is-indent"
            );

          // Add new group level 1 header and footer
          if (record.grouping.level_1_code !== current_category_1.code) {
            // Add previous group footer
            if (current_category_1.code)
              addRecord(
                1,
                current_category_1.name || "",
                current_category_1.balance,
                "is-indent"
              );

            // Add group header
            addRecord(1, record.grouping.level_1_name, undefined, "category");

            current_category_1.balance = 0;
          }

          // Add new group level 2 header
          res.push({
            level: 2,
            description: record.grouping.level_2_name,
            balance: "",
            class: "is-indent",
          });

          current_category_2.balance = 0;
        }

        addRecord(
          3,
          `${record.account_code} - ${record.account_name}`,
          record.balance,
          "is-indent-2"
        );

        current_category_0 = {
          balance: (current_category_0.balance || 0) + record.balance,
        };
        current_category_1 = {
          code: record.grouping.level_1_code,
          name: record.grouping.level_1_name,
          balance: (current_category_1.balance || 0) + record.balance,
        };

        current_category_2 = {
          code: record.grouping.level_2_code,
          name: record.grouping.level_2_name,
          balance: (current_category_2.balance || 0) + record.balance,
        };
      });

      if (res.length > 0) {
        addRecord(
          2,
          current_category_2.name || "",
          current_category_2.balance,
          "is-indent"
        );
        addRecord(
          1,
          current_category_1.name || "",
          current_category_1.balance,
          "is-indent"
        );
        addRecord(
          0,
          `Retained earnings`,
          -(current_category_0.balance || 0),
          "result, is-underline"
        );
      }
      return res;
    }
  };

  const exportOptions: any = {
    EXCEL: {
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      filename: "Balance sheet.xlsx",
    },
    PDF: { mimeType: "application/pdf", filename: "Balance sheet.pdf" },
  };

  const onExportClicked = async (exportOption: string) => {
    const response = await authenticatedApiV2Client.get(
      "/reports/balance-sheet",
      {
        responseType: "blob",
        headers: {
          Accept: exportOptions[exportOption].mimeType,
        },
        params: reportParams,
      }
    );

    const exportURL = window.URL.createObjectURL(response.data);
    const tempLink = document.createElement("a");
    tempLink.href = exportURL;
    tempLink.setAttribute("download", exportOptions[exportOption].filename);
    tempLink.click();
  };

  return (
    <ReportWrap
      className="income-statement-report"
      title={t("balance_sheet.title")}
      onExportClicked={onExportClicked}
      exportOptions={Object.keys(exportOptions)}
      filterItems={
        <>
          <div className="filter-row">
            <DatePicker selectedDate={reportDate} onChange={changeReportDate} />
            <LabelsFilter
              activeLabels={activeLabels}
              setActiveLabels={setActiveLabels}
              noMargin
            />
          </div>
        </>
      }
      data={
        <GridView
          columns={[
            {
              Header: t("balance_sheet.gridview.description"),
              accessor: "description",
              align: "left",
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <div
                  style={{
                    marginLeft: `${
                      Math.max(0, cellProps.row.original.level - 1) * 30
                    }px`,
                    fontWeight: `${
                      Math.abs(3 - cellProps.row.original.level) * 300
                    }`,
                  }}
                >
                  {cellProps.value}
                </div>
              ),
            },
            {
              Header: t("Sum {{currency}}", {
                currency: clientAccount?.accounting_currency,
              }),
              accessor: "balance",
              align: "right",
              maxWidth: 800,
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {cellProps.value}
                </span>
              ),
            },
          ]}
          data={parsedReport}
          loading={balanceSheetReportFetching}
          relativeRowHeight={30}
          emptyStateTitle=""
          stickyHeader
        />
      }
    />
  );
};

export default BalanceSheetReport;
