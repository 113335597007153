import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { CompanyDropdown } from "./CompanyDropdown";
import { Button, LinkButton } from "../commons";
import { common, pathKeys } from "../../constants";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Form as BootstrapForm, Col, Row } from "react-bootstrap";
import { useSignUp } from "./useSignUp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { cookie } from "../../utils";
import {
  IconEmail,
  IconEye,
  IconEyeHidden,
  IconPassLock,
} from "../../assests/icons";
import { downloadAppleStore, downloadGooglePlay } from "../../assests/images";

interface Organization {
  id: string;
  name: string;
  address: string;
  organization_number: string;
}

interface SignupFormValues {
  organization: Organization | null;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

const SignupSchema = Yup.object().shape({
  organization: Yup.object()
    .shape({
      name: Yup.string(),
      address: Yup.string(),
      organization_number: Yup.string(),
    })
    .required(),
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string()
    .required()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/),
});

const SignUpForm: React.FC<{ isPopUp?: boolean }> = ({ isPopUp = false }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPass, setShowPass] = React.useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync: signUp } = useSignUp();
  const initialValues: SignupFormValues = {
    organization: null,
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  };

  const handleSubmit = async (values: SignupFormValues) => {
    if (checkIsMobile()) {
      setIsMobile(true);
      return;
    }
    const apiValues = {
      organization_id: values.organization?.id ?? "",
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
    };
    try {
      const data = await signUp(apiValues);
      const { status, message } = data;
      if (status === true) {
        if (!data?.user?.accounts?.length) {
          toast.error(t("login_page.no_client_account"));
          return;
        } else {
          toast.success(t("signup_page.signup_success"));
        }
        queryClient.setQueryData(["User"], data.user);
        cookie.setToken(data.token);
        navigate(
          `${pathKeys.CLIENT_ACCOUNTS}/${data.user.accounts[0].account.id}${pathKeys.OVERVIEW}`,
          { replace: true }
        );
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const checkIsMobile = () => {
    const viewportWidth: number = window.innerWidth;
    return viewportWidth < 768;
  };

  const signupContainer = isPopUp ? "signup-form shouldCenter" : "signup-form";

  return (
    <div className={signupContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <BootstrapForm>
              {!isMobile ? (
                <>
                  <div className="title">{t("signup_page.title")}</div>
                  <CompanyDropdown
                    onSelect={(org) => setFieldValue("organization", org)}
                    onBlur={handleBlur}
                    className={
                      touched.organization && errors.organization ? "error" : ""
                    }
                  />
                  <div className="mb-3" />
                  <Row>
                    <Col sm={6}>
                      <div className="field">
                        <BootstrapForm.Label htmlFor="firstname">
                          {t("signup_page.first_name_label")}
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          name="firstname"
                          type="text"
                          maxLength={20}
                          className={
                            touched.firstname && errors.firstname ? "error" : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="field">
                        <BootstrapForm.Label htmlFor="lastname">
                          {t("signup_page.last_name_label")}
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          name="lastname"
                          type="text"
                          maxLength={20}
                          className={
                            touched.lastname && errors.lastname ? "error" : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mb-3" />
                  <div className="field">
                    <BootstrapForm.Label htmlFor="email">
                      {t("signup_page.email_label")}
                    </BootstrapForm.Label>
                    <div className="d-flex position-relative">
                      <BootstrapForm.Control
                        name="email"
                        type="email"
                        className={touched.email && errors.email ? "error" : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div
                        role="button"
                        className="icon-space position-absolute"
                      >
                        <IconEmail />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3" />
                  <div className="field">
                    <BootstrapForm.Label htmlFor="password">
                      {t("signup_page.password_label")}
                    </BootstrapForm.Label>
                    <div className="d-flex position-relative">
                      <BootstrapForm.Control
                        name="password"
                        type={showPass ? "text" : "password"}
                        className={`${showPass ? "customInputPadding" : ""} ${
                          touched.password && errors.password ? "error" : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div
                        role="button"
                        className="position-absolute end-0 mt-3 me-3"
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? <IconEye /> : <IconEyeHidden />}
                      </div>
                      <div
                        role="button"
                        className="icon-space position-absolute ml-4"
                      >
                        <IconPassLock />
                      </div>
                    </div>
                    <ul>
                      <li>{t("signup_page.password_requirement_1")}</li>
                      <li>{t("signup_page.password_requirement_2")}</li>
                      <li>{t("signup_page.password_requirement_3")}</li>
                    </ul>
                  </div>
                  <div className="mb-4" />
                  <div className="mb-4" />
                  <div className="text-center">
                    <Button
                      loading={isSubmitting}
                      disabled={Object.keys(errors).length > 0}
                      className="signup-button"
                      size="lg"
                      onClick={handleSubmit}
                      variant={common.BOOTSTRAP_VARIANTS.PRIMARY}
                      text={t("signup_page.signup_button")}
                      type="submit"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-center">📱 Exciting News!</h2>
                  <p className="text-left mt-3">
                    Download Our App for a Better Experience! Hey, We just
                    noticed you're on a mobile device, and we want to provide
                    you with the best experience. Our website isn't fully
                    responsive on phones yet.
                    <h6 className="text-center mt-3">
                      You can enjoy seamless browsing by using our mobile app.
                      🚀
                    </h6>
                  </p>
                  <div className="d-flex justify-content-center mt-3 ">
                    <a
                      href="https://apps.apple.com/in/app/snapbooks-digital-accountant/id1574509527"
                      className="me-2"
                    >
                      <img
                        src={downloadAppleStore}
                        alt="download apple store"
                        width="150"
                        height="50"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.snapbooks">
                      <img
                        src={downloadGooglePlay}
                        alt="download google play"
                        width="150"
                        height="50"
                      />
                    </a>
                  </div>
                </>
              )}
            </BootstrapForm>
          );
        }}
      </Formik>
      <div className="mb-2" />
      {!isMobile ? (
        <div className="d-flex justify-content-center login-link">
          <LinkButton
            onClick={() => navigate(pathKeys.LOGIN)}
            text={t("signup_page.already_have_account")}
            rightText={t("signup_page.login")}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SignUpForm;
