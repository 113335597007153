import cn from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconSearch } from "../../assests/icons";
import { Input, Modal } from "../../components/commons";
import Assets from "./Assets";
import BankAccounts from "./BankAccounts";
import BusinessPartners from "./BusinessPartners";
import Departments from "./Departments";
import PeriodLocks from "./PeriodLocks/PeriodLocks";
import Projects from "./Projects";
import "./style.scss";
import Contracts from "./Contracts/Contracts";

interface Props {
  loading?: boolean;
  show?: boolean;
  onHide?: () => void;
  clientAccountId: number;
}
const MasterDataModal: React.FC<Props> = ({
  onHide = () => {},
  loading = false,
  show = false,
  clientAccountId,
}) => {
  const { t } = useTranslation();
  const [keyWord, changeKeyWord] = useState("");
  const [activeTab, setActiveTab] = useState("businesspartners");

  const clearState = () => {
    changeKeyWord("");
  };

  const handleOnHide = () => {
    clearState();
    onHide();
  };

  return (
    <Modal
      className="master-data-modal"
      show={show}
      loading={loading}
      size="xl"
      dialogClassName="modal-80w"
      backdrop="static"
      onHide={handleOnHide}
      title={t("master_data.master_data")}
    >
      <div className="content">
        <div className="left-panel">
          <div className="search-bar">
            <Input
              size="md"
              placeholder={t(
                "common_components.input.placeholder_search_by_keyword"
              )}
              value={keyWord}
              onChange={changeKeyWord}
            />
            <span>
              <IconSearch />
            </span>
          </div>
          <div className="tabs-list">
            <div
              role="button"
              className={cn(
                "tab-item",
                activeTab === "businesspartners" && "active"
              )}
              onClick={() => setActiveTab("businesspartners")}
            >
              {t("master_data.businesspartners")}
            </div>
            <div
              role="button"
              className={cn(
                "tab-item",
                activeTab === "bank_accounts" && "active"
              )}
              onClick={() => setActiveTab("bank_accounts")}
            >
              {t("master_data.bank_accounts")}
            </div>
            <div
              role="button"
              className={cn("tab-item", activeTab === "projects" && "active")}
              onClick={() => setActiveTab("projects")}
            >
              {t("master_data.projects")}
            </div>
            <div
              role="button"
              className={cn(
                "tab-item",
                activeTab === "departments" && "active"
              )}
              onClick={() => setActiveTab("departments")}
            >
              {t("master_data.departments")}
            </div>
            <div
              role="button"
              className={cn("tab-item", activeTab === "assets" && "active")}
              onClick={() => setActiveTab("assets")}
            >
              {t("master_data.assets")}
            </div>
          </div>
          <div className="tabs-list">
            <div
              role="button"
              className={cn(
                "tab-item",
                activeTab === "period_locks" && "active"
              )}
              onClick={() => setActiveTab("period_locks")}
            >
              {t("master_data.period_locks")}
            </div>
            <div
              role="button"
              className={cn("tab-item", activeTab === "contracts" && "active")}
              onClick={() => setActiveTab("contracts")}
            >
              {t("master_data.contracts")}
            </div>
          </div>
        </div>
        <div className="right-panel">
          {activeTab === "businesspartners" && (
            <BusinessPartners
              clientAccountId={clientAccountId}
              keyWord={keyWord}
            />
          )}
          {activeTab === "bank_accounts" && (
            <BankAccounts clientAccountId={clientAccountId} keyWord={keyWord} />
          )}
          {activeTab === "projects" && (
            <Projects clientAccountId={clientAccountId} />
          )}
          {activeTab === "departments" && (
            <Departments clientAccountId={clientAccountId} />
          )}
          {activeTab === "assets" && (
            <Assets clientAccountId={clientAccountId} />
          )}
          {activeTab === "period_locks" && (
            <PeriodLocks clientAccountId={clientAccountId} />
          )}
          {activeTab === "contracts" && (
            <Contracts clientAccountId={clientAccountId} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MasterDataModal;
