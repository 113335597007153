import React, { useState, memo } from "react";
import { useQueryClient, useIsFetching, useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";

import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { Button, GridView, Loading } from "../../components/commons";
import { showErrorNotification } from "../../utils/toastr";

import { IconEdit } from "../../assests/icons";

import { useGetAssets, useCreateAssets, useUpdateAsset } from "../../api";

import EditAsset from "./EditAsset";
import "./style.scss";

const Assets = ({ clientAccountId }) => {
  const { t } = useTranslation();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const queryClient = useQueryClient();
  const [selectedAsset, setAsset] = useState(null);
  const [query, setQuery] = useState({ page: 1, pageSize: 7 });

  const { data: assets, refetch: refetchAssets } = useGetAssets(
    clientAccountId,
    query.page,
    query.pageSize
  );
  const { mutateAsync: updateAsset } = useUpdateAsset();
  const { mutateAsync: createAssets } = useCreateAssets();

  const handleCreateAsset = (assetData) => {
    const data = { ...assetData };
    data.client_account_id = clientAccountId;
    if (data.id) {
      updateAsset(data).then(() => {
        setAsset(null);
        refetchAssets();
        queryClient.invalidateQueries("dimension-values");
      });
    } else {
      createAssets(data).then(() => {
        setAsset(null);
        refetchAssets();
        queryClient.invalidateQueries("dimension-values");
      });
    }
  };

  const handleQueryChange = (data) => {
    const q = {
      ...(query || {}),
      ...data,
    };
    setQuery(q);
  };

  return (
    <div className="detail-page">
      <Loading childLoading loading={isFetching > 0 || isMutating > 0} />
      {selectedAsset === null ? (
        <div className="business-partner-list">
          <div className="business-partner-gridview">
            <GridView
              columns={[
                {
                  Header: t("master_data.gridview.id"),
                  accessor: "sequence_number",
                  align: "left",
                  maxWidth: "9%",
                },
                {
                  Header: t("master_data.gridview.name"),
                  accessor: "name",
                  align: "left",
                  maxWidth: "15%",
                },
                {
                  Header: "",
                  accessor: "delete",
                  align: "left",
                  maxWidth: "6%",
                  className: "delete-wrap",
                  Cell: (cellProps) => (
                    <span
                      className="delete-icon"
                      role="button"
                      onClick={() => setAsset(cellProps.row.original)}
                    >
                      <IconEdit />
                    </span>
                  ),
                },
              ]}
              data={(assets || {}).assets}
              pages={(assets || {}).pages}
              relativeRowHeight={30}
              onStateChange={(q) => handleQueryChange(q)}
              emptyStateTitle=""
            />
          </div>
          <div className="text-right">
            <Button
              size="md40"
              className="add-button"
              variant={BOOTSTRAP_VARIANTS.SECONDARY}
              text={t("master_data.add_new_asset")}
              onClick={() => setAsset(undefined)}
            />
          </div>
        </div>
      ) : (
        <EditAsset
          onBack={() => setAsset(null)}
          onSave={handleCreateAsset}
          showErrorNotification={showErrorNotification}
          asset={selectedAsset}
        />
      )}
    </div>
  );
};

export default memo(Assets);
