import moment from "moment";

import { ObjectTypes } from "../../constants";
import { GeneralLedgerAccount } from "../../types/GeneralLedgerAccountV2";
import {
  AccountDimensionCombination,
  JournalEntry,
  JournalEntryDimension,
  JournalEntryLine,
} from "../../types/JournalEntryV2";
import { TaxCode } from "../../types/TaxCode";
import { cloneDeep } from "lodash";

type SetJournalEntryAction = {
  type: "set";
  payload: JournalEntry;
};

type SetDescriptionAction = {
  type: "setDescription";
  payload: string;
};

type SetLinesAction = {
  type: "setLines";
  payload: JournalEntryLine[];
};

type SetLineAction = {
  type: "setLine";
  payload: { index: number; line: JournalEntryLine };
};

type AddLineAction = {
  type: "addLine";
  payload: { index?: number; line: JournalEntryLine };
};

type RemoveLineAction = {
  type: "removeLine";
  payload: { index: number };
};

type SetLineIdAction = {
  type: "setLineId";
  payload: { index: number; lineId: number };
};

type SetPostingDateAction = {
  type: "setPostingDate";
  payload: { index: number; postingDate: string };
};

type SetAccountAction = {
  type: "setAccount";
  payload: { index: number; account: AccountDimensionCombination };
};

type SetDebitAction = {
  type: "setDebit";
  payload: { index: number; debit: number };
};

type SetCreditAction = {
  type: "setCredit";
  payload: { index: number; credit: number };
};

type SetGrossAmountAction = {
  type: "setGrossAmount";
  payload: { index: number; grossAmount: number };
};

type SetNetAmountAction = {
  type: "setNetAmount";
  payload: { index: number; netAmount: number };
};

type SetCurrencyCodeAction = {
  type: "setCurrencyCode";
  payload: { index: number; currencyCode: string };
};

type SetTaxCodeAction = {
  type: "setTaxCode";
  payload: { index: number; taxCode: TaxCode };
};

type SetLineDescriptionAction = {
  type: "setLineDescription";
  payload: { index: number; description: string };
};

type SetDimensionsAction = {
  type: "setDimensions";
  payload: { index: number; dimensions: JournalEntryDimension[] };
};

export type JournalEntryReducerAction =
  | SetJournalEntryAction
  | SetDescriptionAction
  | SetLinesAction
  | SetLineAction
  | AddLineAction
  | RemoveLineAction
  | SetLineIdAction
  | SetPostingDateAction
  | SetAccountAction
  | SetDebitAction
  | SetCreditAction
  | SetGrossAmountAction
  | SetNetAmountAction
  | SetCurrencyCodeAction
  | SetTaxCodeAction
  | SetLineDescriptionAction
  | SetDimensionsAction;

export type JournalEntryReducer = (
  state: JournalEntry,
  action: JournalEntryReducerAction
) => JournalEntry;

export class JournalEntryController {
  accountResolver: (accountId: number) => GeneralLedgerAccount | undefined;

  taxCodeResolver: (taxCodeId: number) => TaxCode | undefined;

  constructor(
    accountResolver: (accountId: number) => GeneralLedgerAccount | undefined,
    taxCodeResolver: (taxCodeId: number) => TaxCode | undefined
  ) {
    this.accountResolver = accountResolver;
    this.taxCodeResolver = taxCodeResolver;
  }

  reducer: JournalEntryReducer = (state, action) => {
    const je = cloneDeep(state);
    je.lines = je.lines || [];

    switch (action.type) {
      case "set":
        const newJe = cloneDeep(action.payload);

        if (newJe?.lines) {
          /* Remove local currency amounts and move to foreign currency regardless of currency. 
             For simple editing. Backend takes care of the rest. */
          newJe.lines.forEach((jel) => {
            delete jel.exchange_rate;
            if (!jel.debit_fc) jel.debit_fc = jel.debit;
            delete jel.debit;
            if (!jel.credit_fc) jel.credit_fc = jel.credit;
            delete jel.credit;
            if (!jel.tax_amount_fc) jel.tax_amount_fc = jel.tax_amount;
            delete jel.tax_amount;
            if (!jel.tax_base_amount_fc)
              jel.tax_base_amount_fc = jel.tax_base_amount;
            delete jel.tax_base_amount;
          });

          newJe.lines.sort((a, b) => a.line_id - b.line_id);
        }
        return newJe;
      case "setDescription":
        je.description = action.payload;
        return je;
      case "setLines":
        je.lines = action.payload;
        return je;
      case "setLine":
        this.removeLine(je.lines, action.payload.index);
        this.addLine(je.lines, action.payload.line, action.payload.index);
        return je;
      case "addLine":
        this.addLine(je.lines, action.payload?.line, action.payload?.index);
        return je;
      case "removeLine":
        this.removeLine(je.lines, action.payload.index);
        return je;
      case "setLineId":
        this.setLineId(je.lines, action.payload.index, action.payload.lineId);
        return je;
      case "setPostingDate":
        this.setPostingDate(
          je.lines,
          action.payload.index,
          action.payload.postingDate
        );
        return je;
      case "setAccount":
        this.setAccount(
          je.lines,
          action.payload.index,
          action.payload.account.account
        );
        if (action.payload.account.dimension) {
          this.addDimension(
            je.lines,
            action.payload.index,
            action.payload.account.dimension
          );
        }
        return je;
      case "setDebit":
        this.setDebitAmount(
          je.lines,
          action.payload.index,
          action.payload.debit,
          je.lines[action.payload.index].currency_code
        );
        return je;
      case "setCredit":
        this.setCreditAmount(
          je.lines,
          action.payload.index,
          action.payload.credit,
          je.lines[action.payload.index].currency_code
        );
        return je;
      case "setGrossAmount":
        const tax = this.getTaxCode(je.lines, action.payload.index);
        const taxRate = tax?.tax_rate || 0;

        if (
          this.getGrossNetLinesDebitCredit(je.relation_type as ObjectTypes) ===
          "credit"
        )
          this.setCreditAmount(
            je.lines,
            action.payload.index,
            action.payload.grossAmount / (1 + taxRate / 100),
            je.lines[action.payload.index].currency_code
          );
        else
          this.setDebitAmount(
            je.lines,
            action.payload.index,
            action.payload.grossAmount / (1 + taxRate / 100),
            je.lines[action.payload.index].currency_code
          );
        return je;
      case "setNetAmount":
        if (
          this.getGrossNetLinesDebitCredit(je.relation_type as ObjectTypes) ===
          "credit"
        )
          this.setCreditAmount(
            je.lines,
            action.payload.index,
            action.payload.netAmount,
            je.lines[action.payload.index].currency_code
          );
        else
          this.setDebitAmount(
            je.lines,
            action.payload.index,
            action.payload.netAmount,
            je.lines[action.payload.index].currency_code
          );
        return je;
      case "setCurrencyCode":
        je.lines.forEach((line) => {
          line.currency_code = action.payload.currencyCode;
        });
        return je;
      case "setTaxCode":
        this.setTaxCode(je.lines, action.payload.index, action.payload.taxCode);
        return je;
      case "setLineDescription":
        this.setDescription(
          je.lines,
          action.payload.index,
          action.payload.description
        );
        return je;
      case "setDimensions":
        this.setDimensions(
          je.lines,
          action.payload.index,
          action.payload.dimensions
        );
        return je;
      default:
        return state;
    }
  };

  getReceivablePayableLines = (
    lines: JournalEntryLine[],
    documentType: ObjectTypes
  ) => {
    if (documentType === ObjectTypes.AP_RECEIPT) {
      const receivablePayablelines: JournalEntryLine[] = [];
      if (lines[0]?.account_code === "2400")
        receivablePayablelines.push(lines[0]);
      else return receivablePayablelines;

      if (lines[1]?.account_code === "2400")
        receivablePayablelines.push(lines[1]);
      else return receivablePayablelines;

      if (lines[2]?.account_code === "2910")
        receivablePayablelines.push(lines[2]);

      return receivablePayablelines;
    }

    if (
      [ObjectTypes.AP_CREDIT_NOTE, ObjectTypes.AP_INVOICE].includes(
        documentType as ObjectTypes
      )
    ) {
      if (lines[0]?.account_code === "2400") return [lines[0]];
    }

    if (
      [ObjectTypes.AR_CREDIT_NOTE, ObjectTypes.AR_INVOICE].includes(
        documentType as ObjectTypes
      )
    ) {
      if (lines[0]?.account_code === "1500") return [lines[0]];
    }
  };

  getReceivablePayableBusinessPartnerId = (
    lines: JournalEntryLine[],
    documentType: ObjectTypes
  ) => {
    const receivablePayableLine = this.getReceivablePayableLines(
      lines,
      documentType
    )?.[0];
    if (!receivablePayableLine) return;

    return receivablePayableLine.dimensions?.find(
      (d) => d.relation_type === "businesspartner"
    )?.relation_id;
  };

  /** Gets the sales / purchase document default posting side for the sales / purchase account lines */
  getGrossNetLinesDebitCredit = (documentType: ObjectTypes) => {
    if (
      [
        ObjectTypes.AR_CREDIT_NOTE,
        ObjectTypes.AP_INVOICE,
        ObjectTypes.AP_RECEIPT,
      ].includes(documentType as ObjectTypes)
    ) {
      // In this case the AR/AP lines are posted to credit side.
      // Sales and purchase lines should therefore be posted to debit side.
      return "debit";
    }

    if (
      [ObjectTypes.AP_CREDIT_NOTE, ObjectTypes.AR_INVOICE].includes(
        documentType as ObjectTypes
      )
    ) {
      // In this case the AR/AP lines are posted to debit side.
      // Sales and purchase lines should therefore be posted to credit side.
      return "credit";
    }
  };

  getLineKey = (line: JournalEntryLine) => {
    return `${line.line_id}-${line.posting_date}-${line.account_id}-${
      line.debit
    }-${line.debit_fc}-${line.credit}-${line.credit_fc}-${line.currency_code}-${
      line.tax_code_id
    }-${line.tax_for_line}-${line.description}-${line.dimensions
      ?.map((d) => d.relation_type + ":" + d.relation_id)
      .join("-")}`;
  };

  /** Mutates the journal entry line array and adds a line at index specified.
   *  Reorganizes line id's and tax lines as well. Returns new length of lines array. */
  addLine = (
    lines: JournalEntryLine[],
    line: JournalEntryLine,
    index?: number
  ) => {
    const maxLineId = Math.max(...lines.map((l) => l.line_id));
    if (index === undefined || index === lines.length) {
      line.line_id = maxLineId + 1;
      lines.push(line);
    } else {
      lines.splice(index, 0, line);
      for (let idx = lines.length - 1; idx >= index; idx -= 1) {
        this.setLineId(lines, idx, idx + 1);
      }
    }

    // Augment line with default values
    if (!line.account && line.account_id)
      line.account = this.accountResolver(line.account_id);

    if (!line.tax_line && !line.tax_for_line)
      this.recalculateLineTax(lines, index || lines.length - 1);

    return lines.length;
  };

  /** Mutates the journal entry line array and removes a line at the index specified.
   *  Reorganizes line id's and tax lines as well */
  removeLine = (lines: JournalEntryLine[], index: number) => {
    const allLinesIndices = [index];
    lines.forEach((l, i) => {
      if (l.tax_for_line === lines[index].line_id) allLinesIndices.push(i);
    });

    allLinesIndices.sort((a, b) => b - a);

    allLinesIndices.forEach((idx) => {
      lines.splice(idx, 1);
    });

    lines.forEach((l, idx) => {
      this.setLineId(lines, idx, idx + 1);
    });
  };

  /** Sets the lines line_id at the specified index. Also updates line_id of tax lines
   *  connected to the line. Mutates the object / array in place. */
  setLineId = (lines: JournalEntryLine[], index: number, lineId: number) => {
    /* eslint no-param-reassign: ["error", { "props": false }] */
    const taxLinesIndices = [] as number[];
    lines.forEach((l, i) => {
      if (l.tax_for_line === lines[index].line_id) taxLinesIndices.push(i);
    });

    lines[index].line_id = lineId;
    taxLinesIndices.forEach((idx) => {
      lines[idx].tax_for_line = lineId;
    });
  };

  /** Sets the lines posting_date at the specified index.
   *  Mutates the object / array in place. */
  setPostingDate = (
    lines: JournalEntryLine[],
    index: number,
    date: string | Date
  ) => {
    const taxLinesIndices = [] as number[];
    lines.forEach((l, i) => {
      if (l.tax_for_line === lines[index].line_id) taxLinesIndices.push(i);
    });

    lines[index].posting_date =
      date instanceof Date ? moment(date).format("YYYY-MM-DD") : date;
    taxLinesIndices.forEach((idx) => {
      lines[idx].posting_date = lines[index].posting_date;
    });
  };

  setAccount = (
    lines: JournalEntryLine[],
    index: number,
    account: GeneralLedgerAccount
  ) => {
    lines[index].account_code = account?.account_code;
    lines[index].account_id = account?.id;
    lines[index].account = account;
  };

  getAccount = (lines: JournalEntryLine[], index: number) => {
    return lines[index].account;
  };

  setDebitAmount = (
    lines: JournalEntryLine[],
    index: number,
    amount: number,
    currencyCode: string
  ) => {
    lines[index].debit_fc = amount > 0 ? amount : 0;
    lines[index].credit_fc = amount < 0 ? Math.abs(amount) : 0;
    lines[index].currency_code = currencyCode;

    this.recalculateLineTax(lines, index);
  };

  getDebitAmount = (line: JournalEntryLine) => {
    return line.debit_fc || 0;
  };

  setCreditAmount = (
    lines: JournalEntryLine[],
    index: number,
    amount: number,
    currencyCode: string
  ) => {
    lines[index].credit_fc = amount > 0 ? amount : 0;
    lines[index].debit_fc = amount < 0 ? Math.abs(amount) : 0;
    lines[index].currency_code = currencyCode;

    this.recalculateLineTax(lines, index);
  };

  getCreditAmount = (line: JournalEntryLine) => {
    return line.credit_fc || 0;
  };

  getDebitCreditDifference = (lines: JournalEntryLine[]) => {
    const totalDebit = lines.reduce((acc, l) => acc + (l.debit_fc || 0), 0);
    const totalCredit = lines.reduce((acc, l) => acc + (l.credit_fc || 0), 0);

    return totalDebit - totalCredit;
  };

  /** Gross / net amount calculations */
  getGrossAmount = (
    lines: JournalEntryLine[],
    index: number,
    objectType: ObjectTypes
  ) => {
    const taxMultiplier =
      (this.getDebitAmount(lines[index]) || 0) -
        (this.getCreditAmount(lines[index]) || 0) >
      0
        ? 1
        : -1;
    const multiplier =
      this.getGrossNetLinesDebitCredit(objectType) === "debit" ? 1 : -1;

    return (
      ((this.getDebitAmount(lines[index]) || 0) -
        (this.getCreditAmount(lines[index]) || 0) +
        (this.getTaxAmount(lines, index) || 0) * taxMultiplier) *
      multiplier
    );
  };

  getNetAmount = (
    lines: JournalEntryLine[],
    index: number,
    objectType: ObjectTypes
  ) => {
    const multiplier =
      this.getGrossNetLinesDebitCredit(objectType) === "debit" ? 1 : -1;

    return (
      ((this.getDebitAmount(lines[index]) || 0) -
        (this.getCreditAmount(lines[index]) || 0)) *
      multiplier
    );
  };

  /** Return the TaxCode object for the tax code specified on the journal entry line */
  getTaxCode = (lines: JournalEntryLine[], index: number) => {
    const taxCodeId = lines[index]?.tax_code_id;
    if (taxCodeId) return this.taxCodeResolver(taxCodeId);
  };

  setTaxCode = (
    lines: JournalEntryLine[],
    index: number,
    taxCode?: TaxCode
  ) => {
    const jel = lines[index];

    const previousTax = this.getTaxCode(lines, index);
    const wasNoTax = !previousTax;
    const wasAquisitionTax = !!previousTax?.aquisition_tax_account_id;
    const netAmountZeroTax =
      wasNoTax || wasAquisitionTax
        ? Math.abs((jel.debit_fc || 0) - (jel.credit_fc || 0))
        : Math.abs((jel.debit_fc || 0) - (jel.credit_fc || 0)) +
          (jel.tax_amount_fc || 0);

    const isAquisitionTax = !!taxCode?.aquisition_tax_account_id;
    const netAmountFactor = isAquisitionTax ? 0 : taxCode?.tax_rate || 0;

    lines[index].tax_code = taxCode?.code;
    lines[index].tax_code_id = taxCode?.id;
    lines[index].debit_fc =
      (jel.debit_fc || 0) - (jel.credit_fc || 0) > 0
        ? netAmountZeroTax / (1 + netAmountFactor / 100)
        : 0;
    lines[index].credit_fc =
      (jel.debit_fc || 0) - (jel.credit_fc || 0) < 0
        ? netAmountZeroTax / (1 + netAmountFactor / 100)
        : 0;

    this.recalculateLineTax(lines, index);
  };

  getTaxAmount = (lines: JournalEntryLine[], index: number) => {
    return lines[index].tax_amount_fc || lines[index].tax_amount;
  };

  setDescription = (
    lines: JournalEntryLine[],
    index: number,
    description: string
  ): void => {
    lines[index].description = description;
  };

  setDimensions = (
    lines: JournalEntryLine[],
    index: number,
    dimensions: JournalEntryDimension[]
  ): void => {
    lines[index].dimensions = dimensions;
  };

  addDimension = (
    lines: JournalEntryLine[],
    index: number,
    dimension: JournalEntryDimension
  ) => {
    const currentDimensionIndex = lines[index].dimensions?.findIndex(
      (d) => d.relation_type === dimension.relation_type
    );
    if (currentDimensionIndex && currentDimensionIndex >= 0)
      lines[index].dimensions?.splice(currentDimensionIndex, 1, dimension);
    else
      lines[index].dimensions = [...(lines[index].dimensions || []), dimension];
  };

  removeDimension = (
    lines: JournalEntryLine[],
    index: number,
    dimension: JournalEntryDimension
  ): void => {
    const currentDimensionIndex = lines[index].dimensions?.findIndex(
      (d) => d.relation_type === dimension.relation_type
    );
    if (currentDimensionIndex && currentDimensionIndex >= 0)
      lines[index].dimensions?.splice(currentDimensionIndex, 1);
  };

  private recalculateLineTax = (
    lines: JournalEntryLine[],
    index: number
  ): void => {
    const jel = lines[index];
    const existingTaxLineIdxs = lines
      .map((l, i) => (l && l.tax_for_line === jel.line_id ? i : -1))
      .filter((i) => i >= 0)
      .sort((a, b) => b - a);

    existingTaxLineIdxs.forEach((idx) => this.removeLine(lines, idx));

    const newIndex = lines.findIndex((l) => l.line_id === jel.line_id);

    const tax = this.getTaxCode(lines, newIndex);
    const taxRate = tax?.tax_rate || 0;
    const taxBaseAmount = Math.abs(
      (lines[newIndex]?.debit_fc || 0) - (lines[newIndex]?.credit_fc || 0)
    );
    const taxAmount = taxBaseAmount * (taxRate / 100);
    const debitCreditAmount =
      (lines[newIndex]?.debit_fc || 0) - (lines[newIndex]?.credit_fc || 0);

    // Set line tax values
    lines[newIndex].tax_amount_fc = taxAmount;
    lines[newIndex].tax_base_amount_fc = taxBaseAmount;

    if (!tax || !(tax?.aquisition_tax_account_id || tax?.tax_account_id))
      return;

    const taxAccount = tax.tax_account_id
      ? this.accountResolver(tax.tax_account_id)
      : undefined;
    const aquisitionTaxAccount = tax.aquisition_tax_account_id
      ? this.accountResolver(tax.aquisition_tax_account_id)
      : undefined;

    const isAquisitionTax = !!aquisitionTaxAccount;

    // Add tax line
    const taxLine = {
      line_id: Math.max(...lines.map((v) => v.line_id)) + 1,
      posting_date: lines[newIndex].posting_date,
      tax_for_line: lines[newIndex].line_id,
      account_id: taxAccount ? taxAccount.id : lines[newIndex].account?.id,
      account: taxAccount || lines[newIndex].account,
      account_code: taxAccount
        ? taxAccount.account_code
        : lines[newIndex].account?.account_code,
      debit_fc: debitCreditAmount > 0 ? taxAmount : 0,
      credit_fc: debitCreditAmount > 0 ? 0 : taxAmount,
      currency_code: lines[newIndex].currency_code,
      tax_code: tax.code,
      tax_code_id: tax.id,
      tax_line: true,
      from_automation: true,
      dimensions: [],
    } as unknown as JournalEntryLine;
    this.addLine(lines, taxLine, newIndex + 1);

    if (isAquisitionTax) {
      // Add aquisition tax line with tax information
      const aquisitionTaxLine = {
        line_id: Math.max(...lines.map((v) => v.line_id)) + 1,
        posting_date: lines[newIndex].posting_date,
        tax_for_line: lines[newIndex].line_id,
        account_id: aquisitionTaxAccount?.id,
        account: aquisitionTaxAccount,
        account_code: aquisitionTaxAccount?.account_code,
        debit_fc: debitCreditAmount < 0 ? taxAmount : 0,
        credit_fc: debitCreditAmount < 0 ? 0 : taxAmount,
        currency_code: lines[newIndex].currency_code,
        tax_code: tax.code,
        tax_code_id: tax.id,
        tax_line: true,
        from_automation: true,
        dimensions: [],
      } as unknown as JournalEntryLine;
      this.addLine(lines, aquisitionTaxLine, newIndex + 2);
    }
  };
}
