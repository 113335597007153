import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Button, DatePicker, Input } from "../../../components/commons";
import { IconArrowRight } from "../../../assests/icons";

const EditPeriodLock = (props) => {
  const { onBack, onSave } = props;
  const { t } = useTranslation();
  const initialData = {
    locked_until_date: "",
    locking_comment: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleCreatePeriodLock = () => {
    setIsFormSubmitted(true);

    if (!formData.locked_until_date || !formData.locking_comment) {
      return;
    }

    const body = {
      locked_until_date: moment(formData.locked_until_date).format("YYYY-MM-DD"),
      locking_comment : formData.locking_comment
    };

    onSave(body);
  };

  return (
    <div className="edit-page">
      <div className="header">
        <span role="button" className="back-button" onClick={() => onBack()}>
          <IconArrowRight />
        </span>
        <span className="title">{t("create_period_lock_modal.title")}</span>
      </div>
      <div className="detail-form">
        <DatePicker
          type="date-input"
          className="master-data-modal__input"
          size="lg"
          required
          isCheckValid={isFormSubmitted}
          label={t("create_period_lock_modal.locked_until_date")}
          value={formData.locked_until_date ?? ""}
          selectedDate={formData.locked_until_date}
          onChange={(value) =>
            setFormData({ ...formData, locked_until_date: value })
          }
        />

        <Input
          className="master-data-modal__input"
          size="lg"
          required
          isCheckValid={isFormSubmitted}
          label={t("create_period_lock_modal.locking_comment")}
          value={formData.locking_comment ?? ""}
          onChange={(value) =>
            setFormData({ ...formData, locking_comment: value })
          }
        />
      </div>
      <div>
        <Button
          className="save-button"
          size="md40"
          variant="success"
          onClick={handleCreatePeriodLock}
          text={t("master_data.save_button")}
        />
      </div>
    </div>
  );
};
export default EditPeriodLock;
