import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import { Input, Button, LinkButton } from "../../components/commons";
import { common, pathKeys } from "../../constants";
import { cookie } from "../../utils";

import { loginBgImg, loginImg, SnapBooks } from "../../assests/images";
import { IconArrowRight } from "../../assests/icons";

import { detectLanguage } from "../../utils/common";

import { useResetPassword, useGetCurrentUser } from "../../api";

import "./style.scss";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [isEmailValid, setEmailValid] = useState(false);
  const [
    showMessageAfterSuccessResetPassword,
    setShowMessageAfterSuccessResetPassword,
  ] = useState(false);

  const { mutateAsync: resetPassword } = useResetPassword();
  const { data: user, isSuccess: isGetUserSuccess } = useGetCurrentUser(
    cookie.getUserId()
  );

  useEffect(() => {
    if (user && isGetUserSuccess) {
      navigate(pathKeys.CLIENT_ACCOUNTS, { replace: true });
    }
  }, [user]);

  const handleResetPassword = () => {
    const lang = detectLanguage();
    resetPassword({
      email: DOMPurify.sanitize(username, { ALLOWED_TAGS: [] }),
      lang,
    }).then(() => {
      setShowMessageAfterSuccessResetPassword(true);
    });
  };

  return (
    <div
      className="forgot-password-page"
      style={{ backgroundImage: `url(${loginBgImg})` }}
    >
      <div className="logo-container">
        <div className="logo">
          <img src={SnapBooks} alt="" />
        </div>
        <div className="desc">{t("login_page.slogan")}</div>
      </div>
      <div className="forgot-password-form">
        <div>
          <div className="title">
            <span role="button" onClick={() => navigate(pathKeys.LOGIN)}>
              <IconArrowRight />
            </span>
            {t("forgot_password_page.title")}
          </div>
          {showMessageAfterSuccessResetPassword ? (
            <div className="sub-title">
              {t("forgot_password_page.we_sent_email_check_inbox")}
            </div>
          ) : (
            <>
              <div className="sub-title">
                {t("forgot_password_page.sub_title")}
              </div>
              <Input
                value={username}
                required
                onChange={setUsername}
                shouldCheckEmail
                setEmailValid={setEmailValid}
                onEnter={handleResetPassword}
                placeholder={t("forgot_password_page.email_placeholder")}
              />
              <div className="text-center">
                <Button
                  className="forgot-password-button"
                  size="lg"
                  variant={common.BOOTSTRAP_VARIANTS.PRIMARY}
                  text={t("forgot_password_page.reset_password_button")}
                  onClick={handleResetPassword}
                  disabled={!isEmailValid}
                />
              </div>
              <div className="text-center or-text">or</div>
              <div className="text-center login-text">
                <span>
                  {t("forgot_password_page.if_you_remember_your_password")}
                </span>
                <LinkButton
                  onClick={() => navigate(pathKeys.LOGIN)}
                  rightText={t("forgot_password_page.login_link")}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ForgotPassword);
