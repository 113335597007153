import { useContext, useEffect, useState } from "react";
import { JournalEntry } from "../../types/JournalEntryV2";
import { currencyFormat, roundDecimal } from "../../utils/common";
import { useTranslation } from "react-i18next";
import { CurrencyInput, Text } from "../commons";
import { IconGreenQuestions } from "../../assests/icons";
import { ClientAccountContext } from "../../views/App";
import { ClientAccount } from "../../types/ClientAccount";
import { JournalEntryController } from "./JournalEntryFunctions";

interface TotalsSectionProps {
  journalEntry: JournalEntry;
  journalEntryController: JournalEntryController;
}

export const TotalsSection = ({
  journalEntry,
  journalEntryController,
}: TotalsSectionProps) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [difference, setDifference] = useState(0);

  const activeCurrency =
    [
      ...new Set(
        journalEntry?.lines?.map((l) => l.currency_code).filter((l) => l)
      ),
    ]?.[0] || clientAccount.accounting_currency;

  const debitCreditDifferencePopoverText = `<div>
  ${t("post_detail.difference_between_debit_and_credit")}
  <div class="font-weight-bold">${currencyFormat(
    difference,
    activeCurrency,
    true
  )}</div>
</div>`;

  useEffect(() => {
    let totalDebit = 0;
    let totalCredit = 0;

    journalEntry?.lines
      ?.filter((l) => l)
      .forEach((line) => {
        totalDebit += journalEntryController.getDebitAmount(line) || 0;
        totalCredit += journalEntryController.getCreditAmount(line) || 0;
      });

    setTotalDebit(roundDecimal(totalDebit, 2));
    setTotalCredit(roundDecimal(totalCredit, 2));

    setDifference(roundDecimal(totalDebit, 2) - roundDecimal(totalCredit, 2));
  }, [journalEntry]);

  return (
    <div className="post-detail-list-item total">
      <div>{t("post_detail.total")}</div>
      <div />

      <CurrencyInput
        size="md"
        value={totalDebit}
        currencyCode={activeCurrency}
        disabled
      />
      <CurrencyInput
        size="md"
        value={totalCredit}
        currencyCode={activeCurrency}
        disabled
      />

      <div className="difference-wrap">
        <div>{currencyFormat(difference, activeCurrency, true)}</div>
        <Text
          as="span"
          placement="top"
          popover
          popoverText={debitCreditDifferencePopoverText}
          whitePopoverBackground
        >
          <IconGreenQuestions />
        </Text>
      </div>
    </div>
  );
};
