import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  useMutation,
  useQuery,
  useQueryClient,
  useIsFetching,
  useIsMutating,
} from "react-query";
import { Button, GridView, Loading } from "../../../components/commons";
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import { showErrorNotification } from "../../../utils/toastr";
import { authenticatedApiClient } from "../../../api/apiClients";
import EditPeriodLock from "./EditPeriodLock";
import { PeriodLock } from "../../../types/PeriodLock";
import { localeFormatDate } from "../../../utils/common";

import "../style.scss";

const PeriodLocks = (props: any) => {
  const { clientAccountId } = props;
  const { t } = useTranslation();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const queryClient = useQueryClient();
  const [isCreatingPeriodLock, setIsCreatingPeriodLock] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 7;

  const periodLocksQuery = useQuery(
    [
      "period-locks",
      {
        client_account_id: clientAccountId,
        page: page,
      },
    ],
    () => {
      return authenticatedApiClient
        .get("/period-locks", {
          params: {
            client_account_id: clientAccountId,
            page: page,
            per_page: pageSize,
          },
        })
        .then((res) => res.data as { period_locks: PeriodLock[]; pages: any });
    }
  );

  const periodLocksMutation = useMutation(
    (periodLock: PeriodLock) => {
      return authenticatedApiClient.post("period-locks", periodLock);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["period-locks"]);
      },
    }
  );

  const handleCreatePeriodLock = (periodLock: PeriodLock) => {
    periodLock.client_account_id = clientAccountId;
    periodLocksMutation.mutate(periodLock);

    setIsCreatingPeriodLock(false);
  };

  const handleQueryChange = (data: any) => {
    const q = {
      ...data,
    };
    const { page } = q;
    setPage(page);
  };

  return (
    <div className="detail-page">
      <Loading childLoading loading={isFetching > 0 || isMutating > 0} />
      {!isCreatingPeriodLock ? (
        <div className="period-lock-list">
          <div className="period-lock-gridview">
            <GridView
              columns={[
                {
                  Header: t("master_data.gridview.locked_until_date"),
                  accessor: "locked_until_date",
                  align: "left",
                  maxWidth: "12%",
                  Cell: (cellProps) => localeFormatDate(cellProps.value),
                },
                {
                  Header: t("master_data.gridview.locking_comment"),
                  accessor: "locking_comment",
                  align: "left",
                },
                {
                  Header: t("master_data.gridview.locked_by"),
                  align: "right",
                  maxWidth: "28%",
                  Cell: (cellProps) => (
                    <small style={{ opacity: "0.75" }}>
                      <span>
                        {cellProps.row.original.created_by.first_name}{" "}
                        {cellProps.row.original.created_by.last_name}
                        {" - "}
                        {localeFormatDate(
                          cellProps.row.original.created_at
                        )}{" "}
                        {localeFormatDate(
                          cellProps.row.original.created_at,
                          "HH:mm"
                        )}
                      </span>
                    </small>
                  ),
                },
              ]}
              data={periodLocksQuery.data?.period_locks}
              pages={periodLocksQuery.data?.pages}
              relativeRowHeight={30}
              onStateChange={(q) => handleQueryChange(q)}
              emptyStateTitle=""
            />
          </div>
          <div className="text-right">
            <Button
              onClick={() => setIsCreatingPeriodLock(true)}
              className="add-button"
              variant={BOOTSTRAP_VARIANTS.SECONDARY}
              text={t("master_data.add_new_period_lock")}
            />
          </div>
        </div>
      ) : (
        <EditPeriodLock
          onBack={() => setIsCreatingPeriodLock(false)}
          onSave={handleCreatePeriodLock}
          showErrorNotification={showErrorNotification}
        />
      )}
    </div>
  );
};

export default memo(PeriodLocks);
