import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { RecipientsSelect } from '../../components/commons'
import { IconClear } from '../../assests/icons'
import './style.scss'

const ManageRecipientsSelect = ({ onHide, onAdd, loading, show, channelUsers, recipients }) => {
  const { t } = useTranslation()
  const [preSelectedRecipients, setPreSelectedRecipients] = useState([])

  useEffect(() => {
    const selectedRecipientsIds = channelUsers?.map(({ user_id }) => user_id)
    if (selectedRecipientsIds !== undefined) {
      const value = recipients.reduce((res, el) => {
        if (selectedRecipientsIds.includes(el.id)) {
          res.push(el)
        }
        return res
      }, [])
      setPreSelectedRecipients(value)
    }
  }, [channelUsers, recipients])
  
  const handleOnChange = (options, e) => {
    if (e.action === 'select-option') {
      onAdd(e.option.id)
    }
  }

  return (
    <div className={cn('manage-recipients-select', { show })}>
      <span className="close-icon" role="button" onClick={onHide}><IconClear /></span>
      <div className="select-title">{t('messages.manage_recipients')}</div>
      <RecipientsSelect
        loading={loading}
        onChange={handleOnChange}
        recipients={recipients}
        preSelectedRecipients={preSelectedRecipients}
        menuIsOpen
        isSearchable
        isClearable
        isMulti
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
      />
    </div>
  )
}

ManageRecipientsSelect.propTypes = {
  channelUsers: PropTypes.array,
  recipients: PropTypes.array,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onAdd: PropTypes.func,
}

ManageRecipientsSelect.defaultProps = {
  channelUsers: [],
  recipients: [],
  loading: false,
  show : false,
  onHide: () => {},
  onAdd: () => {},
}

export default ManageRecipientsSelect
