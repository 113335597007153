import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { loginBgImg, loginImg, SnapBooks } from "../../assests/images";
import LoginForm from "../../components/LoginForm";

import "./style.scss";

const Login = () => {
  const { t } = useTranslation();
  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(${loginBgImg})` }}
    >
      <div className="logo-container">
        <div className="logo">
          <img src={SnapBooks} alt="" />
        </div>
        <div className="desc">{t("login_page.slogan")}</div>
      </div>
      <LoginForm />
    </div>
  );
};

export default memo(Login);
