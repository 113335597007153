import { useUserAccounts } from "../../../../dataHooks/user";

export const useInDirectClientAccounts = (clientId: number) => {
  const clients = useUserAccounts();

  const clientAccount = clients.find((c) => c.id === clientId);
  if (!clientAccount) {
    return [];
  }

  if (clientAccount.isDirectClient) {
    return clients.filter(
      (c) => c.contract?.provider_client_account_id === clientId
    );
  }

  return clients.filter(
    (c) =>
      c.contract?.provider_client_account_id ===
      clientAccount.contract?.provider_client_account_id
  );
};
