import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Input from '../Input'

import { IconArrowDown, IconGreenAdd, IconGreenReduce, IconGreyAdd } from '../../../assests/icons'
import './style.scss'

const CustomSelect = ({
  label, onChange, value, options, loading, className,
  size, required, noPadding, isCheckValid,
  getOptionLabel, onInputChange, onParentOptionClick
}) => {
  const [checkValid, changeCheckValid] = useState(false)
  const [typingTimeout, setTypingTimeOut] = useState(0)
  const [filteredOptions, setFilteredOptions] = useState({})
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (isCheckValid) changeCheckValid(true)
  }, [isCheckValid])

  useEffect(() => {
    if (options) {
      setFilteredOptions({...options})
    }
  }, [options])

  useEffect(() => {
  }, [loading])

  let isInvalid = false

  if (required && checkValid) {
    isInvalid = (value == null || value === '')
  }

  const handleInputChange = (newVal) => {
    setInputValue(newVal)
    const callback = (data) => {
      setFilteredOptions({...data})
    }

    const loadData = () => {
      onInputChange(newVal, callback)
    }
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    const newTypingTimeOut = setTimeout(loadData, 500)
    setTypingTimeOut(newTypingTimeOut)
  }

  const onInputClick = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const onInputBlur = () => {
    if (Object.keys(selectedItem).length) {
      setInputValue(getOptionLabel(selectedItem))
    } else {
      setInputValue('')
    }
  }

  const closeDropdown = () => {
    setMenuIsOpen(false)
  }

  const handleOptionItemClick = (option) => {
    if (option.level < 3) {
      parentOptionClickHandle(option)
    } else {
      setSelectedItem(option)
      setInputValue(getOptionLabel(option))
      setMenuIsOpen(false)
      onChange(option)
    }
  }

  const parentOptionClickHandle = (option) => {
    option.isOpened = !option.isOpened
    onParentOptionClick(option);
  }

  const getOptionLabelHtmlString = (option) => {
    let string = getOptionLabel(option)

    if (inputValue) {
      const reg = `(${inputValue})`
      const newReg = new RegExp(reg, "gi")
      string = string.replace(newReg, '<span style="background-color: #44dec5;">$1</span>');
    }
    
    return string;
  }

  const renderChildOptions = (pItem) => {
    return (
      Object.values(pItem).map(option => {
        if (inputValue && inputValue != '' && option.children && Object.keys(option.children).length) {
          option.isOpened = true
        }
        return (
          <>
            <div className="py-2 cursor-pointer" onClick={() => handleOptionItemClick(option)}>
              <div className={`ml-${option.level * 3}`}>
                {option.level < 3 &&
                <span role="button" className="me-2">
                  {option.isOpened ? <IconGreenReduce width={16} height={16} /> : <IconGreyAdd width={16} height={16} />}
                </span>
                }
                <span dangerouslySetInnerHTML={{__html: getOptionLabelHtmlString(option)}}></span>
              </div>
            </div>
            {option.isOpened && Object.keys(option.children).length && renderChildOptions(option.children)}
          </>
        )
      })
    )
  }

  const TreeMenu = () => {
    return (
      <div className="bg-white position-absolute py-2 shadow rounded zIndex-20">
        <div className="overflow-auto px-3 mx-h-400">
          {
            Object.values(filteredOptions).map(option => {
              if (inputValue && inputValue != '' && Object.keys(option.children).length) {
                option.isOpened = true
              }
              return (
                <>
                  <div className="py-2 cursor-pointer" onClick={() => handleOptionItemClick(option)}>
                    <div className={`ml-${option.level * 3}`}>
                      {option.level < 3 &&
                      <span role="button" className="me-2">
                        {option.isOpened ? <IconGreenReduce width={16} height={16} /> : <IconGreenAdd width={16} height={16} />}
                      </span>
                      }
                      <span dangerouslySetInnerHTML={{__html: getOptionLabelHtmlString(option)}}></span>
                    </div>
                  </div>
                  {option.isOpened && Object.keys(option.children).length && renderChildOptions(option.children)}
                </>
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn('async-select-control', className || '', {
        [`select-${size}`]: size,
        invalid: isInvalid,
        'no-padding': noPadding
      })}
    >
      {label && <div className={cn('label', required && 'required')}>{label}</div>}
      <Input
        className="my-0"
        value={inputValue}
        onChange={handleInputChange}
        onClick={onInputClick}
        onBlur={onInputBlur}
      />
      <div className="arrow-indicator">
        <span className={menuIsOpen ? 'menu-is-open' : 'menu-is-hidden'}><IconArrowDown /></span>
      </div>
      { menuIsOpen && <div className="position-fixed w-100 h-100 zIndex-10 t-0 l-0" onClick={closeDropdown}></div> }
      {
        menuIsOpen && TreeMenu()
      }
    </div>
  )
}

CustomSelect.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  required: PropTypes.bool,
  options: PropTypes.object,
  noPadding: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  onParentOptionClick: PropTypes.func,
  isCheckValid: PropTypes.bool,
  onInputChange: PropTypes.func
}

CustomSelect.defaultProps = {
  getOptionLabel: ({ label }) => label,
}

export default memo(CustomSelect)
