import { cloneDeep } from "lodash";
import { useContext, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteJournalEntry,
  useGetGeneralLedgerAccounts,
  useJournalEntryReducer,
  useReverseJournalEntry,
  useSaveJournalEntry,
} from "../../api/accountingApi";
import { JournalEntryTable, ReverseDocumentModal } from "../../components";
import PaymentDraftEditor from "../../components/PaymentDraftEditor";
import { Button } from "../../components/commons";
import { BOOTSTRAP_VARIANTS, ObjectTypes } from "../../constants";
import { ClientAccount } from "../../types/ClientAccount";
import { Document } from "../../types/DocumentV2";
import { JournalEntry } from "../../types/JournalEntryV2";
import { PaymentDraft } from "../../types/Payments";
import { Voucher } from "../../types/Voucher";
import { showErrorNotification } from "../../utils/toastr";
import { ClientAccountContext } from "../App";
import { BusinessDocumentWrapper } from "./BusinessDocumentContainer";
import { useSavePaymentDraft } from "../../api/paymentApi";
import { useSavePayrollRun } from "../../api/payrollApi";
import { PaymentDraftReducer } from "../../components/PaymentDraftEditor/PaymentDraftReducer";
import { useReversePosts } from "../../api";

export type JournalEntryWrapper = {
  type: "JournalEntry";
  document?: JournalEntry;
};

export type PaymentDraftWrapper = {
  type: "PaymentDraft";
  document?: PaymentDraft;
};

export type AccountingDocumentWrapper =
  | JournalEntryWrapper
  | PaymentDraftWrapper;

interface AccountingDocumentContainerProps {
  voucher: Voucher;
  voucherType: string;
  businessDocument: BusinessDocumentWrapper | undefined;
  onAccountingDocumentChange: (
    accountingDocument: AccountingDocumentWrapper | undefined
  ) => void;
  disabled?: boolean;
}

const AccountingDocumentContainer = ({
  voucher,
  voucherType,
  businessDocument,
  onAccountingDocumentChange,
}: AccountingDocumentContainerProps) => {
  console.log("AccountingDocumentContainer redraw");
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  // Data state
  const [journalEntry, journalEntryDispatch, journalEntryController] =
    useJournalEntryReducer(clientAccount.id);
  const [paymentDraft, paymentDraftDispatcher] = useReducer(
    PaymentDraftReducer,
    {
      client_account_id: clientAccount.id,
      transfers: [{ currency_code: clientAccount.accounting_currency }],
    } as PaymentDraft
  );

  // Helper state
  const [showReverseDocumentModal, setShowReverseDocumentModal] =
    useState(false);

  // Default date
  const getDefaultDate = () => {
    if (businessDocument?.type === "Document")
      return businessDocument?.document?.document_date;
    if (businessDocument?.type === "PayrollRun")
      return businessDocument?.document?.run_date;
  };
  const [defaultPostingDate, setDefaultPostingDate] = useState<
    string | undefined
  >(() => getDefaultDate());

  // Queries
  const generalLedgerAccountsQuery = useGetGeneralLedgerAccounts(
    clientAccount?.id
  );

  // Mutations
  const savePayrollRunMutation = useSavePayrollRun();
  const { mutateAsync: reversePosts } = useReversePosts();

  // Reverse document modal
  const handleReverseDocument = (data: any) => {
    reversePosts({ ...data, id: voucher?.id }).then((res: any) => {
      if (res) {
        setShowReverseDocumentModal(false);

        onAccountingDocumentChange && onAccountingDocumentChange(res);
      }
    });
  };

  // Voucher status. 4 possible states: draft, booked, deleted, pending
  const isVoucherDeleted = voucher?.is_active === false;
  const isBooked = !!voucher?.journal_entries?.find(
    (je: JournalEntry) => (je.cancelled || "N") === "N" && !je.is_draft
  );
  const isDraft = !!voucher?.journal_entries?.find(
    (je: JournalEntry) => je.is_draft
  );
  const isPending = !isVoucherDeleted && !isBooked && !isDraft;

  // Voucher types
  const isInvoiceDocument = voucherType === "INVOICE";
  const isReceipt = voucherType === "RECEIPT";
  const isDebtCollection =
    businessDocument?.type === "Document" &&
    (businessDocument.document?.document_type ===
      ObjectTypes.AP_DEBT_COLLECTION ||
      businessDocument.document?.document_type ===
        ObjectTypes.AR_DEBT_COLLECTION);
  const isPurchaseDocument =
    businessDocument?.type === "Document" &&
    (businessDocument.document?.document_type === ObjectTypes.AP_RECEIPT ||
      businessDocument.document?.document_type === ObjectTypes.AP_INVOICE ||
      businessDocument.document?.document_type === ObjectTypes.AP_CREDIT_NOTE ||
      businessDocument.document?.document_type ===
        ObjectTypes.AP_DEBT_COLLECTION);
  const activeCurrencyCode =
    businessDocument?.type === "Document"
      ? (businessDocument?.document as Document).currency_code
      : clientAccount.accounting_currency;

  const blankJournalEntry = {
    client_account_id: clientAccount?.id,
    is_draft: true,
    lines: [{ line_id: 1, currency_code: activeCurrencyCode }],
  } as JournalEntry;

  const createJournalEntry = () => {
    if (businessDocument?.type !== "Document") return;

    const document = businessDocument.document as Document;

    const account = generalLedgerAccountsQuery.data?.accounting_accounts.find(
      (a) => a.account_code === (isPurchaseDocument ? "2400" : "1500")
    );

    const payorAccount =
      generalLedgerAccountsQuery.data?.accounting_accounts.find(
        (a) => a.account_code === "2910"
      );

    const collectionFeeAccount =
      generalLedgerAccountsQuery.data?.accounting_accounts.find(
        (a) => a.account_code === "7798"
      );

    const grossTotal = document?.gross_amount;
    const collectionFee =
      (document?.late_fee_amount || 0) + (document?.interest_amount || 0);

    const amount = isPurchaseDocument
      ? isInvoiceDocument || isReceipt || isDebtCollection
        ? -((grossTotal || 0) - collectionFee)
        : (grossTotal || 0) - collectionFee
      : isInvoiceDocument || isReceipt || isDebtCollection
      ? (grossTotal || 0) - collectionFee
      : -((grossTotal || 0) - collectionFee);

    const withPayor = !!document?.payor_id && isReceipt;
    const withCollector = !!document?.collector_id;

    const newLines = [];
    if (!withCollector)
      newLines.push({
        line_id: 1,
        posting_date: businessDocument?.document?.document_date,
        debit_fc: amount > 0 ? amount : 0,
        credit_fc: amount < 0 ? -amount : 0,
        currency_code: activeCurrencyCode,
        account_code: account?.account_code,
        account_id: account?.id,
        account: account,
        dimensions: [
          {
            relation_type: "businesspartner",
            relation_id: document?.business_partner_id,
          },
        ],
        from_automation: true,
      });

    if (withPayor || withCollector)
      /* If payor is set - move payable from 2400 to 2915
         If collector is set - move payable from supplier to collector */
      newLines.push(
        ...[
          {
            line_id: Math.max(0, ...newLines.map((l) => l.line_id)) + 1,
            posting_date: document?.document_date,
            debit_fc: amount > 0 ? 0 : -amount,
            credit_fc: amount < 0 ? 0 : amount,
            currency_code: activeCurrencyCode,
            account_code: account?.account_code,
            account_id: account?.id,
            account: account,
            dimensions: [
              {
                relation_type: "businesspartner",
                relation_id: document?.business_partner_id,
              },
            ],
            from_automation: true,
          },
          {
            line_id: Math.max(0, ...newLines.map((l) => l.line_id)) + 2,
            posting_date: document?.document_date,
            debit_fc: amount > 0 ? amount + collectionFee : 0,
            credit_fc: amount < 0 ? -(amount - collectionFee) : 0,
            currency_code: activeCurrencyCode,
            account_code: withPayor
              ? payorAccount?.account_code
              : account?.account_code,
            account_id: withPayor ? payorAccount?.id : account?.id,
            account: withPayor ? payorAccount : account,
            dimensions: [
              {
                relation_type: "businesspartner",
                relation_id: withPayor
                  ? document?.payor_id
                  : document?.collector_id,
              },
            ],
            from_automation: true,
          },
        ]
      );

    if (document?.late_fee_amount) {
      newLines.push({
        line_id: Math.max(...newLines.map((l) => l.line_id)) + 1,
        posting_date: document?.document_date,
        debit_fc: isPurchaseDocument ? document?.late_fee_amount : 0,
        credit_fc: !isPurchaseDocument ? document?.late_fee_amount : 0,
        currency_code: activeCurrencyCode,
        account_code: collectionFeeAccount?.account_code,
        account_id: collectionFeeAccount?.id,
        account: collectionFeeAccount,
        description: t("Late fee"),
        from_automation: true,
      });
    }

    if (document?.interest_amount) {
      newLines.push({
        line_id: Math.max(...newLines.map((l) => l.line_id)) + 1,
        posting_date: document?.document_date,
        debit_fc: isPurchaseDocument ? document?.interest_amount : 0,
        credit_fc: !isPurchaseDocument ? document?.interest_amount : 0,
        currency_code: activeCurrencyCode,
        account_code: collectionFeeAccount?.account_code,
        account_id: collectionFeeAccount?.id,
        account: collectionFeeAccount,
        description: t("Interest"),
        from_automation: true,
      });
    }

    return {
      is_draft: true,
      posting_date: document?.document_date,
      client_account_id: clientAccount?.id,
      voucher_id: voucher?.id,
      relation_type: document.document_type,
      relation_id: document?.id,
      lines: [...newLines],
    } as JournalEntry;
  };

  const getJournalEntryForEditing = () => {
    const activeJournalEntry = voucher?.journal_entries?.find(
      (je) => (je.cancelled || "N") === "N"
    );

    return activeJournalEntry || createJournalEntry() || blankJournalEntry;
  };

  useEffect(() => {
    if (!voucher || !voucherType || !generalLedgerAccountsQuery.data) return;

    setDefaultPostingDate(getDefaultDate());

    if (
      voucherType === "PAYROLL_PAYMENT_LIST" ||
      voucherType === "PAYROLL_TAX_SETTLEMENT"
    ) {
      paymentDraftDispatcher({ type: "set", value: voucher.payment_draft });
    } else if (voucherType === "PAYROLL_POSTINGS") {
      journalEntryDispatch({
        type: "set",
        payload: voucher.journal_entry || blankJournalEntry,
      });
    } else if (
      [
        "INVOICE",
        "CREDIT_NOTE",
        "RECEIPT",
        "DEBT_COLLECTION_DOCUMENT",
      ].includes(voucherType as ObjectTypes)
    ) {
      journalEntryDispatch({
        type: "set",
        payload: getJournalEntryForEditing(),
      });
    } else if (voucherType === "OTHER") {
      journalEntryDispatch({
        type: "set",
        payload: voucher.journal_entry || blankJournalEntry,
      });
    } else if (!voucherType) {
      journalEntryDispatch({
        type: "set",
        payload: {} as JournalEntry,
      });
    }
  }, [voucher, voucherType, businessDocument, generalLedgerAccountsQuery.data]);

  const saveJournalEntryMutation = useSaveJournalEntry();
  const reverseJournalEntryMutation = useReverseJournalEntry();
  const deleteJournalEntryMutation = useDeleteJournalEntry();

  const savePaymentDraftMutation = useSavePaymentDraft();

  const onJournalEntryReverse = async () => {
    if (
      !journalEntry ||
      !confirm(t("Do you want to reverse the journal entry?"))
    )
      return;
    const res = await reverseJournalEntryMutation.mutateAsync(journalEntry.id!);
    onAccountingDocumentChange &&
      onAccountingDocumentChange({ type: "JournalEntry" });
  };

  const onJournalEntryDelete = async () => {
    if (
      !journalEntry?.id ||
      !confirm(t("Do you want to delete the journal entry?"))
    )
      return;
    const res = await deleteJournalEntryMutation.mutateAsync(journalEntry.id!);
    onAccountingDocumentChange &&
      onAccountingDocumentChange({ type: "JournalEntry" });
  };

  const onJournalEntrySave = async (isDraft: boolean) => {
    if (!journalEntry || !voucher) return;
    const res = cloneDeep(journalEntry);
    res.voucher_id = voucher?.id;
    res.is_draft = isDraft;

    if (!businessDocument) {
    } else if ("Document" === businessDocument.type) {
      res.relation_type = businessDocument?.document?.document_type;
      res.relation_id = businessDocument?.document?.id;
    } else if ("PayrollRun" === businessDocument.type) {
      res.relation_type = ObjectTypes.PAYROLL_RUN;
      res.relation_id = businessDocument?.document?.id;
    } else {
      showErrorNotification(t("Unknown business document type"));
      return;
    }

    // Ensure default values
    res.lines.forEach((line) => {
      if (!line.posting_date) line.posting_date = res.posting_date;
    });

    // Save Journal Entry
    try {
      const response = await saveJournalEntryMutation.mutateAsync(res);
      onAccountingDocumentChange &&
        onAccountingDocumentChange({
          type: "JournalEntry",
          document: response,
        });
    } catch (e: any) {
      showErrorNotification(t("Error saving journal entry: " + e.message));
      return;
    }
  };

  const onPaymentDraftSave = async (draft: PaymentDraft) => {
    if (!draft) return;

    const res = cloneDeep(draft);
    res.voucher_id = voucher?.id;
    res.draft_type = voucherType;

    // Save Payment Draft
    try {
      const response = await savePaymentDraftMutation.mutateAsync(res);

      // Add reference to the payment draft to the payment run
      if (
        voucherType == "PAYROLL_PAYMENT_LIST" &&
        businessDocument?.type === "PayrollRun"
      ) {
        const payrollRun = businessDocument?.document;
        if (payrollRun) {
          payrollRun.payment_draft_id = response.id;
          await savePayrollRunMutation.mutateAsync(payrollRun);
        }
      }

      // Let event listeners know that the payment draft has been saved
      onAccountingDocumentChange &&
        onAccountingDocumentChange({
          type: "PaymentDraft",
          document: response,
        });
    } catch (e: any) {
      showErrorNotification(t("Error saving payment draft: " + e.message));
      return;
    }
  };

  if (generalLedgerAccountsQuery.isLoading)
    return <div>Loading G/L accounts 😘</div>;

  return (
    <>
      <ReverseDocumentModal
        show={showReverseDocumentModal}
        loading={false}
        onHide={() => setShowReverseDocumentModal(false)}
        onSave={handleReverseDocument}
      />
      {["PAYROLL_PAYMENT_LIST", "PAYROLL_TAX_SETTLEMENT"].includes(
        voucherType
      ) && (
        <PaymentDraftEditor
          paymentDraft={paymentDraft}
          paymentDraftDispatcher={paymentDraftDispatcher}
          onSave={onPaymentDraftSave}
          disabled={!!paymentDraft?.id}
          tabIndex={30}
        />
      )}
      {journalEntry?.lines && (
        <JournalEntryTable
          key={journalEntry?.id || "DRAFT"}
          disabled={isVoucherDeleted || !journalEntry?.is_draft}
          journalEntry={journalEntry || ({} as any)}
          journalEntryDispatch={journalEntryDispatch}
          journalEntryController={journalEntryController}
          defaultDate={defaultPostingDate}
          onDefaultDateChange={(date) => setDefaultPostingDate(date)}
          tabIndex={30}
          footer={
            <>
              <div className="je-selector">
                {(isDraft || isPending) && (
                  <span
                    role="button"
                    className={
                      !journalEntry?.id || journalEntry?.is_draft
                        ? "pill selected"
                        : "pill"
                    }
                    onClick={() =>
                      journalEntryDispatch({
                        type: "set",
                        payload: getJournalEntryForEditing(),
                      })
                    }
                  >
                    {journalEntry?.is_draft ? t("Draft") : t("New")}
                  </span>
                )}
                {voucher?.journal_entries
                  ?.filter((je) => je && je.id && !je.is_draft)
                  ?.sort(
                    (a, b) =>
                      (b.sequence_number || 0) - (a.sequence_number || 0)
                  )
                  ?.map((je, index) => (
                    <span
                      key={je.sequence_number || index}
                      role="button"
                      className={
                        je.sequence_number === journalEntry?.sequence_number
                          ? "pill selected"
                          : "pill"
                      }
                      onClick={() =>
                        journalEntryDispatch({
                          type: "set",
                          payload: je,
                        })
                      }
                    >
                      {je.sequence_number ||
                        (je.is_draft ? t("Draft") : t("New"))}
                      <b>
                        {["Y", "C"].includes(je.cancelled || "N") ? ` Ⓡ` : ""}
                      </b>
                    </span>
                  ))}
              </div>
              <div className="je-functions">
                <div className="function-buttons">
                  {journalEntry?.id && journalEntry.is_draft && (
                    <Button
                      className="delete-draft-button"
                      variant={BOOTSTRAP_VARIANTS.SECONDARY}
                      text={t("Delete draft")}
                      onClick={() => onJournalEntryDelete()}
                      disabled={isBooked}
                    />
                  )}
                  {(!journalEntry?.id || journalEntry?.is_draft) && (
                    <>
                      <Button
                        className="save-draft-button"
                        variant={BOOTSTRAP_VARIANTS.SECONDARY}
                        text={t("post_detail.save_draft_button")}
                        onClick={() => onJournalEntrySave(true)}
                        disabled={isBooked}
                      />
                      <Button
                        className="book-button"
                        variant={BOOTSTRAP_VARIANTS.SECONDARY}
                        text={t("post_detail.book_button")}
                        onClick={() => onJournalEntrySave(false)}
                        disabled={isBooked}
                      />
                    </>
                  )}
                  {journalEntry?.id &&
                    !journalEntry?.is_draft &&
                    (journalEntry.cancelled || "N") === "N" && (
                      <Button
                        className="book-button"
                        variant={BOOTSTRAP_VARIANTS.SECONDARY}
                        text={t("Reverse")}
                        onClick={() => onJournalEntryReverse()}
                      />
                    )}
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default AccountingDocumentContainer;
