import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Input } from "../../../components/commons";
import {
  ARCHIVED_DOCUMENT_CATEGORIES,
  ARCHIVED_DOCUMENT_PERIODS,
} from "../../../constants";
import { ArchivedDocument } from "../../../types/ArchivedDocument";
import { ClientAccountContext } from "../../App";
import ArchivedDocumentCategoryField from "./ArchivedDocumentCategoryField";
import ArchivedDocumentPeriodField from "./ArchivedDocumentPeriodField";
import "./style.scss";

const ArchivedDocumentForm = ({
  value,
  onChange,
  disabled,
  tabIndex,
}: {
  value?: ArchivedDocument;
  onChange: (res: ArchivedDocument) => void;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();

  // Data state
  const [documentDate, setDocumentDate] = useState("");
  const [description, setDescription] = useState("");
  const [archivedDocumentCategory, setArchivedDocumentCategory] = useState("");
  const [archivedDocumentPeriod, setArchivedDocumentPeriod] = useState("");

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Limitation for document dates
  const minDate = new Date("2020-01-01").getTime(); // from: 01.01.2019
  const maxDate = Date.now() + 1000 * 60 * 60 * 24 * 365; // to:   ms * sec * min * hour * days

  const onSaveClicked = () => {
    const archivedDocument = _.cloneDeep(value) || ({} as ArchivedDocument);
    archivedDocument.client_account_id = clientAccount.id;
    archivedDocument.date = moment(documentDate).format("YYYY-MM-DD");
    archivedDocument.category = archivedDocumentCategory;
    archivedDocument.period = archivedDocumentPeriod;
    archivedDocument.description = description;

    onChange(archivedDocument);
  };

  const onCancelClicked = () => {
    onChange(value!);
  };

  useEffect(() => {
    setArchivedDocumentCategory(
      ARCHIVED_DOCUMENT_CATEGORIES.toArray().find(
        (el) => el.value === value?.category
      )?.value || ""
    );
    setArchivedDocumentPeriod(
      ARCHIVED_DOCUMENT_PERIODS.toArray().find(
        (el) => el.value === value?.period
      )?.value || ""
    );
    setDocumentDate(value?.date || "");
    setDescription(value?.description || "");
  }, [value]);

  const toDate = (val: string) => {
    if (!val || val.length !== 10) return;

    return moment(val).toDate();
  };

  return (
    <>
      <DatePicker
        label={t("post_detail.date")}
        selectedDate={toDate(documentDate)}
        onChange={(d) => setDocumentDate(moment(d).format("YYYY-MM-DD"))}
        minDate={minDate}
        maxDate={maxDate}
        required
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 1}
      />

      <ArchivedDocumentCategoryField
        archivedDocumentCategory={archivedDocumentCategory}
        setArchivedDocumentCategory={setArchivedDocumentCategory}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 2}
      />
      <ArchivedDocumentPeriodField
        archivedDocumentPeriod={archivedDocumentPeriod}
        setArchivedDocumentPeriod={setArchivedDocumentPeriod}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 3}
      />

      <Input
        size="md"
        label={t("post_detail.description")}
        className="description"
        value={description}
        onChange={setDescription}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 4}
      />

      {!disabled && (
        <div className="function-buttons">
          <Button text={t("Save")} onClick={onSaveClicked}></Button>
          <Button text={t("Cancel")} onClick={onCancelClicked}></Button>
        </div>
      )}
    </>
  );
};

export default ArchivedDocumentForm;
