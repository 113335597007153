import { max, sum } from "lodash";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateExternalReconciliation } from "../../api";
import { Button, Text } from "../../components/commons";
import { ObjectTypes } from "../../constants";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { BankAccount } from "../../types/BankAccount";
import { BankTransaction } from "../../types/BankTransactionV2";
import { ExternalReconciliation } from "../../types/ExternalReconciliation";
import { JournalEntryLine } from "../../types/JournalEntryV2";
import { currencyFormat } from "../../utils/common";
import { ClientAccountContext } from "../App";
import CreateIncomingBalanceModal from "./IncomingBalanceModal";

const ExternalReconciliationCreationContainer = ({
  bankAccount,
  bankTransactions,
  journalEntryLines,
  onExternalReconciliationCreated,
  onBankTransactionCreated,
  onCancel,
}: {
  bankAccount: BankAccount;
  bankTransactions: BankTransaction[];
  journalEntryLines: JournalEntryLine[];
  onExternalReconciliationCreated: (e: ExternalReconciliation) => void;
  onBankTransactionCreated: (t: BankTransaction) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Helper state
  const [mutationInProgress, setMutationInProgress] = useState(false);
  const [
    isCreateIncomingBalanceModalOpen,
    setIsCreateIncomingBalanceModalOpen,
  ] = useState(false);

  // Mutations
  const { mutateAsync: createExternalReconciliation } =
    useCreateExternalReconciliation();

  // Functions
  const selectedBankTransactionsAmountFormatted = currencyFormat(
    sum(bankTransactions.map((t) => t.amount) || 0),
    max(bankTransactions.map((t) => t.currency_code)) ||
      clientAccount.accounting_currency
  );

  const selectedJournalEntryLinesAmountFormatted = currencyFormat(
    sum(journalEntryLines.map((t) => (t.debit || 0) - (t.credit || 0)) || 0),
    max(journalEntryLines.map((t) => clientAccount.accounting_currency)) ||
      clientAccount.accounting_currency
  );

  const differenceFormatted = currencyFormat(
    sum(bankTransactions.map((t) => t.amount)) -
      sum(journalEntryLines.map((t) => (t.debit || 0) - (t.credit || 0))),
    max(journalEntryLines.map((t) => clientAccount.accounting_currency)) ||
      clientAccount.accounting_currency
  );

  // Event handlers
  const handleOpenCreateIncomingBalanceModal = () => {
    setIsCreateIncomingBalanceModalOpen(true);
  };

  const handleCreateExternalReconciliation = async () => {
    try {
      setMutationInProgress(true);

      const externalReconciliation = {
        client_account_id: clientAccount.id,
        external_type: ObjectTypes.BANK_STATEMENT,
        internal_type: ObjectTypes.BANK_ACCOUNT,
        internal_id: bankAccount.id,
        entries: [
          ...bankTransactions.map((t) => ({
            relation_type: ObjectTypes.BANK_STATEMENT,
            relation_id: t.id,
            amount: t.amount,
            currency_code: t.currency_code,
          })),
          ...journalEntryLines.map((l) => ({
            relation_type: ObjectTypes.JOURNAL_ENTRY,
            relation_id: l.journal_entry?.id,
            relation_line_id: l.id,
            amount: !!l.debit ? l.debit : l.credit,
            currency_code: clientAccount.accounting_currency,
          })),
        ],
      } as ExternalReconciliation;

      const createdExternalReconciliation = await createExternalReconciliation(
        externalReconciliation as any
      );

      onExternalReconciliationCreated &&
        onExternalReconciliationCreated(createdExternalReconciliation);
    } finally {
      setMutationInProgress(false);
    }
  };

  return (
    <>
      <CreateIncomingBalanceModal
        bankAccount={bankAccount}
        show={isCreateIncomingBalanceModalOpen}
        onIncomingBalanceCreated={(t: BankTransaction) => {
          onBankTransactionCreated && onBankTransactionCreated(t);
        }}
        onClose={() => setIsCreateIncomingBalanceModalOpen(false)}
      />
      <div>
        <Text>
          {t("Selected bank transactions: ")}
          {selectedBankTransactionsAmountFormatted}
        </Text>
        <Text>
          {t("Selected J/E postings: ")}
          {selectedJournalEntryLinesAmountFormatted}
        </Text>
        <Text>
          {t("Difference: ")}
          {differenceFormatted}
        </Text>
      </div>
      <div className="buttons">
        <div className="buttons-inline">
          <Button
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("Reconcile")}
            onClick={handleCreateExternalReconciliation}
            disabled={
              mutationInProgress ||
              selectedBankTransactionsAmountFormatted !=
                selectedJournalEntryLinesAmountFormatted
            }
            loading={mutationInProgress}
          />
          <Button
            variant={BOOTSTRAP_VARIANTS.INFO}
            text={t("Cancel")}
            onClick={onCancel}
            disabled={mutationInProgress}
            loading={mutationInProgress}
          />
        </div>
      </div>
      <div style={{ paddingTop: "20px" }}>
        <Text as={"a"} onClick={handleOpenCreateIncomingBalanceModal}>
          <small>{t("Create incoming balance bank transaction")}</small>
        </Text>
      </div>
    </>
  );
};

export default memo(ExternalReconciliationCreationContainer);
