import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Select } from "../../../components/commons";
import PaymentMeans from "../../../constants/paymentMeans";

const PaymentMeansField = ({
  value,
  onChange,
  disabled,
  tabIndex,
}: {
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();

  const paymentMeans = [
    {
      value: PaymentMeans.CASH,
      label: t(`payment_means.${PaymentMeans.CASH}`),
    },
    {
      value: PaymentMeans.CARD,
      label: t(`payment_means.${PaymentMeans.CARD}`),
    },
    {
      value: PaymentMeans.BANK_TRANSFER,
      label: t(`payment_means.${PaymentMeans.BANK_TRANSFER}`),
    },
    {
      value: PaymentMeans.DIRECT_DEBIT,
      label: t(`payment_means.${PaymentMeans.DIRECT_DEBIT}`),
    },
    {
      value: PaymentMeans.UNKNOWN,
      label: t(`payment_means.${PaymentMeans.UNKNOWN}`),
    },
  ];

  const paymentMean = paymentMeans.find((p) => p.value === value);

  return (
    <Select
      size="md"
      label={t("post_detail.payment_means")}
      options={paymentMeans}
      value={paymentMean}
      onChange={(o: any) => onChange(o?.value)}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

export default PaymentMeansField;
