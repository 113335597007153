import React, { useEffect, useRef, memo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { IconClear } from "../../../assests/icons";

import "./style.scss";
import LabelsSelect from "../LabelsSelect";
import { DimensionValue } from "../../../types/JournalEntryV2";

interface CustomSelectProps {
  show: boolean;
  onHide: any;
  className: string;
  positionTop?: number;
  dimensions: DimensionValue[];
  onDimensionsChange: (dv: DimensionValue[]) => void;
  [x: string]: any;
}

const CustomSelect = ({
  show,
  onHide,
  className,
  positionTop,
  dimensions,
  onDimensionsChange,
}: CustomSelectProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  // hide when outside click
  useEffect(() => {
    if (show) {
      const handleClickOutside = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as any)) {
          onHide();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [show, ref]);

  return (
    <div
      ref={ref}
      className={cn(className || "", { show })}
      style={{ top: `${positionTop}px` }}
    >
      <span className="close-icon" role="button" onClick={onHide}>
        <IconClear />
      </span>
      {className === "labels-select" ? (
        <div className="select-title">{t("labels.select.manage_labels")}</div>
      ) : (
        <></>
      )}
      <LabelsSelect
        dimensions={dimensions}
        onDimensionsChange={onDimensionsChange}
      />
    </div>
  );
};

export default memo(CustomSelect);
