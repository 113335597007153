import React, { useCallback, useContext } from "react";
import { IconAdd } from "../../assests/icons";
import { ObjectTypes } from "../../constants";
import { ClientAccount } from "../../types/ClientAccount";
import { GeneralLedgerAccountSuggestion } from "../../types/GeneralLedgerAccountV2";
import { JournalEntry, JournalEntryLine } from "../../types/JournalEntryV2";
import { ClientAccountContext } from "../../views/App";

import AccountSuggestions from "./AccountSuggestions";
import {
  JournalEntryController,
  JournalEntryReducerAction,
} from "./JournalEntryFunctions";

import {
  useGetGeneralLedgerAccounts,
  useGetTaxCodes,
} from "../../api/accountingApi";
import JournalEntryLineItem from "./JournalEntryLineItem";
import { TotalsSection } from "./TotalsSection";
import "./style.scss";
import JournalEntryTableHeader from "./JournalEntryTableHeader";

interface JournalEntryTableProps {
  disabled?: boolean;
  footer?: any;
  journalEntry: JournalEntry;
  journalEntryDispatch: React.Dispatch<JournalEntryReducerAction>;
  journalEntryController: JournalEntryController;
  defaultDate?: string;
  onDefaultDateChange?: (date: string) => void;
  tabIndex?: number;
}

const JournalEntryTable = (props: JournalEntryTableProps) => {
  const {
    disabled = false,
    footer,
    journalEntry,
    journalEntryDispatch,
    journalEntryController,
    defaultDate,
    onDefaultDateChange,
    tabIndex,
  } = props;
  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  //Queries
  const generalLedgerAccountsQuery = useGetGeneralLedgerAccounts(
    clientAccount?.id
  );
  const taxCodesQuery = useGetTaxCodes(clientAccount?.id);

  const handleAddLine = () => {
    journalEntryDispatch({
      type: "addLine",
      payload: {
        line: {
          line_id:
            Math.max(...(journalEntry?.lines || []).map((v) => v.line_id)) + 1,
          posting_date: defaultDate,
          currency_code: (journalEntry?.lines || []).map(
            (l) => l.currency_code
          )[0],
        } as JournalEntryLine,
      },
    });
  };

  const handleLineExit = useCallback(
    (lineIndex: number) => {
      if (
        !journalEntry.lines[lineIndex + 1] || // Last line
        (journalEntry.lines[lineIndex + 1].tax_line &&
          !journalEntry.lines[lineIndex + 2]) // Last non-tax line
      ) {
        journalEntryDispatch({
          type: "addLine",
          payload: {
            line: {
              line_id:
                Math.max(...journalEntry.lines.map((l) => l.line_id)) + 1,
              posting_date: defaultDate,
              currency_code: journalEntry.lines[lineIndex].currency_code,
            } as JournalEntryLine,
          },
        });
      }
    },
    [defaultDate, journalEntry.lines, journalEntryDispatch]
  );

  const handleUseSuggestion = (suggestion: GeneralLedgerAccountSuggestion) => {
    const account = generalLedgerAccountsQuery.data?.accounting_accounts?.find(
      (account) => account.id === suggestion.account_code_id
    );
    if (!account) return;

    const taxCode = taxCodesQuery.data?.tax_codes?.find(
      (taxCode) => taxCode.id === suggestion.tax_code_id
    );

    const difference = journalEntryController.getDebitCreditDifference(
      journalEntry.lines
    );
    const amount =
      suggestion.tax_code_id &&
      taxCode?.tax_rate &&
      !taxCode?.aquisition_tax_account_id
        ? difference / (1 + taxCode?.tax_rate / 100)
        : difference;
    if (!journalEntry.lines[journalEntry.lines.length - 1]?.account_code) {
      journalEntryDispatch({
        type: "setLine",
        payload: {
          line: {
            ...journalEntry.lines[journalEntry.lines.length - 1],
            account_code: account.account_code,
            account_id: account.id,
            tax_code_id: suggestion.tax_code_id,
          } as JournalEntryLine,
          index: journalEntry.lines.length - 1,
        },
      });
    } else {
      journalEntryDispatch({
        type: "addLine",
        payload: {
          line: {
            line_id:
              Math.max(...(journalEntry?.lines || []).map((v) => v.line_id)) +
              1,
            posting_date: defaultDate,
            account_id: account.id,
            account_code: account.account_code,
            debit_fc: amount > 0 ? 0 : -amount,
            credit_fc: amount < 0 ? 0 : amount,
            currency_code: (journalEntry?.lines || []).map(
              (l) => l.currency_code
            )[0],
            tax_code_id: suggestion.tax_code_id,
            tax_code: suggestion.tax_code,
          } as JournalEntryLine,
        },
      });
    }
  };

  if (generalLedgerAccountsQuery.isLoading)
    return <div>Loading G/L accounts 😘</div>;

  return (
    <div className={`post-detail-list${" advanced-mode"}`}>
      <JournalEntryTableHeader
        journalEntry={journalEntry}
        journalEntryDispatch={journalEntryDispatch}
        defaultDate={defaultDate}
        onDefaultDateChange={onDefaultDateChange}
        tabIndex={tabIndex || 30}
      />

      {journalEntry?.lines.map((line, index) => (
        <JournalEntryLineItem
          key={`${index}-${line.line_id}`}
          journalEntryLine={line}
          lineIndex={index}
          journalEntryDispatcher={journalEntryDispatch}
          relationType={
            (journalEntry?.relation_type as ObjectTypes) || ObjectTypes.OTHER
          }
          onLineExit={() => handleLineExit(index)}
          tabIndex={tabIndex ? tabIndex + index * 8 : undefined}
          disabled={disabled}
        />
      ))}
      {!disabled && (
        <div className="post-detail-list-item">
          <span className="add-icon" role="button" onClick={handleAddLine}>
            <IconAdd />
          </span>
          <AccountSuggestions
            documentType={journalEntry?.relation_type}
            documentId={journalEntry?.relation_id}
            businessPartnerId={journalEntryController.getReceivablePayableBusinessPartnerId(
              journalEntry?.lines || [],
              journalEntry?.relation_type as ObjectTypes
            )}
            onSuggestionClick={(s: any) => handleUseSuggestion(s)}
          />
        </div>
      )}
      <TotalsSection
        journalEntry={journalEntry}
        journalEntryController={journalEntryController}
      />
      <div className="post-detail-list-item footer">{footer}</div>
    </div>
  );
};

export default JournalEntryTable;
