import React from "react";
import ContentLoader from "react-content-loader";
import "./style.scss";

const Loader = () => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
    height="600px"
  >
    <rect x="0" y="20" rx="3" ry="3" width="100%" height="22" />
    <circle cx="92%" cy="123" r="11" />
    <circle cx="96%" cy="123" r="11" />
    <rect x="50" y="115" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="114" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="114" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="155" rx="3" ry="3" width="100%" height="2" />
    <circle cx="92%" cy="184" r="11" />
    <circle cx="96%" cy="184" r="11" />
    <rect x="50" y="176" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="175" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="175" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="216" rx="3" ry="3" width="100%" height="2" />
    <circle cx="92%" cy="242" r="11" />
    <circle cx="96%" cy="242" r="11" />
    <rect x="50" y="234" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="233" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="233" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="274" rx="3" ry="3" width="100%" height="2" />
    <circle cx="92%" cy="303" r="11" />
    <circle cx="96%" cy="303" r="11" />
    <rect x="50" y="295" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="294" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="294" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="335" rx="3" ry="3" width="100%" height="2" />
    <circle cx="92%" cy="363" r="11" />
    <circle cx="96%" cy="363" r="11" />
    <rect x="50" y="355" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="354" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="354" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="395" rx="3" ry="3" width="100%" height="2" />
    <circle cx="92%" cy="424" r="11" />
    <circle cx="96%" cy="424" r="11" />
    <rect x="50" y="416" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="415" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="415" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="453" rx="3" ry="3" width="100%" height="2" />
    <rect x="0" y="21" rx="3" ry="3" width="2" height="492" />
    <rect x="calc(100% - 2px)" y="21" rx="3" ry="3" width="2" height="492" />
    <circle cx="92%" cy="484" r="11" />
    <circle cx="96%" cy="484" r="11" />
    <rect x="50" y="476" rx="3" ry="3" width="15%" height="15" />
    <rect x="25%" y="475" rx="3" ry="3" width="25%" height="15" />
    <rect x="60%" y="475" rx="3" ry="3" width="25%" height="15" />
    <rect x="0" y="513" rx="3" ry="3" width="100%" height="2" />
    <rect x="0" y="75" rx="3" ry="3" width="100%" height="22" />
    <rect x="0" y="35" rx="3" ry="3" width="68" height="55" />
    <rect x="16%" y="35" rx="3" ry="3" width="149" height="55" />
    <rect x="47%" y="35" rx="3" ry="3" width="200" height="55" />
    <rect x="82%" y="35" rx="3" ry="3" width="100" height="55" />
    <rect x="98%" y="35" rx="3" ry="3" width="100" height="55" />
  </ContentLoader>
);

export default Loader;
