import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { authenticatedApiV2Client } from "../../../api/apiClients";
import { Button, GridView, Loading } from "../../../components/commons";
import { localeFormatDate } from "../../../utils/common";
import { showErrorNotification } from "../../../utils/toastr";

import "../style.scss";
import EditContract from "./EditContract";
import { Contract } from "../../../types/Contract";
import { IconEdit } from "../../../assests/icons";

const Contracts = (props: any) => {
  const { clientAccountId } = props;
  const { t } = useTranslation();

  const [contract, setContract] = useState<Contract | null>(null);

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const pageSize = 7;

  const contractsQuery = useQuery(
    [
      "contracts",
      {
        client_account_id: clientAccountId,
        page: page,
      },
    ],
    () => {
      return authenticatedApiV2Client
        .get("/contracts", {
          params: {
            client_account_id: clientAccountId,
            page: page,
            page_size: pageSize,
          },
        })
        .then((res) => res.data as { data: Contract[]; meta: any });
    }
  );

  const contractsMutation = useMutation(
    (contract: Contract) => {
      return authenticatedApiV2Client.put(`contracts/${contract.id}`, contract);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contracts"]);
      },
    }
  );

  const handleSaveContract = (contract: Contract) => {
    contractsMutation.mutate(contract, { onSuccess: () => setContract(null) });
  };

  const handleQueryChange = (data: any) => {
    const q = {
      ...data,
    };
    const { page } = q;
    setPage(page);
  };

  return (
    <div className="detail-page">
      <Loading childLoading loading={isFetching > 0 || isMutating > 0} />
      {!contract ? (
        <div className="period-lock-list">
          <div className="period-lock-gridview">
            <GridView
              columns={[
                {
                  Header: t("master_data.gridview.type"),
                  accessor: "type",
                  align: "left",
                  Cell: (cellProps) => t(`master_data.gridview.contract_type`),
                },
                {
                  Header: t("master_data.gridview.start_date"),
                  accessor: "start_date",
                  align: "left",
                  Cell: (cellProps) => localeFormatDate(cellProps.value),
                },
                {
                  Header: t("master_data.gridview.end_date"),
                  accessor: "end_date",
                  align: "left",
                  Cell: (cellProps) => localeFormatDate(cellProps.value),
                },
                {
                  Header: "",
                  accessor: "delete",
                  align: "left",
                  maxWidth: "6%",
                  Cell: (cellProps) => (
                    <span
                      className="delete-icon"
                      role="button"
                      onClick={() => setContract(cellProps.row.original)}
                    >
                      <IconEdit />
                    </span>
                  ),
                },
              ]}
              data={contractsQuery.data?.data}
              pages={contractsQuery.data?.meta}
              relativeRowHeight={30}
              onStateChange={(q) => handleQueryChange(q)}
              emptyStateTitle=""
            />
          </div>
        </div>
      ) : (
        <EditContract
          onBack={() => setContract(null)}
          onSave={handleSaveContract}
          showErrorNotification={showErrorNotification}
          contract={contract}
        />
      )}
    </div>
  );
};

export default memo(Contracts);
