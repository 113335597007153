import React, { useState, memo, useEffect, Fragment } from "react";
import cn from "classnames";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";

import { IconArrowDown } from "../../../assests/icons";
import "./style.scss";

interface CustomSelectProps {
  label?: string;
  onChange: any;
  value: any;
  options: any;
  loading?: any;
  className?: any;
  placeholder?: any;
  size?: any;
  required?: any;
  noPadding?: any;
  isCheckValid?: any;
  getOptionValue?: any;
  getOptionLabel?: any;
  disabled?: any;
  [x: string]: any;
}

const CustomSelect = (props: CustomSelectProps) => {
  const {
    label,
    onChange,
    value,
    options,
    loading,
    className,
    placeholder,
    size,
    required,
    noPadding,
    isCheckValid,
    getOptionValue,
    getOptionLabel,
    disabled,
    ...otherProps
  } = props;

  const { t } = useTranslation();

  const [checkValid, changeCheckValid] = useState(false);

  const handleOnBlur = () => changeCheckValid(true);

  useEffect(() => {
    if (isCheckValid) changeCheckValid(true);
  }, [isCheckValid]);

  let isInvalid = false;

  if (required && checkValid) {
    isInvalid = value == null || value === "";
  }

  const Menu = (props: any) => {
    return (
      <Fragment>
        <components.Menu className="custom-select-menu" style={{ zIndex: 10 }} {...props}>
          {props.children}
        </components.Menu>
      </Fragment>
    );
  };

  const DropdownIndicator = (props: any) => {
    return components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span
          className={
            props.selectProps.menuIsOpen ? "menu-is-open" : "menu-is-hidden"
          }
        >
          <IconArrowDown />
        </span>
      </components.DropdownIndicator>
    );
  }

  return (
    <div
      className={cn("select-control", className || "", {
        [`select-${size}`]: size,
        invalid: isInvalid,
        "no-padding": noPadding,
      })}
    >
      {label && (
        <div className={cn("label", required && "required")}>{label}</div>
      )}
      <Select
        className={cn("select")}
        placeholder={placeholder || t("common_components.select.placeholder")}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        onBlur={handleOnBlur}
        onChange={onChange}
        options={options}
        isLoading={loading}
        isSearchable
        isClearable
        isDisabled={disabled}
        {...otherProps}
        components={{ Menu, ...otherProps.components, DropdownIndicator }}
      />
    </div>
  );
};

export default memo(CustomSelect);
