import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Button, Input, Radio, Select, Text } from "../../components/commons";
import { IconArrowRight } from "../../assests/icons";

const EditBusinessPartners = (props) => {
  const { businessPartner, onBack, onSave } = props;
  const { t } = useTranslation();
  const initialData = {
    name: "",
    address: "",
    organization_id: null,
    organization_number: "",
    organization: null,
    is_organization: true,
    email: "",
    zip: "",
    city: "",
    state: "",
    country: "NO",
  };

  const [supplierData, setSupplierData] = useState(initialData);
  const [selectedCountry, changeSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isSupplierFormSubmited, setIsSupplierFormSubmited] = useState(false);

  useEffect(() => {
    const c = t("app.country_list");
    const countries = Object.keys(c).map((i) => {
      return {
        value: i,
        label: c[i],
      };
    });

    setCountries(countries);
    if (businessPartner) {
      setSupplierData(businessPartner);
    }

    const selectedCountry = countries.find(
      (el) => el.value === (businessPartner?.country || supplierData.country)
    );
    changeSelectedCountry(selectedCountry);
  }, [businessPartner]);

  const handleChangeSupplierData = (filed, value) => {
    if (filed === "organization" && !value) {
      setSupplierData({
        ...supplierData,
        organization_id: null,
        organization: null,
      });
    } else {
      setSupplierData({ ...supplierData, [filed]: value });
    }
  };

  const handleCreateSupplier = () => {
    setIsSupplierFormSubmited(true);

    if (!supplierData.name) {
      return;
    }

    const body = {
      ...supplierData,
      // organization_id: supplierData.id || null,
      country: selectedCountry.value,
    };

    if (!body.is_organization) {
      delete body.organization_id;
      delete body.organization_number;
    }

    onSave(body);
  };
  return (
    <div className="edit-page">
      <div className="header">
        <span role="button" className="back-button" onClick={() => onBack()}>
          <IconArrowRight />
        </span>
        {businessPartner ? (
          <span className="title">Edit {businessPartner.name}</span>
        ) : (
          <span className="title">Add New Businesspartner</span>
        )}
      </div>
      <div className="detail-form">
        <div className="radio-group">
          <div className="label">{t("create_supplier_modal.type")}</div>
          <div>
            <Radio
              checked={!supplierData.is_organization}
              label={t("create_supplier_modal.person_type")}
              onClick={() => handleChangeSupplierData("is_organization", false)}
            />
            <Radio
              checked={supplierData.is_organization}
              label={t("create_supplier_modal.organization_type")}
              onClick={() => handleChangeSupplierData("is_organization", true)}
            />
          </div>
        </div>
        <div>&nbsp;</div>
        <Input
          size="lg"
          required
          isCheckValid={isSupplierFormSubmited}
          label={t("master_data.gridview.name")}
          value={supplierData.name ?? ""}
          onChange={(e) => handleChangeSupplierData("name", e)}
        />
        {supplierData.is_organization && (
          <div>
            <Input
              className="master-data-modal__input"
              size="lg"
              label={t("create_supplier_modal.organization_number")}
              value={supplierData.organization_number ?? ""}
              onChange={(e) =>
                handleChangeSupplierData("organization_number", e)
              }
            />
            {supplierData.organization_id && (
              <div style={{ fontSize: "0.5em" }}>
                <Text as="span">
                  {t('Connected to "{{name}}" ({{organization_number}})', {
                    name: supplierData.organization?.name,
                    organization_number:
                      supplierData.organization?.organization_number,
                  })}
                </Text>
                {" - "}
                <Text
                  as="a"
                  onClick={() => handleChangeSupplierData("organization", null)}
                >
                  {t("(Remove connection)")}
                </Text>
              </div>
            )}
          </div>
        )}
        <Input
          className="master-data-modal__input"
          size="lg"
          label={t("master_data.gridview.email")}
          value={supplierData.email ?? ""}
          onChange={(e) => handleChangeSupplierData("email", e)}
        />
        <Input
          className="master-data-modal__input"
          size="lg"
          label={t("master_data.gridview.address")}
          value={supplierData.address ?? ""}
          onChange={(e) => handleChangeSupplierData("address", e)}
        />
        <Input
          className="master-data-modal__input"
          size="lg"
          label={t("master_data.gridview.zip")}
          value={supplierData.zip ?? ""}
          onChange={(e) => handleChangeSupplierData("zip", e)}
        />
        <Input
          className="master-data-modal__input"
          size="lg"
          label={t("master_data.gridview.city")}
          value={supplierData.city ?? ""}
          onChange={(e) => handleChangeSupplierData("city", e)}
        />
        <Input
          className="master-data-modal__input"
          size="lg"
          label={t("master_data.gridview.state")}
          value={supplierData.state ?? ""}
          onChange={(e) => handleChangeSupplierData("state", e)}
        />
        <Select
          size="md"
          label={t("master_data.gridview.country")}
          required
          isCheckValid
          options={countries}
          value={selectedCountry}
          onChange={changeSelectedCountry}
        />
      </div>
      <div>
        <Button
          className="save-button"
          size="md40"
          variant="success"
          onClick={handleCreateSupplier}
          text={t("master_data.save_button")}
        />
      </div>
    </div>
  );
};
export default EditBusinessPartners;
