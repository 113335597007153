import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PDFViewer } from "../../components/commons";
import { ObjectTypes } from "../../constants";
import { useConstCallback } from "../../utils/useConstCallback";

import "./styles.scss";
import { useGetVoucher } from "../../api/voucherApi";

export const PreviewTypes = {
  Voucher: ObjectTypes.VOUCHER,
  JournalEntryLine: ObjectTypes.JOURNAL_ENTRY_LINE,
};

export function preview(type: string | null, voucherId: number | null) {
  window.parent?.postMessage({ type, message: voucherId }, "*");
  (
    document?.getElementById("snapbooks-preview-window") as HTMLIFrameElement
  )?.contentWindow?.postMessage({ type, message: voucherId }, "*");
}

const PreviewView = () => {
  const { t } = useTranslation();

  const [voucherId, setVoucherId] = useState<number>();
  const { data: voucher, isLoading: isLoadingVoucher } =
    useGetVoucher(voucherId);

  const pdfRef = useRef(null);

  const eventListener = useConstCallback(
    (ev: MessageEvent<{ type: string; message: string }>) => {
      if (!ev.data || !ev.data.type || !ev.data.message)
        setVoucherId(undefined);
      else if (ev.data.type === PreviewTypes.Voucher) {
        setVoucherId(!!ev.data.message ? Number(ev.data.message) : undefined);
      }
    }
  );

  useEffect(() => {
    window.addEventListener("message", eventListener);
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  return (
    <div className="preview-view">
      {voucher && (
        <>
          {(voucher?.content_type || "").toLowerCase().includes("pdf") ? (
            <PDFViewer
              {...{
                url: voucher?.download_url,
                showPages: false,
                ref: pdfRef,
                style: { height: null },
              }}
            />
          ) : (
            <img className="image" src={voucher?.download_url} />
          )}
        </>
      )}
    </div>
  );
};

export default PreviewView;
