import { memo } from "react";
import { useTranslation } from "react-i18next";
import { BankTransactionStatement } from "../../types/BankTransactionV2";
import { formatDate } from "../../utils/common";

const StatementItem = ({
  statement: s,
}: {
  statement: BankTransactionStatement;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="statement-container" key={s.id}>
        <div className="date">{formatDate(s.created_at)}</div>
        <div className="category">
          {t("Category")}: {s.category}
        </div>
        {!!s.business_partner && (
          <div className="business-partner">
            {t("BP")}: {s.business_partner?.name}
          </div>
        )}
        <div className="comment">
          {t("Comment")}: {s.comment}
        </div>
      </div>
    </>
  );
};

export default memo(StatementItem);
