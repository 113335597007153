import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { IconPending, IconBooked } from "../../../assests/icons";

import "./style.scss";

const PostStatus = ({ status, greyIfPending }) => {
  const { t } = useTranslation();
  let icon, pendingStatus;
  switch (status) {
    case "PENDING":
    case "PENDING_ANSWER":
      icon = <IconPending />;
      pendingStatus = true;
      break;
    case "DRAFT":
    case "BOOKED":
    case "PAID":
      icon = <IconBooked />;
      break;
    default:
      icon = null;
  }

  return (
    <div
      className={cn("post-status-wrap", {
        "status-grey": pendingStatus && greyIfPending,
      })}
    >
      <div>{icon}</div>
      <div>{t(`document_statuses.${status}`)}</div>
    </div>
  );
};

PostStatus.propTypes = {
  status: PropTypes.string,
  greyIfPending: PropTypes.bool,
};

PostStatus.defaultProps = {
  status: "",
  greyIfPending: false,
};

export default memo(PostStatus);
