import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../components/commons";
import { IconArrowRight } from "../../assests/icons";

const EditProject = (props) => {
  const { project, onBack, onSave } = props;
  const { t } = useTranslation();
  const initialData = {
    name: "",
  };

  const [projectData, setProjectData] = useState(initialData);
  const [isProjectFormSubmitted, setIsProjectFormSubmitted] = useState(false);

  useEffect(() => {
    if (project) {
      setProjectData(project);
    }
  }, [project]);

  const handleChangeProjectData = (filed, value) =>
    setProjectData({ ...projectData, [filed]: value });

  const handleCreateProject = () => {
    setIsProjectFormSubmitted(true);

    if (!projectData.name) {
      return;
    }

    const body = {
      ...projectData,
    };

    onSave(body);
  };

  return (
    <div className="edit-page">
      <div className="header">
        <span role="button" className="back-button" onClick={() => onBack()}>
          <IconArrowRight />
        </span>
        {project ? (
          <span className="title">Edit {project.name}</span>
        ) : (
          <span className="title">Add New Project</span>
        )}
      </div>
      <div className="detail-form">
        <Input
          className="master-data-modal__input"
          size="lg"
          required
          isCheckValid={isProjectFormSubmitted}
          label={t("master_data.name_label")}
          value={projectData.name ?? ""}
          onChange={(e) => handleChangeProjectData("name", e)}
        />
      </div>
      <div>
        <Button
          className="save-button"
          size="md40"
          variant="success"
          onClick={handleCreateProject}
          text={t("master_data.save_button")}
        />
      </div>
    </div>
  );
};
export default EditProject;
