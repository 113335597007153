import { useMutation, useQuery } from "react-query";
import {
  authenticatedApiClient,
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "./apiClients";
import { GeneralLedgerAccount } from "../types/GeneralLedgerAccount";
import { GeneralLedgerAccount as GeneralLedgerAccountV2 } from "../types/GeneralLedgerAccountV2";
import { Pages } from "../types/Pages";
import { JournalEntry } from "../types/JournalEntryV2";
import { TaxCode } from "../types/TaxCode";
import {
  JournalEntryController,
  JournalEntryReducerAction,
} from "../components/JournalEntryTable/JournalEntryFunctions";
import { useMemo, useReducer } from "react";

export function useGetGeneralLedgerAccounts(clientAccountId: number) {
  return useQuery(
    ["getGeneralLedgerAccounts", clientAccountId],
    async () => {
      const res = await authenticatedApiClient.get(`/accounting-accounts`, {
        params: { per_page: 5000 },
      });
      throwErrorByStatus(res);
      return res.data as {
        accounting_accounts: GeneralLedgerAccount[];
        pages: Pages;
      };
    },
    { staleTime: Infinity, enabled: !!clientAccountId }
  );
}

export function useGetTaxCodes(clientAccountId: number) {
  return useQuery(
    "tax-codes",
    async () => {
      const res = await authenticatedApiClient.get("/tax-codes/");
      throwErrorByStatus(res);
      return res.data as { tax_codes: TaxCode[]; pages: Pages };
    },
    { staleTime: Infinity }
  );
}

export function useSaveJournalEntry() {
  return useMutation(async (journalEntry: JournalEntry) => {
    const res = journalEntry.id
      ? await authenticatedApiV2Client.put(
          `journal-entries/${journalEntry.id}`,
          journalEntry
        )
      : await authenticatedApiV2Client.post("journal-entries", journalEntry);
    throwErrorByStatus(res);
    return res.data as JournalEntry;
  });
}

export function useReverseJournalEntry() {
  return useMutation(async (journalEntryId: number) => {
    const res = await authenticatedApiV2Client.patch(
      `/journal-entries/${journalEntryId}`,
      {
        cancelled: "Y",
      }
    );
    throwErrorByStatus(res);
    return res.data as JournalEntry;
  });
}

export function useDeleteJournalEntry() {
  return useMutation(async (journalEntryId: number) => {
    const res = await authenticatedApiV2Client.delete(
      `/journal-entries/${journalEntryId}`
    );
    throwErrorByStatus(res);
  });
}

export function useJournalEntryReducer(
  clientAccountId: number
): [
  JournalEntry,
  React.Dispatch<JournalEntryReducerAction>,
  JournalEntryController
] {
  const taxCodesQuery = useGetTaxCodes(clientAccountId);
  const generalLedgerAccountsQuery =
    useGetGeneralLedgerAccounts(clientAccountId);

  const journalEntryController = useMemo(() => {
    return new JournalEntryController(
      (accountId: number) => {
        return generalLedgerAccountsQuery.data?.accounting_accounts.find(
          (a) => a.id === accountId
        ) as unknown as GeneralLedgerAccountV2;
      },
      (taxCodeId: number) => {
        return taxCodesQuery.data?.tax_codes.find((tc) => tc.id === taxCodeId);
      }
    );
  }, [generalLedgerAccountsQuery.data, taxCodesQuery.data]);

  // Data state
  const [journalEntry, journalEntryDispatch] = useReducer(
    journalEntryController.reducer,
    {} as JournalEntry
  );

  return [journalEntry, journalEntryDispatch, journalEntryController];
}
