import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Button, DatePicker, Input } from "../../../components/commons";
import { IconArrowRight } from "../../../assests/icons";

const EditContract = (props) => {
  const { onBack, onSave, contract } = props;
  const { t } = useTranslation();
  const initialData = !!contract
    ? contract
    : {
        start_date: "",
        end_date: "",
      };

  const [formData, setFormData] = useState(initialData);

  const handleSaveContract = () => {
    if (!formData.start_date) {
      return;
    }

    const body = {
      ...contract,
      start_date: moment(formData.start_date).format("YYYY-MM-DD"),
      end_date: formData.end_date
        ? moment(formData.end_date).format("YYYY-MM-DD")
        : null,
    };

    onSave(body);
  };

  return (
    <div className="edit-page">
      <div className="header">
        <span role="button" className="back-button" onClick={() => onBack()}>
          <IconArrowRight />
        </span>
        <span className="title">{t("edit_contract_modal.title")}</span>
      </div>
      <div className="detail-form">
        <DatePicker
          type="date-input"
          className="master-data-modal__input"
          size="lg"
          required
          label={t("edit_contract_modal.start_date")}
          value={
            formData.start_date ? moment(formData.start_date).toDate() : null
          }
          selectedDate={
            formData.start_date ? moment(formData.start_date).toDate() : null
          }
          onChange={(value) => setFormData({ ...formData, start_date: value })}
        />
        <DatePicker
          type="date-input"
          className="master-data-modal__input"
          size="lg"
          label={t("edit_contract_modal.end_date")}
          value={formData.end_date ? moment(formData.end_date).toDate() : null}
          selectedDate={
            formData.end_date ? moment(formData.end_date).toDate() : null
          }
          onChange={(value) => setFormData({ ...formData, end_date: value })}
        />
      </div>
      <div>
        <Button
          className="save-button"
          size="md40"
          variant="success"
          onClick={handleSaveContract}
          text={t("master_data.save_button")}
        />
      </div>
    </div>
  );
};
export default EditContract;
