import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "../commons";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";

import "./style.scss";

const ReverseDocumentModal = (props) => {
  const { loading, show, onHide, onSave } = props;
  const { t } = useTranslation();

  const body = {
    cancelled: "Y",
  };

  const handleReverseDocument = () => {
    onSave(body);
  };

  const handleOnHide = () => {
    onHide();
  };

  return (
    <Modal
      className="ask-question-modal"
      show={show}
      loading={loading}
      size="lg"
      backdrop="static"
      onHide={handleOnHide}
      // title={modalTitle}
    >
      <>
        <div>
          A new journal entry will be posted which cancels the current one, and
          the voucher will be open for new postings. Do you really want to
          reverse this posting?
        </div>
        <div className="actions">
          <Button
            className="cancel-button"
            size="md"
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("reverse_document_modal.cancel")}
            onClick={handleOnHide}
          />
          <Button
            className="send-button"
            size="md"
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("reverse_document_modal.reverse")}
            onClick={handleReverseDocument}
          />
        </div>
      </>
    </Modal>
  );
};

export default memo(ReverseDocumentModal);
