import React, { useState, useEffect, useContext, memo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Button, Select, Input, Loading } from "../../../components/commons";
import { IconClear, IconGreenQuestions } from "../../../assests/icons";
import {
  BOOTSTRAP_VARIANTS,
  currencyValuesWithFlags,
} from "../../../constants";
import { showErrorNotification } from "../../../utils/toastr";
import { useDebounce } from "../../../utils";

import { useGetIbanCalculation, useGetIbanValidation } from "../../../api";
import { ClientAccountContext } from "../../App";
import "./style.scss";

const CreateBankAccountModal = (props) => {
  const { onHide, onSave, loading, show, currency } = props;
  const { t } = useTranslation();
  const clientAccount = useContext(ClientAccountContext);

  const initialData = {
    name: "",
    bank_country: "",
    bank_code: "",
    currency: "",
    bban: "",
    bic: "",
    iban: "",
    routing_number: "",
    enableBBanRequest: false,
    enableIBanRequest: false,
  };

  const [isBankAccountFormSubmitted, setIsBankAccountFormSubmitted] =
    useState(false);
  const [selectedCountry, changeSelectedCountry] = useState(null);
  const [selectedCurrency, changeSelectedCurrency] = useState(
    currencyValuesWithFlags[0]
  );
  const [countries, setCountries] = useState([]);
  const [bankAccountData, setBankAccountData] = useState(initialData);

  const debouncedBankAccountData = useDebounce(bankAccountData);

  const { data: ibanValidation } = useGetIbanValidation(
    clientAccount?.id,
    debouncedBankAccountData?.iban,
    debouncedBankAccountData?.enableIBanRequest
  );
  const { data: ibanCalculation } = useGetIbanCalculation(
    clientAccount?.id,
    debouncedBankAccountData?.bban,
    selectedCountry?.value,
    debouncedBankAccountData?.enableBBanRequest
  );

  useEffect(() => {
    changeSelectedCurrency(
      currencyValuesWithFlags.find((el) => el.value === currency)
    );
  }, [currency]);

  useEffect(() => {
    const c = t("app.country_list");

    const countries = Object.keys(c).map((i) => {
      return {
        value: i,
        label: c[i],
      };
    });
    changeSelectedCountry({ value: "NO", label: c.NO });
    setCountries(countries);
  }, []);

  const handleChange = (changed, value) => {
    if (changed === "currency") {
      changeSelectedCurrency(value);
    } else if (changed === "bank_country") {
      changeSelectedCountry(value);
      const newData = { ...bankAccountData };
      newData.enableBBanRequest = true;
      newData.enableIBanRequest = false;
      setBankAccountData(newData);
    }
  };

  useEffect(() => {
    if (ibanCalculation) {
      let tmp = bankAccountData;
      let changes_flag = false;
      if (ibanCalculation.iban) {
        changes_flag = true;
        tmp = { ...tmp, iban: ibanCalculation.iban };
      }
      if (ibanCalculation.country) {
        const country = countries.filter(
          (country) => country.value === ibanCalculation.country
        );
        if (country.length === 1) {
          tmp = { ...tmp, bank_country: country[0] };
          changeSelectedCountry(country[0]);
        }
      }
      if (ibanCalculation.bank_code) {
        changes_flag = true;
        tmp = { ...tmp, bank_code: ibanCalculation.bank_code };
      }
      if (ibanCalculation.bic_candidates?.length === 1) {
        changes_flag = true;
        tmp = { ...tmp, bic: ibanCalculation.bic_candidates[0].bic };
      }

      tmp.bank = ibanCalculation.bank;

      if (changes_flag === true) {
        setBankAccountData(tmp);
      }
    }
  }, [ibanCalculation]);

  useEffect(() => {
    if (ibanValidation) {
      let tmp = bankAccountData;
      let changes_flag = false;
      if (ibanValidation.account_number && bankAccountData.bban === "") {
        changes_flag = true;
        tmp = { ...tmp, bban: ibanValidation.account_number };
      }
      if (ibanValidation.country && bankAccountData.country === "") {
        const country = countries.filter(
          (country) => country.value === ibanValidation.country
        );
        if (country.length === 1) {
          tmp = { ...tmp, bank_country: country[0] };
          changeSelectedCountry(country[0]);
        }
      }
      if (ibanValidation.bank_code && bankAccountData.bank_code === "") {
        changes_flag = true;
        tmp = { ...tmp, bank_code: ibanValidation.bank_code };
      }
      if (
        ibanValidation.bic_candidates?.length === 1 &&
        bankAccountData.bic === ""
      ) {
        changes_flag = true;
        tmp = { ...tmp, bic: ibanValidation.bic_candidates[0].bic };
      }
      if (changes_flag === true) {
        setBankAccountData(tmp);
      }
    }
  }, [ibanValidation]);

  const handleChangeBankAccountData = (filed, value) => {
    const newData = { ...bankAccountData, [filed]: value };

    if (filed == "iban") {
      newData.enableBBanRequest = false;
      newData.enableIBanRequest = true;
    }

    if (filed == "bban") {
      newData.enableBBanRequest = true;
      newData.enableIBanRequest = false;
    }

    setBankAccountData(newData);
  };

  const handleCreateBankAccount = () => {
    setIsBankAccountFormSubmitted(true);

    if (!bankAccountData.name) {
      return;
    }

    if (!bankAccountData.bban && !bankAccountData.iban) {
      showErrorNotification(
        t("create_bank_account_modal.iban_or_bban_not_filled")
      );
      return;
    }

    const body = {
      ...bankAccountData,
      currency: selectedCurrency?.value,
      bank_country: selectedCountry.value,
    };

    onSave(body).then((isSuccess) => {
      if (isSuccess) {
        setBankAccountData(initialData);
      }
    });
  };

  return (
    <div className={cn("create-bank-account-modal", { show })}>
      <Loading loading={loading} childLoading />
      <span
        className="clear-icon"
        role="button"
        onClick={() => {
          setIsBankAccountFormSubmitted(false);
          setBankAccountData(initialData);
          onHide();
        }}
      >
        <IconClear />
      </span>
      <div className="title">{t("create_bank_account_modal.title")}</div>
      <div className="content">
        <Input
          label={t("create_bank_account_modal.account_name")}
          size="md"
          noPadding
          required
          isCheckValid={isBankAccountFormSubmitted}
          value={bankAccountData.name}
          onChange={(e) => handleChangeBankAccountData("name", e)}
        />
        <Select
          label={t("create_bank_account_modal.bank_country")}
          size="md"
          noPadding
          required
          isCheckValid
          options={countries}
          value={selectedCountry}
          onChange={(e) => handleChange("bank_country", e)}
        />
        <div className="one-line">
          <Input
            label={t("create_bank_account_modal.bank_code")}
            size="md"
            isCheckValid={isBankAccountFormSubmitted}
            value={bankAccountData.bank_code}
            onChange={(e) => handleChangeBankAccountData("bank_code", e)}
          />
          <div className="bank-name">
            <span role="button" onClick={() => {}}>
              <IconGreenQuestions />
              {bankAccountData.bank || t("create_bank_account_modal.bank_name")}
            </span>
          </div>
        </div>
        <Input
          label={t("create_bank_account_modal.bban")}
          size="md"
          noPadding
          isCheckValid={isBankAccountFormSubmitted}
          value={bankAccountData.bban}
          onChange={(e) => handleChangeBankAccountData("bban", e)}
        />
        <Input
          label={t("create_bank_account_modal.swift_bic")}
          size="md"
          noPadding
          value={bankAccountData.bic}
          onChange={(e) => handleChangeBankAccountData("bic", e)}
        />
        <Input
          label={t("create_bank_account_modal.iban")}
          size="md"
          noPadding
          value={bankAccountData.iban}
          onChange={(e) => handleChangeBankAccountData("iban", e)}
        />
      </div>
      <div className="actions">
        <Button
          className="send-button"
          size="md"
          variant={BOOTSTRAP_VARIANTS.PRIMARY}
          text={t("create_bank_account_modal.add_button")}
          onClick={handleCreateBankAccount}
        />
      </div>
    </div>
  );
};

export default memo(CreateBankAccountModal);
