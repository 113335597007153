import moment from "moment";
import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, DateIntervalPicker } from "../../../components/commons";
import {
  getReportDateRanges,
  setReportDateRanges,
} from "../../../utils/common";

import { ClientAccountContext } from "../../App";

import { authenticatedApiV2Client } from "../../../api/apiClients";
import { IconExport } from "../../../assests/icons";
import { BOOTSTRAP_VARIANTS } from "../../../constants";
import "./style.scss";

const DataExportReport = () => {
  const [t] = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const reportDateRanges = getReportDateRanges();
  const [startDate, changeStartDate] = useState(reportDateRanges[0]);
  const [endDate, changeEndDate] = useState(reportDateRanges[1]);

  const reportParams = {
    client_account_id: clientAccount?.id,
    date_from: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
    date_to: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
  };

  // Effects
  useEffect(() => {
    setReportDateRanges(startDate, endDate);
  }, [startDate, endDate]);

  // Event handlers

  const exportOptions: any = {
    "SAF-T": {
      mimeType: "text/xml",
      filename: `saf-t.xml`,
    },
    ZIP: {
      mimeType: "application/zip",
      filename: `Export.zip`,
    },
  };

  const onExportClicked = async (exportOption: string) => {
    const response = await authenticatedApiV2Client.get(
      "/reports/data-export",
      {
        responseType: "blob",
        headers: {
          Accept: exportOptions[exportOption].mimeType,
        },
        params: reportParams,
      }
    );

    const exportURL = window.URL.createObjectURL(response.data);
    const tempLink = document.createElement("a");
    tempLink.href = exportURL;
    tempLink.setAttribute("download", exportOptions[exportOption].filename);
    tempLink.click();
  };

  // Components

  return (
    <div className={"general-ledger-report"}>
      <div className="title-wrap">
        <div className="title">{t("Data export")}</div>
        <div className="export-options">
          {Object.keys(exportOptions)?.map((o: any, i: number) => (
            <Button
              key={i}
              text={t(o)}
              variant={BOOTSTRAP_VARIANTS.LIGHT}
              size="sm"
              icon={<IconExport />}
              iconPosition="right"
              onClick={() => onExportClicked(o)}
            ></Button>
          ))}
        </div>
      </div>
      <div className="filter-header">
        <div className="filter-row">
          <DateIntervalPicker
            dateFormat="dd/MM/yyyy"
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={changeStartDate}
            onChangeEndDate={changeEndDate}
          />
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default memo(DataExportReport);
