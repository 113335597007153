import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  authenticatedApiClient,
  throwErrorByStatus,
} from "../../api/apiClients";
import { IconGreenQuestions } from "../../assests/icons";
import { Expandable } from "../../components/commons";
import { Pages } from "../../types/Pages";
import { VoucherCategory, VoucherStatement } from "../../types/Voucher";

import "./style.scss";

const VoucherStatementsSection = ({
  voucherStatements,
}: {
  voucherStatements: VoucherStatement[];
}) => {
  const { t } = useTranslation();

  // Queries
  const voucherCategoriesQuery = useQuery(
    "voucher-categories",
    async () => {
      const res = await authenticatedApiClient.get("/voucher-categories");
      throwErrorByStatus(res);
      return res.data as { categories: VoucherCategory[]; pages: Pages };
    },
    { staleTime: Infinity }
  );

  const getPaymentMethod = (statement: VoucherStatement) => {
    switch (statement?.payment_method) {
      case "BUSINESS_ACCOUNT":
        return <span>{t("post_detail.payment_method_business")}</span>;
      case "PRIVATE_ACCOUNT":
        return (
          <span>
            {t("post_detail.payment_method_private", {
              name: statement?.payor?.name,
            })}
          </span>
        );
      case "NOT_PAID":
        return <span>{t("post_detail.payment_method_not_paid")}</span>;
      case "NOT_PAYABLE":
        return <span>{t("post_detail.payment_method_not_payable")}</span>;
      default:
        return "";
    }
  };

  const getCategoryName = (statement: VoucherStatement) => {
    const category = voucherCategoriesQuery.data?.categories?.find(
      (vc) => vc.id === statement?.voucher_category_id
    );
    const parent_category = voucherCategoriesQuery.data?.categories?.find(
      (vc) => vc.id === category?.parent_id
    );
    return (
      <span>
        {parent_category ? `${parent_category.title_no} -> ` : ""}
        {category?.title_no}
      </span>
    );
  };

  return (
    <div className="questions-to-client">
      <Expandable
        contentClassName="padding accounting-question-items"
        title={`${t("post_detail.statement_provided")} (${
          voucherStatements.length
        })`}
        headerIcon={<IconGreenQuestions />}
        defaultActiveKey="0"
      >
        {voucherStatements?.map((statement) => (
          <div key={statement.id} className="accounting-question-item">
            <div className="title">{getPaymentMethod(statement)}</div>
            <div className="answer">
              {`${t("post_detail.statement_category")}: `}
              {getCategoryName(statement)}
              {statement?.additional_information && (
                <p>
                  Additional information: {statement?.additional_information}
                </p>
              )}
              {statement?.participant_information && (
                <p>Participants: {statement?.participant_information}</p>
              )}
              {statement?.project && <p>Project: {statement?.project.name}</p>}
              {statement?.collection && (
                <p>Collection: {statement?.collection.name}</p>
              )}
            </div>
            <div className={cn("answer-status")}>
              {statement?.payor?.name ||
                `${statement.created_by?.first_name} ${statement.created_by?.last_name}`}
            </div>
          </div>
        ))}
      </Expandable>
    </div>
  );
};

export default VoucherStatementsSection;
