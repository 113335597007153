const SALARY_DOCUMENTATION = 'Salary details'
const SALARY_RECONCILIATION = 'Salary reconciliation'
const VAT_RECONCILIATION = 'VAT reconciliation'
const SALES_DOCUMENTATION = 'Sales documentation'
const PURCHASE_DOCUMENTATION = 'Purchase documentation'
const EMPLOYEE_DOCUMENTATION = 'Employee documentation'
const LEAVE_OF_ABSENCE = 'Leave of absence documents'
const BANK_STATEMENT = 'Bank statements'
const PAYMENT_DOCUMENT = 'Payment document'

const ARRAY_ARCHIVED_DOCUMENT_CATEGORIES = [
  { label: 'archived_document_categories.salary_documentation', value: 'Salary details' },
  { label: 'archived_document_categories.salary_reconciliation', value: 'Salary reconciliation' },
  { label: 'archived_document_categories.vat_reconciliation', value: 'VAT reconciliation' },
  { label: 'archived_document_categories.sales_documentation', value: 'Sales documentation' },
  { label: 'archived_document_categories.purchase_documentation', value: 'Purchase documentation' },
  { label: 'archived_document_categories.employee_documentation', value: 'Employee documentation' },
  { label: 'archived_document_categories.leave_of_absence_documents', value: 'Leave of absence documents' },
  { label: 'archived_document_categories.bank_statements', value: 'Bank statements' },
  { label: 'archived_document_categories.payment_document', value: 'Payment document' }
]

export default {
  SALARY_DOCUMENTATION,
  SALARY_RECONCILIATION,
  VAT_RECONCILIATION,
  SALES_DOCUMENTATION,
  PURCHASE_DOCUMENTATION,
  EMPLOYEE_DOCUMENTATION,
  LEAVE_OF_ABSENCE,
  BANK_STATEMENT,
  PAYMENT_DOCUMENT,
  toArray: () => ARRAY_ARCHIVED_DOCUMENT_CATEGORIES
}
