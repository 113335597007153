import { useState, useRef, FC, MouseEvent } from "react";
import Overlay from "react-bootstrap/Overlay";
import { IconTrash } from "../../assests/icons";
import { useTranslation } from "react-i18next";
import { BOOTSTRAP_VARIANTS } from "../../constants";
import { Button } from "../../components/commons";
import { useCurrentClientAccount } from "../../dataHooks/user";
import { useDeleteVoucher } from "../../api";

interface DeleteConfirmationPopupProps {
  onSubmit: () => void;
  voucherId: number;
}

const DeleteConfirmationPopup: FC<DeleteConfirmationPopupProps> = ({
  onSubmit,
  voucherId,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const target = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { mutateAsync: deletePosts, isLoading: deleteLoading } =
    useDeleteVoucher();
  const currentClientAccount = useCurrentClientAccount();
  const isCustomer = currentClientAccount?.amICustomer;

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(false);
  };

  const handleConfirm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await deletePosts(voucherId);
    await onSubmit();
    setShow(false);
  };

  return (
    <>
      <IconTrash
        ref={target as React.RefObject<SVGSVGElement>}
        onClick={(e: MouseEvent) => {
          setShow((showPrev) => !showPrev);
          e.stopPropagation();
        }}
      />
      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        rootClose={true}
      >
        {({ ...props }) => (
          <div
            {...props}
            style={{
              marginLeft: "-95px",
              position: "absolute",
              backgroundColor: "#FFF",
              padding: "18px 10px",
              color: "#000",
              borderRadius: "8px",
              border: "1px solid #DDD",
              marginRight: "20px",
              width: "270px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              ...props.style,
            }}
          >
            <div id="popover-contained">
              <h6>
                {t(
                  isCustomer
                    ? "posts.do_you_want_to_delete_document"
                    : "posts.do_you_want_to_delete_voucher"
                )}
              </h6>
              <div className="mt-3">
                <div className="d-flex btnsContent justify-content-between">
                  <Button
                    className="me-1"
                    size="sm"
                    variant={BOOTSTRAP_VARIANTS.LIGHT}
                    onClick={handleCancel}
                    text={t("common_components.button.cancel")}
                  />
                  <Button
                    size="sm"
                    variant={BOOTSTRAP_VARIANTS.PRIMARY}
                    onClick={handleConfirm}
                    text={t("common_components.button.ok")}
                    loading={deleteLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default DeleteConfirmationPopup;
