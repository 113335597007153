import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Input, Button } from "../../components/commons";
import { common, pathKeys } from "../../constants";
import { loginBgImg, loginImg, SnapBooks } from "../../assests/images";
import { IconArrowRight } from "../../assests/icons";
import { showErrorNotification } from "../../utils/toastr";
import { useSubmitNewPassword } from "../../api";

import "./style.scss";
import NotFound from "../NotFound";
import {
  IconEye,
  IconEyeHidden,
} from "../../assests/icons";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [show, setShow] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const pathArray = location.pathname.split("/");
  const tokenFromUrl = pathArray[pathArray.length - 1];

  const { mutateAsync: submitNewPassword } = useSubmitNewPassword();
  const { token } = useParams();

  if (!token) {
    return <NotFound />;
  }

  const handleSubmitNewPassword = () => {
    if (password.length < 8 || repeatPassword.length < 8) {
      return showErrorNotification(
        t("reset_password_page.input_password_min_8_characters")
      );
    }
    if (password !== repeatPassword) {
      return showErrorNotification(
        t("reset_password_page.password_doesnt_match")
      );
    }
    if (!tokenFromUrl) {
      return showErrorNotification(t("reset_password_page.broken_link"));
    }
    submitNewPassword({ token, password }).then(() => {
      navigate(pathKeys.LOGIN);
    });
  };

  return (
    <div
      className="reset-password-page"
      style={{ backgroundImage: `url(${loginBgImg})` }}
    >

      <div className="logo-container">
        <div className="logo">
          <img src={SnapBooks} alt="" />
        </div>
        <div className="desc">{t("login_page.slogan")}</div>
      </div>
      <div className="reset-password-form">
        <div>
          <div className="title">
            <span role="button" onClick={() => navigate(pathKeys.LOGIN)}>
              <IconArrowRight />
            </span>
            {t("reset_password_page.title")}
          </div>
          <div className="position-relative">
            <Input
              label={t("reset_password_page.new_password")}
              name="password"
              value={password}
              required
              onChange={setPassword}
              placeholder={t("reset_password_page.password_placeholder")}
              type={show ? "text" : "password"}
              className={`${show ? "customInputPadding" : ""} `}
            />
            <div
              role="button"
              className="icon-space position-absolute end-0 top-50"
              onClick={() => setShow(!show)}
            >
              {show ? <IconEye /> : <IconEyeHidden />}
            </div>
          </div>
          <div className="mb-3" />
          <div className="position-relative">
            <Input
              label={t("reset_password_page.repeat_password")}
              name="password"
              value={repeatPassword}
              required
              onChange={setRepeatPassword}
              placeholder={t("reset_password_page.password_placeholder")}
              type={showPass ? "text" : "password"}
              className={`${showPass ? "customInputPadding" : ""} `}
            />
            <div
              role="button"
              className="icon-space position-absolute end-0 top-50"
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? <IconEye /> : <IconEyeHidden />}
            </div>
          </div>
        </div>

        <div className="text-center">
          <Button
            className="submit-new-password-button"
            size="lg"
            variant={common.BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("reset_password_page.save_button")}
            onClick={handleSubmitNewPassword}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ResetPassword);
