import React, { useContext, useEffect, useState } from "react";
import { PayrollRun, validatePayrollRun } from "../../types/PayrollRun";
import { ArchivedDocument } from "../../types/ArchivedDocument";
import DocumentTypeField from "./DocumentTypeField";
import { Voucher } from "../../types/Voucher";
import { Button, Select } from "../../components/commons";
import PayrollRunForm from "./PayrollRunForm";
import ArchivedDocumentForm from "./CommercialDocumentForm/ArchivedDocumentForm";
import DocumentForm from "./CommercialDocumentForm/DocumentForm";
import { ObjectTypes } from "../../constants";
import { Document, validateDocument } from "../../types/DocumentV2";
import { useGetPayrollRuns, useSavePayrollRun } from "../../api/payrollApi";
import { ClientAccount } from "../../types/ClientAccount";
import { ClientAccountContext } from "../App";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  authenticatedApiClient,
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "../../api/apiClients";
import { showErrorNotification } from "../../utils/toastr";
import { IconClear, IconEdit } from "../../assests/icons";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { JournalEntry } from "../../types/JournalEntryV2";

type DocumentWrapper = {
  type: "Document";
  document?: Document;
};

type PayrollRunWrapper = {
  type: "PayrollRun";
  document?: PayrollRun;
};

type ArchivedDocumentWrapper = {
  type: "ArchivedDocument";
  document?: ArchivedDocument;
};

export type BusinessDocumentWrapper =
  | DocumentWrapper
  | PayrollRunWrapper
  | ArchivedDocumentWrapper;

interface BusinnessDocumentContainerProps {
  voucher: Voucher;
  voucherType: string;
  onVoucherTypeChange: (voucherType: string) => void;
  onBusinessDocumentChange: (
    businessDocument: BusinessDocumentWrapper | undefined
  ) => void;
  onEditModeChange?: (isEditing: boolean) => void;
  disabled?: boolean;
}

export const BusinessDocumentContainer = ({
  voucher,
  voucherType,
  onVoucherTypeChange,
  onBusinessDocumentChange,
  onEditModeChange,
  disabled,
}: BusinnessDocumentContainerProps) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  // Data state
  const [documentType, setDocumentType] = useState("");
  const [document, setDocument] = useState<Document>();
  const [payrollRun, setPayrollRun] = useState<PayrollRun>();
  const [archivedDocument, setArchivedDocument] = useState<ArchivedDocument>();

  // Helper state
  const [isEditingDocument, setIsEditingDocument] = useState(false);
  const isSalesDocument =
    documentType === ObjectTypes.AR_INVOICE ||
    documentType === ObjectTypes.AR_CREDIT_NOTE ||
    documentType === ObjectTypes.AR_DEBT_COLLECTION;
  const isPurchaseDocument =
    documentType === ObjectTypes.AP_RECEIPT ||
    documentType === ObjectTypes.AP_INVOICE ||
    documentType === ObjectTypes.AP_CREDIT_NOTE ||
    documentType === ObjectTypes.AP_DEBT_COLLECTION;
  const isArchivedDocument = documentType === ObjectTypes.ARCHIVED_DOCUMENT;
  const isBooked =
    !!voucher?.journal_entries?.find(
      (je: JournalEntry) => (je.cancelled || "N") === "N" && !je.is_draft
    ) || voucher?.payment_draft?.id;

  // Queries
  const payrollRunsQuery = useGetPayrollRuns(clientAccount?.id);
  const payrollRuns = payrollRunsQuery.data?.data || [];

  // Mutations
  const { mutateAsync: savePayrollRun } = useSavePayrollRun();
  const documentMutation = useMutation(async (document: Document) => {
    const res = document.id
      ? await authenticatedApiClient.put(`documents/${document.id}`, document)
      : await authenticatedApiClient.post("documents", document);

    throwErrorByStatus(res);

    return res.data;
  });

  const archivedDocumentMutation = useMutation(
    async (document: ArchivedDocument) => {
      const res = document.id
        ? await authenticatedApiClient.put(
            `archived-documents/${document.id}`,
            document
          )
        : await authenticatedApiClient.post("archived-documents", document);
      throwErrorByStatus(res);
      return res.data;
    }
  );

  // Functions
  const getConsensus = (taskType: string): any => {
    return voucher.tasks?.find((t) => t.task_type === taskType)?.consensus
      ?.data;
  };

  const createDocumentSuggestion = () => {
    const der =
      getConsensus("RECEIPT_DATA_EXTRACTION") ||
      getConsensus("INVOICE_DATA_EXTRACTION") ||
      getConsensus("CREDIT_NOTE_DATA_EXTRACTION");
    const lastStatement = cloneDeep(voucher?.statements)?.sort(
      (a: any, b: any) => (b?.id || 0) - (a?.id || 0)
    )?.[0];
    const lastdoc = cloneDeep(voucher.document);

    const doc: Document = {
      document_type:
        lastdoc?.document_type || der?.internal_document_type || "",
      business_partner: lastdoc?.business_partner || der?.business_partner,
      business_partner_id:
        lastdoc?.business_partner?.id || der?.business_partner?.id,
      payor: lastdoc?.payor || lastStatement?.payor,
      payor_id: lastdoc?.payor_id || lastStatement?.payor_id,
      document_date: lastdoc?.document_date || der?.date,
      due_date: lastdoc?.due_date || der?.payment_due_date,
      document_number: lastdoc?.document_number || der?.invoice_number,
      payment_means: lastdoc?.payment_means,
      bank_account: lastdoc?.bank_account,
      bank_account_id: lastdoc?.bank_account_id,
      payment_ref: lastdoc?.payment_ref || der?.transaction_reference,
      gross_amount: lastdoc?.gross_amount || der?.amount,
      currency_code: lastdoc?.currency_code || der?.currency,
      client_account_id: clientAccount?.id,
      voucher_id: voucher.id,
    };
    return doc;
  };

  // Effects
  useEffect(() => {
    if (!voucher) return;

    // First check business document data
    if (voucher?.archived_document) {
      // Archived document
      setDocumentType(ObjectTypes.ARCHIVED_DOCUMENT);
      setArchivedDocument(voucher?.archived_document);
    } else if (voucher?.payment_draft?.draft_type === "PAYROLL_PAYMENT_LIST") {
      // Payroll payment list
      setDocumentType(ObjectTypes.PAYROLL_RUN);
      setPayrollRun(
        voucher?.payroll_run ||
          payrollRuns.find(
            (pr) => pr.payment_draft_id === voucher?.payment_draft?.id
          )
      );
    } else if (
      voucher?.payment_draft?.draft_type === "PAYROLL_TAX_SETTLEMENT"
    ) {
      // Payroll tax settlement
    } else if (voucher?.payroll_run && !voucher?.payment_draft) {
      // Payroll run / payroll postings
      setDocumentType(ObjectTypes.PAYROLL_RUN);
      setPayrollRun(voucher?.payroll_run);
    } else if (
      voucher?.payment_draft?.draft_type === "TAX_PAYMENT_INFORMATION"
    ) {
      // Tax payment information
    } else if (voucher?.document) {
      // Commercial document
      setDocumentType(voucher?.document?.document_type);
      setDocument(voucher?.document);
    } else {
      // Then check consensus data
      if (
        getConsensus("DOCUMENT_CLASSIFICATION")?.document_type ===
        "PAYROLL_PAYMENT_LIST"
      ) {
        setDocumentType(ObjectTypes.PAYROLL_RUN);
        setIsEditingDocument(!voucher?.payment_draft);
      } else {
        setDocumentType(ObjectTypes.OTHER);
        setIsEditingDocument(true);
      }
    }
  }, [voucher]);

  useEffect(() => {
    onEditModeChange && onEditModeChange(isEditingDocument);
  }, [isEditingDocument]);

  useEffect(() => {
    setIsEditingDocument(!disabled);
  }, [disabled]);

  // Event handlers
  const onPayrollRunSavePressed = async (payrollRun: PayrollRun) => {
    if (!payrollRun) {
      setIsEditingDocument(false);
      return;
    }
    if (!validatePayrollRun(payrollRun)) {
      showErrorNotification(t("post_detail.fill_in_required_fields"));
      return;
    }
    if (voucherType === "PAYROLL_PAYMENT_LIST") {
      onBusinessDocumentChange({ type: "PayrollRun", document: payrollRun });
      setIsEditingDocument(false);
      return;
    }

    try {
      payrollRun.voucher_id = voucher!.id;
      const response = await savePayrollRun(payrollRun);
      onBusinessDocumentChange({ type: "PayrollRun", document: response });
      setIsEditingDocument(false);
    } catch (e: any) {
      showErrorNotification(t("Error saving payroll run: " + e.message));
      return;
    }
  };

  const onDocumentSavePressed = async (document?: Document) => {
    if (!document) {
      setIsEditingDocument(false);
      return;
    }
    if (!validateDocument(document)) {
      showErrorNotification(t("post_detail.fill_in_required_fields"));
      return;
    }

    try {
      document.voucher_id = voucher!.id;
      (document as any).file_id = voucher!.id;
      const response = await documentMutation.mutateAsync(document);
      onBusinessDocumentChange({ type: "Document", document: response });
      setIsEditingDocument(false);
    } catch (e: any) {
      showErrorNotification(
        t("Error saving commercial document: " + e.message)
      );
      return;
    }
  };

  const onDeleteBusinessDocumentPressed = async () => {
    if (!voucher) return;
    if (
      confirm(
        t(
          "Do you really want to delete the business document for this voucher?"
        )
      )
    ) {
      if (document) {
        const res = await authenticatedApiV2Client.delete(
          `/documents/${voucher.document?.id}`
        );
        throwErrorByStatus(res);
        onBusinessDocumentChange({ type: "Document", document: undefined });
        setIsEditingDocument(false);
      } else if (archivedDocument) {
        const res = await authenticatedApiV2Client.delete(
          `/archived_documents/${voucher.archived_document?.id}`
        );
        throwErrorByStatus(res);
        onBusinessDocumentChange({
          type: "ArchivedDocument",
          document: undefined,
        });
        setIsEditingDocument(false);
      } else if (payrollRun) {
        const res = await authenticatedApiV2Client.delete(
          `/payroll_runs/${voucher.payroll_run?.id}`
        );
        throwErrorByStatus(res);
        onBusinessDocumentChange({ type: "PayrollRun", document: undefined });
        setIsEditingDocument(false);
      }
    }
  };

  const onArchivedDocumentSavePressed = async (
    archivedDocument: ArchivedDocument
  ) => {
    if (!archivedDocument) {
      setIsEditingDocument(false);
      return;
    }
    if (!archivedDocument.date) {
      showErrorNotification(t("post_detail.fill_in_date"));
      return;
    }

    try {
      archivedDocument.uploaded_file_id = voucher.id!;
      const response = await archivedDocumentMutation.mutateAsync(
        archivedDocument
      );
      onBusinessDocumentChange &&
        onBusinessDocumentChange({
          type: "ArchivedDocument",
          document: response,
        });
      setIsEditingDocument(false);
    } catch (e: any) {
      showErrorNotification(t("Error archiving document: " + e.message));
      return;
    }
  };

  const onOtherDocumentSavePressed = () => {
    if (onBusinessDocumentChange) {
      onBusinessDocumentChange(undefined);
      setIsEditingDocument(false);
    }
  };

  return (
    <>
      <DocumentTypeField
        value={{ voucherType, documentType: documentType || undefined }}
        onChange={(t) => {
          setDocumentType(t.documentType || "");
          onVoucherTypeChange && onVoucherTypeChange(t.voucherType);
        }}
        disabled={!isEditingDocument}
        tabIndex={1}
      />
      {!isBooked && (
        <div className="document-edit-buttons">
          <IconEdit onClick={() => setIsEditingDocument(true)}></IconEdit>
          <IconClear
            onClick={() => onDeleteBusinessDocumentPressed()}
          ></IconClear>
        </div>
      )}
      {voucherType == ObjectTypes.OTHER && isEditingDocument && (
        <Button
          text={t("Save")}
          onClick={onOtherDocumentSavePressed}
          variant={BOOTSTRAP_VARIANTS.PRIMARY}
        ></Button>
      )}
      {voucherType === "PAYROLL_PAYMENT_LIST" && (
        <Select
          size="md"
          label={t("Payroll run")}
          options={payrollRuns}
          value={payrollRun}
          onChange={onPayrollRunSavePressed}
          getOptionLabel={(pr: PayrollRun): string =>
            `${pr.year} - #${pr.run_number}`
          }
          getOptionValue={(pr: PayrollRun) => pr.id}
          isDisabled={!isEditingDocument}
          tabIndex={2}
        />
      )}
      {voucherType === "PAYROLL_POSTINGS" && (
        <PayrollRunForm
          value={payrollRun}
          onChange={onPayrollRunSavePressed}
          disabled={!isEditingDocument}
          tabIndex={2}
        />
      )}
      {isArchivedDocument && (
        <ArchivedDocumentForm
          value={archivedDocument}
          onChange={onArchivedDocumentSavePressed}
          disabled={!isEditingDocument}
          tabIndex={2}
        />
      )}
      {(isSalesDocument || isPurchaseDocument) && (
        <DocumentForm
          value={document}
          onChange={onDocumentSavePressed}
          documentType={documentType}
          disabled={!isEditingDocument}
          tabIndex={2}
        />
      )}
    </>
  );
};
