import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import cn from "classnames";

import "./style.scss";
import { Text } from "..";

interface Props {
  variant?: string;
  onClick?: (event: any) => void;
  text?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  noPadding?: boolean;
  loadingText?: string;
  size?: "sm" | "lg";
  icon?: React.ReactElement;
  lines?: number;
  iconPosition?: "left" | "right";
  type?: "button" | "submit" | "reset";
}

const CustomButton = (props: Props) => {
  const {
    variant = undefined,
    onClick = () => {},
    text = null,
    className = "",
    loading = false,
    disabled = false,
    noPadding = false,
    loadingText = null,
    size = "sm",
    icon = null,
    lines = null,
    iconPosition = "left",
    ...otherProps
  } = props;
  const textClass = cn("button-text", { dotLoading: loading });
  const displayText = loading ? loadingText || text : text;
  return (
    <Button
      className={cn("custom-button", { noPadding }, [className])}
      onClick={onClick}
      variant={variant}
      disabled={loading || disabled}
      size={size}
      {...otherProps}
    >
      <div
        className={cn("btn-content-wrapper", {
          "icon-left": icon && iconPosition === "left",
          "icon-right": icon && iconPosition === "right",
        })}
      >
        {loading == true && <Spinner animation="border" size="sm" />}
        {(text || loadingText) && (
          <Text as="span" size={size} lines={lines} className={textClass}>
            {!!icon && <span className="icon">{icon}</span>}
            {displayText}
          </Text>
        )}
      </div>
    </Button>
  );
};

export default CustomButton;
