import React from 'react'
import moment from 'moment'

import { LineChart } from '../commons'
import { currencyFormat } from '../../utils/common'
import Loader from './loader'

const chartColors = [
  'rgb(68, 222, 197, 0.8)',
  'rgb(255, 136, 134, 0.8)',
  'rgb(162, 125, 255, 0.8)',
  'rgb(237, 32, 244, 0.8)',
  'rgb(33, 63, 255, 0.8)',
  'rgb(0, 201, 195, 0.8)'
]

const chartOptions = (currency = 'USD') => ({
  spanGaps: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
      fullWidth: true,
      labels: {
        boxWidth: 0
      }
    },
    title: {
      display: false
    },
    tooltip: {
      custom: (tooltip) => {
        if (!tooltip) return
        tooltip.displayColors = false
      },
      bodyAlign: 'center',
      titleAlign: 'center',
      footerAlign: 'center',
      yAlign: 'bottom',
      displayColors: false,
      callbacks: {
        title: () => '',
        label: (tooltipItem) => {
          const val = tooltipItem.dataset.data[tooltipItem.dataIndex]
          return currencyFormat(val, currency, true)
        },
        labelPointStyle: () => ''
      }
    }
  },
  layout: {
    padding: {
      top: 30
    }
  },
  scales: {
    y: {
      gridLines: {
        borderDash: [4],
        color: '#f5f5f5'
      },
      ticks: {
        beginAtZero: true,
        labelOffset: -10,
        callback(label) {
          if (label >= 1000000) return `${currency} ${label / 1000000}M`
          if (label >= 1000) return `${currency} ${label / 1000}k`
          if (label <= -1000000) return `${currency} -${label / -1000000}M`
          if (label <= -1000) return `${currency} -${label / -1000}k`
          if (label < 0) return `${currency} -${-label}`
          return `${currency} ${label}`
        }
      }
    },
    x: {
      gridLines: {
        borderDash: [4],
        color: '#f5f5f5'
      },
      distribution: 'linear',
      offset: false
    }
  }
})

const OverviewChart = props => {
  const { chartData, currency, loading, onLegendColorsUpdated } = props

  if (!chartData || loading) {
    return <Loader />
  }

  // get array of last days of all months for current year
  const months = moment.monthsShort()
  const labels = months.map(el => moment().month(el).endOf('month').format('DD-MM-YYYY'))
  // convert chartData into chart datasets
  const datasets = [
    { label: 'Sales', data: [] },
    { label: 'Expenses', data: [] },
    { label: 'Result', data: [] }
  ]
  labels.forEach(month => {
    datasets.forEach(line => {
      line.data.push(chartData[month] ? chartData[month][line.label.toLowerCase()] : null)
    })
  })

  const options = chartOptions(currency)

  return (
    <LineChart
      colors={chartColors}
      options={options}
      labels={months}
      datasets={datasets}
      onLegendColorsUpdated={onLegendColorsUpdated}
    />
  )
}

export default OverviewChart
