import moment from "moment";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  DateIntervalPicker,
  Input,
  Text,
} from "../../../components/commons";
import {
  currencyFormat,
  localeFormatDate,
  getReportDateRanges,
  setReportDateRanges,
} from "../../../utils/common";

import { ClientAccountContext } from "../../App";

import { useInfiniteQuery } from "react-query";
import {
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "../../../api/apiClients";
import {
  IconCircleC,
  IconCircleR,
  IconExport,
  IconReconciled,
} from "../../../assests/icons";
import JournalEntryDetailsPopup from "../../../components/commons/JournalEntryDetailsPopup";
import ReconciliationDetailsPopup from "../../../components/commons/ReconciliationDetailsPopup";
import "./style.scss";
import { preview, PreviewTypes } from "../../Preview";
import { BOOTSTRAP_VARIANTS, pathKeys } from "../../../constants";
import GeneralLedgerAccountSelect from "../../../components/JournalEntryTable/GeneralLedgerAccountSelect";
import { AccountDimensionCombination } from "../../../types/JournalEntry";

function useGeneralLedgerReport(params: any) {
  return useInfiniteQuery(
    ["useInfiniteGeneralLedgerReport", params],
    async ({ pageParam = 1 }) => {
      const res = await authenticatedApiV2Client.get(
        "/reports/general-ledger",
        {
          params: {
            ...params,
            page: pageParam,
          },
        }
      );
      throwErrorByStatus(res);
      return res?.data;
    },
    {
      enabled: !!params,
      getNextPageParam: (lastPage) => {
        return lastPage?.meta?.page < lastPage?.meta?.pages
          ? lastPage?.meta?.page + 1
          : undefined;
      },
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

const GeneralLedgerReport = () => {
  const [t] = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const [selectedAccount, setSelectedAccount] =
    useState<AccountDimensionCombination>();

  const reportDateRanges = getReportDateRanges();
  const [startDate, changeStartDate] = useState(reportDateRanges[0]);
  const [endDate, changeEndDate] = useState(reportDateRanges[1]);

  const [keyWord, changeKeyWord] = useState("");
  const [reconciliationDetailsPopupId, setReconciliationDetailsPopupId] =
    useState<number | null>();
  const [journalEntryDetailsPopupId, setJournalEntryDetailsPopupId] = useState<
    number | null
  >();

  const reportParams = {
    client_account_id: clientAccount?.id,
    account_codes: selectedAccount?.account?.account_code,
    date_from: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
    date_to: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
    free_text: keyWord,
  };

  // Queries
  const {
    data: reportData,
    isLoading: isReportDataLoading,
    fetchNextPage: fetchNextReportDataPage,
    hasNextPage: hasNextReportDataPage,
    refetch: refetchReportData,
  } = useGeneralLedgerReport(reportParams);

  // Effects
  useEffect(() => {
    setReportDateRanges(startDate, endDate);
  }, [startDate, endDate]);

  // Event handlers
  const handleOpenVoucher = (
    voucherId: number,
    journalEntry: number | null = null,
    fullScreen = false
  ) => {
    if (fullScreen) {
      window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.POSTS}/${voucherId}`,
        "_blank"
      );
    } else {
      preview(PreviewTypes.Voucher, voucherId);
    }
  };

  const onReconciliationDetailsPopupHide = (changes: boolean) => {
    setReconciliationDetailsPopupId(null);
    if (changes) refetchReportData();
  };

  const onJournalEntryDetailsPopupHide = (changes: boolean) => {
    setJournalEntryDetailsPopupId(null);
    if (changes) refetchReportData();
  };

  const exportOptions: any = {
    EXCEL: {
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      filename: `General ledger.xlsx`,
    },
    PDF: {
      mimeType: "application/pdf",
      filename: `General ledger.pdf`,
    },
  };

  const onExportClicked = async (exportOption: string) => {
    const response = await authenticatedApiV2Client.get(
      "/reports/general-ledger",
      {
        responseType: "blob",
        headers: {
          Accept: exportOptions[exportOption].mimeType,
        },
        params: reportParams,
      }
    );

    const exportURL = window.URL.createObjectURL(response.data);
    const tempLink = document.createElement("a");
    tempLink.href = exportURL;
    tempLink.setAttribute("download", exportOptions[exportOption].filename);
    tempLink.click();
  };

  // Infinite scroll element and handlers
  const reportDataEndRef = useRef<HTMLDivElement>(null);
  const handleObserver = useCallback((entries: any) => {
    entries.forEach((e: any) => {
      if (e.isIntersecting) {
        if (e.target === reportDataEndRef.current) fetchNextReportDataPage();
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: "0px 0px 0px 0px",
      threshold: 0,
    });
    if (reportDataEndRef.current) observer.observe(reportDataEndRef.current);
  }, [reportData]);

  // Components
  const debitAmountCell = (
    amount_lc: number,
    amount_fc: number,
    currency_code: string,
    show_sign = false
  ) => {
    return (
      <Text>
        {show_sign && (amount_lc >= 0 ? "+" : "")}
        {clientAccount.accounting_currency === currency_code
          ? currencyFormat(amount_lc, currency_code)
          : currencyFormat(amount_fc, currency_code)}
        {clientAccount.accounting_currency !== currency_code ? (
          <>
            <br />
            <small style={{ opacity: "50%" }}>
              {currencyFormat(amount_lc, clientAccount.accounting_currency)}
            </small>
          </>
        ) : null}
      </Text>
    );
  };

  return (
    <div className={"general-ledger-report"}>
      <JournalEntryDetailsPopup
        journalEntryId={journalEntryDetailsPopupId || undefined}
        show={!!journalEntryDetailsPopupId}
        onHide={(c) => onJournalEntryDetailsPopupHide(c)}
      />
      <ReconciliationDetailsPopup
        journalEntryLineId={reconciliationDetailsPopupId || undefined}
        show={!!reconciliationDetailsPopupId}
        onHide={(c) => onReconciliationDetailsPopupHide(c)}
      />
      <div className="title-wrap">
        <div className="title">{t("General ledger report")}</div>
        <div className="export-options">
          {Object.keys(exportOptions)?.map((o: any, i: number) => (
            <Button
              key={i}
              text={t(o)}
              variant={BOOTSTRAP_VARIANTS.LIGHT}
              size="sm"
              icon={<IconExport />}
              iconPosition="right"
              onClick={() => onExportClicked(o)}
            ></Button>
          ))}
        </div>
      </div>
      <div className="filter-header">
        <div className="filter-row">
          <GeneralLedgerAccountSelect
            account={selectedAccount}
            onAccountChange={setSelectedAccount}
            selectDimensions={false}
          />
          <DateIntervalPicker
            dateFormat="dd/MM/yyyy"
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={changeStartDate}
            onChangeEndDate={changeEndDate}
          />
          <Input
            size="md"
            placeholder={t("common_components.input.placeholder_search")}
            value={keyWord}
            onChange={changeKeyWord}
          />
        </div>
      </div>
      <div>
        {isReportDataLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="report-table">
              <div className="report-table-header">
                <div>{t("Posting date")}</div>
                <div>{t("Due date")}</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div className="right-align">{t("J/E #")}</div>
                <div className="right-align">{t("Doc #")}</div>
                <div>{t("Description")}</div>
                <div className="right-align">{t("Tax Code")}</div>
                <div className="right-align">{t("Tax Am.")}</div>
                <div className="right-align">{t("Amount")}</div>
                <div className="right-align">{t("Rec #")}</div>
              </div>

              {reportData?.pages
                .filter((p) => p?.data)
                .flatMap((p) => p?.data)
                .map((jel, idx, arr) => {
                  const previousGroup = arr[idx - 1]?.grouping;
                  const currentGroup = jel.grouping;
                  return (
                    <React.Fragment key={jel.id}>
                      {previousGroup &&
                        previousGroup?.level_1_code !==
                          currentGroup?.level_1_code && (
                          <>
                            <div className="report-table-row report-table-group-footer">
                              <div>
                                <Text>{previousGroup?.level_1_name}</Text>
                              </div>
                              <div>
                                {t(
                                  "Outgoing balance, movement of {{movement}}",
                                  {
                                    movement: currencyFormat(
                                      (previousGroup?.outgoing_balance || 0) -
                                        (previousGroup?.incoming_balance || 0),
                                      "NOK"
                                    ),
                                  }
                                )}
                              </div>
                              <div></div>
                              <div className="right-align"></div>
                              <div className="right-align">
                                {debitAmountCell(
                                  previousGroup?.outgoing_balance || 0,
                                  0.0,
                                  "NOK"
                                )}
                              </div>
                            </div>
                            <div className="spacer">&nbsp;</div>
                          </>
                        )}
                      {previousGroup?.level_1_code !==
                        currentGroup?.level_1_code && (
                        <div className="report-table-row report-table-group-header">
                          <div>
                            <Text>{currentGroup?.level_1_name}</Text>
                          </div>
                          <div>{t("Incoming balance")}</div>
                          <div></div>
                          <div></div>
                          <div className="right-align">
                            {debitAmountCell(
                              currentGroup?.incoming_balance || 0,
                              0,
                              "NOK"
                            )}
                          </div>
                        </div>
                      )}
                      {jel.id && (
                        <div className="report-table-row">
                          <div>
                            <Text>{localeFormatDate(jel.posting_date)}</Text>
                          </div>
                          <div>
                            <Text>
                              {localeFormatDate(
                                jel.journal_entry?.related_object.due_date
                              )}
                            </Text>
                          </div>
                          <div>
                            {!!jel.reconciled_amount &&
                              Math.abs(jel.reconciled_amount) > 0 && (
                                <Text
                                  as="a"
                                  onClick={() =>
                                    setReconciliationDetailsPopupId(jel.id)
                                  }
                                >
                                  <IconReconciled
                                    width={16}
                                    height={16}
                                    opacity={
                                      Math.abs(jel.debit_amount) -
                                        jel.reconciled_amount >
                                      0
                                        ? "20%"
                                        : "100%"
                                    }
                                  />
                                </Text>
                              )}
                          </div>
                          <div>
                            {jel.journal_entry?.cancelled === "Y" ? (
                              <IconCircleR width={16} height={16} />
                            ) : jel.journal_entry?.cancelled === "C" ? (
                              <IconCircleC width={16} height={16} />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="right-align">
                            <Text
                              as="a"
                              onClick={() =>
                                setJournalEntryDetailsPopupId(
                                  jel.journal_entry?.id
                                )
                              }
                            >
                              {jel.journal_entry?.sequence_number}
                            </Text>
                          </div>
                          <div className="right-align">
                            {jel.journal_entry?.related_object
                              ?.document_number && (
                              <Text
                                as="a"
                                onClick={(e) =>
                                  handleOpenVoucher(
                                    jel.journal_entry?.voucher_id,
                                    null,
                                    e.ctrlKey
                                  )
                                }
                              >
                                {
                                  jel.journal_entry?.related_object
                                    ?.document_number
                                }
                              </Text>
                            )}
                          </div>
                          <div>
                            <Text>
                              {jel.description ||
                                jel.journal_entry?.description}
                            </Text>
                          </div>
                          <div className="right-align">{jel.tax_code}</div>
                          <div className="right-align">
                            {debitAmountCell(
                              jel.tax_amount,
                              jel.tax_amount_fc,
                              jel.currency_code
                            )}
                          </div>
                          <div className="right-align">
                            {debitAmountCell(
                              jel.debit - jel.credit,
                              jel.debit_fc - jel.credit_fc,
                              jel.currency_code
                            )}
                          </div>
                          <div className="right-align">
                            {Array.from(
                              new Set(
                                jel.reconciled_documents
                                  ?.filter(
                                    (d: any) =>
                                      d.relation_number !==
                                      jel.journal_entry?.related_object
                                        ?.document_number
                                  )
                                  ?.map((d: any) => d.relation_number) || []
                              )
                            ).join(", ")}
                          </div>
                        </div>
                      )}
                      {idx == arr.length - 1 && hasNextReportDataPage && (
                        <div ref={reportDataEndRef}></div>
                      )}
                      {idx == arr.length - 1 && !hasNextReportDataPage && (
                        <div className="report-table-row report-table-group-footer">
                          <div>
                            <Text>{currentGroup?.level_1_name}</Text>
                          </div>
                          <div>
                            {t("Outgoing balance - movement {{movement}}", {
                              movement: currencyFormat(
                                (currentGroup?.outgoing_balance || 0) -
                                  (currentGroup?.incoming_balance || 0),
                                "NOK"
                              ),
                            })}
                          </div>
                          <div></div>
                          <div></div>
                          <div className="right-align">
                            {debitAmountCell(
                              currentGroup?.outgoing_balance || 0,
                              0,
                              "NOK"
                            )}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              <div className="postings-list-footer">
                <div className="function-buttons"></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(GeneralLedgerReport);
