import cn from "classnames";
import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconCircleC, IconCircleR } from "../../assests/icons";
import { Text } from "../../components/commons";
import { currencyFormat } from "../../utils/common";
import { ClientAccountContext } from "../App";

const JournalEntryLineListItem = ({
  journalEntryLine,
  selected,
  onClick,
  onDetailsClick,
}: {
  journalEntryLine: any;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onDetailsClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const amount = journalEntryLine?.debit - journalEntryLine?.credit;
  const amount_fc =
    journalEntryLine?.debit_fc || journalEntryLine?.credit_fc
      ? (journalEntryLine?.debit_fc || 0) - (journalEntryLine?.credit_fc || 0)
      : null;

  const memo =
    journalEntryLine?.description ||
    journalEntryLine?.journal_entry?.description;

  return (
    <div
      className={cn("transactions-details-item", {
        selected,
        reconciled: !!journalEntryLine.external_reconciled_amount,
        draft: journalEntryLine.journal_entry.is_draft,
      })}
      role="button"
      onClick={onClick}
    >
      <div className="item-title">
        <div className="company-name">{memo}</div>
        <div className="price">
          {currencyFormat(amount, clientAccount.accounting_currency)}
          {amount_fc && (
            <>
              <br />
              <small style={{ opacity: "50%" }}>
                {currencyFormat(amount_fc, journalEntryLine.currency_code)}
              </small>
            </>
          )}
        </div>
      </div>
      <div className="item-indicator">
        <Text
          as="a"
          onClick={(e) => {
            if (onDetailsClick) {
              onDetailsClick(e);
              e.stopPropagation();
            }
          }}
        >
          #
          {journalEntryLine.journal_entry.sequence_number
            ? journalEntryLine.journal_entry.sequence_number
            : t("Draft")}
        </Text>
        {journalEntryLine.journal_entry.cancelled === "Y" && (
          <IconCircleR width={16} height={16} />
        )}
        {journalEntryLine.journal_entry.cancelled === "C" && (
          <IconCircleC width={16} height={16} />
        )}
      </div>
    </div>
  );
};

export default memo(JournalEntryLineListItem);
