import { uniq } from "lodash";
import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Text } from "../../components/commons";
import { currencyFormat } from "../../utils/common";
import { ClientAccountContext } from "../App";
import { preview, PreviewTypes } from "../Preview";
import { getJournalEntryAmount } from "./bankTransactionFunctions";
import { pathKeys } from "../../constants";

const ReconciliationEntrySuggestionList = ({
  reconciliationEntries,
  selectedEntries,
  onEntryToggle,
  openAmount,
  openAmountCurrencyCode,
}: {
  reconciliationEntries: any[];
  selectedEntries: any[];
  onEntryToggle: (e: any) => void;
  openAmount: number;
  openAmountCurrencyCode: string;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [groupedEntries, setGroupedEntries] = useState<{ [k: string]: any[] }>(
    {}
  );

  const otherEntries = [
    { account_code: "7770", description: t("Bank charges") },
    { account_code: "7740", description: t("Penny difference") },
  ];

  useEffect(() => {
    const temp = {} as { [k: string]: any[] };
    // Group entries by business partner
    uniq(
      reconciliationEntries?.map(
        (e) => (e.business_partner?.id as string) || ""
      )
    ).forEach((id, idx) => {
      temp[idx] = reconciliationEntries.filter(
        (e) => (e.business_partner?.id || "") === id
      );
    });

    setGroupedEntries(temp);
  }, [reconciliationEntries]);

  const handleOpenVoucher = (
    voucherId: number,
    journalEntry: number,
    fullScreen = false
  ) => {
    if (fullScreen) {
      window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.POSTS}/${voucherId}`,
        "_blank"
      );
    } else {
      preview(PreviewTypes.Voucher, voucherId);
    }
  };

  return (
    <>
      {groupedEntries && (
        <>
          {Object.keys(groupedEntries).map((bp) => (
            <div key={bp}>
              <div className="toggle-header">
                <div>
                  {groupedEntries[bp][0].business_partner
                    ? groupedEntries[bp][0].business_partner.name
                    : "No business partner"}
                </div>
              </div>
              {groupedEntries[bp].map((entry) => {
                const [journalEntryLine, amount, amount_fc] =
                  getJournalEntryAmount(entry);
                return (
                  <div
                    key={entry.suggested_journal_entry_line_id}
                    className="toggle-item"
                  >
                    <CheckBox
                      checked={
                        !!selectedEntries?.find(
                          (e) =>
                            e.suggested_journal_entry_line_id ===
                            entry.suggested_journal_entry_line_id
                        )
                      }
                      onClick={() => onEntryToggle(entry)}
                    />
                    <Text
                      as="a"
                      onClick={(e) =>
                        handleOpenVoucher(
                          entry.document_id,
                          entry.id,
                          e.ctrlKey
                        )
                      }
                    >
                      {entry.doc_number ? `#${entry.doc_number}` : ""}{" "}
                      <span>({entry.posting_date})</span>
                    </Text>
                    <div>
                      {journalEntryLine
                        ? currencyFormat(
                            journalEntryLine.currency.symbol ===
                              clientAccount.accounting_currency
                              ? -amount
                              : -amount_fc,
                            journalEntryLine.currency.symbol
                          )
                        : "N/A"}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </>
      )}
      <div className="toggle-header">
        <div>{t("reconcile.other")}</div>
      </div>
      {otherEntries.map((oe) => (
        <div key={oe.account_code} className="toggle-item">
          <CheckBox
            checked={
              !!selectedEntries?.find((e) => e.account_code === oe.account_code)
            }
            onClick={() => onEntryToggle(oe)}
          >
            {oe.description}
          </CheckBox>
          <div>
            {!!selectedEntries?.find(
              (e) => e.account_code === oe.account_code && e.appliedAmount
            )
              ? currencyFormat(
                  -selectedEntries.find(
                    (e) => e.account_code === oe.account_code
                  ).appliedAmount.amount,
                  selectedEntries.find(
                    (e) => e.account_code === oe.account_code
                  ).appliedAmount.currency
                )
              : currencyFormat(-openAmount, openAmountCurrencyCode)}
          </div>
        </div>
      ))}
    </>
  );
};

export default memo(ReconciliationEntrySuggestionList);
