import { sum } from "lodash";
import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCreateAccountingQuestions,
  useCreatePayment,
  useGetDocumentSuggestions,
} from "../../api";
import { AskQuestionModal } from "../../components";
import { Button, CurrencyExchange, Text } from "../../components/commons";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { BankAccount } from "../../types/BankAccount";
import { BankTransaction } from "../../types/BankTransactionV2";
import { JournalEntry, JournalEntryLine } from "../../types/JournalEntryV2";
import { Question } from "../../types/Question";
import { currencyFormat, formatDate } from "../../utils/common";
import { showErrorNotification } from "../../utils/toastr";
import { ClientAccountContext } from "../App";
import {
  getBankTransactionAmount,
  getBankTransactionCurrencyCode,
  getBankTransactionDescription,
  getBankTransactionExchangeRate,
  getJournalEntryAmount,
} from "./bankTransactionFunctions";
import CustomJournalEntryModal from "./CustomJournalEntryModal";
import ReconciliationEntrySuggestionList from "./ReconciliationEntrySuggestionList";
import { useJournalEntryReducer } from "../../api/accountingApi";
import QuestionItem from "./QuestionItem";
import StatementItem from "./StatementItem";

const SinglePaymentCreationContainer = ({
  bankAccount,
  bankTransaction,
  onPaymentCreated,
  onQuestionAsked,
}: {
  bankAccount: BankAccount;
  bankTransaction: BankTransaction;
  onPaymentCreated: (p: JournalEntry) => void;
  onQuestionAsked: (q: Question) => void;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [currencyExchangeData, setCurrencyExchangeData] = useState<any>();
  const [openAmount, setOpenAmount] = useState<number>(0);
  const [selectedSuggestions, setSelectedSuggestions] = useState<any[]>([]);

  // Helper state
  const [showAskQuestionModal, changeShowAskQuestionModal] = useState(false);
  const [showCustomJournalEntryModal, changeShowCustomJournalEntryModal] =
    useState(false);
  const [isCreatingAccountingQuestions, setIsCreatingAccountingQuestions] =
    useState(false);
  const [isAddingCustomJE, setIsAddingCustomJE] = useState(false);
  const [
    customJournalEntry,
    customJournalEntryDispatch,
    journalEntryController,
  ] = useJournalEntryReducer(clientAccount?.id);
  const [mutationInProgress, setMutationInProgress] = useState(false);

  // Queries
  const { data: documentSuggestions, isLoading: isSuggestionsLoading } =
    useGetDocumentSuggestions(
      clientAccount?.id,
      bankTransaction.id,
      bankTransaction.amount > 0 ? "INCOMING" : "OUTGOING",
      currencyExchangeData?.baseCurrencyCode,
      currencyExchangeData?.exchangeRate || 1,
      currencyExchangeData?.baseAmount
    );

  // Mutations
  const { mutateAsync: createAccountingQuestions } =
    useCreateAccountingQuestions();
  const { mutateAsync: createPayment } = useCreatePayment();

  // Effects
  useEffect(() => {
    if (bankTransaction) {
      const amount = currencyExchangeData?.baseAmount || bankTransaction.amount;
      const currencyCode =
        currencyExchangeData?.baseCurrencyCode || bankTransaction.currency_code;
      setOpenAmount(amount);
      customJournalEntryDispatch({
        type: "set",
        payload: {
          posting_date: bankTransaction.booking_date,
          description: bankTransaction.memo,
          lines: [
            {
              line_id: 1,
              posting_date: bankTransaction.booking_date,
              account_code: "1920",
              debit_fc: amount > 0 ? amount : 0,
              credit_fc: amount < 0 ? -amount : 0,
              currency_code: currencyCode,
              dimensions: [
                {
                  relation_type: "bankacc",
                  relation_id: bankAccount.id as number,
                },
              ],
            },
            {
              line_id: 2,
              posting_date: bankTransaction.booking_date,
              credit_fc: amount > 0 ? amount : 0,
              debit_fc: amount < 0 ? -amount : 0,
              currency_code: currencyCode,
            } as JournalEntryLine,
          ],
        } as JournalEntry,
      });
    } else {
      customJournalEntryDispatch({
        type: "set",
        payload: {} as JournalEntry,
      });
    }
  }, [bankAccount, bankTransaction, currencyExchangeData]);

  useEffect(() => {
    setSelectedSuggestions([]);
  }, [bankAccount, bankTransaction]);

  useEffect(() => {
    const openAmount =
      (currencyExchangeData?.baseAmount || 0) -
      sum(selectedSuggestions.map((s) => s.appliedAmount?.amount || 0));

    setOpenAmount(openAmount);
  }, [selectedSuggestions]);

  // Event handlers
  const closeAskQuestionModal = () => changeShowAskQuestionModal(false);
  const handleOpenAskQuestionModal = () => {
    changeShowAskQuestionModal(true);
  };
  const handleSendAskQuestions = (data: Question) => {
    setIsCreatingAccountingQuestions(true);
    createAccountingQuestions({
      ...data,
      bank_transaction_id: bankTransaction.id,
    } as any).then(() => {
      setIsCreatingAccountingQuestions(false);
      closeAskQuestionModal();
      onQuestionAsked && onQuestionAsked(data);
    });
  };

  const closeCustomJournalEntryModal = () =>
    changeShowCustomJournalEntryModal(false);
  const handleOpenCustomJournalEntryModal = () => {
    changeShowCustomJournalEntryModal(true);
  };
  const handleAddCustomJournalEntry = async (account_entries: any) => {
    setIsAddingCustomJE(true);
    const data = {
      client_account_id: clientAccount.id,
      bank_account_id: bankAccount.id,
      bank_transaction_id: bankTransaction.id,
      currency: currencyExchangeData?.baseCurrencyCode,
      exchange_rate: currencyExchangeData?.exchangeRate,
      payment_date: bankTransaction.booking_date,
      amount: currencyExchangeData?.baseAmount,
      memo: getBankTransactionDescription(bankTransaction),
      account_entries,
    };
    const payment = await createPayment(data as any);

    onPaymentCreated && onPaymentCreated(payment);
  };

  const getTransactionId = (entry: any) => {
    return (
      entry?.suggested_journal_entry_line_id || entry?.id || entry?.account_code
    );
  };

  const toggleReconciliationEntrySuggestion = (entry: any) => {
    if (
      !!selectedSuggestions?.find(
        (s) => getTransactionId(s) === getTransactionId(entry)
      )
    )
      setSelectedSuggestions([
        ...(selectedSuggestions.filter(
          (s) => getTransactionId(s) !== getTransactionId(entry)
        ) || []),
      ]);
    else if (entry.account_code) {
      const temp = [...(selectedSuggestions || [])];
      temp.push({
        ...entry,
        appliedAmount: {
          amount: openAmount,
          currency: currencyExchangeData?.baseCurrencyCode,
          exchange_rate: currencyExchangeData?.exchangeRate,
        },
      });
      setSelectedSuggestions(temp);
    } else {
      const [journalEntryLine, amount, amount_fc] =
        getJournalEntryAmount(entry);
      const journal_entry_amount =
        journalEntryLine.currency.symbol === clientAccount.accounting_currency
          ? amount
          : amount_fc;
      const debitCreditMultiplier =
        currencyExchangeData?.baseAmount > 0 ? -1 : 1;

      const temp = [...(selectedSuggestions || [])];
      temp.push({
        ...entry,
        appliedAmount: {
          amount:
            debitCreditMultiplier > 0
              ? Math.max(journal_entry_amount, openAmount)
              : Math.min(journal_entry_amount, openAmount),
          currency: currencyExchangeData?.baseCurrencyCode,
          exchange_rate: currencyExchangeData?.exchangeRate,
        },
      });

      setSelectedSuggestions(temp);
    }
  };

  // Event handlers
  const handleCreatePayment = async () => {
    if (!(selectedSuggestions.length > 0)) {
      showErrorNotification(
        t("reconcile.please_select_at_least_one_suggestion")
      );
      return;
    }

    try {
      setMutationInProgress(true);

      const journal_entries = [] as any[];
      const account_entries = [] as any[];
      selectedSuggestions.map((s) => {
        const [journalEntryLine] = getJournalEntryAmount(s);
        if (s.account_code) {
          account_entries.push({
            account_code: s.account_code,
            amount: -s.appliedAmount.amount,
            currency: s.appliedAmount.currency || bankTransaction.currency_code,
            dimensions: [
              {
                relation_type: "bankacc",
                relation_id: bankAccount.id,
              },
            ],
          });
        } else {
          journal_entries.push({
            journal_entry_id: journalEntryLine.journal_entry_id,
            journal_entry_line_id: journalEntryLine.id,
            amount: -s.appliedAmount.amount,
            currency: s.appliedAmount.currency || bankTransaction.currency_code,
          });
        }
      });

      const data = {
        client_account_id: clientAccount.id,
        bank_account_id: bankAccount.id,
        bank_transaction_id: bankTransaction.id,
        currency: currencyExchangeData?.baseCurrencyCode,
        exchange_rate: currencyExchangeData?.exchangeRate,
        payment_date: bankTransaction.booking_date,
        amount: currencyExchangeData?.baseAmount,
        memo: getBankTransactionDescription(bankTransaction),
        reconciliation_entries: {
          journal_entries,
        },
        account_entries,
      };

      const payment = await createPayment(data as any);

      onPaymentCreated && onPaymentCreated(payment);
    } finally {
      setMutationInProgress(false);
    }
  };

  return (
    <>
      <AskQuestionModal
        show={showAskQuestionModal}
        loading={isCreatingAccountingQuestions}
        onHide={closeAskQuestionModal}
        onSave={handleSendAskQuestions}
        answerTypes={["ATTACH_FILE", "TYPE_ANSWER"]}
      />
      <CustomJournalEntryModal
        journalEntry={customJournalEntry}
        journalEntryDispatch={customJournalEntryDispatch}
        journalEntryController={journalEntryController}
        show={showCustomJournalEntryModal}
        loading={isAddingCustomJE}
        onHide={closeCustomJournalEntryModal}
        onAdd={handleAddCustomJournalEntry}
      />

      {!!bankTransaction.questions?.length && (
        <>
          <div className="suggestions-title">{t("Questions & Answers")}</div>
          <div>
            {bankTransaction.questions
              .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
              .map((q) => (
                <QuestionItem key={q.id} question={q} />
              ))}
          </div>
        </>
      )}

      {!!bankTransaction.statements?.length && (
        <>
          <div className="suggestions-title">{t("Statement")}</div>
          <div>
            {bankTransaction.statements
              .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
              .map((s) => (
                <StatementItem key={s.id} statement={s} />
              ))}
          </div>
        </>
      )}

      <div className="suggestions-title">{t("Currency information")}</div>
      <CurrencyExchange
        baseAmount={getBankTransactionAmount(bankTransaction)}
        baseCurrencyCode={getBankTransactionCurrencyCode(bankTransaction)}
        exchangeRate={getBankTransactionExchangeRate(bankTransaction)}
        rateDate={bankTransaction.booking_date}
        counterAmount={bankTransaction.amount}
        counterCurrencyCode={bankTransaction.currency_code}
        accountingCurrencyCode={clientAccount?.accounting_currency}
        onChange={setCurrencyExchangeData}
      />
      <div className="suggestions-title">{t("reconcile.suggestions")}</div>
      <ReconciliationEntrySuggestionList
        reconciliationEntries={documentSuggestions?.documents}
        selectedEntries={selectedSuggestions}
        onEntryToggle={toggleReconciliationEntrySuggestion}
        openAmount={openAmount}
        openAmountCurrencyCode={currencyExchangeData?.baseCurrencyCode}
      />

      <div className="buttons">
        <div className="toggle-item">
          <Text>Open amount</Text>
          <div>
            {currencyFormat(openAmount, currencyExchangeData?.baseCurrencyCode)}
          </div>
        </div>

        <Button
          variant={BOOTSTRAP_VARIANTS.LIGHT}
          text={t("reconcile.custom_journal_entry")}
          onClick={handleOpenCustomJournalEntryModal}
        />

        <div className="buttons-inline">
          <Button
            variant={BOOTSTRAP_VARIANTS.DARK}
            text={t("reconcile.ask_customer")}
            onClick={handleOpenAskQuestionModal}
          />

          <Button
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("reconcile.create_payment")}
            onClick={handleCreatePayment}
            disabled={mutationInProgress}
            loading={mutationInProgress}
          />
        </div>
      </div>
    </>
  );
};
export default memo(SinglePaymentCreationContainer);
