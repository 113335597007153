import React from 'react'

import ContentLoader from 'react-content-loader'

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height="40"
    backgroundColor="#f3f3f3"
    foregroundColor="#adacac"
  >
    <rect x="10%" y="0" rx="5" ry="5" width="70" height="9" />
    <rect x="40%" y="0" rx="5" ry="5" width="70" height="9" />
    <rect x="70%" y="0" rx="5" ry="5" width="70" height="9" />

    <rect x="10%" y="15" rx="5" ry="5" width="100" height="20" />
    <rect x="40%" y="15" rx="5" ry="5" width="100" height="20" />
    <rect x="70%" y="15" rx="5" ry="5" width="100" height="20" />

  </ContentLoader>
)

export default Loader
