import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import { pathKeys } from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";
// import { IconGreenQuestions } from "../../../assests/icons";
// import EditableText from "../EditableText";
import cn from "classnames";
import { ClientAccount } from "../../../../types/ClientAccount";
import { useInDirectClientAccounts } from "../Hooks/useInDirectClientAccounts";
import { useCurrentClientAccountFromContext } from "../../../../dataHooks/user";
import HeaderProfile from "./HeaderProfile";
import { AccountingNotesOverlay } from "./AccountingNotesOverlay";

export const ContractsDropdown: React.FC = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const params = useParams();
  const clientAccountId = params.clientId;

  const clientAccounts = useInDirectClientAccounts(Number(clientAccountId));
  const currentClientAccount = useCurrentClientAccountFromContext();

  const isContractActive = (c: ClientAccount) => {
    if (!c.contract) {
      return false;
    }
    return (
      c.contract.start_date &&
      moment(c.contract.start_date) < moment() &&
      (!c?.contract.end_date || moment(c.contract.end_date) > moment())
    );
  };

  const onContractChange = (clientAccount: ClientAccount) => {
    navigate(
      `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.OVERVIEW}`
    );
  };

  const inDirectClients = {
    active: clientAccounts
      .filter((c) => isContractActive(c))
      .filter(
        (c) =>
          isContractActive(c) &&
          (!keyword ||
            (c.display_name || "").search(new RegExp(keyword, "i")) > -1)
      ),
    inActive: clientAccounts
      .filter((c) => !isContractActive(c))
      .filter(
        (c) =>
          !isContractActive(c) &&
          (!keyword ||
            (c.display_name || "").search(new RegExp(keyword, "i")) > -1)
      ),
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const combinedContracts = _.concat(
      inDirectClients.active,
      inDirectClients.inActive
    );
    if (combinedContracts.length === 0) {
      return;
    }

    const keyCode = e.which || e.keyCode;
    if (keyCode == 10 || keyCode == 13) {
      e.preventDefault();

      if (focusedIndex != null && combinedContracts[focusedIndex]) {
        onContractChange(combinedContracts[focusedIndex]);
      }
    }

    if (keyCode == 38) {
      //ArrowUp
      if (focusedIndex == null || focusedIndex == 0) {
        setFocusedIndex(combinedContracts.length - 1);
        return;
      }

      setFocusedIndex(focusedIndex - 1);
    }

    if (keyCode == 40) {
      //ArrowDown
      if (
        focusedIndex == null ||
        focusedIndex == combinedContracts.length - 1
      ) {
        setFocusedIndex(0);
        return;
      }

      setFocusedIndex(focusedIndex + 1);
    }
  };

  return (
    <div className="left-header">
      <Dropdown
        className="clients"
        onToggle={(nextShow) => {
          if (nextShow) {
            setTimeout(() => {
              document.getElementById("searchInputRef")?.focus();
            }, 250);
          }
        }}
      >
        <Dropdown.Toggle as={HeaderProfile}>
          <span className="name">
            {currentClientAccount
              ? currentClientAccount.display_name
              : "Select a client"}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 3 }}>
            <Form.Control
              autoFocus
              id="searchInputRef"
              autoComplete="off"
              placeholder="Type to filter..."
              onChange={(e) => {
                setKeyword(e.target.value);
                setFocusedIndex(null);
              }}
              value={keyword}
              style={{ fontSize: 13, boxShadow: "none" }}
              onKeyDown={onKeyDown}
            />
          </div>
          <Dropdown.Item className="header-item" active={false}>
            Active contracts
          </Dropdown.Item>
          {inDirectClients.active?.length === 0 && (
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 10,
                fontSize: "13px",
                fontStyle: "italic",
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              No records found
            </div>
          )}
          {inDirectClients.active?.map((client, index) => (
            <Dropdown.Item
              eventKey={client.id}
              key={client.id}
              active={client.id === currentClientAccount.id}
              className={cn({
                focused: focusedIndex != null && focusedIndex == index,
              })}
              onClick={() => onContractChange(client)}
            >
              {client.display_name}
            </Dropdown.Item>
          ))}
          {inDirectClients.inActive?.length > 0 && (
            <Dropdown.Header>Inactive contracts</Dropdown.Header>
          )}
          {inDirectClients.inActive?.map((inDirectClient, index) => (
            <Dropdown.Item
              eventKey={inDirectClient.id}
              key={inDirectClient.id}
              active={inDirectClient.id === currentClientAccount.id}
              className={cn({
                focused:
                  focusedIndex != null &&
                  focusedIndex == index + inDirectClients.active?.length,
              })}
              onClick={() => onContractChange(inDirectClient)}
            >
              {inDirectClient.display_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <AccountingNotesOverlay />
    </div>
  );
};
