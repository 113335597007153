import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import moment from "moment";

import { Button, DatePicker, Input, Select } from "../../../components/commons";
import { ClientAccountContext } from "../../App";

import { PayrollRun } from "../../../types/PayrollRun";

const YearOptions = [
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];

const PayrollRunForm = ({
  value,
  onChange,
  disabled,
  tabIndex,
}: {
  value?: PayrollRun;
  onChange: (res: PayrollRun) => void;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Data state
  const [runDate, setRunDate] = useState("");
  const [year, setYear] = useState("");
  const [runNumber, setRunNumber] = useState("");

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Limitation for document dates
  const minDate = new Date("2020-01-01").getTime(); // from: 01.01.2019
  const maxDate = Date.now() + 1000 * 60 * 60 * 24 * 365; // to:   ms * sec * min * hour * days

  const isFormValid = () => !!runDate && !!year && !!runNumber;

  const onSaveClicked = () => {
    if (!isFormValid()) return;

    const run: PayrollRun = {
      ...value,
      id: value?.id,
      client_account_id: clientAccount?.id,
      run_date: runDate && moment(runDate).format("YYYY-MM-DD"),
      year: +year,
      run_number: +runNumber,
    };

    onChange(run);
  };

  useEffect(() => {
    if (runDate && !year) setYear(runDate);
  }, [runDate]);

  useEffect(() => {
    if (!value) return;

    setRunDate(value?.run_date || "");
    setYear(value?.year?.toString() || "");
    setRunNumber(value?.run_number?.toString() || "");
  }, [value]);

  const toDate = (val: string) => {
    if (!val || val.length !== 10) return;

    return moment(val).toDate();
  };

  return (
    <>
      <DatePicker
        label={t("Run date")}
        selectedDate={toDate(runDate)}
        onChange={(d) => setRunDate(moment(d).format("YYYY-MM-DD"))}
        minDate={minDate}
        maxDate={maxDate}
        required
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 1}
      />

      <Select
        size="md"
        label={t("Year")}
        options={YearOptions}
        value={YearOptions.find((o) => o.value === year)}
        onChange={(o: any) => setYear(o.value)}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 2}
        required
      />
      <Input
        size="md"
        label={t("Run number")}
        value={runNumber}
        onChange={setRunNumber}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 3}
        required
      />

      {!disabled && <Button text={t("Save")} onClick={onSaveClicked}></Button>}
    </>
  );
};

export default PayrollRunForm;
