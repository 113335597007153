enum ObjectTypes {
  BUSINESS_PARTNER = "businesspartner",
  AP_RECEIPT = "RECPT",
  AR_INVOICE = "ARINV",
  AP_INVOICE = "APINV",
  AR_CREDIT_NOTE = "ARCRN",
  AP_CREDIT_NOTE = "APCRN",
  AR_DEBT_COLLECTION = "ARDEB",
  AP_DEBT_COLLECTION = "APDEB",
  AP_STATEMENT = "APSTM",
  BANK_ACCOUNT = "bankacc",
  BANK_STATEMENT = "PAYMT",
  OTHER = "OTHER",
  ARCHIVED_DOCUMENT = "ARCIV",
  PAYMENT = "PAYMT",
  JOURNAL_ENTRY = "JE",
  JOURNAL_ENTRY_LINE = "JEL",
  VOUCHER = "VOUCHER",
  PAYROLL_RUN = "PRRUN",
  PAYMENT_DRAFT = "PAYDR",
}

export default ObjectTypes;
