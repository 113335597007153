import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.scss";
import { Button, ExportToExcelButton } from "../commons";
import { useTranslation } from "react-i18next";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { IconExport } from "../../assests/icons";

const ReportWrap = ({
  title,
  className,
  filterItems,
  data,
  exportData,
  handleGetAllData,
  onExportClicked,
  exportOptions,
}) => {
  const [t] = useTranslation();
  return (
    <div className={cn("report-page-wrap", className)}>
      <div className="report-header">
        <div className="title">{title}</div>
        <div className="export-options">
          {exportOptions?.map((o, i) => (
            <Button
              key={i}
              text={t(o)}
              variant={BOOTSTRAP_VARIANTS.LIGHT}
              size="sm"
              icon={<IconExport />}
              iconPosition="right"
              onClick={() => onExportClicked(o)}
            ></Button>
          ))}
        </div>
        {!exportOptions && (
          <ExportToExcelButton
            filename={title}
            data={exportData}
            handleGetAllData={handleGetAllData}
            onExportClicked={onExportClicked}
          />
        )}
      </div>
      <div className="filter-header">{filterItems}</div>
      <div className="report-list">{data}</div>
    </div>
  );
};

ReportWrap.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  filterItems: PropTypes.node,
  data: PropTypes.node,
  exportData: PropTypes.array,
  onExportClicked: PropTypes.func,
  exportOptions: PropTypes.arrayOf(PropTypes.string),
  handleGetAllData: PropTypes.func,
};

ReportWrap.defaultProps = {
  title: "",
  className: "",
  filterItems: null,
  data: null,
  exportData: [],
  onExportClicked: null,
  exportOptions: null,
  handleGetAllData: null,
};

export default ReportWrap;
