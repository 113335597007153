import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import DOMPurify from "dompurify";

import {
  Button,
  Modal,
  Input,
  RecipientsSelect,
} from "../../components/commons";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";

import "./style.scss";

const NewThreadModal = ({
  onHide,
  onAdd,
  loading,
  show,
  users,
  isAccountant,
}) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [isInvalidText, setIsInvalidText] = useState(false);
  const [text, setText] = useState("");

  const clearState = () => {
    setSubject("");
    setRecipients([]);
    setIsInvalidText(false);
    setText("");
  };

  const handleOnHide = () => {
    clearState();
    onHide();
  };

  const handleOnAdd = () => {
    clearState();
    if (!DOMPurify.sanitize(text, { ALLOWED_TAGS: [] })) {
      return setIsInvalidText(true);
    }
    onAdd({
      subject,
      recipients,
      text: DOMPurify.sanitize(text, { ALLOWED_TAGS: [] }),
    });
  };

  const handleSetText = (e) => {
    const val = e.target.value;
    setIsInvalidText(!val);
    setText(val);
  };

  const handleOnBlurText = () => setIsInvalidText(!text);

  return (
    <Modal
      className="new-thread-modal"
      show={show}
      loading={loading}
      size="lg"
      backdrop="static"
      onHide={handleOnHide}
      title={t("messages.start_new_thread")}
    >
      <div className="content">
        <div>
          <Input
            className="new-thread-modal__input"
            size="lg"
            required
            label={t("messages.subject")}
            value={subject}
            onChange={setSubject}
          />
        </div>
        <div className="new-thread-modal__input">
          <RecipientsSelect
            label={t("messages.recipients")}
            required
            recipients={users}
            onSelect={setRecipients}
            withCompanies={!isAccountant}
          />
        </div>
        <div className="new-thread-modal__input">
          <div className={cn("label", "required", { invalid: isInvalidText })}>
            {t("messages.text")}
          </div>
          <textarea
            className={cn("text-textarea", { invalid: isInvalidText })}
            placeholder={t("common_components.input.placeholder")}
            value={text}
            onChange={handleSetText}
            onBlur={handleOnBlurText}
          />
        </div>
        <div className="button-start">
          <Button
            size="md40"
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("messages.start")}
            onClick={handleOnAdd}
            disabled={!subject || !recipients?.length || !text}
          />
        </div>
      </div>
    </Modal>
  );
};

NewThreadModal.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onAdd: PropTypes.func,
  isAccountant: PropTypes.bool,
};

NewThreadModal.defaultProps = {
  users: [],
  loading: false,
  show: false,
  onHide: () => {},
  onAdd: () => {},
  isAccountant: false,
};

export default NewThreadModal;
