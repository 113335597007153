import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "../index";
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import "./style.scss";

const ConfirmModal = ({ show, onSubmit, onHide, title, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="confirm-modal"
      size="lg"
      backdrop="static"
      show={show}
      loading={loading}
      onHide={onHide}
      title={title}
    >
      <div className="actions">
        <Button
          size="md"
          variant={BOOTSTRAP_VARIANTS.PRIMARY}
          text={t("common_components.button.ok")}
          onClick={onSubmit}
        />
        <Button
          size="md"
          variant={BOOTSTRAP_VARIANTS.LIGHT}
          text={t("common_components.button.cancel")}
          onClick={onHide}
        />
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  show: false,
  onSubmit: () => {},
  onHide: () => {},
  title: "",
  loading: false,
};

export default ConfirmModal;
