import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { loginBgImg, loginImg, SnapBooks } from "../../assests/images";
import "./style.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { publicApiClient, throwErrorByStatus } from "../../api/apiClients";
import { Button } from "../../components/commons";
import { common, pathKeys } from "../../constants";
import { Spinner } from "react-bootstrap";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const [param] = useSearchParams();
  const navigate = useNavigate();

  const {
    mutateAsync: verifyEmail,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(async ({ token }: { token: string }) => {
    const res = await publicApiClient.get(
      `/auth/verify-identity?token=${token}`
    );
    throwErrorByStatus(res);
    return res.data;
  });

  const token = param.get("token");
  useEffect(() => {
    if (token) {
      verifyEmail({ token });
    } else {
      // page opened without token
      navigate(pathKeys.LOGIN, { replace: true });
    }
  }, [token]);

  return (
    <div
      className="signup-page"
      style={{ backgroundImage: `url(${loginBgImg})` }}
    >
      <div className="logo-container">
        <div className="logo">
          <img src={SnapBooks} alt="" />
        </div>
        <div className="desc">{t("signup_page.slogan")}</div>
      </div>
      <div
        className="signup-img"
        style={{ backgroundImage: `url(${loginImg})` }}
      />
      <div className="verify-email-card-container">
        <div className="title">{t("verify_email.title")}</div>
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" variant="warning" />
          </div>
        )}
        {isSuccess && (
          <div className="text-center">
            <p>{t("verify_email.success")}</p>
            <Button
              className="login-button"
              size="lg"
              variant={common.BOOTSTRAP_VARIANTS.PRIMARY}
              text={t("verify_email.login")}
              onClick={() => {
                navigate(pathKeys.LOGIN, { replace: true });
              }}
            />
          </div>
        )}
        {isError && (
          <div className="text-center">
            <p>{t("verify_email.error")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(VerifyEmail);
