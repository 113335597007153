import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { ARCHIVED_DOCUMENT_CATEGORIES } from "../../../constants";
import { Select } from "../../../components/commons";

const ArchivedDocumentCategoryField = ({
  archivedDocumentCategory,
  setArchivedDocumentCategory,
  disabled,
  tabIndex,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      size="md"
      label={t("post_detail.archived_document_category")}
      options={ARCHIVED_DOCUMENT_CATEGORIES.toArray()}
      onChange={(o) => setArchivedDocumentCategory(o.value)}
      value={ARCHIVED_DOCUMENT_CATEGORIES.toArray().find(
        (c) => c.value === archivedDocumentCategory
      )}
      getOptionLabel={({ label }) => t(label)}
      required
      isClearable={false}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

export default ArchivedDocumentCategoryField;
