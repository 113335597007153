import moment from "moment";
import { useContext, useEffect, useState, memo } from "react";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { IconLabel, IconVioletLabel } from "../../../assests/icons";
import { ReportWrap } from "../../../components";
import {
  AsyncSelect,
  DateIntervalPicker,
  GridView,
  Input,
  LabelsFilter,
  Text,
} from "../../../components/commons";
import getLabelAbbreviation from "../../../constants/labelShortcuts";

import {
  colorizeKeyword,
  currencyFormat,
  detectLanguage,
  getReportDateRanges,
  setReportDateRanges,
} from "../../../utils/common";

import {
  useGetDimensionValues,
  useGetJournalEntriesRaw,
  useGetJournalEntriesReport,
  useGetCurrency,
  getJournalEntriesReport,
} from "../../../api";

import { ClientAccountContext } from "../../App";

import "./style.scss";
import { PreviewTypes, preview } from "../../Preview";
import { pathKeys } from "../../../constants";

const JournalEntriesReport = () => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const [selectedNumber, setSelectedNumber] = useState("");
  const [selectedType, setSelectedTypes] = useState(null);

  const reportDateRanges = getReportDateRanges();

  const [startDate, changeStartDate] = useState(reportDateRanges[0]);
  const [endDate, changeEndDate] = useState(reportDateRanges[1]);

  const [keyWord, changeKeyWord] = useState("");
  const [parsedReport, changeParsedReport] = useState([]);
  const [lines, setLines] = useState({ 0: {}, 1: {} });
  const [activeLabels, setActiveLabels] = useState([]);
  const [disableMultiSearchFlag, setDisableMultiSearchFlag] = useState(false);
  const [journalEntriesParams, setJournalEntriesParams] = useState(null);

  const dimensionValuesQuery = useGetDimensionValues(clientAccount, false);
  const { data: journalEntries, isLoading: journalEntriesFetching } =
    useGetJournalEntriesReport(journalEntriesParams, true);
  const { data: journalEntriesRaw } = useGetJournalEntriesRaw(
    clientAccount?.id
  );
  const { data: currency } = useGetCurrency(clientAccount?.id, false);

  const setSelectedNumberClearRest = (data) => {
    setSelectedTypes(null);
    handleSetType(null, 1, 0, "relation_type");
    setActiveLabels([]);
    changeKeyWord("");
    setSelectedNumber(data);
    if (data.length === 0) {
      setDisableMultiSearchFlag(false);
    } else {
      setDisableMultiSearchFlag(true);
    }
  };

  const handleQueryChange = (data) => {
    const q = {
      ...(data || {}),
      client_account_id: clientAccount?.id,
      description: keyWord,
      doc_relation_type: selectedType,
      journal_entry: selectedNumber,
    };
    if (selectedNumber === "") {
      q.date_from = moment(startDate).format("YYYY-MM-DD");
      q.date_to = moment(endDate).format("YYYY-MM-DD");
    }
    activeLabels.forEach((activeLabel) => {
      const param = `filter_${activeLabel.relation_type}`;
      if (!(param in q)) {
        q[param] = activeLabel.relation_id;
      } else {
        q[param] = `${q[param]},${activeLabel.relation_id}`;
      }
    });
    setJournalEntriesParams(q);
    // getJournalEntriesRaw();
    // getCurrency(clientAccount.id);
  };

  const handleOnStateChange = (data) => {
    handleQueryChange(data);
  };

  const handleOpenVoucher = (voucherId, journalEntry, fullScreen = false) => {
    if (fullScreen) {
      window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.POSTS}/${voucherId}`,
        "_blank"
      );
    } else {
      preview(PreviewTypes.Voucher, voucherId);
    }
  };

  useEffect(() => {
    setReportDateRanges(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (clientAccount) {
      handleQueryChange();
    }
  }, [
    clientAccount,
    startDate,
    endDate,
    keyWord,
    selectedNumber,
    selectedType,
    activeLabels,
  ]);

  useEffect(() => {
    if (clientAccount) {
      handleQueryChange();
    }
  }, [dimensionValuesQuery.data]);

  useEffect(() => {
    if (journalEntries) {
      const res = parseRawData(journalEntries);
      if (res !== parsedReport) {
        changeParsedReport(parseRawData(journalEntries));
      }
    }
  }, [journalEntries, currency]);

  const getLabelsPopoverText = (curLabels) => {
    const matchedLabels = { cnt: 0, text: "" };
    if (curLabels && dimensionValuesQuery.data?.dimension_values) {
      curLabels.forEach(({ relation_type, relation_id }) => {
        const tmp = dimensionValuesQuery.data?.dimension_values?.filter(
          (obj) =>
            obj.relation_type === relation_type &&
            obj.relation_id === relation_id
        );
        const name = tmp?.length > 0 ? tmp[0].relation_name : undefined;
        matchedLabels.cnt += 1;
        matchedLabels.text = matchedLabels.text.concat(
          `<div>${
            getLabelAbbreviation(relation_type) || relation_type
          } - ${name}</div>`
        );
      });
      return matchedLabels;
    }
  };

  const parseRawData = (report) => {
    if (report && currency) {
      const currency_of_client = currency.client_currency || "";
      const res = [];
      let prev_idx = null;
      let label = "";
      report.journal_entries_raw.forEach((journal_entry) => {
        if (journal_entry.journal_entry !== prev_idx) {
          res.push({
            journal_entry: journal_entry.voucher_id ? (
              <Text
                as="a"
                onClick={(e) =>
                  handleOpenVoucher(
                    journal_entry?.voucher_id,
                    journal_entry.journal_entry,
                    e.ctrlKey
                  )
                }
              >
                {journal_entry.journal_entry}
              </Text>
            ) : (
              <Text as="span">{journal_entry.journal_entry}</Text>
            ),
            date: journal_entry.date,
            description: journal_entry.description,
            tax_code: null,
            tax_sum: null,
            sum: null,
            class: "is-underline, is-bold",
          });
          label = getLabelsPopoverText(journal_entry.labels);
          res.push({
            journal_entry: null,
            date: "", // "Mon, 19 Oct 2020 00:00:00 GMT",
            description:
              journal_entry.line_description || journal_entry.description,
            tax_code: journal_entry.tax_code,
            tax_sum:
              journal_entry.tax_sum !== 0
                ? currencyFormat(journal_entry.tax_sum, currency_of_client)
                : "",
            sum: currencyFormat(journal_entry.sum, currency_of_client),
            label:
              label && label.cnt !== 0 && activeLabels ? (
                <Text
                  as="span"
                  placement="top"
                  popover
                  popoverText={label.text}
                  whitePopoverBackground
                >
                  {label.cnt} <IconVioletLabel />
                </Text>
              ) : (
                <span>
                  0 <IconLabel />
                </span>
              ),
            class: "row",
          });
        } else {
          label = getLabelsPopoverText(journal_entry.labels);
          res.push({
            journal_entry: null,
            date: null,
            description:
              journal_entry.line_description || journal_entry.description,
            tax_code: journal_entry.tax_code,
            tax_sum:
              journal_entry.tax_sum !== 0
                ? currencyFormat(journal_entry.tax_sum, currency_of_client)
                : "",
            sum: currencyFormat(journal_entry.sum, currency_of_client),
            label:
              label && label.cnt !== 0 && activeLabels ? (
                <Text
                  as="span"
                  placement="top"
                  popover
                  popoverText={label.text}
                  whitePopoverBackground
                >
                  {label.cnt} <IconVioletLabel />
                </Text>
              ) : (
                <span>
                  0 <IconLabel />
                </span>
              ),
            class: "row",
          });
        }
        prev_idx = journal_entry.journal_entry;
      });
      return res;
    }
  };

  const parseRawData2 = (report) => {
    if (report && currency) {
      const currency_of_client = currency.client_currency || "";
      const res = [];

      report.journal_entries_raw.forEach((journal_entry) => {
        res.push({
          journal_entry: journal_entry.journal_entry,
          date: journal_entry.date,
          description:
            journal_entry.line_description || journal_entry.description,
          tax_code: journal_entry.tax_code,
          tax_sum: journal_entry.tax_sum !== 0 ? journal_entry.tax_sum : "",
          sum: journal_entry.sum,
          currency: currency_of_client,
        });
      });
      return res;
    }
  };

  const handleSetType = (value, key, index, field) => {
    const newLines = { ...lines };
    newLines[key].line_id = index + 1;
    newLines[key][field] = value;
    setLines(newLines);
    setSelectedTypes((value || {}).relation_type);
  };

  const exportData = (report) =>
    report?.map((el) => ({
      [t("journal_entries.gridview.journal_entry")]: el.journal_entry,
      [t("journal_entries.gridview.date")]: moment(el.date).isValid()
        ? moment(el.date).format("DD/MM/YYYY")
        : el.date,
      [t("journal_entries.gridview.description")]: el.description,
      [t("journal_entries.gridview.tax_code")]: el.tax_code,
      [t("journal_entries.gridview.tax_sum")]: el.tax_sum,
      [t("journal_entries.gridview.sum")]: el.sum,
      ["currency"]: el.currency,
    }));

  const handleGetAllData = async () => {
    const lang = detectLanguage();

    const q = {
      client_account_id: clientAccount.id,
      description: keyWord,
      doc_relation_type: selectedType,
      journal_entry: selectedNumber,
      // ...(stateData || {})
    };

    if (selectedNumber === "") {
      q.date_from = moment(startDate).format("YYYY-MM-DD");
      q.date_to = moment(endDate).format("YYYY-MM-DD");
    }
    activeLabels.forEach((activeLabel) => {
      const param = `filter_${activeLabel.relation_type}`;
      if (!(param in q)) {
        q[param] = activeLabel.relation_id;
      } else {
        q[param] = `${q[param]},${activeLabel.relation_id}`;
      }
    });

    const { data: report } = await getJournalEntriesReport({ ...q, lang });

    const res = exportData(parseRawData2(report));
    return { data: res, labels: activeLabels };
  };

  return (
    <>
      <ReportWrap
        className="journal-entries-report"
        title={t("journal_entries.title")}
        handleGetAllData={handleGetAllData}
        filterItems={
          <>
            <div className="filter-row">
              <Input
                size="md"
                placeholder={t("common_components.input.placeholder_search")}
                value={selectedNumber}
                onChange={setSelectedNumberClearRest}
              />
              <AsyncSelect
                size="md"
                className="account-code"
                options={(journalEntriesRaw || {}).relation_types}
                filterOption={() => true}
                onChange={(item) => handleSetType(item, 1, 0, "relation_type")}
                value={(lines[1] || {}).relation_type}
                defaultOptions={(lines[1] || {}).relation_type}
                getOptionLabel={({ relation_type_label }) =>
                  `${relation_type_label}`
                }
                getOptionValue={({ relation_type }) => relation_type}
                disabled={disableMultiSearchFlag}
              />
              <DateIntervalPicker
                dateFormat="dd/MM/yyyy"
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={changeStartDate}
                onChangeEndDate={changeEndDate}
                disabled={disableMultiSearchFlag}
              />
              <Input
                size="md"
                placeholder={t("common_components.input.placeholder_search")}
                value={keyWord}
                onChange={changeKeyWord}
                disabled={disableMultiSearchFlag}
              />
            </div>
            <LabelsFilter
              activeLabels={activeLabels}
              setActiveLabels={setActiveLabels}
              disabled={disableMultiSearchFlag}
            />
          </>
        }
        data={
          <GridView
            columns={[
              {
                Header: t("journal_entries.gridview.journal_entry"),
                accessor: "journal_entry",
                align: "left",
                maxWidth: 105,
              },
              {
                Header: t("journal_entries.gridview.date"),
                accessor: "date",
                align: "left",
                maxWidth: 110,
                Cell: (cellProps) =>
                  cellProps.value
                    ? moment(cellProps.value).isValid()
                      ? moment(cellProps.value).format("DD/MM/YYYY")
                      : cellProps.value
                    : "",
              },
              {
                Header: t("journal_entries.gridview.description"),
                accessor: "description",
                align: "left",
                Cell: (cellProps) => {
                  if (
                    !keyWord ||
                    cellProps.value === t("general_ledger.ingoing_balance") ||
                    cellProps.value === t("general_ledger.outgoing_balance")
                  ) {
                    return cellProps.value;
                  }
                  const newSentence = colorizeKeyword(cellProps.value, keyWord);
                  return ReactHtmlParser(newSentence);
                },
              },
              {
                Header: t("journal_entries.gridview.tax_code"),
                accessor: "tax_code",
                align: "left",
                maxWidth: 90,
              },
              {
                Header: t("journal_entries.gridview.tax_sum"),
                accessor: "tax_sum",
                align: "left",
                maxWidth: 120,
              },
              {
                Header: t("journal_entries.gridview.sum"),
                accessor: "sum",
                align: "right",
                maxWidth: 140,
              },
              {
                Header: "", // t('general_ledger.gridview.sum'),
                accessor: "label",
                // classCustom: 'is-subresult, is-2-underline',
                align: "right",
                maxWidth: 80,
              },
            ]}
            data={parsedReport}
            pages={(journalEntries || {}).pages}
            loading={journalEntriesFetching}
            relativeRowHeight={30}
            onStateChange={(query) => handleOnStateChange(query)}
            emptyStateTitle=""
          />
        }
      />
    </>
  );
};

export default memo(JournalEntriesReport);
