import { useMutation, useQuery } from "react-query";
import { PayrollRun } from "../types/PayrollRun";
import { authenticatedApiV2Client, throwErrorByStatus } from "./apiClients";
import { Pages } from "../types/PagesV2";

export function useGetPayrollRuns(
  clientAccountId: number,
  _with: string[] = []
) {
  return useQuery(["getPayrollRuns", clientAccountId, _with], async () => {
    const res = await authenticatedApiV2Client.get(`/payroll-runs`, {
      params: { client_account_id: clientAccountId, with: _with.join(",") },
    });
    throwErrorByStatus(res);
    return res.data as { data: PayrollRun[]; meta: Pages };
  });
}

export function useSavePayrollRun() {
  return useMutation(async (data: PayrollRun) => {
    const res = data.id
      ? await authenticatedApiV2Client.post(`/payroll-runs/${data.id}/`, data)
      : await authenticatedApiV2Client.post(`/payroll-runs/`, data);

    throwErrorByStatus(res);
    return res.data;
  });
}
