import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconDarkClose,
  IconLeftVioletArrow,
  IconRightVioletArrow,
} from "../../assests/icons";
import { pathKeys } from "../../constants";
import { ClientAccountContext } from "../App";
import "./style.scss";
import { useGetPreviousNextVouchers } from "../../api";
import { PostStatus } from "../../components/commons";

const HeaderField = ({ voucher, resetLocalState }) => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  // Context state
  const clientAccount = useContext(ClientAccountContext);

  let voucherStatus = voucher?.posting_status;
  if (voucher?.journal_entry?.is_draft) voucherStatus = "DRAFT";
  else if (
    (voucher?.journal_entry?.is_draft === false &&
      (voucher?.journal_entry?.cancelled || "N") === "N") ||
    voucher?.payment_draft?.id
  ) {
    voucherStatus = "BOOKED";
  } else if (voucher?.archived_document) {
    voucherStatus = "ARCHIVED";
  } else if (voucher?.is_active === false) {
    voucherStatus = "DELETED";
  } else {
    voucherStatus = "PENDING";
  }

  const postingStatusQueryMapper = {
    PENDING: "PENDING,PENDING_ANSWER,DRAFT",
    PENDING_ANSWER: "PENDING,PENDING_ANSWER,DRAFT",
    DRAFT: "PENDING,PENDING_ANSWER,DRAFT",
    BOOKED: "BOOKED",
    ARCHIVED: "ARCHIVED",
    INACTIVE: "INACTIVE",
  };

  const navigationEnabled =
    postingStatusQueryMapper[voucherStatus] === "PENDING,PENDING_ANSWER,DRAFT";

  const query = {
    posting_status: postingStatusQueryMapper[voucherStatus],
    order_by: ["PENDING", "PENDING_ANSWER", "DRAFT"].includes(
      voucherStatus || "PENDING"
    )
      ? "upload_date desc"
      : "document_date desc",
    per_page: 500,
    client_account_id: clientAccount?.id,
  };

  const { previous: previousVoucher, next: nextVoucher } =
    useGetPreviousNextVouchers(voucher?.id, query, {
      enabled: navigationEnabled,
    });

  const goToPost = (count) => {
    const postToGo = count > 0 ? nextVoucher : previousVoucher;
    if (postToGo) {
      resetLocalState();
      navigate(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}/${postToGo.voucher_id}`
      );
    }
  };

  return (
    <div className="header">
      <div className="d-flex">
        {navigationEnabled ? (
          <>
            <div
              className="bg-badge rounded-circle p-1"
              role="button"
              onClick={() => goToPost(-1)}
            >
              <IconLeftVioletArrow />
            </div>
            <div
              className="bg-badge rounded-circle p-1 mx-2"
              role="button"
              onClick={() => goToPost(1)}
            >
              <IconRightVioletArrow />
            </div>
          </>
        ) : (
          <>
            <div></div>
            <div></div>
          </>
        )}
        <div className="title">{voucher?.file_name}</div>
        <div className="bg-badge rounded-pill border mx-3 text-white rounded  px-3 py-1">
          <PostStatus status={voucherStatus} />
        </div>
      </div>
      <div
        className="back-button"
        role="button"
        onClick={() =>
          navigate(`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}`)
        }
      >
        <IconDarkClose />
      </div>
    </div>
  );
};

export default HeaderField;
