import React, { memo, useContext, useState } from "react";
import { GeneralLedgerAccount } from "../../types/GeneralLedgerAccount";
import {
  AccountDimensionCombination,
  DimensionValue,
  JournalEntryDimension,
} from "../../types/JournalEntry";
import { AccountDimensionCombination as AccountDimensionCombinationV2 } from "../../types/JournalEntryV2";
import { Pages } from "../../types/Pages";
import { truncateString } from "../../utils/common";

import { useGetDimensionValues } from "../../api";

import { useGetGeneralLedgerAccounts } from "../../api/accountingApi";
import { ClientAccountContext } from "../../views/App";
import { LabelsSelect, Select } from "../commons";

interface GeneralLedgerAccountSelectProps {
  account?: AccountDimensionCombination | AccountDimensionCombinationV2;
  onAccountChange?: (gla: AccountDimensionCombination) => void;
  selectDimensions?: boolean;
  disabled?: boolean;
  tabIndex?: number;
}

const GeneralLedgerAccountSelect = ({
  account,
  onAccountChange,
  selectDimensions = true,
  disabled,
  tabIndex,
}: GeneralLedgerAccountSelectProps) => {
  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [selectedAccount, setSelectedAccount] =
    useState<GeneralLedgerAccount>();

  // Helper state
  const [isSelectingMandatoryDimension, setIsSelectingMandatoryDimension] =
    useState(false);

  // Queries
  const generalLedgerAccountsQuery = useGetGeneralLedgerAccounts(
    clientAccount?.id
  );

  const { data } = useGetDimensionValues(clientAccount);
  const dimensionValuesData = data as {
    dimension_values: DimensionValue[];
    pages: Pages;
  };

  const getOptionLabelAccountingAccounts = (
    option: GeneralLedgerAccount,
    selectedOption?: AccountDimensionCombination | AccountDimensionCombinationV2
  ) => {
    if (
      option?.mandatory_dimensions &&
      selectedOption &&
      option?.id === selectedOption?.account?.id
    ) {
      const dimensionName = dimensionValuesData?.dimension_values.find(
        (dv) =>
          dv.relation_type === selectedOption.dimension?.relation_type &&
          dv.relation_id === selectedOption.dimension.relation_id
      )?.relation_name;
      return (
        <div style={dimensionName ? { fontSize: "0.8em" } : {}}>
          {`${option.account_code} - ${truncateString(
            option.description_key,
            25
          )}`}
          <br />
          {dimensionName}
        </div>
      );
    }
    return `${option.account_code} - ${option.description_key}`;
  };

  const filterOption = (
    option: { data: GeneralLedgerAccount },
    input: string
  ) => {
    return (
      option?.data?.account_code?.startsWith(input) ||
      option?.data?.description_key
        ?.toLocaleLowerCase()
        ?.includes(input?.toLocaleLowerCase())
    );
  };

  const onAccountChangeInternal = (account: GeneralLedgerAccount) => {
    setSelectedAccount(account);

    if (account?.mandatory_dimensions && selectDimensions)
      setIsSelectingMandatoryDimension(true);
    else onAccountChange && onAccountChange({ account: account });
  };

  const onDimensionChangeInternal = (
    dimension: JournalEntryDimension | null
  ) => {
    setIsSelectingMandatoryDimension(false);

    selectedAccount &&
      dimension &&
      onAccountChange &&
      onAccountChange({ account: selectedAccount, dimension });
  };

  return (
    <>
      {!isSelectingMandatoryDimension ? (
        <Select
          size="md"
          className="account-codes"
          options={generalLedgerAccountsQuery.data?.accounting_accounts || []}
          loading={generalLedgerAccountsQuery.isLoading}
          onChange={onAccountChangeInternal}
          value={account?.account || ""}
          getOptionLabel={(option: GeneralLedgerAccount) =>
            getOptionLabelAccountingAccounts(option, account)
          }
          getOptionValue={({ id }: GeneralLedgerAccount) => id}
          filterOption={filterOption}
          disabled={!!disabled}
          tabIndex={tabIndex}
        />
      ) : (
        <LabelsSelect
          dimensions={[]}
          onDimensionsChange={(dim) =>
            onDimensionChangeInternal(dim?.length ? dim[0] : null)
          }
          dimensionTypes={selectedAccount?.mandatory_dimensions.map(
            (md) => md.relation_type
          )}
          isMulti={false}
          tabIndex={tabIndex}
        />
      )}
    </>
  );
};

export default memo(GeneralLedgerAccountSelect);
