import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Chip, Input, LabelsSelect } from "../index";
import "./style.scss";

const LabelsFilter = (props) => {
  const { activeLabels, setActiveLabels, disabled, noMargin } = props;
  const { t } = useTranslation();
  const ref = useRef(null);
  const [showLabelsSelect, setShowLabelsSelect] = useState(false);

  const handleUpdateLabels = (labels) => {
    setShowLabelsSelect(true);
    setActiveLabels(labels);
  };

  const deleteChip = (key) =>
    setActiveLabels((prevActiveLabels) =>
      prevActiveLabels.filter((value, i) => i !== key)
    );

  // hide when outside click
  useEffect(() => {
    if (showLabelsSelect) {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setShowLabelsSelect(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showLabelsSelect, ref]);

  return (
    <div ref={ref} className={cn('filter-label', { 'no-margin': noMargin })}>
      {!showLabelsSelect ? (
        <Input
          size="md"
          placeholder={t("common_components.input.placeholder_search")}
          value={`Added labels (${activeLabels.length})`}
          onClick={() => setShowLabelsSelect(true)}
          disabled={disabled}
          readOnly
        />
      ) : (
        <LabelsSelect
          show={showLabelsSelect}
          onHide={() => setShowLabelsSelect(false)}
          className="labels-select-flex"
          dimensions={activeLabels}
          onDimensionsChange={handleUpdateLabels}
          onClickAddNew={() => console.log("create new label modal")}
          disabled={disabled}
        />
      )}
      {activeLabels.map((chip, index) => (
        <Chip
          key={index}
          idx={index}
          text={chip.relation_name}
          onClick={() => deleteChip(index)}
        />
      ))}
    </div>
  );
};

export default LabelsFilter;
