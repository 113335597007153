import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FormGroup, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next";

interface Company {
  id: number;
  name: string;
  organization_number: string;
  address: string;
}

const SEARCH_URI = "https://api.snapbooks.com/snapbooks/api/v1/organizations/public/";

export const CompanyDropdown = ({onSelect, onBlur, className = ""}: {onSelect: (org: Company) => void, onBlur: (props: any) => void, className?: string}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Company[]>([]);
  const { t } = useTranslation();

  const handleSearch = (query: string) => {
    setIsLoading(true);

    fetch(`${SEARCH_URI}?expanded=1&description=${query}&page=1&per_page=10`)
      .then((resp) => resp.json())
      .then(({organizations}: {organizations: Company[]      }) => {
        console.log(organizations);
        setOptions(organizations);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
      });
  };

  const filterBy = () => true;

  return (
    <FormGroup>
    <Form.Label className="org-label required">{t("signup_page.organization")}</Form.Label>
    <AsyncTypeahead
      filterBy={filterBy}
      id="async-example"
      isLoading={isLoading}
      labelKey="name"
      minLength={3}
      onSearch={handleSearch}
      onChange={(org: any) => {
        if (!org.length) {
          org[0] = null
        }
        onSelect(org[0] as Company);
      }}
      options={options}
      placeholder={t("signup_page.search_for_a_company")}
      inputProps={{
        name: "organization",
        className: `company-dropdown-input ${className}`,
      }}
      onBlur={onBlur}
      renderMenuItemChildren={(option: any) => (
        <div key={option.id}>
          <span><strong className="bold">{option.name}</strong></span>
          <div>{option.organization_number}</div>
          <span className="text-secondary">{option.address}</span>
        </div>
      )}
    />
    </FormGroup>
  );
};
