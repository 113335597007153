import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { IconGreenQuestions } from "../../assests/icons";
import { Expandable } from "../../components/commons";

import DocumentAttachmentItem from "./DocumentAttachmentItem";

import "./style.scss";

const DocumentAttachments = ({ attachments, isLoading }: any) => {
  const { t } = useTranslation();

  return (
    <div className="document-attachments">
      <Expandable
        contentClassName="padding document-attachment-items"
        title={
          isLoading
            ? t("app.loadingTitle")
            : `${t("post_detail.document_attachments")} (${
                attachments?.length || 0
              })`
        }
        headerIcon={<IconGreenQuestions />}
        defaultActiveKey="1"
      >
        {!isLoading &&
          attachments.map((attachment: any) => (
            <DocumentAttachmentItem
              key={attachment.id}
              attachment={attachment}
            />
          ))}
      </Expandable>
    </div>
  );
};

export default DocumentAttachments;
