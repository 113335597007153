import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams, NavLink } from "react-router-dom";
import { pathKeys } from "../../../../constants";
import { IconArrowRight } from "../../../../assests/icons";

export const ReconciliationsToggle = forwardRef(
  ({ onClick }: { onClick: (e: any) => void }, ref: any) => {
    const { t } = useTranslation();
    const { clientId } = useParams();

    return (
      <NavLink
        ref={ref}
        className="sidebar-link icon"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        to={`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.RECONCILIATION}`}
      >
        <span>{t("header.menu.reconcile")}</span>
        <IconArrowRight />
      </NavLink>
    );
  }
);
ReconciliationsToggle.displayName = "ReconciliationsToggle";
