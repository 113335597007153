import cn from "classnames";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { IconBlueQuestions, IconGreenQuestions } from "../../assests/icons";
import { Expandable } from "../../components/commons";
import { Question } from "../../types/Question";
import { currencyFormat } from "../../utils/common";
import { getBankTransactionDescription } from "./bankTransactionFunctions";
import {
  BankTransaction,
  BankTransactionDetails,
  ReceivablePayment,
} from "../../types/BankTransactionV2";

const BankTransactionListItem = ({
  bankTransaction,
  selected,
  onClick,
}: {
  bankTransaction: BankTransaction;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  const { t } = useTranslation();

  const getDetailFromTo = (
    detail: BankTransactionDetails | ReceivablePayment
  ) => {
    const fromTo =
      ("debtor_name" in detail ? detail?.debtor_name : null) ||
      ("receiver_name" in detail ? detail?.receiver_name : null) ||
      ("sender_name" in detail ? detail?.sender_name : null);
    return fromTo;
  };

  const getDetailReference = (
    detail: BankTransactionDetails | ReceivablePayment
  ) => {
    const reference =
      detail?.structured_reference ||
      ("document_number" in detail ? detail?.document_number : null) ||
      ("invoice_number" in detail ? detail?.invoice_number : null) ||
      (detail?.description === getBankTransactionDescription(bankTransaction)
        ? undefined
        : detail?.description);
    return reference;
  };

  const getDetailAmount = (
    detail: BankTransactionDetails | ReceivablePayment
  ) => {
    const amount = detail.amount
      ? currencyFormat(detail.amount, bankTransaction.currency_code)
      : undefined;
    return amount;
  };

  const getDetailText = (
    detail: BankTransactionDetails | ReceivablePayment
  ) => {
    const fromTo = getDetailFromTo(detail);
    const reference = getDetailReference(detail);
    const amount = getDetailAmount(detail);

    if (fromTo || reference || amount)
      return [fromTo, reference, amount].filter((v) => v).join(" : ");

    return "";
  };

  const hasDetails =
    (bankTransaction?.details
      ?.flatMap((d) => {
        return [getDetailFromTo(d), getDetailReference(d)];
      })
      ?.filter((v) => v?.length)?.length ?? 0) > 0;

  const hasReceivables =
    (bankTransaction?.receivable_payments?.length ?? 0) > 0;

  return (
    <div
      className={cn("transactions-details-item", {
        selected,
        reconciled: !!bankTransaction.external_reconciled_amount,
      })}
      role="button"
      onClick={onClick}
    >
      <div className="item-title">
        <div className="company-name">
          {getBankTransactionDescription(bankTransaction)}
        </div>
        <div className="price">
          {currencyFormat(
            bankTransaction.amount,
            bankTransaction.currency_code
          )}
        </div>
      </div>
      {!hasReceivables && hasDetails && (
        <Expandable
          className="payments-references"
          title={t("reconcile.payment_references")}
        >
          {(bankTransaction.details || []).map((detail: any) => (
            <div key={detail.id}>{getDetailText(detail)}</div>
          ))}
        </Expandable>
      )}
      {hasReceivables && (
        <Expandable
          className="payments-references"
          title={t("reconcile.payment_references")}
        >
          {(bankTransaction.receivable_payments || []).map((detail: any) => (
            <div key={detail.id}>{getDetailText(detail)}</div>
          ))}
        </Expandable>
      )}
      {!!bankTransaction.questions?.find(
        (q: Question) => q.question_status == "OPEN"
      ) ? (
        <IconBlueQuestions
          style={{ position: "absolute", right: "0px", bottom: "0px" }}
        />
      ) : (
        !!bankTransaction.questions?.find(
          (q: Question) => q.question_status == "CLOSED"
        ) && (
          <IconGreenQuestions
            style={{ position: "absolute", right: "0px", bottom: "0px" }}
          />
        )
      )}
    </div>
  );
};

export default memo(BankTransactionListItem);
