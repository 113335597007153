import { useQuery } from "react-query";
import { authenticatedApiClient, throwErrorByStatus } from "./apiClients";
import { ClientAccount } from "../types/ClientAccount";

export function useGetClientAccount(clientAccountId: number) {
  return useQuery(
    ["client-account", clientAccountId],
    async () => {
      const res = await authenticatedApiClient.get(
        `/client-accounts/${clientAccountId}`
      );
      throwErrorByStatus(res);
      return res.data as ClientAccount;
    },
    { staleTime: Infinity, enabled: !!clientAccountId }
  );
}

export const useGetUserClients = () => {
  return useQuery(
    ["user-client-accounts"],
    async () => {
      const res = await authenticatedApiClient.get("/client-accounts/?with=client_contracts");
      throwErrorByStatus(res);
      return res.data.data as ClientAccount[];
    },
    { staleTime: Infinity }
  );
}