import React, { useContext, useState, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { FILE_UPLOAD_ACCEPTED } from "../../constants/common";
import { Button, Input, PDFViewer, UploadFile } from "../../components/commons";
import { IconExit, IconMagnifyingGlass, IconSplit } from "../../assests/icons";
import { BOOTSTRAP_VARIANTS, pathKeys } from "../../constants";

import { showErrorNotification } from "../../utils/toastr";

import { useSplitPdf, useUploadDocuments } from "../../api";

import { ClientAccountContext } from "../App";

import "react-awesome-lightbox/build/style.css";
import "./style.scss";

const FileViewWindow = (props) => {
  const { isNewPost, voucher } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const pdfRef = useRef(null);

  const [imageLoaded, changeImageLoaded] = useState(false);
  const [splitState, setSplitState] = useState(false);
  const [splitPageNumber, setSplitPageNumber] = useState(null);
  const [documentsUploading, setDocumentsUploading] = useState(false);
  const [pageControls, setPageControls] = useState(null);

  const clientAccount = useContext(ClientAccountContext);

  const { mutateAsync: uploadDocuments } = useUploadDocuments();
  const { mutateAsync: splitPdf } = useSplitPdf();

  const handleImageLoaded = () => changeImageLoaded(true);

  const handleFilesUpload = async (files) => {
    setDocumentsUploading(true);
    const data = await uploadDocuments({ accountId: clientAccount.id, files });
    setDocumentsUploading(false);
    const newPostId = data.files[0]?.id;
    if (newPostId) {
      navigate(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}/${newPostId}`
      );
    }
  };

  const handleFilesRejected = () =>
    showErrorNotification(
      `${t("client_container.upload_error")} ${FILE_UPLOAD_ACCEPTED.join(", ")}`
    );

  const handleOpenSplitPdfWindow = () => {
    setSplitState(true);
  };

  const handleCloseSplitPdfWindow = () => {
    setSplitState(false);
  };

  const handleSplitPdf = () => {
    if (voucher?.id && splitPageNumber) {
      splitPdf({ id: voucher.id, splitPageNumber });
    }
    setSplitState(false);
  };

  const onPageChange = ({ currentPage, totalPages }) => {
    setPageControls(
      <div className="pdf-viewer-page-controls">
        <button
          type="button"
          disabled={currentPage === 1}
          onClick={() => pdfRef.current.go(-1)}
        >
          ‹
        </button>
        <span>
          {currentPage} of {totalPages}
        </span>
        <button
          type="button"
          disabled={currentPage === totalPages}
          onClick={() => pdfRef.current.go(1)}
        >
          ›
        </button>
      </div>
    );
  };

  return (
    <div
      className={cn("post-image-wrap", {
        isNewPost,
        "split-controls-open": splitState,
      })}
    >
      <div
        className={cn("post-image", {
          "not-pdf": !(voucher?.content_type || "")
            .toLowerCase()
            .endsWith("pdf"),
        })}
      >
        {isNewPost || !voucher?.download_url ? (
          <div className="upload-file">
            <UploadFile
              accept={FILE_UPLOAD_ACCEPTED}
              onDropAccepted={handleFilesUpload}
              onDropRejected={handleFilesRejected}
              loading={documentsUploading}
              multiple={false}
              withIcon
            />
          </div>
        ) : (voucher.content_type || "").toLowerCase().endsWith("pdf") ? (
          <>
            <PDFViewer
              url={voucher.download_url}
              onLoadSuccess={handleImageLoaded}
              onPageChange={onPageChange}
              showPages={false}
              thumbnailsActive
              ref={pdfRef}
            />
            {imageLoaded && (
              <>
                {splitState === false ? (
                  <span className="split-icon rounded-pill" role="button">
                    <div className="page-split-container rounded-pill">
                      <Button
                        className="split-pdf-button text-white rounded-pill border-0"
                        variant={BOOTSTRAP_VARIANTS.PRIMARY}
                        text="&nbsp;&nbsp;&nbsp;&nbsp;Split"
                        icon={<IconSplit />}
                        onClick={handleOpenSplitPdfWindow}
                      />
                    </div>
                  </span>
                ) : (
                  <span className="split-icon" role="button">
                    <div className="page-split-container">
                      <div className="split-pdf-text-active">Page ranges</div>
                      <Input
                        size="md"
                        className="split-pdf-input-active"
                        value={splitPageNumber || ""}
                        onChange={(e) => setSplitPageNumber(e)}
                        tabIndex={5}
                      />
                      <Button
                        className="split-pdf-button-active"
                        variant={BOOTSTRAP_VARIANTS.PRIMARY}
                        text="Split"
                        onClick={handleSplitPdf}
                      />
                      <div
                        className="split-pdf-button-exit"
                        role="button"
                        onClick={handleCloseSplitPdfWindow}
                      >
                        <IconExit />
                      </div>
                    </div>
                  </span>
                )}
                {pageControls != null && pageControls}
                <span
                  className="zoom-icon"
                  role="button"
                  onClick={() => window.open(voucher?.original_url, "_blank")}
                >
                  <IconMagnifyingGlass />
                </span>
              </>
            )}
          </>
        ) : (
          <>
            <img
              className="image"
              src={voucher?.download_url}
              alt={t("post_detail.document_url_invalid")}
              onLoad={handleImageLoaded}
            />
            {imageLoaded && (
              <span
                className="zoom-icon"
                role="button"
                onClick={() => window.open(voucher?.original_url, "_blank")}
              >
                <IconMagnifyingGlass />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(FileViewWindow);
