import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal, Radio } from "../commons";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { showErrorNotification } from "../../utils/toastr";

import "./style.scss";

const AskQuestionModal = (props) => {
  const {
    onHide,
    onSave,
    loading,
    show,
    answerTypes = ["TYPE_ANSWER"],
  } = props;
  const { t } = useTranslation();

  const [answerType, setAnswerType] = useState("TYPE_ANSWER");
  const [notPredefinedQuestion, setNotPredefinedQuestion] = useState("");

  const handleOnHide = () => {
    onHide();
  };

  const isStatement = answerType === "STATEMENT";
  const isFile = answerType === "ATTACH_FILE";
  const isText = answerType === "TYPE_ANSWER";

  const handleSendAskQuestions = () => {
    if (isText && (!notPredefinedQuestion || !notPredefinedQuestion.trim())) {
      showErrorNotification(t("ask_question_modal.please_input_question"));
      return;
    }

    const question = isStatement
      ? "Vi trenger litt mer informasjon..."
      : isFile
      ? "Vi mangler bilag på denne..."
      : notPredefinedQuestion;

    const body = {
      question,
      answer_action: answerType,
    };
    onSave(body);
  };

  return (
    <Modal
      className="ask-question-modal"
      show={show}
      loading={loading}
      size="lg"
      backdrop="static"
      onHide={handleOnHide}
      title={t("ask_question_modal.title")}
    >
      <>
        <div className="content">
          <div className="label">{t("ask_question_modal.question_type")}</div>
          <div>
            {answerTypes.includes("STATEMENT") && (
              <Radio
                checked={isStatement}
                label="Statement"
                onClick={() => setAnswerType("STATEMENT")}
              />
            )}
            {answerTypes.includes("ATTACH_FILE") && (
              <Radio
                checked={isFile}
                label="Upload"
                onClick={() => setAnswerType("ATTACH_FILE")}
              />
            )}
            {answerTypes.includes("TYPE_ANSWER") && (
              <Radio
                checked={isText}
                label="Text answer"
                onClick={() => setAnswerType("TYPE_ANSWER")}
              />
            )}
          </div>
          {isText && (
            <>
              <div className="label">{t("ask_question_modal.question")}</div>
              <Input
                size="md"
                noPadding
                value={notPredefinedQuestion}
                onChange={(e) => setNotPredefinedQuestion(e)}
              />
            </>
          )}
        </div>
        <div className="actions">
          <Button
            className="send-button"
            size="md"
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("ask_question_modal.send_button")}
            onClick={handleSendAskQuestions}
          />
        </div>
      </>
    </Modal>
  );
};

export default memo(AskQuestionModal);
