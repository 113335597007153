import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../components/commons";
import { IconArrowRight } from "../../assests/icons";

const EditDepartment = (props) => {
  const { department, onBack, onSave } = props;
  const { t } = useTranslation();
  const initialData = {
    name: "",
  };

  const [departmentData, setDepartmentData] = useState(initialData);
  const [isDepartmentFormSubmitted, setIsDepartmentFormSubmitted] =
    useState(false);

  useEffect(() => {
    if (department) {
      setDepartmentData(department);
    }
  }, [department]);

  const handleChangeDepartmentData = (filed, value) =>
    setDepartmentData({ ...departmentData, [filed]: value });

  const handleCreateDepartment = () => {
    setIsDepartmentFormSubmitted(true);

    if (!departmentData.name) {
      return;
    }

    const body = {
      ...departmentData,
    };

    onSave(body);
  };

  return (
    <div className="edit-page">
      <div className="header">
        <span role="button" className="back-button" onClick={() => onBack()}>
          <IconArrowRight />
        </span>
        {department ? (
          <span className="title">Edit {department.name}</span>
        ) : (
          <span className="title">Add New Department</span>
        )}
      </div>
      <div className="detail-form">
        <Input
          className="master-data-modal__input"
          size="lg"
          required
          isCheckValid={isDepartmentFormSubmitted}
          label={t("master_data.name_label")}
          value={departmentData.name ?? ""}
          onChange={(e) => handleChangeDepartmentData("name", e)}
        />
      </div>
      <div>
        <Button
          className="save-button"
          size="md40"
          variant="success"
          onClick={handleCreateDepartment}
          text={t("master_data.save_button")}
        />
      </div>
    </div>
  );
};
export default EditDepartment;
