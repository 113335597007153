import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CurrencyInput,
  DatePicker,
  Modal,
} from "../../components/commons";

import "./style.scss";
import { BankAccount } from "../../types/BankAccount";
import { BankTransaction } from "../../types/BankTransactionV2";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { ClientAccountContext } from "../App";
import {
  useCreateBankTransaction,
  useCreateExternalReconciliation,
} from "../../api";
import moment from "moment";

const CreateIncomingBalanceModal = ({
  bankAccount,
  show,
  onIncomingBalanceCreated,
  onClose,
}: {
  bankAccount: BankAccount;
  show: boolean;
  onIncomingBalanceCreated: (t: BankTransaction) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [balanceDate, setBalanceDate] = useState(new Date());
  const [balance, setBalance] = useState(0);

  // Mutations
  const { mutateAsync: createBankTransaction } = useCreateBankTransaction();

  // Event handlers
  const createIncomingBalance = async () => {
    const bankTransaction = {
      client_account_id: clientAccount.id,
      payee_bank_account_id: balance > 0 ? bankAccount.id : undefined,
      payor_bank_account_id: balance < 0 ? bankAccount.id : undefined,
      booking_date: moment(balanceDate).format("YYYY-MM-DD"),
      amount: balance,
      currency_code: clientAccount.accounting_currency,
      exchange_rate: 1,
      bank_reference: "IB",
      memo: t("Incoming balance transaction"),
    } as BankTransaction;

    const created = await createBankTransaction(bankTransaction as any);

    onIncomingBalanceCreated && onIncomingBalanceCreated(created);
    onClose && onClose();
  };

  return (
    <Modal
      className="incoming-balance-modal"
      show={show}
      size="xl"
      onHide={onClose}
      title={t("Create incoming balance bank transaction")}
      draggable
    >
      <div className="content">
        <DatePicker
          label={t("Incoming balance date")}
          selectedDate={balanceDate}
          onChange={setBalanceDate}
        />
        <CurrencyInput
          label={t("Balance at day end")}
          currencyCode={clientAccount.accounting_currency}
          value={balance}
          onChange={setBalance}
        />
        <div className="function-buttons">
          <div className="label">&nbsp;</div>
          <Button
            variant={BOOTSTRAP_VARIANTS.PRIMARY}
            text={t("Add")}
            onClick={createIncomingBalance}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateIncomingBalanceModal;
