export default function getLabelAbbreviation(type) {
  const abbrevations = {
    bankacc: "BA",
    businesspartner: "BP",
    department: "DP",
    project: "PR",
    asset: "AS",
  };

  return abbrevations[type];
}
