import cn from "classnames";
import _ from "lodash";
import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";

import {
  DimensionValue,
  JournalEntryDimension,
} from "../../../types/JournalEntry";
import { ClientAccountContext } from "../../../views/App";

import { useGetDimensionValues } from "../../../api";

import { CheckBox } from "../index";
import Select from "../Select";

interface CustomSelectProps {
  onClickAddNew?: any;
  className?: string;
  dimensions: JournalEntryDimension[];
  onDimensionsChange: (dv: JournalEntryDimension[]) => void;
  dimensionTypes?: string[];
  isMulti?: boolean;
  tabIndex?: number;
  [x: string]: any;
}

const CustomSelect = ({
  onClickAddNew,
  className,
  dimensions,
  onDimensionsChange,
  dimensionTypes,
  isMulti = true,
  tabIndex,
  ...otherProps
}: CustomSelectProps) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [options, setOptions] = useState<{ label: string; options: DimensionValue[] }[]>([]);

  // Queries
  const dimensionValuesQuery = useGetDimensionValues(clientAccount);

  useEffect(() => {
    if (dimensionValuesQuery.data) {
      let relation_types = _.uniq(
        dimensionValuesQuery.data.dimension_values?.map((dv: any) => dv.relation_type)
      );
      if (dimensionTypes?.length)
        relation_types = relation_types.filter((rt: any) =>
          dimensionTypes.includes(rt)
        );

      const _options = relation_types.map((rt) => ({
        label: rt,
        options: dimensionValuesQuery.data.dimension_values?.filter((dv: any) => dv.relation_type == rt)
      })) as { label: string; options: DimensionValue[] }[];
      setOptions(_options);
    }
  }, [dimensionValuesQuery.data]);

  const isOptionSelected = (item: JournalEntryDimension) => {
    return dimensions?.some(
      (e) =>
        e?.relation_id === item?.relation_id &&
        e?.relation_type === item?.relation_type
    );
  };

  const isOptionDisabled = (item: JournalEntryDimension) => {
    return dimensions?.some((e) => e?.relation_type === item?.relation_type);
  };

  const getDimensionName = (dimension: JournalEntryDimension) => {
    return dimensionValuesQuery.data?.dimension_values.find(
      (l: any) =>
        l?.relation_type === dimension.relation_type &&
        l?.relation_id === dimension.relation_id
    )?.relation_name;
  };

  const Option = (props: any) => (
    <components.Option
      {...props}
      className={cn("custom-option", {
        selected: isOptionSelected(props.data),
      })}
    >
      <CheckBox
        checked={isOptionSelected(props.data)}
        onClick={() => props.selectOption(props.data)}
      />
      <span>{props.data.relation_name}</span>
    </components.Option>
  );

  const onChangeInternal = (opt: any) => {
    onDimensionsChange &&
      onDimensionsChange(opt ? (Array.isArray(opt) ? opt : [opt]) : []);
  };

  return (
    <Select
      isLoading={dimensionValuesQuery.isLoading}
      size={"md"}
      className={cn("select", className)}
      placeholder={t("common_components.input.placeholder_search")}
      components={{ ...(isMulti ? { Option } : {}) }}
      value={dimensions}
      onChange={onChangeInternal}
      getOptionLabel={(dimension: JournalEntryDimension) =>
        getDimensionName(dimension) || ""
      }
      getOptionValue={({ relation_id, relation_type }: JournalEntryDimension) =>
        `${relation_type}_${relation_id}`
      }
      options={options}
      isOptionSelected={isOptionSelected}
      isOptionDisabled={isOptionDisabled}
      menuIsOpen
      isSearchable
      isClearable
      autoFocus
      isMulti={isMulti}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      tabIndex={tabIndex?.toString()}
      {...otherProps}
    />
  );
};

export default memo(CustomSelect);
