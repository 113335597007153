import React, { forwardRef, useRef, useState, memo } from "react";
import cn from "classnames";
import { Dropdown, Overlay, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { IconArrowRight, IconMasterData } from "../../../assests/icons";
import { profileImage, textLogoImg } from "../../../assests/images";
import { pathKeys } from "../../../constants";
import MasterDataModal from "../../../views/MasterData";

import "./style.scss";
import { useCurrentClientAccountFromContext } from "../../../dataHooks/user";
import { ContractsDropdown } from "./Components/ContractsDropdown";
import { AccountsDropdown } from "./Components/AccountsDropdown";
import { ReconciliationsToggle } from "./Components/ReconciliationsToggle";

const ReportsToggle = forwardRef(
  ({ onClick }: { onClick: (e: any) => void }, ref: any) => {
    const { t } = useTranslation();
    const { clientId } = useParams();

    return (
      <NavLink
        ref={ref}
        className="sidebar-link icon"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        to={`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}`}
      >
        <span>{t("header.menu.reports")}</span>
        <IconArrowRight />
      </NavLink>
    );
  }
);
ReportsToggle.displayName = "ReportsToggle";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { t } = useTranslation();
  const [showMasterDataModal, setShowMasterDataModal] = useState(false);

  const currentClientAccount = useCurrentClientAccountFromContext();
  const isCustomer = currentClientAccount?.amICustomer;

  if (!currentClientAccount) {
    return null;
  }

  const clientId = currentClientAccount.id;
  const { display_name } = currentClientAccount;

  return (
    <div className="header-bar">
      <div>
        <div className={cn("content", { accountant: !isCustomer })}>
          <div className="d-flex align-items-baseline">
            <NavLink
              className="logo"
              to={`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.OVERVIEW}`}
            >
              <img src={textLogoImg} alt="" />
            </NavLink>
            {isCustomer ? (
              <span className="bg-white text-black rounded-pill border border-light mx-2 px-2 py-1">
                {display_name}
              </span>
            ) : null}
          </div>
          {!isCustomer && <ContractsDropdown />}
          <div className="menu">
            <NavLink
              className="sidebar-link"
              to={`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.OVERVIEW}`}
            >
              <span>{t("header.menu.overview")}</span>
            </NavLink>
            <NavLink
              className="sidebar-link"
              to={`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${
                isCustomer ? pathKeys.DOCUMENTS : pathKeys.VOUCHERS
              }`}
            >
              <span>{t(isCustomer ? "documents.title" : "Vouchers")}</span>
            </NavLink>
            <Dropdown>
              <Dropdown.Toggle as={ReconciliationsToggle} />
              <Dropdown.Menu className="reports-menu">
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.BANK_RECONCILIATION}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.BANK_RECONCILIATION}`
                    )
                  }
                >
                  {t("header.menu.reconcile_menu.bank")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.INTERNAL_RECONCILIATION}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.INTERNAL_RECONCILIATION}`
                    )
                  }
                >
                  {t("header.menu.reconcile_menu.internal")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle as={ReportsToggle} />
              <Dropdown.Menu className="reports-menu">
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.GENERAL_LEDGER}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.GENERAL_LEDGER}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.general_ledger")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.JOURNAL_ENTRIES}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.JOURNAL_ENTRIES}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.journale_entries")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.INCOME_STATEMENT}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.INCOME_STATEMENT}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.income_statement")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.BALANCE_STATEMENT}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.BALANCE_STATEMENT}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.balance_statement")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.VAT}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.VAT}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.value_added_tax_return_report")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.ACCOUNTS_RECEIVABLE}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.ACCOUNTS_RECEIVABLE}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.accounts_receivable")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.ACCOUNTS_PAYABLE}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.ACCOUNTS_PAYABLE}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.accounts_payable")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.TRIAL_BALANCE}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.TRIAL_BALANCE}`
                    )
                  }
                >
                  {t("header.menu.reports_menu.trial_balance")}
                </Dropdown.Item>
                <Dropdown.Item
                  className={cn({
                    active:
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.DATA_EXPORT}` ===
                      location.pathname,
                  })}
                  onClick={() =>
                    navigate(
                      `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.REPORTS}${pathKeys.DATA_EXPORT}`
                    )
                  }
                >
                  {t("Data Export")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink
              hidden={isCustomer}
              className="sidebar-link"
              to={`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.MESSAGES}`}
            >
              <span>{t("header.menu.messages")}</span>
            </NavLink>
            <a
              className="sidebar-link"
              hidden={!isCustomer}
              target="_blank"
              href={
                process.env.NODE_ENV === "production"
                  ? "https://faktura.snapbooks.no"
                  : "https://faktura.staging.snapbooks.no"
              }
            >
              <div>{t("header.menu.create_invoice")}</div>
            </a>
            <div
              className="notification"
              role="button"
              onClick={() => setShowMasterDataModal(true)}
            >
              <IconMasterData />
            </div>
            <MasterDataModal
              clientAccountId={clientId}
              loading={false}
              show={showMasterDataModal}
              onHide={() => setShowMasterDataModal(false)}
            />
            <Overlay
              target={target.current}
              show={show}
              placement="bottom-end"
              rootClose
              onHide={() => setShow(false)}
            >
              {(props) => (
                <Popover className="notification-popover" {...props}>
                  <Popover.Header as="h3">Notifications</Popover.Header>
                  <Popover.Body>
                    <div className="notification-item">
                      <img
                        className="profile-image"
                        src={profileImage}
                        alt=""
                      />
                      <div className="notification-content">
                        <div className="time">Today (4:24 PM)</div>
                        <div className="name">Maria Cary</div>
                        <div className="content">sent Message to you</div>
                      </div>
                    </div>
                    <div className="notification-item">
                      <img
                        className="profile-image"
                        src={profileImage}
                        alt=""
                      />
                      <div className="notification-content">
                        <div className="time">20 Jun, (4:24 PM)</div>
                        <div className="name">Maria Cary</div>
                        <div className="content">
                          sent Question to you about 51681351464.pdf document
                        </div>
                      </div>
                    </div>
                  </Popover.Body>
                </Popover>
              )}
            </Overlay>
            <AccountsDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
