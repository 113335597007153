import moment from "moment";
import { DatePicker, Input, Text } from "../../../components/commons";
import { DocumentReference } from "../../../types/DocumentV2";
import { useTranslation } from "react-i18next";
import { useGetDocuments } from "../../../api";
import { useContext } from "react";
import { ClientAccountContext } from "../../App";
import { pathKeys } from "../../../constants";
import { Document } from "../../../types/Document";

export const DocumentReferenceItem = ({
  value: reference,
  onChange,
  document,
  itemIndex,
  disabled,
  tabIndex,
}: {
  value: DocumentReference;
  onChange: (d: DocumentReference) => void;
  document?: Document;
  itemIndex?: number;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const index = itemIndex || 0;

  // Queries
  const documentsQuery = useGetDocuments({
    client_account_id: clientAccount?.id,
    business_partner_id: document?.business_partner_id,
    document_number: reference.referenced_document_number,
  });
  const documentMatch = documentsQuery.data?.documents?.find(
    (d: any) =>
      d.business_partner_id === document?.business_partner_id &&
      (!reference.referenced_document_type ||
        d.document_type === reference.referenced_document_type) &&
      d.document_number === reference.referenced_document_number &&
      !d.files?.find(
        (f: any) => f.type === "VOUCHER" && f.is_active === false
      ) &&
      d.file_id !== document?.file_id
  );

  const toDate = (val: string) => {
    if (!val || val.length !== 10) return;

    return moment(val).toDate();
  };

  const onReferenceLabelClicked = () => {
    if (documentMatch) {
      const voucherMatchId = documentMatch?.file_id;
      const win = window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.POSTS}/${documentMatch.file_id}`,
        "_blank"
      );
      win?.focus();
    }
  };

  const invoiceNumberLabel = (
    <>
      <Text as={documentMatch ? "a" : "span"} onClick={onReferenceLabelClicked}>
        {t("Invoice number reference (#{{refnum}})", {
          refnum: index + 1,
        })}
      </Text>
      {documentMatch && (
        <a
          style={{
            position: "absolute",
            right: "0px",
            fontSize: "0.8em",
            fontWeight: "bold",
            color: "green",
            lineHeight: "1.8em",
          }}
          role="link"
          onClick={() => onReferenceLabelClicked()}
        >
          {t("Found!")}
        </a>
      )}
    </>
  );

  if (
    disabled &&
    !reference.referenced_document_number &&
    !reference.relation_id
  )
    return null;

  return (
    <>
      <Input
        size="md"
        label={invoiceNumberLabel as any}
        value={reference.referenced_document_number}
        onChange={(e: string) => {
          onChange({
            ...reference,
            referenced_document_number: e,
          });
        }}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 14 + index}
      />
      <DatePicker
        label={t("Invoice date (#{{refnum}})", {
          refnum: index + 1,
        })}
        selectedDate={toDate(reference.referenced_document_date)}
        onChange={(d) => {
          onChange({
            ...reference,
            referenced_document_date: moment(d).format("YYYY-MM-DD"),
          });
        }}
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 14 + index}
      />
    </>
  );
};
