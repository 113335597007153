import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { ObjectTypes } from "../../constants";
import { Select } from "../../components/commons";
import { isEqual } from "lodash";

const DocumentTypeField = ({
  value,
  onChange,
  isCheckValid,
  disabled,
  tabIndex,
  ...otherProps
}: {
  value: { voucherType: string; documentType?: string };
  onChange: (type: { voucherType: string; documentType?: string }) => void;
  isCheckValid?: boolean;
  disabled?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();

  const documentTypes = [
    {
      label: t("post_detail.commercial_document"),
      options: [
        {
          value: {
            voucherType: "RECEIPT",
            documentType: ObjectTypes.AP_RECEIPT,
          },
          label: t(`object_types.${ObjectTypes.AP_RECEIPT}`),
        },
        {
          value: {
            voucherType: "INVOICE",
            documentType: ObjectTypes.AP_INVOICE,
          },
          label: t(`object_types.${ObjectTypes.AP_INVOICE}`),
        },
        {
          value: {
            voucherType: "INVOICE",
            documentType: ObjectTypes.AR_INVOICE,
          },
          label: t(`object_types.${ObjectTypes.AR_INVOICE}`),
        },
        {
          value: {
            voucherType: "CREDIT_NOTE",
            documentType: ObjectTypes.AP_CREDIT_NOTE,
          },
          label: t(`object_types.${ObjectTypes.AP_CREDIT_NOTE}`),
        },
        {
          value: {
            voucherType: "CREDIT_NOTE",
            documentType: ObjectTypes.AR_CREDIT_NOTE,
          },
          label: t(`object_types.${ObjectTypes.AR_CREDIT_NOTE}`),
        },
        {
          value: {
            voucherType: "DEBT_COLLECTION_DOCUMENT",
            documentType: ObjectTypes.AP_DEBT_COLLECTION,
          },
          label: t(`object_types.${ObjectTypes.AP_DEBT_COLLECTION}`),
        },
        {
          value: {
            voucherType: "DEBT_COLLECTION_DOCUMENT",
            documentType: ObjectTypes.AR_DEBT_COLLECTION,
          },
          label: t(`object_types.${ObjectTypes.AR_DEBT_COLLECTION}`),
        },
      ],
    },
    {
      label: t("post_detail.payroll_document"),
      options: [
        {
          value: {
            voucherType: "PAYROLL_POSTINGS",
            documentType: ObjectTypes.PAYROLL_RUN,
          },
          label: t(`object_types.PAYROLL_POSTINGS`),
        },
        {
          value: {
            voucherType: "PAYROLL_PAYMENT_LIST",
            documentType: ObjectTypes.PAYROLL_RUN,
          },
          label: t(`object_types.PAYROLL_PAYMENT_LIST`),
        },
        {
          value: {
            voucherType: "PAYROLL_TAX_SETTLEMENT",
            documentType: undefined,
          },
          label: t(`object_types.PAYROLL_TAX_SETTLEMENT`),
        },
      ],
    },
    {
      label: t("post_detail.other"),
      options: [
        {
          value: {
            voucherType: "ARCHIVED_DOCUMENT",
            documentType: ObjectTypes.ARCHIVED_DOCUMENT,
          },
          label: t(`object_types.${ObjectTypes.ARCHIVED_DOCUMENT}`),
        },
        {
          value: {
            voucherType: "OTHER",
            documentType: undefined,
          },
          label: t(`object_types.${ObjectTypes.OTHER}`),
        },
      ],
    },
  ];

  return (
    <>
      <Select
        size="md"
        label={t("post_detail.document_type")}
        options={documentTypes}
        onChange={(opt: any) => onChange(opt.value)}
        value={documentTypes
          .flatMap((t) => t.options || t)
          .find((t) => isEqual(t.value, value))}
        getOptionLabel={({ label }: { label: string }) => label}
        getOptionValue={({ value }: { value: any }) => value}
        required
        isClearable={false}
        isCheckValid={isCheckValid}
        disabled={disabled}
        tabIndex={tabIndex}
        {...otherProps}
      />
    </>
  );
};

export default DocumentTypeField;
