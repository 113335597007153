import { toast } from 'react-toastify';

const options = {
  position: 'bottom-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export function showSuccessNotification(msg) {
  return toast.success(msg, options);
}

export function showErrorNotification(error) {
  return toast.error(error && error.toString ? error.toString() : (error || 'Something went wrong. Please try again'), options);
}
