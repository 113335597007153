import { useMutation } from "react-query";
import { authenticatedApiV2Client, throwErrorByStatus } from "./apiClients";
import { PaymentDraft } from "../types/Payments";

export function useSavePaymentDraft() {
  return useMutation(async (data: PaymentDraft) => {
    const res = data.id
      ? await authenticatedApiV2Client.post(`/payment-drafts/${data.id}/`, data)
      : await authenticatedApiV2Client.post(`/payment-drafts/`, data);

    throwErrorByStatus(res);
    return res.data;
  });
}
