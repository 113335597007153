import cn from "classnames";
import { useContext, useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { AskQuestionModal } from "../../components";
import { ObjectTypes, pathKeys } from "../../constants";

import { JournalEntry } from "../../types/JournalEntryV2";
import { Question } from "../../types/Question";
import VoucherStatementsSection from "./VoucherStatementsSection";
import { ClientAccount } from "../../types/ClientAccount";

import {
  useCreateAccountingQuestions,
  useDeleteVoucher,
  useGetPreviousNextVouchers,
} from "../../api";
import { ClientAccountContext } from "../App";

import AccountingQuestionsField from "./AccountingQuestionsField";
import DocumentAttachments from "./DocumentAttachments";

import FileViewWindow from "./FileViewWindow";
import HeaderField from "./HeaderField";
import Loader from "./loader";
import StatusField from "./StatusField";
import "./style.scss";
import { useGetVoucher } from "../../api/voucherApi";
import {
  BusinessDocumentContainer,
  BusinessDocumentWrapper,
} from "./BusinessDocumentContainer";
import AccountingDocumentContainer, {
  AccountingDocumentWrapper,
} from "./AccountingDocumentContainer";
import { isEmpty } from "lodash";

const PostDetail = () => {
  const { t } = useTranslation();
  const { id, clientId } = useParams();
  const navigate = useNavigate();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);
  const queryClient = useQueryClient();

  // Data state
  const [voucherType, setVoucherType] = useState<string>();
  const [businessDocument, setBusinessDocument] = useState<
    BusinessDocumentWrapper | undefined
  >();

  // Helper state
  const [isEditingDocument, setIsEditingDocument] = useState(false);
  const [showAskQuestionModal, changeShowAskQuestionModal] = useState(false);
  const [isCreatingAccountingQuestions, setIsCreatingAccountingQuestions] =
    useState(false);

  const { mutateAsync: createAccountingQuestions } =
    useCreateAccountingQuestions();
  const { mutateAsync: deletePosts } = useDeleteVoucher();

  const resetLocalState = () => {
    changeShowAskQuestionModal(false);
    setIsCreatingAccountingQuestions(false);
  };

  // Queries
  const voucherQuery = useGetVoucher(Number(id), [
    "source_file",
    "target_files",
    "attachments",
    "document.business_partner.organization",
    "document.bank_account",
    "document.document_references",
    "document.collector",
    "document.payor",
    "document.payment_lines",
    "documents",
    "journal_entry.lines.dimensions",
    "journal_entries.lines.dimensions",
    "archived_document",
    "payroll_run",
    "payment_draft.transfers.account_entries",
    "payment_draft.transfers.debtor.bank_account",
    "payment_draft.transfers.creditor.bank_account",
    "statements.created_by",
    "statements.payor",
    "statements.project",
    "statements.collection",
    "collection",
    "tasks.consensus",
    "tasks.results",
    "questions.answer_options",
  ]);
  const voucher = voucherQuery.data;

  // Voucher status. 4 possible states: draft, booked, deleted, pending
  const isBooked = !!voucher?.journal_entries?.find(
    (je: JournalEntry) => (je.cancelled || "N") === "N" && !je.is_draft
  );
  const isDeleted = voucher?.is_active === false;

  const query = {
    posting_status: "PENDING,PENDING_ANSWER,DRAFT",
    order_by: "upload_date desc",
    per_page: 500,
    client_account_id: clientAccount?.id,
  };

  const { previous: previousVoucher, next: nextVoucher } =
    useGetPreviousNextVouchers(voucher?.id, query, {
      enabled: !isBooked && !isDeleted,
    });

  const navigateNextVoucher = () => {
    const voucherTo = nextVoucher || previousVoucher;
    if (voucherTo) {
      resetLocalState();
      navigate(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}/${voucherTo.voucher_id}`
      );
    } else {
      navigate(`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}`);
    }
  };

  const isAccountingDocument =
    !!voucher &&
    !!voucherType &&
    (!!businessDocument?.document ||
      voucherType === "PAYROLL_TAX_SETTLEMENT" ||
      voucherType === ObjectTypes.OTHER);

  const getVoucherType = () => {
    const documentType = voucher?.document?.document_type;
    if (
      documentType &&
      [ObjectTypes.AP_RECEIPT].includes(documentType as ObjectTypes)
    )
      return "RECEIPT";
    if (
      [ObjectTypes.AR_INVOICE, ObjectTypes.AP_INVOICE].includes(
        documentType as ObjectTypes
      )
    )
      return "INVOICE";
    if (
      [ObjectTypes.AR_CREDIT_NOTE, ObjectTypes.AP_CREDIT_NOTE].includes(
        documentType as ObjectTypes
      )
    )
      return "CREDIT_NOTE";

    if (
      [ObjectTypes.AR_DEBT_COLLECTION, ObjectTypes.AP_DEBT_COLLECTION].includes(
        documentType as ObjectTypes
      )
    )
      return "DEBT_COLLECTION_DOCUMENT";

    if (voucher?.payment_draft?.draft_type)
      return voucher?.payment_draft?.draft_type;

    if (voucher?.payroll_run) return "PAYROLL_POSTINGS";

    const classificationConsensus = voucher?.tasks?.find(
      (task) => task.task_type === "DOCUMENT_CLASSIFICATION"
    )?.consensus?.data?.document_type;

    return classificationConsensus;
  };

  const loadVoucher = () => {
    if (!voucher) return;

    if (voucher.document) {
      setVoucherType(getVoucherType());
      setBusinessDocument({ type: "Document", document: voucher.document });
    } else if (voucher.archived_document) {
      setVoucherType(getVoucherType() || "OTHER");
      setBusinessDocument({
        type: "ArchivedDocument",
        document: voucher.archived_document,
      });
    } else if (voucher.payroll_run) {
      setVoucherType(getVoucherType() || "PAYROLL_POSTINGS");
      setBusinessDocument({
        type: "PayrollRun",
        document: voucher.payroll_run,
      });
    } else {
      setVoucherType(getVoucherType() || "OTHER");
      setBusinessDocument(undefined);
    }
  };

  useEffect(() => {
    if (!voucher) return;

    if (voucher.client_account_id != Number(clientId)) {
      navigate(`${pathKeys.CLIENT_ACCOUNTS}/${clientId}${pathKeys.POSTS}`);
      return;
    }

    loadVoucher();
  }, [voucher]);

  // Event handlers
  const closeAskQuestionModal = () => changeShowAskQuestionModal(false);

  const handleSendAskQuestions = (data: Question) => {
    setIsCreatingAccountingQuestions(true);
    createAccountingQuestions({ ...data, document_id: id } as any).then(() => {
      setIsCreatingAccountingQuestions(false);
      closeAskQuestionModal();
    });
  };

  const onVoucherTypeChange = (type: string) => {
    if (type === "PAYROLL_TAX_SETTLEMENT")
      setIsEditingDocument(!isEditingDocument);

    setVoucherType(type);
  };

  const onBusinessDocumentChange = async (doc?: BusinessDocumentWrapper) => {
    if (doc?.type === "ArchivedDocument" && doc.document) {
      queryClient.invalidateQueries("vouchers");
      navigateNextVoucher();
    } else if (voucherType === "PAYROLL_PAYMENT_LIST") {
      setBusinessDocument(doc);
    } else if (voucherType === "OTHER") {
      setBusinessDocument(doc);
      setIsEditingDocument(false);
    } else {
      voucherQuery.refetch().then(() => {
        loadVoucher();
        setIsEditingDocument(false);
      });
    }
  };

  const onAccountingDocumentChange = async (
    doc?: AccountingDocumentWrapper
  ) => {
    if (!doc) return;

    if (!!doc.document) {
      queryClient.invalidateQueries("vouchers");
      navigateNextVoucher();
    } else {
      voucherQuery.refetch();
    }
  };

  if (voucherQuery.isLoading) {
    return <Loader />;
  }

  return (
    <div className={cn("post-detail-page")} key={voucher?.id}>
      <AskQuestionModal
        show={showAskQuestionModal}
        loading={isCreatingAccountingQuestions}
        onHide={closeAskQuestionModal}
        onSave={handleSendAskQuestions}
        answerTypes={["STATEMENT", "TYPE_ANSWER"]}
      />
      <div className="content">
        <div className="grid-wrap left-panel">
          <div className="post-info-wrap">
            <HeaderField voucher={voucher} resetLocalState={resetLocalState} />
          </div>
        </div>

        <FileViewWindow voucher={voucher} />
        <div className="detail-form">
          {voucher && (
            <BusinessDocumentContainer
              voucher={voucher}
              voucherType={voucherType || ""}
              onVoucherTypeChange={onVoucherTypeChange}
              onBusinessDocumentChange={onBusinessDocumentChange}
              onEditModeChange={setIsEditingDocument}
              disabled={!isEditingDocument}
            />
          )}
        </div>
        {!isEmpty(voucher?.attachments) && (
          <DocumentAttachments
            attachments={(voucher?.attachments || []).filter(
              (a: any) => a.is_active
            )}
          />
        )}
        {!isEmpty(voucher?.questions) && (
          <AccountingQuestionsField
            accountingQuestions={voucher?.questions || []}
            isLoading={voucherQuery.isLoading}
          />
        )}
        {!isEmpty(voucher?.statements) && (
          <VoucherStatementsSection
            voucherStatements={voucher?.statements || []}
          />
        )}
        {!isEditingDocument && isAccountingDocument && (
          <>
            <AccountingDocumentContainer
              voucher={voucher}
              voucherType={voucherType || ""}
              businessDocument={businessDocument}
              onAccountingDocumentChange={onAccountingDocumentChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PostDetail;
