import React, { useState, memo, useEffect, Fragment } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

import { IconArrowDown } from "../../../assests/icons";
import "./style.scss";

interface CustomSelectProps {
  label?: string;
  onChange: (o: any) => void;
  value: any;
  options: any[];
  loading: any;
  className: any;
  placeholder?: any;
  size: any;
  required?: any;
  noPadding?: any;
  isCheckValid?: any;
  getOptionValue: any;
  getOptionLabel: any;
  disabled: boolean;
  onInputChange: (i: any, c: any) => void;
  onClose?: () => void;
  defaultMenuIsOpen?: boolean;
  [x: string]: any;
}

const CustomSelect = (props: CustomSelectProps) => {
  const {
    label,
    onChange,
    value,
    options,
    loading,
    className,
    placeholder,
    size,
    required,
    noPadding,
    isCheckValid,
    getOptionValue,
    getOptionLabel,
    disabled,
    onInputChange,
    onClose,
    defaultMenuIsOpen,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const [checkValid, changeCheckValid] = useState(false);
  const [typingTimeout, setTypingTimeOut] = useState<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  const handleOnBlur = () => {
    changeCheckValid(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isCheckValid) changeCheckValid(true);
  }, [isCheckValid]);

  useEffect(() => {
    if (options) {
      setFilteredOptions([...options]);
    }
  }, [options]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  let isInvalid = false;

  if (required && checkValid) {
    isInvalid = value == null || value === "";
  }

  const handleInputChange = (newVal: any, act: any) => {
    if (act.action === "input-change") {
      const callback = (data: any) => {
        setIsLoading(false);
        setFilteredOptions([...data]);
      };
      if (!newVal) {
        callback([]);
        // return
      }

      setIsLoading(true);
      const loadData = () => {
        onInputChange && onInputChange(newVal, callback);
      };
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      const newTypingTimeOut = setTimeout(loadData, 500);
      setTypingTimeOut(newTypingTimeOut);
    }
  };

  const handleKeyDown = (event: any) => {
    if (isLoading && (event?.which === 9 || event?.which === 13)) {
      event.preventDefault();
    }
  };

  const Menu = (props: any) => {
    return (
      <Fragment>
        <components.Menu
          className="async-select-menu"
          style={{ zIndex: 10 }}
          {...props}
        >
          {props.children}
        </components.Menu>
      </Fragment>
    );
  };

  const DropdownIndicator = (props: any) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span
          className={
            props.selectProps.menuIsOpen ? "menu-is-open" : "menu-is-hidden"
          }
        >
          <IconArrowDown />
        </span>
      </components.DropdownIndicator>
    );

  return (
    <div
      className={cn("async-select-control", className || "", {
        [`select-${size}`]: size,
        invalid: isInvalid,
        "no-padding": noPadding,
      })}
    >
      {label && (
        <div className={cn("label", required && "required")}>{label}</div>
      )}
      <Select
        className={cn("select")}
        placeholder={placeholder || t("common_components.input.placeholder")}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        onBlur={handleOnBlur}
        onChange={onChange}
        onMenuClose={onClose}
        onKeyDown={handleKeyDown}
        options={filteredOptions}
        isLoading={isLoading}
        isSearchable
        isClearable
        isDisabled={disabled}
        onInputChange={handleInputChange}
        {...otherProps}
        components={{
          Menu,
          ...(otherProps as any).components,
          DropdownIndicator,
        }}
        defaultMenuIsOpen={defaultMenuIsOpen}
      />
    </div>
  );
};

CustomSelect.defaultProps = {
  getOptionLabel: ({ label }: { label: any }) => label,
  getOptionValue: ({ value }: { value: any }) => value,
  disabled: false,
};

export default memo(CustomSelect);
