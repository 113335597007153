import { memo, useContext } from "react";
import { Question } from "../../types/Question";
import { formatDate } from "../../utils/common";
import { useTranslation } from "react-i18next";
import { Text } from "../../components/commons";
import { pathKeys } from "../../constants";
import { ClientAccountContext } from "../App";
import { PreviewTypes, preview } from "../Preview";

const QuestionItem = ({ question: q }: { question: Question }) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  const handleOpenVoucher = (
    voucherId: number,
    journalEntryId?: number,
    fullScreen = false
  ) => {
    if (fullScreen) {
      window.open(
        `${pathKeys.CLIENT_ACCOUNTS}/${clientAccount.id}${pathKeys.POSTS}/${voucherId}`,
        "_blank"
      );
    } else {
      preview(PreviewTypes.Voucher, voucherId);
    }
  };

  return (
    <>
      <div className="question-container" key={q.id}>
        <div className="date">{formatDate(q.created_at)}</div>
        <div className="question">Q: {q.question}</div>
        {!!q.answer_options?.length && q.answer_action === "ATTACH_FILE" && (
          <div className="answer">
            A:{" "}
            <Text
              as="a"
              onClick={(e) =>
                handleOpenVoucher(
                  q.answer_options[0].option,
                  undefined,
                  e.ctrlKey
                )
              }
            >
              File uploaded
            </Text>
          </div>
        )}
        {!!q.answer_options?.length && q.answer_action === "TYPE_ANSWER" && (
          <div className="answer">A: {q.answer_options[0].option}</div>
        )}
        {!q.answer_options?.length && q.question_status === "CLOSED" && (
          <div className="answer">A: {t("Closed without answer")}</div>
        )}
      </div>
    </>
  );
};

export default memo(QuestionItem);
