import React, { useCallback, useMemo, useRef } from "react";

export function useConstCallback<T extends CallableFunction>(
  func: T,
  deps?: React.DependencyList
): T {
  const ref = useRef<T>(func);

  useMemo(() => {
    ref.current = func;
  }, deps);

  return useCallback(
    (...params: any) => ref.current(...params),
    []
  ) as unknown as T;
}
