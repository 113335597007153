import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EmptyState } from '../../components/commons'
import { pathKeys } from '../../constants'
import './styles.scss'

const useCustomTranslation = () => {
  const { t } = useTranslation()
  return {
    unauthorizedTitle: t('forbidden.unauthorized_title'),
    unauthorizedMessage: t('forbidden.unauthorized_message'),
    unauthorizedLinkText: t('forbidden.unauthorized_link_text')
  }
}

const Forbidden = () => {
  const translator = useCustomTranslation()
  const navigate = useNavigate()
  const handleBackToHome = () => {
    navigate(pathKeys.LOGIN, { replace: true })
  }
  const content = () => <>
    <div>
      {translator.unauthorizedMessage}
    </div>
    <div className="link-to-home">
      <a
        href="#top"
        onClick={handleBackToHome}
      >{translator.unauthorizedLinkText}</a> {' to return home.'}
    </div>
  </>

  return (
    <span className="forbidden-page page-content">
      <EmptyState
        title={translator.unauthorizedTitle}
        text={content}
      />
    </span>
  )
}

export default Forbidden
