import { useQuery, useMutation } from 'react-query';

import { authenticatedApiClient, throwErrorByStatus } from './apiClients';

export function useGetChatChannels(params, noLoading = false) {
  // const method = this.Methods.GET
  // const endpoint = '/chat/channels/'

  return useQuery(['getChatChannels', params],
    async () => {
      const res = await authenticatedApiClient.get(`/chat/channels/`, { params });
      throwErrorByStatus(res);
      return res.data.channels;
    }, { enabled: !!params, meta: { ignoreLoadingIndicator: noLoading } });
}

export function useGetChatMessages(channel_id, expanded = 1) {
  // const method = this.Methods.GET
  // const endpoint = '/chat/messages/'

  return useQuery(['getChatMessages', channel_id],
    async () => {
      const res = await authenticatedApiClient.get(`/chat/messages/`, { params: { channel_id, expanded } });
      throwErrorByStatus(res);
      return res.data;
    }, { enabled: !!channel_id, meta: { ignoreLoadingIndicator: true } });
}

export function useCreateChatMessage() {
  // const method = this.Methods.POST
  // const endpoint = '/chat/messages/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/chat/messages/`, data);
    throwErrorByStatus(res);
    return res.data;
  }, { meta: { ignoreLoadingIndicator: true } });
}

export function useReadChatMessage() {
  // const method = this.Methods.PUT
  // const endpoint = '/chat/messages-stat/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.put(`/chat/messages-stat/`, data);
    throwErrorByStatus(res);
    return res.data;
  }, { meta: { ignoreLoadingIndicator: true } });
}

export function useGetChatUsers(client_account_id, search, expanded = 1) {
  // const method = this.Methods.GET
  // const endpoint = '/chat/user/'

  return useQuery(['getChatUsers', client_account_id, search, expanded],
    async () => {
      const res = await authenticatedApiClient.get(`/chat/user/`, { params: { client_account_id, search, expanded } });
      throwErrorByStatus(res);
      return res.data;
    });
}

export function useUpdateChatUser() {
  // const method = this.Methods.PUT
  // const endpoint = `/chat/channels/${channelId}`

  return useMutation(async ({ channelId, ...data }) => {
    const res = await authenticatedApiClient.put(`/chat/channels/${channelId}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateChatChannel() {
  // const method = this.Methods.POST
  // const endpoint = '/chat/channels/'

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/chat/channels/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useDeleteChatChannel() {
  // const method = this.Methods.DELETE
  // const endpoint = `/chat/channels/${id}`

  return useMutation(async (id) => {
    const res = await authenticatedApiClient.put(`/chat/channels/${id}`);
    throwErrorByStatus(res);
    return res.data;
  });
}
