const cardTransactionRegex =
  /.+ (\*\d+) \d{2}.\d{2} (USD|NOK|EUR|GBP) (\d+.\d+) (.+) Kurs: (\d+.\d+)/;

export const getBankTransactionAmount = (bankTransaction) => {
  if (!bankTransaction) return;
  const matches =
    getBankTransactionDescription(bankTransaction).match(cardTransactionRegex);
  if (matches?.length === 6) return matches[3];

  return bankTransaction.amount;
};

export const getBankTransactionCurrencyCode = (bankTransaction) => {
  if (!bankTransaction) return;
  const matches =
    getBankTransactionDescription(bankTransaction).match(cardTransactionRegex);
  if (matches?.length === 6) return matches[2];

  return bankTransaction.currency_code;
};

export const getBankTransactionExchangeRate = (bankTransaction) => {
  if (!bankTransaction) return;
  const matches =
    getBankTransactionDescription(bankTransaction).match(cardTransactionRegex);
  if (matches?.length === 6) return matches[5];

  return bankTransaction.exchange_rate;
};

export const getBankTransactionDescription = (bankTransaction) => {
  const singleDetails =
    bankTransaction.details?.length === 1 ? bankTransaction.details[0] : null;
  const singleReceivable =
    bankTransaction.receivable_payments?.length === 1
      ? bankTransaction.receivable_payments[0]
      : null;

  return (
    bankTransaction.memo ||
    singleReceivable?.description ||
    singleDetails?.description ||
    singleDetails?.bank_information ||
    (bankTransaction.amount > 0
      ? singleDetails?.sender_name
      : singleDetails?.receiver_name) ||
    "Bank transaction w/o description"
  );
};

export const getJournalEntryAmount = (s) => {
  let amount = 0;
  let amount_fc = 0;
  const journalEntryLine = (s.journal_entry_lines || []).find(
    (i) => i.id === s.suggested_journal_entry_line_id
  );

  if (journalEntryLine) {
    amount =
      journalEntryLine.debit -
      (s.already_paid_debit || 0) -
      (journalEntryLine.credit - (s.already_paid_credit || 0));
    amount_fc =
      journalEntryLine.debit_fc -
      (s.already_paid_debit_fc || 0) -
      (journalEntryLine.credit_fc - (s.already_paid_credit_fc || 0));
  }

  return [journalEntryLine, +amount, amount_fc ? +amount_fc : 0];
};
