import { cloneDeep } from "lodash";
import {
  PaymentAccountEntry,
  PaymentDraft,
  PaymentTransfer,
} from "../../types/Payments";
import moment from "moment";

export interface PaymentDraftReducerAction {
  type:
    | "set"
    | "addTransferByIndex"
    | "removeTransferByIndex"
    | "setTransferRequestedTransferDate"
    | "setTransferDebtorAccount"
    | "setTransferCreditorBusinessPartner"
    | "setTransferCreditorAccount"
    | "setTransferAmount"
    | "setTransferCurrencyCode"
    | "setTransferCreditorReference"
    | "setTransferAccount"
    | "setTransferTaxCode"
    | "setTransferDimensions"
    | "setTransferMemo";

  transferIndex?: number;
  value?: any;
}

export const PaymentDraftReducer = (
  state: PaymentDraft,
  action: PaymentDraftReducerAction
) => {
  const newState = cloneDeep(state);
  const ensureAccountEntries = (transferIndex: number) => {
    if (!newState.transfers[transferIndex].account_entries) {
      newState.transfers[transferIndex].account_entries = [
        {} as PaymentAccountEntry,
      ];
    }
  };

  switch (action.type) {
    case "set":
      return action.value;
    case "addTransferByIndex":
      const transfer = action.value || ({} as PaymentTransfer);
      if (!action.transferIndex) {
        newState.transfers.push(transfer);
      } else {
        newState.transfers.splice(action.transferIndex! + 1, 0, transfer);
      }
      return newState;
    case "removeTransferByIndex":
      newState.transfers.splice(action.transferIndex!, 1);
      return newState;
    case "setTransferRequestedTransferDate":
      newState.transfers[action.transferIndex!].requested_transfer_date =
        action.value ? moment(action.value).format("YYYY-MM-DD") : null;
      return newState;
    case "setTransferDebtorAccount":
      newState.transfers[action.transferIndex!].debtor = {
        bank_account_id: action.value?.id,
      };
      return newState;
    case "setTransferCreditorAccount":
      newState.transfers[action.transferIndex!].creditor = {
        bank_account_id: action.value?.id,
      };
      return newState;
    case "setTransferAmount":
      ensureAccountEntries(action.transferIndex!);
      newState.transfers[action.transferIndex!].amount = action.value;
      if (action.value > 0)
        newState.transfers[action.transferIndex!].account_entries![0].debit =
          action.value;
      else
        newState.transfers[action.transferIndex!].account_entries![0].credit =
          -action.value;
      newState.transfers[
        action.transferIndex!
      ].account_entries![0].currency_code =
        newState.transfers[action.transferIndex!].currency_code;
      return newState;
    case "setTransferCurrencyCode":
      ensureAccountEntries(action.transferIndex!);
      newState.transfers[action.transferIndex!].currency_code = action.value;
      newState.transfers[
        action.transferIndex!
      ].account_entries![0].currency_code = action.value;
      return newState;
    case "setTransferCreditorReference":
      newState.transfers[action.transferIndex!].creditor_reference =
        action.value;
      return newState;
    case "setTransferAccount":
      ensureAccountEntries(action.transferIndex!);
      newState.transfers[
        action.transferIndex!
      ].account_entries![0].account_code = action.value?.account?.account_code;
      newState.transfers[action.transferIndex!].account_entries![0].dimensions =
        action.value?.dimension ? [action.value?.dimension] : [];
      return newState;
    case "setTransferTaxCode":
      ensureAccountEntries(action.transferIndex!);
      newState.transfers[
        action.transferIndex!
      ].account_entries![0].tax_code_id = action.value?.id;
      return newState;
    case "setTransferDimensions":
      ensureAccountEntries(action.transferIndex!);
      newState.transfers[action.transferIndex!].account_entries![0].dimensions =
        action.value;
      return newState;
    case "setTransferMemo":
      ensureAccountEntries(action.transferIndex!);
      newState.transfers[action.transferIndex!].account_entries![0].memo =
        action.value;
      return newState;
    default:
      break;
  }

  return state;
};
