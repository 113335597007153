import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import {
  authenticatedApiClient,
  throwErrorByStatus,
} from "../../../api/apiClients";
import BankAccountSelect from "../../../components/BankAccountSelect/BankAccountSelect";
import BusinessPartnerSelect from "../../../components/BusinessPartnerSelect/BusinessPartnerSelect";
import { Button, DatePicker, Input } from "../../../components/commons";
import { BOOTSTRAP_VARIANTS, ObjectTypes } from "../../../constants";
import PaymentMeans from "../../../constants/paymentMeans";
import { BankAccount } from "../../../types/BankAccount";
import { BusinessPartner } from "../../../types/BusinessPartner";
import { Document as DocumentV1 } from "../../../types/Document";
import { Document, DocumentReference } from "../../../types/DocumentV2";
import { Organization } from "../../../types/Organization";
import { showErrorNotification } from "../../../utils/toastr";
import { ClientAccountContext } from "../../App";

import CreateBankAccountModal from "./CreateBankAccountModal";
import CreateSupplierModal from "./CreateSupplierModal";
import CurrencyField from "./CurrencyField";
import DocumentNumberField from "./DocumentNumberField";
import PaymentMeansField from "./PaymentMeansField";
import PaymentReferenceField from "./PaymentReferenceField";
import "./style.scss";

import DebtCollectionSeverityField from "./DebtCollectionSeverityField";
import { DocumentReferenceItem } from "./DocumentReferenceItem";

const DocumentForm = ({
  value,
  onChange,
  documentType,
  disabled,
  tabIndex,
}: {
  value?: Document;
  onChange: (res?: Document) => void;
  documentType: string;
  disabled: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Context state
  const clientAccount = useContext(ClientAccountContext);

  // Data state
  const [businessPartner, setBusinessPartner] = useState<BusinessPartner>();
  const [payor, setPayor] = useState<BusinessPartner>();
  const [collector, setCollector] = useState<BusinessPartner>();
  const [collectionSeverity, setCollectionSeverity] = useState<string>("");
  const [collectionCaseNumber, setCollectionCaseNumber] = useState<string>("");
  const [documentDate, setDocumentDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [paymentMeans, setPaymentMeans] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  const [paymentReference, setPaymentReference] = useState("");
  const [grossAmount, setGrossAmount] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [lateFeeAmount, setLateFeeAmount] = useState("");
  const [interestAmount, setInterestAmount] = useState("");
  const [currencyCode, setCurrencyCode] = useState(
    clientAccount.accounting_currency
  );
  const [documentReferences, setDocumentReferences] = useState([
    {},
  ] as DocumentReference[]);

  // Helper state
  const [isCreatingBankAccount, setIsCreatingBankAccount] = useState(false);
  const [showCreateBankAccountModal, setShowCreateBankAccountModal] =
    useState(false);
  const [isCreatingBusinessPartner, setIsCreatingBusinessPartner] =
    useState(false);
  const [showCreateBusinessPartnerModal, setShowCreateBusinessPartnerModal] =
    useState(false);
  const [
    createBusinessPartnerModalReturnType,
    setCreateBusinessPartnerModalReturnType,
  ] = useState<"payor" | "collector" | "businessPartner">("businessPartner");
  const [createBusinessPartnerModalData, setCreateBusinessPartnerModalData] =
    useState<BusinessPartner>();

  // Mutations
  const businessPartnerMutation = useMutation(
    (bp: BusinessPartner) => {
      return authenticatedApiClient
        .post("business-partners", bp)
        .then((res: any) => res.data as BusinessPartner);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("business-partners");
      },
    }
  );
  const bankAccountMutation = useMutation(
    async (ba: BankAccount) => {
      const res = await authenticatedApiClient.post("bank-accounts", ba);
      throwErrorByStatus(res);
      return res.data as BankAccount;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bank-accounts");
      },
    }
  );

  const isInvoiceDocument =
    documentType === ObjectTypes.AP_INVOICE ||
    documentType === ObjectTypes.AR_INVOICE;
  const isReceipt = documentType === ObjectTypes.AP_RECEIPT;
  const isDebtCollection =
    documentType === ObjectTypes.AR_DEBT_COLLECTION ||
    documentType === ObjectTypes.AP_DEBT_COLLECTION;
  const isSalesDocument =
    documentType === ObjectTypes.AR_INVOICE ||
    documentType === ObjectTypes.AR_CREDIT_NOTE ||
    documentType === ObjectTypes.AR_DEBT_COLLECTION;
  const isPurchaseDocument =
    documentType === ObjectTypes.AP_RECEIPT ||
    documentType === ObjectTypes.AP_INVOICE ||
    documentType === ObjectTypes.AP_CREDIT_NOTE ||
    documentType === ObjectTypes.AP_DEBT_COLLECTION;

  // Limitation for document dates
  const minDate = new Date("2020-01-01").getTime(); // from: 01.01.2019
  const maxDate = Date.now() + 1000 * 60 * 60 * 24 * 365; // to:   ms * sec * min * hour * days

  const onSaveClicked = () => {
    const document: DocumentV1 = {
      ...value,
      id: value?.id,
      client_account_id: clientAccount?.id,
      file_id: value?.voucher_id,
      document_type: documentType,
      business_partner: businessPartner,
      business_partner_id: businessPartner?.id,
      payor,
      payor_id: payor?.id,
      collector,
      collector_id: collector?.id,
      collection_severity: collectionSeverity,
      collection_case_number: collectionCaseNumber,
      document_date: documentDate && moment(documentDate).format("YYYY-MM-DD"),
      due_date: dueDate && moment(dueDate).format("YYYY-MM-DD"),
      document_number: documentNumber,
      payment_means: paymentMeans,
      payment_card_number: cardNumber,
      bank_account: bankAccount,
      bank_account_id: bankAccount?.id,
      payment_ref: paymentReference,
      currency_code: currencyCode || "",
      gross_total: +grossAmount,
      net_amount: +netAmount,
      late_fee_amount: +lateFeeAmount,
      interest_amount: +interestAmount,
      document_references: documentReferences?.filter(
        (r) => r.relation_id || r.referenced_document_number
      ),
    };

    onChange(document);
  };

  const onCancelClicked = () => {
    onChange();
  };

  useEffect(() => {
    if (documentDate && !dueDate) setDueDate(documentDate);
  }, [documentDate]);

  useEffect(() => {
    if (!value) return;

    setBusinessPartner(value?.business_partner);
    setPayor(value?.payor);
    setCollector(value?.collector);

    setDocumentDate(value?.document_date || "");
    setDueDate(value?.due_date || "");

    setDocumentNumber(value?.document_number);

    setCollectionSeverity(value?.collection_severity || "");
    setCollectionCaseNumber(value?.collection_case_number || "");

    const pym =
      value?.payment_means ||
      (value?.bank_account_id ? PaymentMeans.BANK_TRANSFER : "");
    setPaymentMeans(pym);
    setCardNumber(value?.payment_card_number || "");
    setBankAccount(value?.bank_account);
    setPaymentReference(value?.payment_ref);

    setGrossAmount(value?.gross_amount?.toString() || "");
    setNetAmount(value?.net_amount?.toString() || "");
    setLateFeeAmount(value?.late_fee_amount?.toString() || "");
    setInterestAmount(value?.interest_amount?.toString() || "");
    setCurrencyCode(value?.currency_code || clientAccount.accounting_currency);
    setDocumentReferences(
      (value?.document_references || []).concat([{} as DocumentReference])
    );
  }, [value]);

  const handleOrganizationSelected = (
    org: Organization,
    returnType: "payor" | "collector" | "businessPartner"
  ) => {
    if (org) {
      setCreateBusinessPartnerModalData({
        ...org,
        client_account_id: clientAccount?.id,
        is_organization: true,
        organization_id: org.id,
        zip: org.postal,
      });
      setCreateBusinessPartnerModalReturnType(returnType);
      setShowCreateBusinessPartnerModal(true);
    }
  };

  const toDate = (val: string) => {
    if (!val || val.length !== 10) return;

    return moment(val).toDate();
  };

  const handleCreateBusinessPartner = async (bp: BusinessPartner) => {
    const data = { ...bp };
    data.client_account_id = clientAccount?.id;
    setIsCreatingBusinessPartner(true);
    try {
      const res = (await businessPartnerMutation.mutateAsync(data)) as any;
      setBusinessPartner(res);
      setShowCreateBusinessPartnerModal(false);
    } catch (error: any) {
      showErrorNotification(error.message);
    } finally {
      setIsCreatingBusinessPartner(false);
    }
  };

  const handleCreateCollector = async (bp: BusinessPartner) => {
    const data = { ...bp };
    data.client_account_id = clientAccount?.id;
    setIsCreatingBusinessPartner(true);
    try {
      const res = (await businessPartnerMutation.mutateAsync(data)) as any;
      setCollector(res);
      setShowCreateBusinessPartnerModal(false);
    } catch (error: any) {
      showErrorNotification(error.message);
    } finally {
      setIsCreatingBusinessPartner(false);
    }
  };

  const handleCreateBankAccount = async (ba: BankAccount) => {
    const data = { ...ba };
    data.client_account_id = clientAccount?.id;
    if (!!collector?.id) {
      data.business_partner_id = collector.id;
    } else if (ObjectTypes.AP_INVOICE === documentType && businessPartner?.id) {
      data.business_partner_id = businessPartner.id;
    }
    setIsCreatingBankAccount(true);
    try {
      const res = await bankAccountMutation.mutateAsync(data);
      setBankAccount(res);
      setShowCreateBankAccountModal(false);
      return true;
    } catch {
      return false;
    } finally {
      setIsCreatingBankAccount(false);
    }
  };
  return (
    <>
      <CreateSupplierModal
        show={showCreateBusinessPartnerModal}
        loading={isCreatingBusinessPartner}
        onSave={(bp: BusinessPartner) =>
          createBusinessPartnerModalReturnType == "businessPartner"
            ? handleCreateBusinessPartner(bp)
            : handleCreateCollector(bp)
        }
        onHide={() => setShowCreateBusinessPartnerModal(false)}
        data={createBusinessPartnerModalData}
      />
      <CreateBankAccountModal
        show={showCreateBankAccountModal}
        loading={isCreatingBankAccount}
        onSave={handleCreateBankAccount}
        onHide={() => setShowCreateBankAccountModal(false)}
        documentType={documentType}
      />
      {isDebtCollection && (
        <DebtCollectionSeverityField
          value={collectionSeverity}
          onChange={setCollectionSeverity}
          disabled={disabled}
          tabIndex={(tabIndex || 0) + 1}
        />
      )}
      {(isSalesDocument || isPurchaseDocument) && (
        <>
          <BusinessPartnerSelect
            label={isSalesDocument ? "Customer" : "Supplier"}
            value={businessPartner}
            onChange={setBusinessPartner}
            onOrganizationSelected={(org: Organization) =>
              handleOrganizationSelected(org, "businessPartner")
            }
            disabled={disabled}
            required
            onAddButtonClicked={() => {
              setCreateBusinessPartnerModalReturnType("businessPartner");
              setShowCreateBusinessPartnerModal(true);
            }}
            tabIndex={(tabIndex || 0) + 2}
            documentType={documentType}
          />
          {isDebtCollection && (
            <BusinessPartnerSelect
              label={t("Collector")}
              value={collector}
              onChange={setCollector}
              onOrganizationSelected={(org: Organization) =>
                handleOrganizationSelected(org, "collector")
              }
              disabled={disabled}
              required
              onAddButtonClicked={() => {
                setCreateBusinessPartnerModalReturnType("collector");
                setShowCreateBusinessPartnerModal(true);
              }}
              tabIndex={(tabIndex || 0) + 3}
              documentType={documentType}
            />
          )}
        </>
      )}
      <DatePicker
        label={t("post_detail.date")}
        selectedDate={toDate(documentDate)}
        onChange={(d) => setDocumentDate(moment(d).format("YYYY-MM-DD"))}
        minDate={minDate}
        maxDate={maxDate}
        required
        disabled={disabled}
        tabIndex={(tabIndex || 0) + 4}
      />
      {isPurchaseDocument && isReceipt && (
        <BusinessPartnerSelect
          label={"Payor"}
          value={payor}
          onChange={setPayor}
          onlyPeople
          isPayor
          disabled={disabled}
          onAddButtonClicked={() => setShowCreateBusinessPartnerModal(true)}
          tabIndex={(tabIndex || 0) + 5}
          documentType={documentType}
        />
      )}

      {(isInvoiceDocument || isDebtCollection) && (
        <DatePicker
          label={t("post_detail.due_date")}
          selectedDate={toDate(dueDate)}
          onChange={(d) => setDueDate(moment(d).format("YYYY-MM-DD"))}
          required
          disabled={disabled}
          tabIndex={(tabIndex || 0) + 6}
        />
      )}
      {(isSalesDocument || isPurchaseDocument) && (
        <>
          <DocumentNumberField
            documentType={documentType}
            voucherId={value?.voucher_id}
            value={documentNumber}
            onChange={setDocumentNumber}
            disabled={disabled}
            businessPartnerId={businessPartner?.id}
            tabIndex={(tabIndex || 0) + 7}
          />
          {(isInvoiceDocument || isReceipt || isDebtCollection) && (
            <PaymentMeansField
              value={paymentMeans}
              onChange={setPaymentMeans}
              disabled={disabled}
              tabIndex={(tabIndex || 0) + 8}
            />
          )}
          {paymentMeans == PaymentMeans.CARD && (
            <Input
              label={t("post_detail.card_number")}
              size="md"
              value={cardNumber}
              onChange={setCardNumber}
              disabled={disabled}
              tabIndex={(tabIndex || 0) + 9}
            />
          )}
          {paymentMeans == PaymentMeans.BANK_TRANSFER && (
            <BankAccountSelect
              businessPartnerId={
                !!collector
                  ? collector.id
                  : isPurchaseDocument
                  ? businessPartner?.id
                  : undefined
              }
              label={t("post_detail.bank_account")}
              value={bankAccount}
              onChange={setBankAccount}
              onAddButtonClicked={() => setShowCreateBankAccountModal(true)}
              disabled={disabled}
              tabIndex={(tabIndex || 0) + 10}
            />
          )}
          {(isInvoiceDocument || isDebtCollection) && (
            <PaymentReferenceField
              value={paymentReference}
              documentType={documentType}
              voucherId={value?.voucher_id}
              onChange={setPaymentReference}
              businessPartnerId={businessPartner?.id}
              disabled={disabled}
              tabIndex={(tabIndex || 0) + 11}
            />
          )}
          {!isDebtCollection && (
            <CurrencyField
              label={t("post_detail.net_amount")}
              currencyCode={currencyCode}
              value={netAmount}
              onChange={setNetAmount}
              disabled={disabled}
              accountingCurrencyCode={clientAccount?.accounting_currency}
              rateDate={documentDate}
              tabIndex={(tabIndex || 0) + 12}
            />
          )}
          <CurrencyField
            label={t("post_detail.gross_amount")}
            currencyCode={currencyCode}
            onCurrencyCodeChange={setCurrencyCode}
            value={grossAmount}
            onChange={setGrossAmount}
            disabled={disabled}
            accountingCurrencyCode={clientAccount?.accounting_currency}
            rateDate={documentDate}
            tabIndex={(tabIndex || 0) + 13}
          />
          {isDebtCollection && (
            <>
              <CurrencyField
                label={t("post_detail.late_fee_amount")}
                currencyCode={currencyCode}
                onCurrencyCodeChange={setCurrencyCode}
                value={lateFeeAmount}
                onChange={setLateFeeAmount}
                disabled={disabled}
                accountingCurrencyCode={clientAccount?.accounting_currency}
                rateDate={documentDate}
                tabIndex={(tabIndex || 0) + 14}
              />
              <CurrencyField
                label={t("post_detail.interest_amount")}
                currencyCode={currencyCode}
                onCurrencyCodeChange={setCurrencyCode}
                value={interestAmount}
                onChange={setInterestAmount}
                disabled={disabled}
                accountingCurrencyCode={clientAccount?.accounting_currency}
                rateDate={documentDate}
                tabIndex={(tabIndex || 0) + 15}
              />
              <br />
              <br />
              {documentReferences.map((reference, index) => (
                <DocumentReferenceItem
                  key={index}
                  value={reference}
                  onChange={(value) => {
                    setDocumentReferences([
                      ...documentReferences.slice(0, index),
                      value,
                      ...documentReferences.slice(index + 1),
                      ...(!documentReferences[index + 1] // add empty reference if current is last
                        ? [{} as DocumentReference]
                        : []),
                    ]);
                  }}
                  document={value}
                  itemIndex={index}
                  disabled={disabled}
                  tabIndex={(tabIndex || 0) + 16 + index * 2}
                />
              ))}
            </>
          )}
          {!disabled && (
            <div className="function-buttons">
              <Button
                text={t("Save")}
                onClick={onSaveClicked}
                variant={BOOTSTRAP_VARIANTS.PRIMARY}
              ></Button>
              <Button
                text={t("Cancel")}
                onClick={onCancelClicked}
                variant={BOOTSTRAP_VARIANTS.LIGHT}
              ></Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DocumentForm;
