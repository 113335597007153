import { memo, useEffect, useState } from "react";
import { DatePicker, Input } from "../commons";
import { JournalEntry } from "../../types/JournalEntryV2";
import { JournalEntryReducerAction } from "./JournalEntryFunctions";
import { useTranslation } from "react-i18next";

interface JournalEntryTableHeaderProps {
  journalEntry: JournalEntry;
  journalEntryDispatch: React.Dispatch<JournalEntryReducerAction>;
  defaultDate?: string;
  onDefaultDateChange?: (date: string) => void;
  disabled?: boolean;
  tabIndex?: number;
}

const JournalEntryTableHeader = ({
  journalEntry,
  journalEntryDispatch,
  defaultDate,
  onDefaultDateChange,
  disabled,
  tabIndex,
}: JournalEntryTableHeaderProps) => {
  const { t } = useTranslation();

  // Data state
  const defaultPostingDate =
    defaultDate || journalEntry?.lines?.map((l) => l.posting_date)[0] || "";

  return (
    <>
      <div className="post-detail-list-header">
        <div>{t("post_detail.date")}</div>
        <div>{t("post_detail.line_account")}</div>
        <div>{t("post_detail.line_debit")}</div>
        <div>{t("post_detail.line_credit")}</div>
        <div>{t("post_detail.line_tax_code")}</div>
        <div>{t("post_detail.line_description")}</div>
      </div>

      <div className="post-detail-list-header">
        <div>
          <DatePicker
            selectedDate={
              defaultPostingDate ? new Date(defaultPostingDate) : new Date()
            }
            onChange={(dt) => onDefaultDateChange && onDefaultDateChange(dt)}
            required
            disabled={disabled}
            tabIndex={tabIndex || 30}
          />
        </div>

        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <Input
            size="md"
            value={journalEntry?.description || ""}
            debounceTime={1000}
            onChange={(e: string) =>
              journalEntryDispatch({ type: "setDescription", payload: e })
            }
            disabled={disabled}
            tabIndex={(tabIndex || 30) + 1}
          />
        </div>
      </div>
    </>
  );
};

export default memo(JournalEntryTableHeader);
