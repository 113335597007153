import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import {
  Button,
  Select,
  Input,
  Loading,
  Radio,
} from "../../../components/commons";
import { IconClear } from "../../../assests/icons";

import { BOOTSTRAP_VARIANTS } from "../../../constants/common";

import "./style.scss";

const countiesOptions = [
  { value: "Oslo", label: "Oslo" },
  { value: "Rogaland", label: "Rogaland" },
  { value: "Møre og Romsdal", label: "Møre og Romsdal" },
  { value: "Nordland", label: "Nordland" },
  { value: "Viken", label: "Viken" },
  { value: "Innlandet", label: "Innlandet" },
  { value: "Vestfold og Telemark", label: "Vestfold og Telemark" },
  { value: "Agder", label: "Agder" },
  { value: "Vestland", label: "Vestland" },
  { value: "Trøndelag", label: "Trøndelag" },
  { value: "Troms og Finnmark", label: "Troms og Finnmark" },
];

const CreateSupplierModal = ({ onHide, onSave, loading, show, data }) => {
  const { t } = useTranslation();
  const initialData = {
    name: "",
    address: "",
    organization_id: null,
    organization_number: "",
    is_organization: true,
    email: "",
    zip: "",
    city: "",
    state: "",
    country: "NO",
  };

  const [isSupplierFormSubmited, setIsSupplierFormSubmited] = useState(false);
  const [selectedCountry, changeSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  const [supplierData, changeSupplierData] = useState(initialData);

  useEffect(() => {
    if (data) {
      changeSupplierData(data);
    }
  }, [data]);

  useEffect(() => {
    const c = t("app.country_list");

    const countries = Object.keys(c).map((i) => {
      return {
        value: i,
        label: c[i],
      };
    });
    const selectedCountry = countries.find(
      (el) => el.value === supplierData.country
    );
    changeSelectedCountry(selectedCountry);
    setCountries(countries);
  }, []);

  const handleChangeSupplierData = (filed, value) =>
    changeSupplierData({ ...supplierData, [filed]: value });

  const handleCreateSupplier = () => {
    setIsSupplierFormSubmited(true);

    if (!supplierData.name) {
      return;
    }

    const body = {
      ...supplierData,
      organization_id: supplierData.id || supplierData.organization_id || null,
      country: selectedCountry.value,
    };

    if (!body.is_organization) {
      delete body.organization_id;
      delete body.organization_number;
    }

    onSave(body);
    changeSupplierData(initialData);
  };

  return (
    <div className={cn("create-supplier-modal", { show })}>
      <Loading loading={loading} childLoading />
      <span className="clear-icon" role="button" onClick={onHide}>
        <IconClear />
      </span>
      <div className="title">{t("create_supplier_modal.title")}</div>
      <div className="content">
        <div className="label">{t("create_supplier_modal.type")}</div>
        <div>
          <Radio
            checked={!supplierData.is_organization}
            label={t("create_supplier_modal.person_type")}
            onClick={() => handleChangeSupplierData("is_organization", false)}
          />
          <Radio
            checked={supplierData.is_organization}
            label={t("create_supplier_modal.organization_type")}
            onClick={() => handleChangeSupplierData("is_organization", true)}
          />
        </div>
        <Input
          size="md"
          label={t("create_supplier_modal.name")}
          required
          isCheckValid={isSupplierFormSubmited}
          value={supplierData.name || ""}
          onChange={(e) => handleChangeSupplierData("name", e)}
        />
        {supplierData.is_organization && (
          <Input
            size="md"
            label={t("create_supplier_modal.organization_number")}
            isCheckValid={isSupplierFormSubmited}
            value={supplierData.organization_number || ""}
            onChange={(e) => handleChangeSupplierData("organization_number", e)}
          />
        )}
        <Input
          size="md"
          label={t("create_supplier_modal.email")}
          isCheckValid={isSupplierFormSubmited}
          value={supplierData.email || ""}
          onChange={(e) => handleChangeSupplierData("email", e)}
        />
        <Input
          size="md"
          label={t("create_supplier_modal.address")}
          isCheckValid={isSupplierFormSubmited}
          value={supplierData.address || ""}
          onChange={(e) => handleChangeSupplierData("address", e)}
        />
        <Input
          size="md"
          label="ZIP"
          isCheckValid={isSupplierFormSubmited}
          value={supplierData.zip || ""}
          onChange={(e) => handleChangeSupplierData("zip", e)}
        />
        <Input
          label={t("create_supplier_modal.city")}
          size="md"
          noPadding
          isCheckValid={isSupplierFormSubmited}
          autocompleteItems={countiesOptions.map((i) => i.label)}
          value={supplierData.city || ""}
          onChange={(e) => handleChangeSupplierData("city", e)}
        />
        <Input
          label={t("create_supplier_modal.state")}
          size="md"
          noPadding
          value={supplierData.state || ""}
          onChange={(e) => handleChangeSupplierData("state", e)}
        />
        <Select
          label={t("create_supplier_modal.country")}
          size="md"
          noPadding
          required
          options={countries}
          value={selectedCountry || "NO"}
          onChange={changeSelectedCountry}
        />
      </div>
      <div className="actions">
        <Button
          className="send-button"
          size="md"
          variant={BOOTSTRAP_VARIANTS.PRIMARY}
          text={t("create_supplier_modal.add_button")}
          onClick={handleCreateSupplier}
        />
      </div>
    </div>
  );
};

export default CreateSupplierModal;
