import classNames from "classnames";
import { last, nth } from "lodash";
import moment from "moment";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../components/commons";
import JournalEntryDetailsPopup from "../../components/commons/JournalEntryDetailsPopup";
import { BankTransaction } from "../../types/BankTransactionV2";
import { JournalEntryLine } from "../../types/JournalEntryV2";
import { currencyFormat } from "../../utils/common";
import BankTransactionListItem from "./BankTransactionListItem";
import JournalEntryLineListItem from "./JournalEntryLineListItem";
import TransactionsLoader from "./transactionsLoader";

const TransactionDayList = ({
  transactions,
  isLoading,
  selectedBankTransactions,
  selectedJournalEntryLines,
  onBankTransactionToggle,
  onJournalEntryLineToggle,
  onJournalEntryLineChange,
  onViewingLastBankTransaction,
  onViewingLastJournalEntryLine,
}: {
  transactions: {
    [k: string]: {
      bankTransactions: BankTransaction[];
      journalEntryLines: JournalEntryLine[];
      balances: any;
    };
  };
  isLoading: boolean;
  selectedBankTransactions: BankTransaction[];
  selectedJournalEntryLines: JournalEntryLine[];
  onBankTransactionToggle: (
    t: BankTransaction | BankTransaction[],
    isMultiSelection: boolean
  ) => void;
  onJournalEntryLineToggle: (t: JournalEntryLine | JournalEntryLine[]) => void;
  onJournalEntryLineChange: (jel: JournalEntryLine) => void;
  onViewingLastBankTransaction: () => void;
  onViewingLastJournalEntryLine: () => void;
}) => {
  const { t } = useTranslation();

  // Refs
  const bankTransactionListEndRef = useRef<HTMLDivElement>(null);
  const journalEntryLineListEndRef = useRef<HTMLDivElement>(null);

  // Queries
  const [
    journalEntryLineDetailsPopupEntry,
    setJournalEntryLineDetailsPopupEntry,
  ] = useState<JournalEntryLine | null>();

  // Functionality
  const lastBankTransaction = nth(
    Object.keys(transactions || {}).flatMap(
      (d) => transactions[d].bankTransactions || []
    ),
    -25
  );

  const lastJournalEntryLine = nth(
    Object.keys(transactions || {}).flatMap(
      (d) => transactions[d].journalEntryLines || []
    ),
    -25
  );

  const isReconciledBankTransactionSelected = selectedBankTransactions?.some(
    (t) => t.external_reconciled_amount
  );

  // Event handlers
  const handleObserver = useCallback((entries: any) => {
    entries.forEach((e: any) => {
      if (e.isIntersecting) {
        if (e.target === bankTransactionListEndRef.current)
          onViewingLastBankTransaction && onViewingLastBankTransaction();
        if (e.target === journalEntryLineListEndRef.current)
          onViewingLastJournalEntryLine && onViewingLastJournalEntryLine();
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: "0px 0px 0px 0px",
      threshold: 0,
    });
    if (bankTransactionListEndRef.current)
      observer.observe(bankTransactionListEndRef.current);
    if (journalEntryLineListEndRef.current)
      observer.observe(journalEntryLineListEndRef.current);
  }, [transactions]);

  const handleCloseJournalEntryDetailsPopup = (isChanges: boolean) => {
    if (isChanges && journalEntryLineDetailsPopupEntry)
      onJournalEntryLineChange(journalEntryLineDetailsPopupEntry);

    setJournalEntryLineDetailsPopupEntry(null);
  };

  const handleDayHeaderClick = (day: string) => {
    onBankTransactionToggle(
      transactions[day].bankTransactions?.filter(
        (t) => !t.external_reconciled_amount
      ),
      true
    );
    onJournalEntryLineToggle(
      transactions[day].journalEntryLines?.filter(
        (t) => !t.external_reconciled_amount
      )
    );
  };

  return (
    <>
      {journalEntryLineDetailsPopupEntry?.journal_entry?.id && (
        <JournalEntryDetailsPopup
          journalEntryId={journalEntryLineDetailsPopupEntry.journal_entry.id}
          show={!!journalEntryLineDetailsPopupEntry}
          onHide={handleCloseJournalEntryDetailsPopup}
        />
      )}
      <div className={classNames("summary")}>
        <div>{t("reconcile.bank_transactions")}</div>
        <div>{t("reconcile.posted_transactions")}</div>
      </div>
      {isLoading && (
        <>
          <TransactionsLoader />
          <TransactionsLoader />
        </>
      )}
      {!transactions && (
        <div className="no-data">{t("reconcile.there_is_no_data")}</div>
      )}
      {transactions &&
        Object.keys(transactions).map((date) => (
          <div key={date}>
            <div className="transactions-header">
              <span style={{ display: "none" }}>
                {transactions[date]["balances"] &&
                  currencyFormat(
                    transactions[date]["balances"].starting_balance -
                      transactions[date]["balances"].ending_balance,
                    transactions[date]["balances"].currency
                  )}
              </span>
              <Text
                onClick={(e) => {
                  if (e.ctrlKey) handleDayHeaderClick(date);
                }}
              >
                {moment(date).format("DD MMMM YYYY")}
              </Text>
              <span style={{ display: "none" }}>
                {transactions[date]["balances"] &&
                  currencyFormat(
                    transactions[date]["balances"].starting_balance_local -
                      transactions[date]["balances"].ending_balance_local,
                    transactions[date]["balances"].currency
                  )}
              </span>
            </div>
            <div className="transactions-balance">
              <div>
                <div>{t("reconcile.start_of_day_balance")}</div>
                <div>
                  {transactions[date]["balances"] &&
                    currencyFormat(
                      transactions[date]["balances"].starting_balance,
                      transactions[date]["balances"].currency
                    )}
                </div>
              </div>
              <div>
                <div>{t("reconcile.start_of_day_balance")}</div>
                <div>
                  {transactions[date]["balances"] &&
                    currencyFormat(
                      transactions[date]["balances"].starting_balance_local,
                      transactions[date]["balances"].currency
                    )}
                </div>
              </div>
            </div>
            <div className="transactions-details">
              <div>
                {transactions[date]["bankTransactions"].map(
                  (bankTransaction) => (
                    <React.Fragment
                      key={`bankTransactions-${bankTransaction.id}`}
                    >
                      <BankTransactionListItem
                        bankTransaction={bankTransaction}
                        selected={
                          !!selectedBankTransactions?.find(
                            (t) => t.id === bankTransaction.id
                          )
                        }
                        onClick={(ev) => {
                          onBankTransactionToggle(
                            bankTransaction,
                            !bankTransaction.external_reconciled_amount &&
                              !isReconciledBankTransactionSelected &&
                              ev.ctrlKey
                          );
                        }}
                      />
                      {bankTransaction === lastBankTransaction && (
                        <div ref={bankTransactionListEndRef}></div>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
              <div>
                {transactions[date]["journalEntryLines"].map((jel) => (
                  <React.Fragment key={`journalEntryLines-${jel.id}`}>
                    <JournalEntryLineListItem
                      journalEntryLine={jel}
                      selected={
                        !!selectedJournalEntryLines?.find(
                          (l) => l.id === jel.id
                        )
                      }
                      onClick={(ev) => {
                        if (!jel.external_reconciled_amount)
                          onJournalEntryLineToggle(jel);
                      }}
                      onDetailsClick={() =>
                        setJournalEntryLineDetailsPopupEntry(jel)
                      }
                    />
                    {jel === lastJournalEntryLine && (
                      <div ref={journalEntryLineListEndRef}></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default memo(TransactionDayList);
