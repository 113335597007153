import cn from "classnames";
import PropTypes from "prop-types";
import { useIsFetching, useIsMutating } from "react-query";

import { loadingGrey } from "../../../assests/images";

import "./style.scss";

const Loading = ({ msg, className, loading, childLoading }) => {
  const customOverlayClass = cn(
    "webim-loading",
    { "child-loading": childLoading },
    [className]
  );

  return loading ? (
    <div className={customOverlayClass}>
      <img src={loadingGrey} alt="loading" />
      {msg && <span>{msg}</span>}
    </div>
  ) : null;
};

const { string, bool } = PropTypes;

Loading.propTypes = {
  msg: string,
  className: string,
  loading: bool,
  childLoading: bool,
};

Loading.defaultProps = {
  childLoading: false,
};

export default Loading;

export const LoadingSpinnerAuto = () => {
  const isFetching = useIsFetching({
    predicate: (mutation) => {
      return !mutation.meta || !mutation.meta.ignoreLoadingIndicator;
    },
  });
  const isMutating = useIsMutating({
    predicate: (mutation) => {
      return !mutation.meta || !mutation.meta.ignoreLoadingIndicator;
    },
  });

  return <Loading loading={isFetching > 0 || isMutating > 0} />;
};
