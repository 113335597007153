import { Dropdown } from "react-bootstrap";
import { useLogout } from "../../../../api";
import HeaderProfile from "./HeaderProfile";
import { BsPersonCircle } from "react-icons/bs";
import {
  useCurrentClientAccount,
  useUserData,
} from "../../../../dataHooks/user";
import { cookie } from "../../../../utils";
import { pathKeys } from "../../../../constants";
import { useQueryClient } from "react-query";
import { ClientAccount } from "../../../../types/ClientAccount";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDirectClientAccounts } from "../Hooks/useDirectClientAccounts";

export const AccountsDropdown: React.FC = () => {
  const { t } = useTranslation();
  const { mutateAsync: logout } = useLogout();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const user = useUserData();
  const accounts = useDirectClientAccounts();
  const currentClientAccount = useCurrentClientAccount();

  const handleAccountChange = (account: ClientAccount) => {
    navigate(`/clients/${account.id}/overview`);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={HeaderProfile}>
        <div className="profile-name-img-wrap">
          <BsPersonCircle className="profile-image" />
          <span className="name">{`${user?.first_name || ""} ${
            user?.last_name || ""
          }`}</span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {accounts.map((account) => (
          <Dropdown.Item
            eventKey={account.id}
            key={account.id}
            active={
              currentClientAccount &&
              (account.id === currentClientAccount.id ||
                currentClientAccount.contract?.provider_client_account_id ===
                  account.id)
            }
            onClick={() => {
              handleAccountChange(account);
            }}
          >
            {account.display_name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            logout().then(() => {
              cookie.deleteToken();
              queryClient.invalidateQueries();
              window.location.href = pathKeys.LOGIN;
            });
          }}
        >
          {t("header.menu.logout")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
