import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Input } from "../../commons";
import { IconSearch, IconAdd } from "../../../assests/icons";
import Channel from "./Channel";

import "./style.scss";

const ChannelList = (props) => {
  const {
    channels,
    // pages,
    activeChannel,
    handleChannelSelect,
    addNew,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="channel-list">
      <div className="header">
        <div>{t("messages.message_threads")}</div>
        <div className="new-thread" role="button" onClick={addNew}>
          <IconAdd />
        </div>
      </div>
      <div className="search-bar">
        <Input
          size="md"
          placeholder={t(
            "common_components.input.placeholder_search_by_keyword"
          )}
          onChange={() => {}}
        />
        <span>
          <IconSearch />
        </span>
      </div>
      <div className="items">
        {channels.map((channel, i) => (
          <Channel
            key={i}
            channel={channel}
            active={activeChannel?.channel_id === channel.channel_id}
            onChannelSelection={() => handleChannelSelect(channel)}
          />
        ))}
      </div>
    </div>
  );
};

ChannelList.propTypes = {
  channels: PropTypes.array,
  activeChannel: PropTypes.object,
  handleChannelSelect: PropTypes.func,
  addNew: PropTypes.func,
};

ChannelList.defaultProps = {
  channels: [],
  activeChannel: {},
  handleChannelSelect: () => {},
  addNew: () => {},
};

export default ChannelList;
