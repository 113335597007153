import { useMutation, useQuery } from "react-query";
import { Voucher } from "../types/Voucher";
import {
  authenticatedApiClient,
  authenticatedApiV2Client,
  throwErrorByStatus,
} from "./apiClients";

export function useGetVoucher(id?: number, _with: string[] = []) {
  return useQuery(
    ["getVoucher", id],
    async () => {
      const res = await authenticatedApiV2Client.get(`/vouchers/${id}`, {
        params: { with: _with.join(",") },
      });
      throwErrorByStatus(res);
      return res.data as Voucher;
    },
    { enabled: !!id }
  );
}

export function useDeleteVoucher() {
  return useMutation(async (id: number) => {
    const res = await authenticatedApiClient.delete(`/vouchers/${id}`);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useUploadDocuments() {
  return useMutation(async ({ accountId, files }:{accountId:number,files:File[]}) => {
    const body = new FormData();
    for (let index = 0; index < (files || []).length; index++) {
      body.append("file", files[index]);
    }

    body.append("client_account_id", `${accountId}`);

    const res = await authenticatedApiClient.post(`/files/`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetVoucherCategories() {
  return useQuery(["getVoucherCategories"], async () => {
    const res = await authenticatedApiClient.get(`/voucher-categories`);
    throwErrorByStatus(res);
    return res.data;
  });
}
