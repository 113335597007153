import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { ARCHIVED_DOCUMENT_PERIODS } from "../../../constants";
import { Select } from "../../../components/commons";

const ArchivedDocumentPeriodField = ({
  archivedDocumentPeriod,
  setArchivedDocumentPeriod,
  disabled,
  tabIndex,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      size="md"
      label={t("post_detail.archived_document_period")}
      options={ARCHIVED_DOCUMENT_PERIODS.toArray()}
      onChange={(o) => setArchivedDocumentPeriod(o.value)}
      value={ARCHIVED_DOCUMENT_PERIODS.toArray().find(
        (p) => p.value === archivedDocumentPeriod
      )}
      getOptionLabel={({ label }) => t(label)}
      getOptionValue={({ value }) => value}
      required
      isClearable={false}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

export default ArchivedDocumentPeriodField;
