import { Spinner } from "react-bootstrap";

export const FullScreenLoader = () => {
  return (
    <div
      className="w-100 h-100 d-flex justify-content-center align-items-center"
      style={{
        transform: "scale(3)",
      }}
    >
      <Spinner animation="grow" />
    </div>
  );
};
