import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { localeFormatDate } from "../../../utils/common";

import "./style.scss";

const Channel = ({ channel, active, onChannelSelection }) => {
  const { t } = useTranslation();
  const newMessagesCount = channel.messages_amount;
  return (
    <div
      role="button"
      className={cn("channel-item", {
        active,
        "with-new-messages": !!newMessagesCount,
      })}
      onClick={() => onChannelSelection(channel)}
    >
      <div className="info">
        <div className="thread-name">
          {channel.channel_name ||
            `${t("messages.thread")} #${channel.channel_id}`}
        </div>
        {newMessagesCount ? (
          <div className="counter">{channel.messages_amount}</div>
        ) : (
          <div />
        )}
        <div className="date">
          {channel.last_message_time
            ? localeFormatDate(channel.last_message_time, "DD MMM")
            : "-"}
        </div>
      </div>
      <div className="last-message">{channel.last_message}</div>
    </div>
  );
};

Channel.propTypes = {
  channel: PropTypes.object,
  active: PropTypes.bool,
  onChannelSelection: PropTypes.func,
};

Channel.defaultProps = {
  channel: {},
  active: false,
  onChannelSelection: () => {},
};

export default Channel;
