import axios, { AxiosResponse } from "axios";
import { BadRequestError } from "../errors/BadRequestError";
import { ForbiddenError } from "../errors/ForbiddenError";
import { HTTPError } from "../errors/HTTPError";
import { UnauthorizedError } from "../errors/UnauthorizedError";
import { cookie } from "../utils";
import { pathKeys } from "../constants";
import { showErrorNotification } from "../utils/toastr";
import FormData from "form-data";

export const publicApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  headers: {},
});

export const authenticatedApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  headers: {},
});

export const authenticatedApiV2Client = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT?.replace("/v1", "/v2"),
  headers: {},
});

export const throwErrorByStatus = (response: AxiosResponse<any>) => {
  const errorMessage =
    response?.data?.message ||
    response?.data?.error_message ||
    response?.statusText;

  if (response?.status === 500) {
    showErrorNotification(errorMessage || "Internal Server Error");
    throw new HTTPError(errorMessage || "Internal Server Error");
  }

  if (response?.status === 400) {
    showErrorNotification(errorMessage);
    throw new BadRequestError(errorMessage);
  }
  if (response?.status === 401) {
    if (window.location.pathname != pathKeys.LOGIN) {
      window.location.href = pathKeys.LOGIN;
    }

    cookie.deleteToken();
    showErrorNotification(errorMessage);
    throw new UnauthorizedError(errorMessage);
  }
  if (response?.status === 403) {
    showErrorNotification(errorMessage);
    throw new ForbiddenError(errorMessage);
  }
  if (response?.status > 399) {
    showErrorNotification(errorMessage);
    throw new HTTPError(errorMessage);
  }
};

authenticatedApiClient.interceptors.request.use(async (config) => {
  if (config && config.headers && !config.headers?.Authorization) {
    const token = await cookie.getToken();
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (!token) {
      return config;
    }
    if (!config?.url?.includes("refresh-token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

authenticatedApiV2Client.interceptors.request.use(async (config) => {
  if (config && config.headers) {
    const token = await cookie.getToken();
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (!token) {
      return config;
    }
    if (!config?.url?.includes("refresh-token")) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

publicApiClient.interceptors.response.use(
  (response) => response,
  (error) => error.response
);

authenticatedApiClient.interceptors.response.use(
  (response) => response,
  (error) => error.response
);

authenticatedApiV2Client.interceptors.response.use(
  (response) => response,
  (error) => error.response
);
