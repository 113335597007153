import React from 'react'

import ContentLoader from 'react-content-loader'

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height="300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="20" rx="5" ry="5" width="150" height="9" />
    <rect x="220" y="20" rx="5" ry="5" width="150" height="9" />

    <rect x="0" y="50" rx="5" ry="5" width="100%" height="250" />
  </ContentLoader>
)

export default Loader
