import React, { ReactNode, forwardRef } from "react";

import { IconArrowDown } from "../../../../assests/icons";

interface HeaderProfileProps {
  children?: ReactNode;
  onClick?: () => void;
}

const HeaderProfile = forwardRef<HTMLDivElement, HeaderProfileProps>(
  ({ children, onClick }, ref) => {
    return (
      <div className="profile" role="button" ref={ref} onClick={onClick}>
        {children}
        <IconArrowDown />
      </div>
    );
  }
);

export default HeaderProfile;
