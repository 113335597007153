const root = "";

const base = {
  LOGIN: root + "/login",
  SIGNUP: root + "/signup",
  VERIFY_IDENTITY: root + "/verify-identity",
  FORGOT_PASSWORD: root + "/forgot-password",
  RESET_PASSWORD: root + "/reset-password/:token",
  ADMIN: root + "/admin",
  CLIENT_ACCOUNTS: root + "/clients",
};

const appendix = {
  MESSAGES: "/messages",
  REPORTS: "/reports",
  DOCUMENTS: "/documents",
  VOUCHERS: "/vouchers",
  POSTS: "/posts",
  OVERVIEW: "/overview",
  GENERAL_LEDGER: "/general-ledger",
  RECONCILIATION: "/reconcile",
  BANK_RECONCILIATION: "/reconcile/bank",
  INTERNAL_RECONCILIATION: "/reconcile/internal",
  VAT: "/vat",
  JOURNAL_ENTRIES: "/journal-entries",
  INCOME_STATEMENT: "/income-statement",
  VALUE_ADDED_TAX_RETURN_REPORT: "/value-added-tax-return-report",
  BALANCE_STATEMENT: "/balance-sheet",
  ACCOUNTS_RECEIVABLE: "/accounts-receivable",
  TRIAL_BALANCE: "/trial-balance",
  ACCOUNTS_PAYABLE: "/accounts-payable",
  DATA_EXPORT: "/data-export",
  NO_ACTIVE_CONTRACTS: "/no-active-contracts",
  NOT_FOUND: "/not-found",
  FORBIDDEN: "/forbidden",
  ALL_CONTRACTS: "/all",
};

const routes = { ...appendix, ...base };

export default routes;
