import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { useQuery } from "react-query";

import { BOOTSTRAP_VARIANTS } from "../../constants";
import { Button, Select } from "../commons";
import { BankAccount } from "../../types/BankAccount";
import { Pages } from "../../types/Pages";
import { authenticatedApiClient } from "../../api/apiClients";
import { ClientAccountContext } from "../../views/App";

import "./style.scss";

const BankAccountSelect = ({
  businessPartnerId,
  label,
  onChange,
  onAddButtonClicked,
  disabled,
  tabIndex,
  value,
}: {
  businessPartnerId?: number;
  label?: string;
  onChange: (ba: BankAccount) => void;
  onAddButtonClicked?: () => void;
  disabled?: boolean;
  tabIndex?: number;
  value?: BankAccount;
}) => {
  const { t } = useTranslation();

  const clientAccount = useContext(ClientAccountContext);

  const bankAccountsQuery = useQuery(
    ["bank-accounts", businessPartnerId],
    () => {
      return authenticatedApiClient
        .get("/bank-accounts", {
          params: {
            client_account_id: clientAccount.id,
            business_partner_id: businessPartnerId,
            per_page: 10,
          },
        })
        .then(
          (res) => res.data as { bank_accounts: BankAccount[]; pages: Pages }
        );
    }
  );

  const BankAccountsMenu = (props: any) => (
    <components.Menu {...props}>
      {props.children}
      <div className="add-new-bank-account">
        <Button
          size="sm"
          variant={BOOTSTRAP_VARIANTS.SECONDARY}
          text={t("post_detail.add_bank_account")}
          onClick={() => {
            console.log("add new bank account", onAddButtonClicked);
            onAddButtonClicked && onAddButtonClicked();
          }}
        />
      </div>
    </components.Menu>
  );

  const Option = ({ label, value, options, ...props }: any) => {
    const opt = options.find((o: any) => o.id == value);

    return (
      <components.Option {...props}>
        <div>{label}</div>
        <div style={{ color: "#7c7c7c" }}>{opt?.bban}</div>
      </components.Option>
    );
  };

  return (
    <Select
      size="md"
      label={label}
      value={value}
      options={bankAccountsQuery.data?.bank_accounts}
      loading={bankAccountsQuery.isLoading}
      className="bank-account"
      components={{
        Option,
        ...(onAddButtonClicked && { Menu: BankAccountsMenu }),
      }}
      onChange={onChange}
      getOptionLabel={({ name, bban }: any) =>
        !!businessPartnerId ? bban : name
      }
      getOptionValue={({ id }: any) => id}
      disabled={disabled}
      tabIndex={tabIndex}
      placeholder={undefined}
      required={undefined}
      noPadding={undefined}
      isCheckValid={undefined}
    />
  );
};

export default BankAccountSelect;
