import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import './style.scss'

const DefaultLogo = ({ title, size, background, className, withTooltip }) => {
  const renderTooltip = useMemo(
    (props) => (
      <Tooltip {...props} className="custom-tooltip">
        <div className="popover-text" dangerouslySetInnerHTML={{ __html: title }} />
      </Tooltip>
    ),
    [withTooltip]
  )
  const logoNode =
    <div className={cn('default-logo', `default-logo--${size}`, className)} style={{ backgroundColor: background }}>
      {title?.charAt(0).toUpperCase()}
    </div>

  return withTooltip ? (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {logoNode}
    </OverlayTrigger>
  ) : (
    logoNode
  )
}

DefaultLogo.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  background: PropTypes.string,
  className: PropTypes.string,
  withTooltip: PropTypes.bool,
}

DefaultLogo.defaultProps = {
  title: '',
  size: 'md',
  background: 'rgb(255, 136, 134, 0.8)',
  className: '',
  withTooltip: false,
}

export default DefaultLogo
