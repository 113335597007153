import React, { memo } from "react";
import { useQuery } from "react-query";
import { authenticatedApiClient } from "../../api/apiClients";
import { Pages } from "../../types/Pages";
import { TaxCode } from "../../types/TaxCode";
import { detectLanguage } from "../../utils/common";
import { Select } from "../commons";

interface TaxCodeSelectProps {
  taxCode?: TaxCode | null;
  onTaxCodeChange: (tc: TaxCode) => void;
  taxCategory?: string;

  disabled?: boolean;
  tabIndex?: number;
}

const TaxCodeSelect = ({
  taxCode,
  onTaxCodeChange,
  taxCategory,
  disabled,
  tabIndex,
}: TaxCodeSelectProps) => {
  // Queries
  const taxCodesQuery = useQuery(
    "tax-codes",
    async () => {
      const res = await authenticatedApiClient.get("/tax-codes/", {
        params: { lang: detectLanguage() },
      });
      return res.data as { tax_codes: TaxCode[]; pages: Pages };
    },
    { staleTime: Infinity }
  );

  const taxCodeOptions = ["input", "output"]
    .filter((c) => !taxCategory || c === taxCategory)
    .map((c) => ({
      label: c,
      options: taxCodesQuery.data?.tax_codes.filter((tc) => tc.category === c),
    }));

  const getTaxCodeOptionLabel = (tax: TaxCode) => {
    const lang = detectLanguage();
    const description =
      lang === "nn" || lang === "nb"
        ? tax.description_nob
        : tax.description_eng;
    return `${tax.code} ${description} (${tax.tax_rate || 0}%)`;
  };

  return (
    <Select
      size="md"
      className="tax-codes"
      options={taxCodeOptions}
      loading={taxCodesQuery.isLoading}
      onChange={onTaxCodeChange}
      value={taxCode || null}
      getOptionLabel={getTaxCodeOptionLabel}
      getOptionValue={({ id }: TaxCode) => id}
      isCheckValid
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

export default memo(TaxCodeSelect);
