import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, LinkButton } from "../commons";
import { common, pathKeys } from "../../constants";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Form as BootstrapForm } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { cookie } from "../../utils";
import {
  authenticatedApiClient,
  throwErrorByStatus,
} from "../../api/apiClients";
import { b64EncodeUnicode } from "../../api";
import { ClientAccountWithRole } from "../../types/User";
import { Contract } from "../../types/Contract";
import {
  IconEmail,
  IconEye,
  IconEyeHidden,
  IconPassLock,
} from "../../assests/icons";
import { downloadAppleStore, downloadGooglePlay } from "../../assests/images";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(8),
});

interface LoginResponse {
  token: string;
  user: {
    accounts: ClientAccountWithRole[];
    contracts: Contract[];
  };
}

const LoginForm: React.FC<{ isPopUp?: boolean }> = ({ isPopUp = false }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showPass, setShowPass] = useState(false);
  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const login = async (values: LoginFormValues) => {
    const header = {
      Authorization: `Basic ${b64EncodeUnicode(
        `${values.email}:${values.password}`
      )}`,
    };
    const res = await authenticatedApiClient.get("/auth/login", {
      headers: header,
    });
    throwErrorByStatus(res);

    return res.data;
  };

  const redirectHome = (user: LoginResponse["user"]) => {
    navigate(
      `${pathKeys.CLIENT_ACCOUNTS}/${user.accounts[0].account.id}${pathKeys.OVERVIEW}`,
      { replace: true }
    );
  };

  const handleSubmit = async (values: LoginFormValues) => {
    if (checkIsMobile()) {
      setIsMobile(true);
      return;
    }
    const apiValues = {
      email: values.email,
      password: values.password,
    };
    try {
      const data = await login(apiValues);
      if (!data?.user?.accounts?.length) {
        toast.error(t("login_page.no_client_account"));
        return;
      }
      queryClient.setQueryData(["User"], data.user);
      cookie.setToken(data.token);
      redirectHome(data.user);
    } catch (error: any) {
      console.log(error);
    }
  };

  const checkIsMobile = () => {
    const viewportWidth: number = window.innerWidth;
    return viewportWidth < 768;
  };

  const loginContainer = isPopUp ? "login-form shouldCenter" : "login-form";

  return (
    <div className={loginContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <BootstrapForm>
              {!isMobile ? (
                <>
                  <div className="title">{t("login_page.title")}</div>
                  <div className="field">
                    <BootstrapForm.Label htmlFor="email">
                      {t("login_page.email_label")}
                    </BootstrapForm.Label>
                    <div className="d-flex position-relative">
                      <BootstrapForm.Control
                        name="email"
                        type="email"
                        className={touched.email && errors.email ? "error" : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div
                        role="button"
                        className="icon-space position-absolute"
                      >
                        <IconEmail />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3" />
                  <div className="field">
                    <BootstrapForm.Label htmlFor="password">
                      {t("login_page.password_label")}
                    </BootstrapForm.Label>
                    <div className="d-flex position-relative">
                      <BootstrapForm.Control
                        name="password"
                        type={showPass ? "text" : "password"}
                        className={`${showPass ? "customInputPadding" : ""} ${
                          touched.password && errors.password ? "error" : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div
                        role="button"
                        className="position-absolute end-0 mt-3 me-3"
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? <IconEye /> : <IconEyeHidden />}
                      </div>
                      <div
                        role="button"
                        className="icon-space position-absolute ml-4"
                      >
                        <IconPassLock />
                      </div>
                    </div>
                  </div>
                  <div className="forgote-password forgotten-text">
                    <LinkButton
                      onClick={() => navigate(pathKeys.FORGOT_PASSWORD)}
                      text={t("login_page.forgot_password_link")}
                    />
                  </div>
                  <div className="mb-4" />
                  <div className="text-center">
                    <Button
                      loading={isSubmitting}
                      disabled={Object.keys(errors).length > 0}
                      className="login-button"
                      size="lg"
                      onClick={handleSubmit}
                      variant={common.BOOTSTRAP_VARIANTS.PRIMARY}
                      text={t("login_page.login_button")}
                      type="submit"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-center">📱 Exciting News!</h2>
                  <p className="text-left mt-3">
                    Download Our App for a Better Experience! Hey, We just
                    noticed you're on a mobile device, and we want to provide
                    you with the best experience. Our website isn't fully
                    responsive on phones yet.
                    <h6 className="text-center mt-3">
                      You can enjoy seamless browsing by using our mobile app.
                      🚀
                    </h6>
                  </p>
                  <div className="d-flex justify-content-center mt-3">
                    <a
                      href="https://apps.apple.com/in/app/snapbooks-digital-accountant/id1574509527"
                      className="me-2"
                    >
                      <img
                        src={downloadAppleStore}
                        alt="download apple store"
                        width="150"
                        height="50"
                      />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.snapbooks">
                      <img
                        src={downloadGooglePlay}
                        alt="download google play"
                        width="150"
                        height="50"
                      />
                    </a>
                  </div>
                </>
              )}
            </BootstrapForm>
          );
        }}
      </Formik>
      <div className="mb-2" />
      {!isMobile ? (
        <div className="d-flex justify-content-center forgot-password">
          <LinkButton
            onClick={() => navigate(pathKeys.SIGNUP)}
            text={t("login_page.dont_have_account")}
            rightText={t("login_page.create_one")}
          />
        </div>
      ) : null}
    </div>
  );
};

export default LoginForm;
