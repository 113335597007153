import { memo } from "react";
import { useTranslation } from "react-i18next";
import { BankTransaction } from "../../types/BankTransactionV2";
import QuestionItem from "./QuestionItem";
import StatementItem from "./StatementItem";
import BankTransactionListItem from "./BankTransactionListItem";

const SinglePaymentDetails = ({
  bankTransaction,
}: {
  bankTransaction: BankTransaction;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="suggestions-title">{t("Transaction details")}</div>

      <BankTransactionListItem
        bankTransaction={bankTransaction}
        selected={false}
        onClick={() => {}}
      />

      {!!bankTransaction.questions?.length && (
        <>
          <div className="suggestions-title">{t("Questions & Answers")}</div>
          <div>
            {bankTransaction.questions
              .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
              .map((q) => (
                <QuestionItem key={q.id} question={q} />
              ))}
          </div>
        </>
      )}

      {!!bankTransaction.statements?.length && (
        <>
          <div className="suggestions-title">{t("Statement")}</div>
          <div>
            {bankTransaction.statements
              .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
              .map((s) => (
                <StatementItem key={s.id} statement={s} />
              ))}
          </div>
        </>
      )}
    </>
  );
};
export default memo(SinglePaymentDetails);
