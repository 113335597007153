import React, { useEffect, memo } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import { Header } from "../../components/commons";
import { pathKeys } from "../../constants";
import { ClientAccountContext } from "../App";
import { useConstCallback } from "../../utils/useConstCallback";

import {
  AccountsPayableReport,
  AccountsReceivableReport,
  BalanceSheetReport,
  GeneralLedgerReport,
  IncomeStatementReport,
  JournalEntriesReport,
  Messages,
  Overview,
  Post,
  PostDetail,
  InternalReconciliation,
  StubPage,
  TrialBalanceReport,
  VatReport,
  NotFound,
  Forbidden,
  DataExportReport,
} from "../index";

import "./style.scss";
import BankReconciliation from "../BankReconciliation";
import { preview } from "../Preview";
import { useCurrentClientAccount } from "../../dataHooks/user";

const ClientContainerWithPreview = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentContractClientAccount = useCurrentClientAccount();

  const currentClientAccount = useCurrentClientAccount();
  const isCustomer = currentClientAccount?.amICustomer;

  const { clientId } = useParams<{ clientId: string }>();

  useEffect(() => {
    preview(null, null);
  }, [location]);

  useEffect(() => {
    window.addEventListener("message", eventListener);
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  const eventListener = useConstCallback(
    (ev: MessageEvent<{ type: string; message: string }>) => {}
  );

  if (!currentContractClientAccount) {
    return <NotFound />;
  }

  return (
    <ClientAccountContext.Provider value={currentContractClientAccount}>
      <Header />
      <div
        className={cn("client-container-page", "container-page", {
          expanded: true,
        })}
      >
        <div className="left-panel">
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={`${pathKeys.CLIENT_ACCOUNTS}/${currentContractClientAccount.id}${pathKeys.OVERVIEW}`}
                />
              }
            />
            <Route path="/forbidden" element={<Forbidden />} />
            <Route path={`${pathKeys.OVERVIEW}`} element={<Overview />} />

            {/* Documents for customers - Vouchers for accountants - posts get redirected based on the user type */}
            <Route
              path={`${isCustomer ? pathKeys.DOCUMENTS : pathKeys.VOUCHERS}`}
              element={<Post />}
            />
            <Route
              path={`${pathKeys.POSTS}`}
              element={
                <Navigate
                  replace
                  to={location.pathname.replace(
                    pathKeys.POSTS,
                    isCustomer ? pathKeys.DOCUMENTS : pathKeys.VOUCHERS
                  )}
                />
              }
            />
            <Route
              path={`${pathKeys.POSTS}/:id`}
              element={
                <Navigate
                  replace
                  to={location.pathname.replace(
                    pathKeys.POSTS,
                    isCustomer ? pathKeys.DOCUMENTS : pathKeys.VOUCHERS
                  )}
                />
              }
            />
            <Route
              path={`${
                isCustomer ? pathKeys.DOCUMENTS : pathKeys.VOUCHERS
              }/:id`}
              element={<PostDetail />}
            />

            <Route path={`${pathKeys.MESSAGES}`} element={<Messages />} />
            <Route
              path={`${pathKeys.BANK_RECONCILIATION}`}
              element={<BankReconciliation />}
            />
            <Route
              path={`${pathKeys.INTERNAL_RECONCILIATION}`}
              element={<InternalReconciliation />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.INCOME_STATEMENT}`}
              element={<IncomeStatementReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.GENERAL_LEDGER}`}
              element={<GeneralLedgerReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.VAT}`}
              element={<VatReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.JOURNAL_ENTRIES}`}
              element={<JournalEntriesReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.ACCOUNTS_RECEIVABLE}`}
              element={<AccountsReceivableReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.ACCOUNTS_PAYABLE}`}
              element={<AccountsPayableReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.BALANCE_STATEMENT}`}
              element={<BalanceSheetReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.TRIAL_BALANCE}`}
              element={<TrialBalanceReport />}
            />
            <Route
              path={`${pathKeys.REPORTS}${pathKeys.DATA_EXPORT}`}
              element={<DataExportReport />}
            />

            <Route
              path={`${pathKeys.NO_ACTIVE_CONTRACTS}`}
              element={
                <StubPage
                  title={t("common_phrases.no_active_contracts")}
                  subtitle=""
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </ClientAccountContext.Provider>
  );
};

export default memo(ClientContainerWithPreview);
