import { useMutation, useQuery } from "react-query";

import { detectLanguage } from "../utils/common";

import { authenticatedApiClient, throwErrorByStatus } from "./apiClients";

export function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

export function useLogout() {
  // const method = this.Methods.POST;
  // const endpoint = "/auth/logout";

  return useMutation(async () => {
    const res = await authenticatedApiClient.post(`/auth/logout`);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useResetPassword() {
  // const method = this.Methods.POST;
  // const endpoint = "/reset-password";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/reset-password`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetCurrentUser(userId) {
  // const method = this.Methods.GET;
  // const endpoint = `/users/${userId}`;

  return useQuery(
    ["User"],
    async () => {
      const res = await authenticatedApiClient.get(`/users/${userId}`);
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!userId, retry: false }
  );
}

export function useGetUser(userId) {
  // const method = this.Methods.GET;
  // const endpoint = `/users/${userId}`;

  return useQuery(
    ["users", userId],
    async () => {
      const res = await authenticatedApiClient.get(`/users/${userId}`);
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!userId, retry: false }
  );
}



export function useGetOverview(clientAccountId) {
  // const method = this.Methods.GET;
  // const endpoint = `/client/overview?client_account_id=${clientAccountId}`;

  return useQuery(
    ["getOverview", clientAccountId],
    async () => {
      const res = await authenticatedApiClient.get(
        `/client/overview?client_account_id=${clientAccountId}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!clientAccountId }
  );
}

export function useGetCurrency(clientAccountId, loadingIndicator = true) {
  // const method = this.Methods.GET;
  // const endpoint = `/client/currency?client_account_id=${clientAccountId}`;

  return useQuery(
    ["getCurrency", clientAccountId],
    async () => {
      const res = await authenticatedApiClient.get(
        `/client/currency?client_account_id=${clientAccountId}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!clientAccountId,
      meta: { ignoreLoadingIndicator: !loadingIndicator },
    }
  );
}

export function useGetDocuments(params, expanded = 1) {
  // const method = this.Methods.GET;
  // const endpoint = `/documents/?expanded=${expanded}`;

  return useQuery(["getDocuments", params, expanded], async () => {
    const res = await authenticatedApiClient.get(
      `/documents/?expanded=${expanded}`,
      { params }
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetVouchers(params) {
  return useQuery(["getVouchers", params], async () => {
    const res = await authenticatedApiClient.get(`/vouchers`, { params });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetPreviousNextVouchers(
  currentVoucherId,
  params,
  hookParams
) {
  const result = useQuery(["getVouchers", params], async () => {
    if (hookParams?.enabled === false) return {};

    const res = await authenticatedApiClient.get(`/vouchers`, { params });
    throwErrorByStatus(res);
    return res.data;
  });

  const currentVoucherIndex = result?.data?.vouchers?.findIndex(
    (v) => v.voucher_id === currentVoucherId
  );

  return {
    previous: result?.data?.vouchers?.[currentVoucherIndex - 1],
    next: result?.data?.vouchers?.[currentVoucherIndex + 1],
  };
}

export function useGetQuestionTemplates() {
  // const method = this.Methods.GET;
  // const endpoint = "/question-templates/?expanded=1";

  return useQuery(["getQuestionTemplates"], async () => {
    const res = await authenticatedApiClient.get(
      `/question-templates/?expanded=1`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateQuestionTemplates() {
  // const method = this.Methods.POST;
  // const endpoint = "/question-templates/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/question-templates/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useUpdateQuestionTemplates() {
  // const method = this.Methods.PUT;
  // const endpoint = `/question-templates/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.put(
      `/question-templates/${id}`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useDeleteQuestionTemplates() {
  // const method = this.Methods.DELETE;
  // const endpoint = `/question-templates/${id}`;

  return useMutation(async (id) => {
    const res = await authenticatedApiClient.delete(
      `/question-templates/${id}`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateAccountingQuestions() {
  // const method = this.Methods.POST;
  // const endpoint = "/accounting-questions/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(
      `/accounting-questions/`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetAccountingQuestions(id, status, clientAccountId) {
  // const method = this.Methods.GET;
  const query = ["per_page=500"];

  if (id) {
    query.push(`document_id=${id}`);
  }
  if (clientAccountId) {
    query.push(`client_account_id=${clientAccountId}`);
  }
  if (status) {
    query.push(`status=${status}`);
  }

  // const endpoint = `/accounting-questions/?expanded=1&${query.join('&')}`;

  return useQuery(
    ["getAccountingQuestions", id, status, clientAccountId],
    async () => {
      const res = await authenticatedApiClient.get(
        `/accounting-questions/?${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    }
  );
}

export function useAnswerAccountingQuestion() {
  // const method = this.Methods.POST;
  // const endpoint = `/accounting-questions/${id}/answer/`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.post(
      `/accounting-questions/${id}/answer/`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateBusinessPartners() {
  // const method = this.Methods.POST;
  // const endpoint = "/business-partners/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/business-partners/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useEditBusinessPartners() {
  // const method = this.Methods.PUT;
  // const endpoint = `/business-partners/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.put(
      `/business-partners/${id}`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useUpdateBusinessPartner() {
  // const method = this.Methods.POST;
  // const endpoint = `/business-partners/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.post(
      `/business-partners/${id}`,
      data
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateProjects() {
  // const method = this.Methods.POST;
  // const endpoint = "/projects/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/projects/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useUpdateProject() {
  // const method = this.Methods.PUT;
  // const endpoint = `/projects/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.put(`/projects/${id}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateDepartments() {
  // const method = this.Methods.POST;
  // const endpoint = "/departments/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/departments/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useUpdateDepartment() {
  // const method = this.Methods.PUT;
  // const endpoint = `/departments/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.put(`/departments/${id}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateAssets() {
  // const method = this.Methods.POST;
  // const endpoint = "/assets/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/assets/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useUpdateAsset() {
  // const method = this.Methods.PUT;
  // const endpoint = `/assets/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.put(`/assets/${id}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetBusinessPartners(
  clientAccountId,
  description,
  perPage,
  page,
  iswithStatistics = false,
  expanded = 1,
  loadingIndicator = true
) {
  // const method = this.Methods.GET;
  const query = [];
  const _with = ["organization"];
  if (clientAccountId) {
    query.push(`client_account_id=${clientAccountId}`);
  }
  query.push(`description=${description || ""}`);
  if (expanded) {
    query.push(`expanded=${expanded}`);
  }
  if (iswithStatistics) {
    _with.push("statistics");
  }
  if (perPage) {
    query.push(`per_page=${perPage}`);
  }
  if (page) {
    query.push(`page=${page}`);
  }
  if (_with.length) {
    query.push(`with=${_with.join(",")}`);
  }

  return useQuery(
    [
      "getBusinessPartners",
      clientAccountId,
      description,
      expanded,
      perPage,
      page,
    ],
    async () => {
      const res = await authenticatedApiClient.get(
        `/business-partners/?${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: !!clientAccountId,
      meta: { ignoreLoadingIndicator: !loadingIndicator },
    }
  );
}

export function useGetOrganizations(description, perPage, expanded = 1) {
  // const method = this.Methods.GET;
  const query = [];
  query.push(`description=${description || ""}`);
  if (expanded) {
    query.push(`expanded=${expanded}`);
  }
  if (perPage) {
    query.push(`per_page=${perPage}`);
  }
  // const endpoint = `/organizations/?${query.join('&')}`;

  return useQuery(
    ["getOrganizations", description, expanded, perPage],
    async () => {
      const res = await authenticatedApiClient.get(
        `/organizations/?${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!description }
  );
}

export function useGetAccountingAccount(id, params) {
  // const method = this.Methods.GET;
  // const endpoint = `/accounting-accounts/${id}`;

  return useQuery(["getAccountingAccount", id, params], async () => {
    const res = await authenticatedApiClient.get(`/accounting-accounts/${id}`, {
      params,
    });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetAccountSuggestions(
  clientAccountId,
  businessPartnerId,
  documentType
) {
  // const method = this.Methods.GET;
  // const endpoint = `/accounting-account-suggestions/?client_account_id=${clientAccountId}&business_partner_id=${businessPartnerId}&doc_type=${documentType}`;

  return useQuery(
    ["getAccountSuggestions", clientAccountId, businessPartnerId, documentType],
    async () => {
      const res = await authenticatedApiClient.get(
        `/accounting-account-suggestions/?client_account_id=${clientAccountId}&business_partner_id=${businessPartnerId}&doc_type=${documentType}`
      );
      throwErrorByStatus(res);
      return res.data;
    }
  );
}

export function useGetJournalEntriesRaw(
  clientAccountId,
  journal_sequence_number,
  expanded = 1
) {
  // const method = this.Methods.GET;
  const query = [];

  query.push(`journal_entry=${journal_sequence_number || ""}`);
  if (expanded) {
    query.push(`expanded=${expanded}`);
  }
  if (clientAccountId) {
    query.push(`client_account_id=${clientAccountId}`);
  }

  // const endpoint = `/journal-entries/list/?${query.join('&')}`;

  return useQuery(
    [
      "getJournalEntriesRaw",
      journal_sequence_number,
      clientAccountId,
      expanded,
    ],
    async () => {
      const res = await authenticatedApiClient.get(
        `/journal-entries/list/?${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data.journal_entries;
    },
    {
      enabled: !!clientAccountId,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useGetTaxCodes(category, expanded = 1) {
  // const method = this.Methods.GET;
  // const endpoint = "/tax-codes/";

  const params = {
    expanded,
    lang: detectLanguage(),
    category,
  };

  return useQuery(["getTaxCodes", params], async () => {
    const res = await authenticatedApiClient.get(`/tax-codes/`, { params });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useReversePosts() {
  // const method = this.Methods.PATCH;
  // const endpoint = "/posts/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.patch(`/posts/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetOrganizationInfo(id) {
  // const method = this.Methods.GET;
  const query = [];

  if (id) {
    query.push(`client_account_id=${id}`);
  }

  // const endpoint = `/client/accounting_companies?expanded=1&${query.join('&')}`;

  return useQuery(["getOrganizationInfo", id], async () => {
    const res = await authenticatedApiClient.get(
      `/client/accounting_companies?expanded=1&${query.join("&")}`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetBankAccounts(
  clientId,
  businessPartnerId,
  page,
  perPage,
  description,
  expanded = 1
) {
  // const method = this.Methods.GET;
  const query = [];

  if (clientId) {
    query.push(`client_account_id=${clientId}`);
  }
  if (businessPartnerId) {
    query.push(`business_partner_id=${businessPartnerId}`);
  }

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  if (description) {
    query.push(`description=${description}`);
  }

  // const endpoint = `/bank-accounts/?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    [
      "getBankAccounts",
      clientId,
      businessPartnerId,
      expanded,
      page,
      perPage,
      description,
    ],
    async () => {
      const res = await authenticatedApiClient.get(
        `/bank-accounts/?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!clientId }
  );
}

export function useGetProjects(clientId, page, perPage, expanded = 1) {
  // const method = this.Methods.GET;
  const query = [];

  if (clientId) {
    query.push(`client_account_id=${clientId}`);
  }

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  // const endpoint = `/projects/?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    ["getProjects", clientId, expanded, page, perPage],
    async () => {
      const res = await authenticatedApiClient.get(
        `/projects/?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!clientId }
  );
}

export function useGetDepartments(clientId, page, perPage, expanded = 1) {
  // const method = this.Methods.GET;
  const query = [];

  if (clientId) {
    query.push(`client_account_id=${clientId}`);
  }

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  // const endpoint = `/departments/?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    ["getDepartments", clientId, expanded, page, perPage],
    async () => {
      const res = await authenticatedApiClient.get(
        `/departments/?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!clientId }
  );
}

export function useGetAssets(clientId, page, perPage, expanded = 1) {
  // const method = this.Methods.GET;
  const query = [];

  if (clientId) {
    query.push(`client_account_id=${clientId}`);
  }

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  // const endpoint = `/assets/?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    ["getAssets", clientId, expanded, page, perPage],
    async () => {
      const res = await authenticatedApiClient.get(
        `/assets/?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    { enabled: !!clientId }
  );
}

export function useGetIbanCalculation(
  clientId,
  bban,
  country,
  isEnable,
  bankCode,
  page,
  perPage,
  expanded = 1
) {
  // const method = this.Methods.GET;
  const query = [];

  if (clientId) {
    query.push(`client_account_id=${clientId}`);
  }
  if (bankCode) {
    query.push(`bankcode=${bankCode}`);
  }

  if (bban) {
    query.push(`account=${bban}`);
  }

  if (country) {
    query.push(`country=${country}`);
  }

  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  // const endpoint = `/bank-accounts/iban-calculation?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    [
      "getIbanCalculation",
      clientId,
      expanded,
      bankCode,
      bban,
      country,
      page,
      perPage,
    ],
    async () => {
      const res = await authenticatedApiClient.get(
        `/bank-accounts/iban-calculation?expanded=${expanded}&${query.join(
          "&"
        )}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled:
        isEnable && !!country && !!bban && !!clientId && bban.length >= 5,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useGetIbanValidation(clientId, iban, isEnable, expanded = 1) {
  // const method = this.Methods.GET;
  const query = [];

  if (clientId) {
    query.push(`client_account_id=${clientId}`);
  }

  if (iban) {
    query.push(`iban=${iban}`);
  }

  // const endpoint = `/bank-accounts/iban-validation?expanded=${expanded}&${query.join(
  //   "&"
  // )}`;

  return useQuery(
    ["getIbanValidation", clientId, iban, expanded],
    async () => {
      const res = await authenticatedApiClient.get(
        `/bank-accounts/iban-validation?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    },
    {
      enabled: isEnable && !!iban && !!clientId,
      meta: { ignoreLoadingIndicator: true },
    }
  );
}

export function useCreateBankAccounts() {
  // const method = this.Methods.POST;
  // const endpoint = "/bank-accounts/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/bank-accounts/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useEditBankAccounts() {
  // const method = this.Methods.PUT;
  // const endpoint = `/bank-accounts/${id}`;

  return useMutation(async ({ id, ...data }) => {
    const res = await authenticatedApiClient.post(`/bank-accounts/${id}`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetLabels(params) {
  // const method = this.Methods.GET;
  // const endpoint = "/labels/";

  return useQuery(["getLabels", params], async () => {
    const res = await authenticatedApiClient.get(`/labels/`, { params });
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetCategories(filter, page, perPage, expanded = 1) {
  // const method = this.Methods.GET;

  const query = [];

  if (filter) {
    query.push(`filter=${filter}`);
  }
  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  if (expanded) {
    query.push(`expanded=${expanded}`);
  }

  // const endpoint = `/categories/?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    ["getCategories", filter, expanded, page, perPage],
    async () => {
      const res = await authenticatedApiClient.get(
        `/categories/?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    }
  );
}

export function useGetCategoriesLv1(expanded = 1) {
  // const method = this.Methods.GET;
  // const endpoint = `/categories/?expanded=${expanded}`; // lv1/'

  return useQuery(["getCategoriesLv1", expanded], async () => {
    const res = await authenticatedApiClient.get(
      `/categories/?expanded=${expanded}`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetChildInvoiceCategories(parentCode) {
  // const method = this.Methods.GET;
  // const endpoint = `/invoice/categories/get_child/?parent_code=${parentCode}`;

  return useQuery(["getChildInvoiceCategories", parentCode], async () => {
    const res = await authenticatedApiClient.get(
      `/invoice/categories/get_child/?parent_code=${parentCode}`
    );
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useCreateDoc() {
  // const method = this.Methods.POST;
  // const endpoint = "/documents/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/documents/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}

export function useGetUnits(
  filter,
  version,
  common,
  page,
  perPage,
  expanded = 1
) {
  // const method = this.Methods.GET;

  const query = [];

  if (filter) {
    query.push(`filter=${filter}`);
  }
  if (version) {
    query.push(`version=${version}`);
  }
  if (common) {
    query.push(`common=${common}`);
  }
  if (page) {
    query.push(`page=${page}`);
  }

  if (perPage) {
    query.push(`per_page=${perPage}`);
  }

  // const endpoint = `/units/?expanded=${expanded}&${query.join('&')}`;

  return useQuery(
    ["getUnits", filter, version, common, (expanded = 1), page, perPage],
    async () => {
      const res = await authenticatedApiClient.get(
        `/units/?expanded=${expanded}&${query.join("&")}`
      );
      throwErrorByStatus(res);
      return res.data;
    }
  );
}

export function useGetItems(client_account_id, filter, expanded = 1) {
  // const method = this.Methods.GET;
  // const endpoint = `/items/?client_account_id=${client_account_id}&filter=${filter}&expanded=${expanded}`;

  return useQuery(
    ["getItems", client_account_id, filter, expanded],
    async () => {
      const res = await authenticatedApiClient.get(
        `/items/?client_account_id=${client_account_id}&filter=${filter}&expanded=${expanded}`
      );
      throwErrorByStatus(res);
      return res.data;
    }
  );
}

export function useAddItem() {
  // const method = this.Methods.POST;
  // const endpoint = "/items/";

  return useMutation(async (data) => {
    const res = await authenticatedApiClient.post(`/items/`, data);
    throwErrorByStatus(res);
    return res.data;
  });
}
