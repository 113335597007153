import React from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import packageJson from "../package.json";

import App from "./views/App";

let sentry_env = process.env.REACT_APP_SENTRY_ENVIRONMENT;

if (process.env.REACT_APP_VERCEL_ENV == 'preview') {
  sentry_env = 'development';
}

Sentry.init({
  dsn: "https://9b869288a7e64080bc183129dc03e631@o459671.ingest.sentry.io/5459611",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  release: `snapbook-fe@${packageJson.version}`,
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: sentry_env,
});

const FallbackComponent = (props: any) => {
  return (
    <div style={{ padding: 10 }}>
      <div style={{ fontWeight: "700" }}>
        An unexpected error occurred, please try again later
      </div>
      <div style={{ color: "#b91616", paddingTop: 5, fontSize: 12 }}>
        {props.error?.message}
      </div>
    </div>
  );
};

const Root = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Root;
