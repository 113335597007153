import React, { useState } from "react";
import moment from 'moment';
import Lightbox from "react-awesome-lightbox";

import { IconMagnifyingGlassSmall } from "../../assests/icons";

import "./style.scss";

const DocumentAttachmentItem = ({ attachment }) => {
  const [imageLoaded, changeImageLoaded] = useState(false);
  const [zoomImage, changeZoomImage] = useState(false);

  const handleImageLoaded = () => changeImageLoaded(true);

  return (
    <div
      key={attachment.id}
      className="document-attachment-item"
    >
      <div>
        <img
          alt=""
          style={{ height: "80px", width: "100px", objectFit: "cover" }}
          src={attachment.thumbnail_url}
          onLoad={handleImageLoaded}
        />
        {(imageLoaded || !attachment.thumbnail_url) && (
          <span
            className="zoom-icon"
            role="button"
            onClick={() => {
              if (attachment.content_type.toLowerCase().endsWith("pdf") || !attachment.thumbnail_url) {
                window.open(attachment.original_url, "_blank");
              } else {
                changeZoomImage(true);
              }
            }
            }
          >
            <IconMagnifyingGlassSmall />
          </span>
        )}
        {zoomImage && (
          <Lightbox
            image={attachment.download_url}
            title={attachment.file_name}
            onClose={() => changeZoomImage(false)}
          />
        )}
      </div>
      <div className="document-attachment-item-content">
        <div><b>File Name:</b> {attachment.file_name}</div>
        <div><b>Uploaded Date:</b> {moment(attachment.uploaded_date).format('lll')}</div>
      </div>
    </div>
  );
};

export default DocumentAttachmentItem;
