import React, { useState, useRef, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import { useQueryClient } from 'react-query'
import Spinner from 'react-bootstrap/Spinner'

import { isToday } from '../../../utils/common'
import { DefaultLogo } from '../../commons'
import { IconAddButton, IconSend } from '../../../assests/icons'

import './style.scss'

const MessagesList = props => {
  const {
    channel,
    messages,
    // pages,
    onMessageSend,
    onAddRecipient,
    loading
  } = props
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(['User'])

  const messagesEndRef = useRef(null)
  const [newMessage, setNewMessage] = useState('')

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto', block: 'end' })
    }
  }, [messages])

  const renderMessage = (key, message, senderName) =>  (
    <div
      key={key}
      ref={(parseInt(key, 10) + 1 === messages.length) ? messagesEndRef: null}
      className={cn('item', { unread: !message.read_flags?.read_flag })}
    >
      <DefaultLogo className="profile-image" title={senderName} size="lg" />
      <div className="content">
        <div className="username">{senderName}</div>
        <div className="message" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.message.replace(/\n/gi, '<br/>'), { ALLOWED_TAGS: ['br'] }) }} />
      </div>
      <div className="time">
        {moment(message.created_at).format(isToday(message.created_at) ? 'h:mm a' : 'D.MM')}
      </div>
    </div>
  )

  const renderMessages = () => {
    const items = []
    if (messages) {
      messages.forEach((message, i) => {
        const sender = channel?.users?.find(user => user.user_id === message.from_user)
        const senderName = sender ? `${sender.user_name} ${sender.user_last_name}` : `${user.first_name} ${user.last_name}`
        items.push(renderMessage(i, message, senderName))
      })
    }
    if (!items.length) return <div className="items centered">{t('messages.no_messages_yet')}</div>
    return <div className="items">{items}</div>
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const handleSendMessage = () => {
    if (DOMPurify.sanitize(newMessage, { ALLOWED_TAGS: [] })) {
      onMessageSend(DOMPurify.sanitize(newMessage, { ALLOWED_TAGS: [] }))
      setNewMessage('')
    }
  }

  const handleAddRecipients = () => {
    if (channel) {
      onAddRecipient()
    }
  }

  return (
    <div className="messages-list">
      <div className="header">
        <div className="header-info">
          <div className="title">{channel ? (channel.channel_name || `${t('messages.thread')} #${channel.channel_id}`) : '-'}</div>
          <div className="time">
            {channel?.last_message_time ? `${t('messages.last_message')} ${moment(channel.last_message_time).format('h:mm a, D/M/YYYY')}` : '-'}
          </div>
        </div>
        <div className="users-wrap">
          <div className="users-list">
            {channel?.users?.map(user =>
              <DefaultLogo
                key={user.user_id}
                title={`${user.user_name} ${user.user_last_name}`}
                size="sm"
                className="user-logo"
                withTooltip
              />)}
          </div>
          <div role="button" className="add-new-user" onClick={handleAddRecipients}><IconAddButton /></div>
        </div>
      </div>
      {renderMessages()}
      <div className="text-field">
        {loading && <div className="spinner-container">
          <Spinner className="spinner" animation="border" variant="secondary" size="sm" />
        </div>}
        <textarea
          value={newMessage}
          className="new-message"
          placeholder={t('messages.write_here')}
          onKeyPress={handleKeyPress}
          onChange={e => setNewMessage(e.target.value)}
        />
        <span role="button" className="send-icon" onClick={handleSendMessage}><IconSend /></span>
      </div>
    </div>
  )
}

MessagesList.propTypes = {
  channel: PropTypes.object,
  messages: PropTypes.array,
  onMessageSend: PropTypes.func,
  onAddRecipient: PropTypes.func,
}

MessagesList.defaultProps = {
  channel: {},
  messages: [],
  onMessageSend: () => {},
  onAddRecipient: () => {},
}

export default memo(MessagesList)
