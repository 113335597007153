import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useGetDimensionValues } from "../../api";
import {
  useGetGeneralLedgerAccounts,
  useGetTaxCodes,
} from "../../api/accountingApi";
import { IconClear, IconLabel, IconVioletLabel } from "../../assests/icons";
import { ObjectTypes } from "../../constants";
import getLabelAbbreviation from "../../constants/labelShortcuts";
import { ClientAccount } from "../../types/ClientAccount";
import {
  AccountDimensionCombination,
  DimensionValue,
  JournalEntryLine,
} from "../../types/JournalEntryV2";
import { ClientAccountContext } from "../../views/App";
import { CurrencyInput, DatePicker, Input, Text } from "../commons";
import LabelsSelectPopup from "../commons/LabelsSelectPopup";
import GeneralLedgerAccountSelect from "./GeneralLedgerAccountSelect";
import { JournalEntryReducerAction } from "./JournalEntryFunctions";
import TaxCodeSelect from "./TaxCodeSelect";
import { TaxCode } from "../../types/TaxCode";
import moment from "moment";

interface JournalEntryLineItemProps {
  journalEntryLine: JournalEntryLine;
  lineIndex: number;
  journalEntryDispatcher: React.Dispatch<JournalEntryReducerAction>;
  relationType: ObjectTypes;
  onLineExit?: () => void;
  disabled?: boolean;
  tabIndex?: number;
}

const JournalEntryLineItem = ({
  journalEntryDispatcher,
  journalEntryLine,
  lineIndex,
  relationType,
  onLineExit,
  disabled,
  tabIndex,
}: JournalEntryLineItemProps) => {
  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  // Helper state
  const [showLabelsSelect, setShowLabelsSelect] = useState(false);

  // Queries
  const dimensionValuesQuery = useGetDimensionValues(clientAccount);
  const taxCodesQuery = useGetTaxCodes(clientAccount?.id);
  const generalLedgerAccountsQuery = useGetGeneralLedgerAccounts(
    clientAccount?.id
  );

  const getDimensionValue = (relation_type: string, relation_id: number) => {
    return dimensionValuesQuery.data?.dimension_values?.find(
      (l: any) =>
        l?.relation_type === relation_type && l?.relation_id === relation_id
    );
  };

  const dimensionsPopoverText = useMemo(() => {
    let text = "";
    if (!journalEntryLine?.dimensions?.length) return text;

    journalEntryLine.dimensions.forEach(({ relation_type, relation_id }) => {
      if (relation_type) {
        const dim = getDimensionValue(relation_type, relation_id);
        text = text.concat(
          `<div>${getLabelAbbreviation(relation_type) || relation_type} - ${
            dim?.relation_name
          }</div>`
        );
      }
    });
    return text;
  }, [journalEntryLine?.dimensions]);

  const getAccountMandatoryDimensionCombination = (jel: JournalEntryLine) => {
    if (!jel) return;

    const account =
      jel.account ||
      generalLedgerAccountsQuery.data?.accounting_accounts.find(
        (a) => a.account_code === jel.account_code || a.id === jel.account_id
      );

    if (!account) return;

    const dimension = jel.dimensions?.find(
      (dim) =>
        dim.relation_type === account?.mandatory_dimensions?.[0]?.relation_type
    );

    return { account, dimension };
  };

  const getTaxCategory = (jel: JournalEntryLine) => {
    if (jel?.account_code >= "3000" && jel?.account_code <= "3999") {
      return "output";
    }
    if (
      (jel?.account_code >= "4000" && jel?.account_code <= "4999") ||
      (jel?.account_code >= "6000" && jel?.account_code <= "7999")
    ) {
      return "input";
    }

    if (
      [
        ObjectTypes.AP_RECEIPT,
        ObjectTypes.AP_INVOICE,
        ObjectTypes.AP_DEBT_COLLECTION,
      ].includes(relationType)
    )
      return "input";
    else if (
      [
        ObjectTypes.AR_INVOICE,
        ObjectTypes.AR_CREDIT_NOTE,
        ObjectTypes.AR_DEBT_COLLECTION,
      ].includes(relationType)
    )
      return "output";
  };

  const handlePostingDateChange = useCallback(
    (d: Date) => {
      if (moment(d).format("YYYY-MM-DD") !== journalEntryLine.posting_date)
        journalEntryDispatcher({
          type: "setPostingDate",
          payload: {
            index: lineIndex,
            postingDate: moment(d).format("YYYY-MM-DD"),
          },
        });
    },
    [lineIndex, journalEntryLine.posting_date]
  );

  const handleGeneralLedgerAccountChange = useCallback(
    (gla: any) => {
      journalEntryDispatcher({
        type: "setAccount",
        payload: {
          index: lineIndex,
          account: gla as AccountDimensionCombination,
        },
      });
    },
    [lineIndex]
  );

  const handleCurrencyCodeChange = useCallback(
    (c: string) => {
      if (c !== journalEntryLine.currency_code)
        journalEntryDispatcher({
          type: "setCurrencyCode",
          payload: { index: lineIndex, currencyCode: c },
        });
    },
    [lineIndex, journalEntryLine.currency_code]
  );

  const handleDebitChange = useCallback(
    (d: number) => {
      if (d !== (journalEntryLine.debit_fc || 0))
        journalEntryDispatcher({
          type: "setDebit",
          payload: { index: lineIndex, debit: d },
        });
    },
    [lineIndex, journalEntryLine.debit_fc]
  );

  const handleCreditChange = useCallback(
    (c: number) => {
      if (c !== (journalEntryLine.credit_fc || 0))
        journalEntryDispatcher({
          type: "setCredit",
          payload: { index: lineIndex, credit: c },
        });
    },
    [lineIndex, journalEntryLine.credit_fc]
  );

  const handleTaxCodeChange = useCallback(
    (c: TaxCode) => {
      if (c?.id !== journalEntryLine.tax_code_id)
        journalEntryDispatcher({
          type: "setTaxCode",
          payload: { index: lineIndex, taxCode: c },
        });
    },
    [lineIndex, journalEntryLine.tax_code_id]
  );

  const handleDescriptionChange = useCallback(
    (d: string) => {
      if (d !== journalEntryLine.description)
        journalEntryDispatcher({
          type: "setLineDescription",
          payload: { index: lineIndex, description: d },
        });
    },
    [lineIndex, journalEntryLine.description]
  );

  const toggleLabelsSelect = useCallback(() => {
    console.log("toggleLabelsSelect", disabled, journalEntryLine);
    if (
      !(disabled || !journalEntryLine.account_id || journalEntryLine.tax_line)
    )
      setShowLabelsSelect(!showLabelsSelect);
  }, [disabled, journalEntryLine, showLabelsSelect]);

  const hideLabelsSelect = useCallback(() => {
    setShowLabelsSelect(false);
  }, []);

  const handleDimensionsChange = useCallback(
    (d: DimensionValue[]) => {
      journalEntryDispatcher({
        type: "setDimensions",
        payload: { index: lineIndex, dimensions: d },
      });
    },
    [lineIndex]
  );

  const handleDescriptionKeyDown = (event: React.KeyboardEvent) => {
    event.key === "Tab" && onLineExit && onLineExit();
  };

  return (
    <div className="post-detail-list-item item">
      <div>
        <DatePicker
          selectedDate={
            journalEntryLine.posting_date
              ? new Date(journalEntryLine.posting_date)
              : undefined
          }
          onSelect={handlePostingDateChange}
          required
          disabled={disabled || journalEntryLine.tax_line}
          tabIndex={tabIndex !== undefined ? tabIndex + 1 : undefined}
        />
      </div>
      <div>
        <GeneralLedgerAccountSelect
          account={
            getAccountMandatoryDimensionCombination(journalEntryLine) as any
          }
          onAccountChange={handleGeneralLedgerAccountChange}
          tabIndex={tabIndex !== undefined ? tabIndex + 2 : undefined}
          disabled={disabled || journalEntryLine.tax_line}
        />
      </div>
      <div>
        <CurrencyInput
          size="md"
          value={journalEntryLine.debit_fc || 0}
          currencyCode={journalEntryLine.currency_code}
          onCurrencyCodeChange={handleCurrencyCodeChange}
          onChange={handleDebitChange}
          required={!!journalEntryLine.account && !journalEntryLine.credit}
          disabled={
            disabled ||
            !!journalEntryLine.credit_fc ||
            (!journalEntryLine?.account && !journalEntryLine?.account_code) ||
            journalEntryLine.tax_line
          }
          tabIndex={tabIndex !== undefined ? tabIndex + 3 : undefined}
        />
      </div>
      <div>
        <CurrencyInput
          size="md"
          value={journalEntryLine.credit_fc || 0}
          currencyCode={journalEntryLine.currency_code}
          onCurrencyCodeChange={handleCurrencyCodeChange}
          onChange={handleCreditChange}
          required={!!journalEntryLine.account && !journalEntryLine.debit}
          disabled={
            disabled ||
            !!journalEntryLine.debit_fc ||
            (!journalEntryLine?.account && !journalEntryLine?.account_code) ||
            journalEntryLine.tax_line
          }
          tabIndex={tabIndex !== undefined ? tabIndex + 4 : undefined}
        />
      </div>
      <div>
        <TaxCodeSelect
          taxCode={taxCodesQuery.data?.tax_codes.find(
            (t) => t.id === journalEntryLine.tax_code_id
          )}
          onTaxCodeChange={handleTaxCodeChange}
          taxCategory={getTaxCategory(journalEntryLine)}
          disabled={
            disabled ||
            !journalEntryLine?.account_code ||
            journalEntryLine?.tax_line
          }
          tabIndex={tabIndex !== undefined ? tabIndex + 5 : undefined}
        />
      </div>
      <div>
        <Input
          size="md"
          value={journalEntryLine.description || ""}
          onChange={handleDescriptionChange}
          onKeyDown={(e: any) => {
            handleDescriptionKeyDown(e);
          }}
          debounceTime={500}
          disabled={
            disabled ||
            !journalEntryLine.account_id ||
            journalEntryLine.tax_line
          }
          tabIndex={tabIndex !== undefined ? tabIndex + 6 : undefined}
        />
      </div>
      <div>
        <div className="label-wrap" role="button" onClick={toggleLabelsSelect}>
          {journalEntryLine.dimensions?.length ? (
            <Text
              as="span"
              placement="top"
              popover
              popoverText={dimensionsPopoverText}
              whitePopoverBackground
            >
              {journalEntryLine.dimensions.length} <IconVioletLabel />
            </Text>
          ) : (
            <span>
              0 <IconLabel />
            </span>
          )}
        </div>
      </div>
      <div>
        {!disabled && !journalEntryLine.tax_line && (
          <span
            className="clear-icon"
            role="button"
            onClick={() =>
              journalEntryDispatcher({
                type: "removeLine",
                payload: { index: lineIndex },
              })
            }
          >
            <IconClear />
          </span>
        )}
      </div>
      {!disabled && showLabelsSelect && (
        <LabelsSelectPopup
          show={showLabelsSelect}
          onHide={hideLabelsSelect}
          className="labels-select"
          dimensions={journalEntryLine?.dimensions || []}
          onDimensionsChange={handleDimensionsChange}
        />
      )}
    </div>
  );
};

export default memo(JournalEntryLineItem);
