import React, { useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";

import { cookie } from "../../../utils";
import { pathKeys } from "../../../constants";
import { useGetClientAccountsV2, useGetCurrentUser } from "../../../api";
import { Container, Spinner } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { ClientAccount } from "../../../types/ClientAccount";
import { FullScreenLoader } from "../../FullScreenLoader/FullScreenLoader";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const prepareClients = (clients: any[]) => {
  const userId: string = cookie.getUserId();

  return clients.map((client) => {
    const isDirectClient = client.users.some(
      (user: any) => user.user_id === userId
    );
    return {
      ...client,
      users: null,
      isDirectClient,
      amICustomer: !client.provider_contracts?.length && isDirectClient,
      contract:
        client.client_contracts && client.client_contracts.length
          ? client.client_contracts[0]
          : null,
      provider_contracts: undefined,
      client_contracts: undefined,
    } as ClientAccount;
  });
};

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { children } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const queryClient = useQueryClient();
  const userQuery = useGetCurrentUser(cookie.getUserId());
  const clientsQuery = useGetClientAccountsV2();

  const isLoading = userQuery.isLoading || clientsQuery.isLoading;
  const isError = userQuery.isError || clientsQuery.isError;
  const user = userQuery.data;
  const clients = clientsQuery.data?.data;

  useEffect(() => {
    const token = cookie.getToken();
    if (!token || isError) {
      window.location.href = pathKeys.LOGIN;
    }
  }, [location, isError, isLoading]);

  useEffect(() => {
    if (!isError && !isLoading && user && clients) {
      queryClient.setQueryData("Clients", prepareClients(clients));
      queryClient.setQueryData("User", user);
      setIsLoaded(true);
    }
  }, [isLoading, isError]);

  if (isLoading || !isLoaded) {
    return <FullScreenLoader />;
  }

  return <div className="protected-route-wrapper">{children}</div>;
};

export default memo(ProtectedRoute);
