import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  CheckBox,
  DateIntervalPicker,
  GridView,
} from "../../../components/commons";
import { ReportWrap } from "../../../components";
import { decimalFormat, getReportDateRanges } from "../../../utils/common";

import { ClientAccountContext } from "../../App";

import "./style.scss";
import { UseQueryResult } from "react-query";
import { authenticatedApiV2Client } from "../../../api/apiClients";
import { useGetTrialBalanceReport } from "../../../api";
import { ClientAccount } from "../../../types/ClientAccount";
import { sumBy, some } from "lodash";

interface TrialBalanceRecord {
  grouping: {
    level_1_code: string;
    level_1_name: string;
    level_2_code: string;
    level_2_name: string;
    level_3_code: string;
    level_3_name: string;
  };
  account_code: string;
  account_name: string;
  relation_id?: number;
  relation_name?: string;
  debit: number;
  credit: number;
  incoming_balance: number;
  outgoing_balance: number;
}

interface TrialBalanceGroupRecord {
  code: string;
  name?: string;
  debit?: number;
  credit?: number;
  incoming_balance?: number;
  outgoing_balance?: number;
}

const TrialBalanceReport = () => {
  const { t } = useTranslation();
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  const reportDateRanges = getReportDateRanges();

  const [startDate, changeStartDate] = useState(reportDateRanges[0]);
  const [endDate, changeEndDate] = useState(reportDateRanges[1]);
  const [isShowDimensionLevel, setIsShowDimensionLevel] = useState(false);
  const [isShowZeroMovement, setIsShowZeroMovement] = useState(true);

  const [parsedReport, changeParsedReport] = useState<any[]>([]);

  const reportParams = {
    client_account_id: clientAccount?.id,
    date_from: moment(startDate).format("YYYY-MM-DD"),
    date_to: moment(endDate).format("YYYY-MM-DD"),
    include_dimensions: isShowDimensionLevel,
    include_zero_movement: isShowZeroMovement,
  };
  const { data: trialBalanceReport, isLoading: trialBalanceReportFetching } =
    useGetTrialBalanceReport(reportParams) as UseQueryResult<
      TrialBalanceRecord[],
      boolean
    >;

  useEffect(() => {
    if (trialBalanceReport) {
      const res = parseRawData();
      if (res !== parsedReport) {
        changeParsedReport(res || []);
      }
    }
  }, [trialBalanceReport]);

  const parseRawData = () => {
    if (trialBalanceReport && clientAccount?.accounting_currency) {
      const res: any = [];

      const addRecord = (
        level: number,
        description: string,
        debit?: number,
        credit?: number,
        incoming_balance?: number,
        outgoing_balance?: number,
        _class?: string
      ) => {
        res.push({
          level,
          description,
          debit: debit ? decimalFormat(debit, 0) : 0 || "",
          credit: credit ? decimalFormat(credit, 0) : 0 || "",
          incoming_balance: incoming_balance
            ? decimalFormat(incoming_balance, 0)
            : 0 || "",
          outgoing_balance: outgoing_balance
            ? decimalFormat(outgoing_balance, 0)
            : 0 || "",
          class: _class,
        });
      };

      let current_category_0 = {
        code: "",
      } as TrialBalanceGroupRecord;
      let current_category_1 = {
        code: "",
      } as TrialBalanceGroupRecord;
      let current_category_2 = {
        code: "",
      } as TrialBalanceGroupRecord;
      let current_category_3 = {
        code: "",
      } as TrialBalanceGroupRecord;

      trialBalanceReport.forEach((record) => {
        if (record.grouping.level_3_code !== current_category_3.code) {
          if (record.grouping.level_2_code !== current_category_2.code) {
            if (record.grouping.level_1_code !== current_category_1.code) {
              // Add group header
              addRecord(
                1,
                record.grouping.level_1_name,
                sumBy(trialBalanceReport, (r) => {
                  return r.grouping.level_1_code ===
                    record.grouping.level_1_code
                    ? r.debit
                    : 0;
                }),
                sumBy(trialBalanceReport, (r) => {
                  return r.grouping.level_1_code ===
                    record.grouping.level_1_code
                    ? r.credit
                    : 0;
                }),
                sumBy(trialBalanceReport, (r) => {
                  return r.grouping.level_1_code ===
                    record.grouping.level_1_code
                    ? r.incoming_balance
                    : 0;
                }),
                sumBy(trialBalanceReport, (r) => {
                  return r.grouping.level_1_code ===
                    record.grouping.level_1_code
                    ? r.outgoing_balance
                    : 0;
                }),
                "category"
              );
            }

            // Add new group level 2 header
            addRecord(
              2,
              record.grouping.level_2_name,
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_2_code === record.grouping.level_2_code
                  ? r.debit
                  : 0;
              }),
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_2_code === record.grouping.level_2_code
                  ? r.credit
                  : 0;
              }),
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_2_code === record.grouping.level_2_code
                  ? r.incoming_balance
                  : 0;
              }),
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_2_code === record.grouping.level_2_code
                  ? r.outgoing_balance
                  : 0;
              }),
              "is-indent"
            );
          }

          // Add new group level 3 header
          const movement = sumBy(trialBalanceReport, (r) => {
            return r.grouping.level_3_code === record.grouping.level_3_code
              ? r.debit - r.credit
              : 0;
          });
          if (isShowZeroMovement || Math.abs(movement) > 0)
            addRecord(
              3,
              `${record.grouping.level_3_code} - ${record.grouping.level_3_name}`,
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_3_code === record.grouping.level_3_code
                  ? r.debit
                  : 0;
              }),
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_3_code === record.grouping.level_3_code
                  ? r.credit
                  : 0;
              }),
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_3_code === record.grouping.level_3_code
                  ? r.incoming_balance
                  : 0;
              }),
              sumBy(trialBalanceReport, (r) => {
                return r.grouping.level_3_code === record.grouping.level_3_code
                  ? r.outgoing_balance
                  : 0;
              }),
              "is-indent-2"
            );
        }

        const movement = sumBy(trialBalanceReport, (r) => {
          return r.account_code === record.account_code &&
            r.relation_id === record.relation_id
            ? r.debit - r.credit
            : 0;
        });
        if (
          isShowDimensionLevel &&
          some(trialBalanceReport, (r) => {
            return r.account_code === record.account_code && r.relation_id;
          }) &&
          (isShowZeroMovement || Math.abs(movement) > 0)
        )
          addRecord(
            4,
            record.relation_name || "No dimension",
            record.debit,
            record.credit,
            record.incoming_balance,
            record.outgoing_balance,
            "is-indent-3"
          );

        current_category_0 = {
          code: "",
          debit: (current_category_0.debit || 0) + record.debit,
          credit: (current_category_0.credit || 0) + record.credit,
          incoming_balance:
            (current_category_0.incoming_balance || 0) +
            record.incoming_balance,
          outgoing_balance:
            (current_category_0.outgoing_balance || 0) +
            record.outgoing_balance,
        };
        current_category_1 = {
          code: record.grouping.level_1_code,
          name: record.grouping.level_1_name,
          debit: (current_category_1.debit || 0) + record.debit,
          credit: (current_category_1.credit || 0) + record.credit,
          incoming_balance:
            (current_category_1.incoming_balance || 0) +
            record.incoming_balance,
          outgoing_balance:
            (current_category_1.outgoing_balance || 0) +
            record.outgoing_balance,
        };

        current_category_2 = {
          code: record.grouping.level_2_code,
          name: record.grouping.level_2_name,
          debit: (current_category_2.debit || 0) + record.debit,
          credit: (current_category_2.credit || 0) + record.credit,
          incoming_balance:
            (current_category_2.incoming_balance || 0) +
            record.incoming_balance,
          outgoing_balance:
            (current_category_2.outgoing_balance || 0) +
            record.outgoing_balance,
        };

        current_category_3 = {
          code: record.grouping.level_3_code,
          name: `${record.grouping.level_3_code} - ${record.grouping.level_3_name}`,
          debit: (current_category_3.debit || 0) + record.debit,
          credit: (current_category_3.credit || 0) + record.credit,
          incoming_balance:
            (current_category_3.incoming_balance || 0) +
            record.incoming_balance,
          outgoing_balance:
            (current_category_3.outgoing_balance || 0) +
            record.outgoing_balance,
        };
      });

      return res;
    }
  };

  const exportOptions: any = {
    EXCEL: {
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      filename: "Balance sheet.xlsx",
    },
    PDF: { mimeType: "application/pdf", filename: "Balance sheet.pdf" },
  };

  const onExportClicked = async (exportOption: string) => {
    const response = await authenticatedApiV2Client.get(
      "/reports/trial-balance",
      {
        responseType: "blob",
        headers: {
          Accept: exportOptions[exportOption].mimeType,
        },
        params: reportParams,
      }
    );

    const exportURL = window.URL.createObjectURL(response.data);
    const tempLink = document.createElement("a");
    tempLink.href = exportURL;
    tempLink.setAttribute("download", exportOptions[exportOption].filename);
    tempLink.click();
  };

  return (
    <ReportWrap
      className="trial-balance-report"
      title={t("trial_balance.title")}
      onExportClicked={onExportClicked}
      exportOptions={Object.keys(exportOptions)}
      filterItems={
        <>
          <div className="filter-row">
            <DateIntervalPicker
              dateFormat="dd/MM/yyyy"
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={changeStartDate}
              onChangeEndDate={changeEndDate}
            />
            <CheckBox
              checked={isShowDimensionLevel}
              onClick={() => setIsShowDimensionLevel(!isShowDimensionLevel)}
            >
              {t("Show dimensions")}
            </CheckBox>
            <CheckBox
              checked={isShowZeroMovement}
              onClick={() => setIsShowZeroMovement(!isShowZeroMovement)}
            >
              {t("Show 0 movement")}
            </CheckBox>
          </div>
        </>
      }
      data={
        <GridView
          columns={[
            {
              Header: t("balance_sheet.gridview.description"),
              accessor: "description",
              align: "left",
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <div
                  style={{
                    marginLeft: `${
                      Math.max(0, cellProps.row.original.level - 1) * 30
                    }px`,
                    fontWeight: `${
                      Math.abs(4 - cellProps.row.original.level) * 300
                    }`,
                  }}
                >
                  {cellProps.value}
                </div>
              ),
            },
            {
              Header: t("IB {{currency}}", {
                currency: clientAccount?.accounting_currency,
              }),
              accessor: "incoming_balance",
              align: "right",
              maxWidth: 150,
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {cellProps.value}
                </span>
              ),
            },
            {
              Header: t("Debit {{currency}}", {
                currency: clientAccount?.accounting_currency,
              }),
              accessor: "debit",
              align: "right",
              maxWidth: 150,
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {cellProps.value}
                </span>
              ),
            },
            {
              Header: t("Credit {{currency}}", {
                currency: clientAccount?.accounting_currency,
              }),
              accessor: "credit",
              align: "right",
              maxWidth: 150,
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {cellProps.value}
                </span>
              ),
            },
            {
              Header: t("OB {{currency}}", {
                currency: clientAccount?.accounting_currency,
              }),
              accessor: "outgoing_balance",
              align: "right",
              maxWidth: 150,
              classCustom: "is-underline",
              Cell: (cellProps: any) => (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  {cellProps.value}
                </span>
              ),
            },
          ]}
          data={parsedReport}
          loading={trialBalanceReportFetching}
          relativeRowHeight={30}
          emptyStateTitle=""
          stickyHeader
        />
      }
    />
  );
};

export default TrialBalanceReport;
