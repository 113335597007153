import { round } from "lodash";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useGetGeneralLedgerAccounts } from "../../api/accountingApi";
import { authenticatedApiClient } from "../../api/apiClients";
import { IconAdd } from "../../assests/icons";
import { ClientAccount } from "../../types/ClientAccount";
import { GeneralLedgerAccountSuggestion } from "../../types/GeneralLedgerAccount";
import { ClientAccountContext } from "../../views/App";
import { Text } from "../commons";

interface AccountSuggestionsProps {
  documentType?: string;
  documentId?: number;
  businessPartnerId?: number;
  onSuggestionClick?: (s: GeneralLedgerAccountSuggestion) => void;
}

const AccountSuggestions = (props: AccountSuggestionsProps) => {
  const { t } = useTranslation();

  // Context state
  const clientAccount = useContext<ClientAccount>(ClientAccountContext);

  const accountSuggestionsQuery = useQuery(
    [
      "accounting-account-suggestions",
      {
        client_account_id: clientAccount?.id,
        doc_type: props.documentType,
        document_id: props.documentId,
        business_partner_id: props.businessPartnerId,
      },
    ],
    async () => {
      if (
        !clientAccount ||
        !props.documentType ||
        !(props.documentId || props.businessPartnerId)
      ) {
        return {
          account_suggestions: [],
        };
      }
      const res = await authenticatedApiClient.get(
        "/accounting-account-suggestions/",
        {
          params: {
            client_account_id: clientAccount?.id,
            doc_type: props.documentType,
            document_id: props.documentId,
            business_partner_id: props.businessPartnerId,
          },
        }
      );
      return res.data as {
        account_suggestions: GeneralLedgerAccountSuggestion[];
      };
    }
  );

  const generalLedgerAccountsQuery = useGetGeneralLedgerAccounts(
    clientAccount?.id
  );

  const getAccountSuggestionPopoverText = (
    suggestion: GeneralLedgerAccountSuggestion
  ) => {
    return `
      <div>
        <p><b>${suggestion.account_code || suggestion.account_tax_code} ${
      generalLedgerAccountsQuery.data?.accounting_accounts.find(
        (a) => a.id === suggestion.account_code_id
      )?.description_key || ""
    }</b></p>
      ${
        (
          {
            client: t("post_detail.the_account_have_been_used_client", {
              account_code: suggestion.account_code,
              tax_code: suggestion.tax_code,
              count: suggestion.count,
            }),
            organization: t(
              "post_detail.the_account_have_been_used_organization",
              {
                account_code: suggestion.account_code,
                tax_code: suggestion.tax_code,
                count: suggestion.count,
              }
            ),
            industry: t("post_detail.the_account_have_been_used_industry", {
              account_code: suggestion.account_code,
              tax_code: suggestion.tax_code,
              count: suggestion.count,
            }),
            ml: t("post_detail.the_account_have_been_used_ml", {
              account_tax_code: suggestion.account_tax_code,
              probability: round(suggestion.probability * 100),
            }),
          } as any
        )[suggestion.match_type as string]
      }
      </div>`;
  };

  if (accountSuggestionsQuery.isLoading)
    return <span>Loading account suggestions 😘</span>;

  return (
    <div style={{ gridColumn: "span 4" }}>
      {accountSuggestionsQuery.data?.account_suggestions
        ?.slice(0, 3)
        .map((suggestion) => (
          <span
            key={`${suggestion.account_code_id}|${suggestion.tax_code_id}|${suggestion.match_type}`}
            className="add-icon pill"
            style={{ marginRight: 15 }}
            role="button"
            onClick={() =>
              props.onSuggestionClick && props.onSuggestionClick(suggestion)
            }
          >
            <Text
              as="span"
              placement="top"
              popover
              popoverText={getAccountSuggestionPopoverText(suggestion)}
              whitePopoverBackground
            >
              <IconAdd /> {suggestion.account_code} |{" "}
              {suggestion.tax_code || "-"}
            </Text>
          </span>
        ))}
    </div>
  );
};

export default memo(AccountSuggestions);
