const BOOKED = 'Booked'
const PAID = 'Paid'
const PENDING = 'Pending'
const DRAFT = 'Draft'
const ARCHIVED = 'Archived'
const PENDING_ANSWER = 'PENDING_ANSWER'


const ARRAY_DOCUMENT_STATUSES = [
  { label: 'document_statuses.PAID', value: 'PAID' },
  { label: 'document_statuses.DRAFT', value: 'DRAFT' },
  { label: 'document_statuses.PENDING_ANSWER', value: 'Waiting for Answer' },
  { label: 'document_statuses.PENDING', value: 'PENDING' },
  { label: 'document_statuses.BOOKED', value: 'BOOKED' },
  { label: 'document_statuses.ARCHIVED', value: 'ARCHIVED'}
]

export default {
  BOOKED,
  PAID,
  PENDING,
  DRAFT,
  ARCHIVED,
  PENDING_ANSWER,
  toArray: () => ARRAY_DOCUMENT_STATUSES
}
