import { useQuery } from 'react-query';

import { authenticatedApiClient, throwErrorByStatus } from './apiClients';

export function useGetExchangeRates(query) {
  return useQuery(['exchangeRates', query],
    async () => {
      const res = await authenticatedApiClient.get(`/exchange-rates/`, { params: query });
      throwErrorByStatus(res);
      return res.data.exchange_rate;
    }, { enabled: !!query });
}
