import React from 'react'
import ContentLoader from 'react-content-loader'
import './style.scss'

const Loader = () => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
    height="600px"
  >
    <rect x="20" y="0" rx="5" ry="5" width="642" height="464" />

    <rect x="682" y="10" rx="5" ry="5" width="500" height="30" />
    <rect x="682" y="60" rx="5" ry="5" width="250" height="30" />
    <rect x="682" y="96" rx="5" ry="5" width="602" height="368" />

    <rect x="20" y="484" rx="5" ry="5" width="1264" height="322" />
  </ContentLoader>
)

export default Loader
